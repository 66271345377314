.pf-v5-c-avatar {
  --pf-v5-c-avatar--BorderColor: transparent;
  --pf-v5-c-avatar--BorderWidth: 0;
  --pf-v5-c-avatar--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-avatar--Width: 2.25rem;
  --pf-v5-c-avatar--Height: 2.25rem;
  --pf-v5-c-avatar--m-sm--Width: 1.5rem;
  --pf-v5-c-avatar--m-sm--Height: 1.5rem;
  --pf-v5-c-avatar--m-md--Width: 2.25rem;
  --pf-v5-c-avatar--m-md--Height: 2.25rem;
  --pf-v5-c-avatar--m-lg--Width: 4.5rem;
  --pf-v5-c-avatar--m-lg--Height: 4.5rem;
  --pf-v5-c-avatar--m-xl--Width: 8rem;
  --pf-v5-c-avatar--m-xl--Height: 8rem;
  --pf-v5-c-avatar--m-light--BorderColor: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-c-avatar--m-light--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-avatar--m-dark--BorderColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-avatar--m-dark--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  width: var(--pf-v5-c-avatar--Width);
  height: var(--pf-v5-c-avatar--Height);
  border: var(--pf-v5-c-avatar--BorderWidth) solid var(--pf-v5-c-avatar--BorderColor);
  border-radius: var(--pf-v5-c-avatar--BorderRadius);
}

.pf-v5-c-avatar.pf-m-light {
  --pf-v5-c-avatar--BorderColor: var(--pf-v5-c-avatar--m-light--BorderColor);
  --pf-v5-c-avatar--BorderWidth: var(--pf-v5-c-avatar--m-light--BorderWidth);
}

.pf-v5-c-avatar.pf-m-dark {
  --pf-v5-c-avatar--BorderColor: var(--pf-v5-c-avatar--m-dark--BorderColor);
  --pf-v5-c-avatar--BorderWidth: var(--pf-v5-c-avatar--m-dark--BorderWidth);
}

.pf-v5-c-avatar.pf-m-sm {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height);
}

@media (width >= 576px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width));
  }
}

@media (width >= 768px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-lg, var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-xl, var(--pf-v5-c-avatar--m-sm--Width-on-lg, var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-2xl, var(--pf-v5-c-avatar--m-sm--Width-on-xl, var(--pf-v5-c-avatar--m-sm--Width-on-lg, var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width))))));
  }
}

@media (width >= 576px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height));
  }
}

@media (width >= 768px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-lg, var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-xl, var(--pf-v5-c-avatar--m-sm--Height-on-lg, var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-2xl, var(--pf-v5-c-avatar--m-sm--Height-on-xl, var(--pf-v5-c-avatar--m-sm--Height-on-lg, var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height))))));
  }
}

.pf-v5-c-avatar.pf-m-md {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height);
}

@media (width >= 576px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width));
  }
}

@media (width >= 768px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-lg, var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-xl, var(--pf-v5-c-avatar--m-md--Width-on-lg, var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-2xl, var(--pf-v5-c-avatar--m-md--Width-on-xl, var(--pf-v5-c-avatar--m-md--Width-on-lg, var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width))))));
  }
}

@media (width >= 576px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height));
  }
}

@media (width >= 768px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-lg, var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-xl, var(--pf-v5-c-avatar--m-md--Height-on-lg, var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-2xl, var(--pf-v5-c-avatar--m-md--Height-on-xl, var(--pf-v5-c-avatar--m-md--Height-on-lg, var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height))))));
  }
}

.pf-v5-c-avatar.pf-m-lg {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height);
}

@media (width >= 576px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width));
  }
}

@media (width >= 768px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-lg, var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-xl, var(--pf-v5-c-avatar--m-lg--Width-on-lg, var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-2xl, var(--pf-v5-c-avatar--m-lg--Width-on-xl, var(--pf-v5-c-avatar--m-lg--Width-on-lg, var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width))))));
  }
}

@media (width >= 576px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height));
  }
}

@media (width >= 768px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-lg, var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-xl, var(--pf-v5-c-avatar--m-lg--Height-on-lg, var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-2xl, var(--pf-v5-c-avatar--m-lg--Height-on-xl, var(--pf-v5-c-avatar--m-lg--Height-on-lg, var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height))))));
  }
}

.pf-v5-c-avatar.pf-m-xl {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height);
}

@media (width >= 576px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width));
  }
}

@media (width >= 768px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-lg, var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-xl, var(--pf-v5-c-avatar--m-xl--Width-on-lg, var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-2xl, var(--pf-v5-c-avatar--m-xl--Width-on-xl, var(--pf-v5-c-avatar--m-xl--Width-on-lg, var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width))))));
  }
}

@media (width >= 576px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height));
  }
}

@media (width >= 768px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-lg, var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-xl, var(--pf-v5-c-avatar--m-xl--Height-on-lg, var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-2xl, var(--pf-v5-c-avatar--m-xl--Height-on-xl, var(--pf-v5-c-avatar--m-xl--Height-on-lg, var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height))))));
  }
}

.pf-v5-c-menu {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-menu__group, .pf-v5-c-menu__list-item, .pf-v5-c-menu__list {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}

.pf-m-hidden.pf-v5-c-menu__group, .pf-m-hidden.pf-v5-c-menu__list-item, .pf-m-hidden.pf-v5-c-menu__list {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}

@media screen and (width >= 576px) {
  .pf-m-hidden-on-sm.pf-v5-c-menu__group, .pf-m-hidden-on-sm.pf-v5-c-menu__list-item, .pf-m-hidden-on-sm.pf-v5-c-menu__list {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-sm.pf-v5-c-menu__group, .pf-m-visible-on-sm.pf-v5-c-menu__list-item, .pf-m-visible-on-sm.pf-v5-c-menu__list {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 768px) {
  .pf-m-hidden-on-md.pf-v5-c-menu__group, .pf-m-hidden-on-md.pf-v5-c-menu__list-item, .pf-m-hidden-on-md.pf-v5-c-menu__list {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-md.pf-v5-c-menu__group, .pf-m-visible-on-md.pf-v5-c-menu__list-item, .pf-m-visible-on-md.pf-v5-c-menu__list {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 992px) {
  .pf-m-hidden-on-lg.pf-v5-c-menu__group, .pf-m-hidden-on-lg.pf-v5-c-menu__list-item, .pf-m-hidden-on-lg.pf-v5-c-menu__list {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-lg.pf-v5-c-menu__group, .pf-m-visible-on-lg.pf-v5-c-menu__list-item, .pf-m-visible-on-lg.pf-v5-c-menu__list {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 1200px) {
  .pf-m-hidden-on-xl.pf-v5-c-menu__group, .pf-m-hidden-on-xl.pf-v5-c-menu__list-item, .pf-m-hidden-on-xl.pf-v5-c-menu__list {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-xl.pf-v5-c-menu__group, .pf-m-visible-on-xl.pf-v5-c-menu__list-item, .pf-m-visible-on-xl.pf-v5-c-menu__list {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 1450px) {
  .pf-m-hidden-on-2xl.pf-v5-c-menu__group, .pf-m-hidden-on-2xl.pf-v5-c-menu__list-item, .pf-m-hidden-on-2xl.pf-v5-c-menu__list {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-2xl.pf-v5-c-menu__group, .pf-m-visible-on-2xl.pf-v5-c-menu__list-item, .pf-m-visible-on-2xl.pf-v5-c-menu__list {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

.pf-v5-c-menu {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-menu--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-menu--MinWidth: auto;
  --pf-v5-c-menu--Width: auto;
  --pf-v5-c-menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-menu--Top: auto;
  --pf-v5-c-menu--m-flyout__menu--Top: calc(var(--pf-v5-c-menu__list--PaddingTop) * -1 + var(--pf-v5-c-menu--m-flyout__menu--top-offset));
  --pf-v5-c-menu--m-flyout__menu--Right: auto;
  --pf-v5-c-menu--m-flyout__menu--Bottom: auto;
  --pf-v5-c-menu--m-flyout__menu--Left: calc(100% + var(--pf-v5-c-menu--m-flyout__menu--left-offset));
  --pf-v5-c-menu--m-flyout__menu--m-top--Bottom: calc(var(--pf-v5-c-menu__list--PaddingTop) * -1);
  --pf-v5-c-menu--m-flyout__menu--m-left--Right: calc(100% + var(--pf-v5-c-menu--m-flyout__menu--m-left--right-offset));
  --pf-v5-c-menu--m-plain--BoxShadow: none;
  --pf-v5-c-menu--m-flyout__menu--top-offset: 0px;
  --pf-v5-c-menu--m-flyout__menu--left-offset: 0px;
  --pf-v5-c-menu--m-flyout__menu--m-left--right-offset: 0px;
  --pf-v5-c-menu__content--Height: auto;
  --pf-v5-c-menu__content--MaxHeight: none;
  --pf-v5-c-menu--m-scrollable__content--MaxHeight: 18.75rem;
  --pf-v5-c-menu--c-divider--MarginTop: 0;
  --pf-v5-c-menu--c-divider--MarginBottom: 0;
  --pf-v5-c-menu__list--c-divider--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__list--c-divider--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__header--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--c-menu__item--MarginTop: calc(var(--pf-v5-c-menu__header--PaddingTop) * -1 / 2);
  --pf-v5-c-menu__header--c-menu__item--MarginRight: calc(var(--pf-v5-c-menu__header--PaddingRight) * -1 / 2);
  --pf-v5-c-menu__header--c-menu__item--MarginBottom: calc(var(--pf-v5-c-menu__header--PaddingBottom) * -1 / 2);
  --pf-v5-c-menu__header--c-menu__item--MarginLeft: calc(var(--pf-v5-c-menu__header--PaddingLeft) * -1 / 2);
  --pf-v5-c-menu__header--c-menu__item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__header--c-menu__item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--c-menu__item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__header--c-menu__item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--c-menu__item--BackgroundColor: transparent;
  --pf-v5-c-menu__header--c-menu__item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-menu__header--c-menu__item--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-menu__search--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__search--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__search--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__search--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header__search--PaddingTop: 0;
  --pf-v5-c-menu__list--Display: block;
  --pf-v5-c-menu__list--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__list--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__list-item--Display: flex;
  --pf-v5-c-menu__list-item--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu__list-item--BackgroundColor: transparent;
  --pf-v5-c-menu__list-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-menu__list-item--m-loading--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__item--OutlineOffset: calc(.125rem * -1);
  --pf-v5-c-menu__item--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-menu__item--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-menu__item--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-menu__list-item--m-disabled__item--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-menu__list-item--m-danger__item--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-menu__list-item--m-load__item--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-menu__group--Display: block;
  --pf-v5-c-menu__group-title--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__group-title--PaddingRight: var(--pf-v5-c-menu__item--PaddingRight);
  --pf-v5-c-menu__group-title--PaddingLeft: var(--pf-v5-c-menu__item--PaddingLeft);
  --pf-v5-c-menu__group-title--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-menu__group-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-menu__group-title--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu__item-description--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-menu__item-description--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu__item-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-check--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-toggle-icon--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-toggle-icon--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__list-item--m-disabled__item-toggle-icon--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-menu__item-text--item-toggle-icon--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-toggle-icon--item-text--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-select-icon--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-select-icon--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu__item-select-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-menu__item-external-icon--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-external-icon--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-menu__item-external-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-menu__item-external-icon--Opacity: 0;
  --pf-v5-c-menu__item-action--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-action--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__item-action--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-action--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__item-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu__item-action--BackgroundColor: transparent;
  --pf-v5-c-menu__item-action--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu__item-action--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-menu__item-action--m-favorited--Color: var(--pf-v5-global--palette--gold-400);
  --pf-v5-c-menu__item-action--m-favorited--hover--Color: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-menu__item-action-icon--Height: calc(var(--pf-v5-c-menu__item--FontSize) * var(--pf-v5-c-menu__item--LineHeight));
  --pf-v5-c-menu__item-action--m-favorite__icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-menu__breadcrumb--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__breadcrumb--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__breadcrumb--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__breadcrumb--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__breadcrumb--c-breadcrumb__item--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-menu__breadcrumb--c-breadcrumb__heading--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-menu--m-drilldown--c-menu--Top: 0;
  --pf-v5-c-menu--m-drilldown--c-menu--TransitionDuration--transform: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-menu--m-drilldown--c-menu--Transition: transform var(--pf-v5-c-menu--m-drilldown--c-menu--TransitionDuration--transform);
  --pf-v5-c-menu--m-drilldown__content--TransitionDuration--height: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-menu--m-drilldown__content--TransitionDuration--transform: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-menu--m-drilldown__content--Transition: transform var(--pf-v5-c-menu--m-drilldown__content--TransitionDuration--transform), height var(--pf-v5-c-menu--m-drilldown__content--TransitionDuration--height);
  --pf-v5-c-menu--m-drilldown__list--TransitionDuration--transform: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-menu--m-drilldown__list--Transition: transform var(--pf-v5-c-menu--m-drilldown__list--TransitionDuration--transform);
  --pf-v5-c-menu--m-drilled-in--c-menu__list-item--m-current-path--c-menu--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-menu__footer--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__footer--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__footer--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__footer--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__footer--BoxShadow: none;
  --pf-v5-c-menu__footer--after--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu__footer--after--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-menu--m-scrollable__footer--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-menu--m-scrollable__footer--after--BorderTopWidth: 0;
  --pf-v5-c-menu--m-scrollable__footer--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu--m-nav--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-menu--m-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-menu--m-nav__list--PaddingTop: 0;
  --pf-v5-c-menu--m-nav__list--PaddingBottom: 0;
  --pf-v5-c-menu--m-nav__list-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-menu--m-nav__list-item--focus-within--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-menu--m-nav__list-item--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-menu--m-nav__item--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-menu--m-nav__item--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-menu--m-nav__item--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-menu--m-nav__item--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-menu--m-nav__item--OutlineOffset: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-menu--m-nav__item--before--BorderBottomColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-menu--m-nav__item--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu--m-nav__item--hover--after--BorderLeftColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-menu--m-nav__item--hover--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu--m-nav__item--hover--after--Top: calc(var(--pf-v5-c-menu--m-nav__item--before--BorderBottomWidth) * -1);
  --pf-v5-c-menu--m-nav__item__list-item--first-child__item--hover--after--Top: 0;
  --pf-v5-c-menu--m-nav__item-description--Color: var(--pf-v5-global--Color--light-200);
  --pf-v5-c-menu--m-nav--c-menu--left-offset: .25rem;
  --pf-v5-c-menu--m-nav--c-menu--m-top--bottom-offset: 0;
  --pf-v5-c-menu--m-nav--c-menu--m-left--right-offset: .25rem;
  --pf-v5-c-menu--m-nav--c-menu--Top: calc(var(--pf-v5-c-menu--m-nav__item--before--BorderBottomWidth) * -1);
  --pf-v5-c-menu--m-nav--c-menu--Left: calc(100% - var(--pf-v5-c-menu--m-nav--c-menu--left-offset));
  --pf-v5-c-menu--m-nav--c-menu--m-left--Right: calc(100% - var(--pf-v5-c-menu--m-nav--c-menu--m-left--right-offset));
  --pf-v5-c-menu--m-nav--c-menu--m-top--Bottom: calc(0 + var(--pf-v5-c-menu--m-nav--c-menu--m-top--bottom-offset));
  --pf-v5-c-menu--m-nav__list-item--first-child--c-menu--Top: 0;
  z-index: var(--pf-v5-c-menu--ZIndex);
  width: var(--pf-v5-c-menu--Width);
  min-width: var(--pf-v5-c-menu--MinWidth);
  background-color: var(--pf-v5-c-menu--BackgroundColor);
  box-shadow: var(--pf-v5-c-menu--BoxShadow);
  inset-block-start: var(--pf-v5-c-menu--Top);
}

.pf-v5-c-menu .pf-v5-c-menu__content .pf-v5-c-menu .pf-v5-c-menu__content {
  overflow: visible;
}

.pf-v5-c-menu .pf-v5-c-divider {
  margin-block-start: var(--pf-v5-c-menu--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-menu--c-divider--MarginBottom);
}

.pf-v5-c-menu .pf-v5-c-menu.pf-m-flyout, .pf-v5-c-menu.pf-m-flyout .pf-v5-c-menu {
  position: absolute;
  inset-block-start: var(--pf-v5-c-menu--m-flyout__menu--Top);
  inset-block-end: var(--pf-v5-c-menu--m-flyout__menu--Bottom);
  inset-inline-start: var(--pf-v5-c-menu--m-flyout__menu--Left);
  inset-inline-end: var(--pf-v5-c-menu--m-flyout__menu--Right);
}

.pf-v5-c-menu .pf-v5-c-menu.pf-m-flyout .pf-v5-c-menu__content, .pf-v5-c-menu.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu__content {
  overflow-y: visible;
}

.pf-v5-c-menu.pf-m-top {
  --pf-v5-c-menu--m-flyout__menu--Top: auto;
  --pf-v5-c-menu--m-flyout__menu--Bottom: var(--pf-v5-c-menu--m-flyout__menu--m-top--Bottom);
}

.pf-v5-c-menu.pf-m-left {
  --pf-v5-c-menu--m-flyout__menu--Right: var(--pf-v5-c-menu--m-flyout__menu--m-left--Right);
  --pf-v5-c-menu--m-flyout__menu--Left: auto;
}

.pf-v5-c-menu.pf-m-drilldown {
  flex-direction: column;
  display: flex;
}

.pf-v5-c-menu.pf-m-drilldown[hidden] {
  display: none;
}

.pf-v5-c-menu.pf-m-drilldown > .pf-v5-c-menu__content {
  transition: var(--pf-v5-c-menu--m-drilldown__content--Transition);
  flex-grow: 1;
  overflow: hidden;
}

.pf-v5-c-menu.pf-m-drilldown.pf-m-drilled-in > .pf-v5-c-menu__content > .pf-v5-c-menu__list, .pf-v5-c-menu.pf-m-drilldown.pf-m-drilled-in > .pf-v5-c-menu__list {
  transform: translateX(-100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-menu.pf-m-drilldown.pf-m-drilled-in > .pf-v5-c-menu__content > .pf-v5-c-menu__list, .pf-v5-c-menu.pf-m-drilldown.pf-m-drilled-in > .pf-v5-c-menu__list {
  transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-menu.pf-m-drilldown > .pf-v5-c-menu__content .pf-v5-c-menu {
  --pf-v5-c-menu--BoxShadow: none;
  transition: var(--pf-v5-c-menu--m-drilldown--c-menu--Transition);
  width: 100%;
  position: absolute;
  inset-block-start: var(--pf-v5-c-menu--m-drilldown--c-menu--Top);
  inset-inline-start: 100%;
}

.pf-v5-c-menu.pf-m-drilldown > .pf-v5-c-menu__content .pf-v5-c-menu.pf-m-drilled-in {
  transform: translateX(-100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-menu.pf-m-drilldown > .pf-v5-c-menu__content .pf-v5-c-menu.pf-m-drilled-in {
  transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list {
  visibility: visible;
  transition: var(--pf-v5-c-menu--m-drilldown__list--Transition);
  position: relative;
  overflow: hidden;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list .pf-v5-c-menu__list {
  --pf-v5-c-menu__list--PaddingTop: 0;
  --pf-v5-c-menu__list--PaddingBottom: 0;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list-item.pf-m-current-path .pf-v5-c-menu {
  z-index: var(--pf-v5-c-menu--m-drilled-in--c-menu__list-item--m-current-path--c-menu--ZIndex);
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list-item.pf-m-current-path > .pf-v5-c-menu {
  overflow: visible;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list-item.pf-m-static > .pf-v5-c-menu {
  position: static;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list-item.pf-m-static:hover {
  background-color: #0000;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list-item:not(.pf-m-current-path) .pf-v5-c-menu {
  display: none;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__item {
  outline-offset: var(--pf-v5-c-menu__item--OutlineOffset);
}

.pf-v5-c-menu.pf-m-drilled-in > .pf-v5-c-menu__content > .pf-v5-c-menu__list {
  visibility: hidden;
  overflow: visible;
}

.pf-v5-c-menu.pf-m-drilled-in > .pf-v5-c-menu__content > .pf-v5-c-menu__list > .pf-v5-c-divider, .pf-v5-c-menu.pf-m-drilled-in > .pf-v5-c-menu__content > .pf-v5-c-menu__list > .pf-v5-c-menu__list-item:not(.pf-m-current-path) {
  display: none;
}

.pf-v5-c-menu.pf-m-drilled-in > .pf-v5-c-menu__content > .pf-v5-c-menu__list > .pf-v5-c-menu__list-item.pf-m-current-path {
  visibility: hidden;
}

.pf-v5-c-menu.pf-m-plain {
  --pf-v5-c-menu--BoxShadow: var(--pf-v5-c-menu--m-plain--BoxShadow);
}

.pf-v5-c-menu.pf-m-scrollable {
  --pf-v5-c-menu__content--MaxHeight: var(--pf-v5-c-menu--m-scrollable__content--MaxHeight);
  --pf-v5-c-menu__footer--BoxShadow: var(--pf-v5-c-menu--m-scrollable__footer--BoxShadow);
  --pf-v5-c-menu__footer--after--BorderTopWidth: var(--pf-v5-c-menu--m-scrollable__footer--after--BorderTopWidth);
}

.pf-v5-c-menu.pf-m-scrollable .pf-v5-c-menu__content {
  overflow-y: auto;
}

.pf-v5-c-menu.pf-m-nav, .pf-v5-c-menu.pf-m-nav .pf-v5-c-menu {
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-c-menu--m-nav--BackgroundColor);
  --pf-v5-c-menu__list--PaddingTop: var(--pf-v5-c-menu--m-nav__list--PaddingTop);
  --pf-v5-c-menu__list--PaddingBottom: var(--pf-v5-c-menu--m-nav__list--PaddingBottom);
  --pf-v5-c-menu__list-item--hover--BackgroundColor: var(--pf-v5-c-menu--m-nav__list-item--hover--BackgroundColor);
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-c-menu--m-nav__list-item--focus-within--BackgroundColor);
  --pf-v5-c-menu__list-item--active--BackgroundColor: var(--pf-v5-c-menu--m-nav__list-item--active--BackgroundColor);
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-menu--m-nav__item--Color);
  --pf-v5-c-menu__item--FontSize: var(--pf-v5-c-menu--m-nav__item--FontSize);
  --pf-v5-c-menu__item--OutlineOffset: var(--pf-v5-c-menu--m-nav__item--OutlineOffset);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-c-menu--m-nav__item--PaddingRight);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-c-menu--m-nav__item--PaddingLeft);
  --pf-v5-c-menu__item-description--Color: var(--pf-v5-c-menu--m-nav__item-description--Color);
  box-shadow: var(--pf-v5-c-menu--m-nav--BoxShadow);
}

.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu__item, .pf-v5-c-menu.pf-m-nav .pf-v5-c-menu .pf-v5-c-menu__item {
  outline-offset: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--OutlineOffset);
  position: relative;
}

.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu__item:before, .pf-v5-c-menu.pf-m-nav .pf-v5-c-menu .pf-v5-c-menu__item:before {
  content: "";
  border-block-end: var(--pf-v5-c-menu--m-nav__item--before--BorderBottomWidth) solid var(--pf-v5-c-menu--m-nav__item--before--BorderBottomColor);
  position: absolute;
  inset-block-end: 0;
  inset-inline: 0;
}

.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu__item:hover:after, .pf-v5-c-menu.pf-m-nav .pf-v5-c-menu .pf-v5-c-menu__item:hover:after {
  content: "";
  border-inline-start: var(--pf-v5-c-menu--m-nav__item--hover--after--BorderLeftWidth) solid var(--pf-v5-c-menu--m-nav__item--hover--after--BorderLeftColor);
  position: absolute;
  inset-block-start: var(--pf-v5-c-menu--m-nav__item--hover--after--Top);
  inset-block-end: 0;
  inset-inline-start: 0;
}

.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu {
  width: 100%;
}

.pf-v5-c-menu.pf-m-flyout.pf-m-nav, .pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu {
  inset-block-start: var(--pf-v5-c-menu--m-nav--c-menu--Top);
  inset-inline-start: var(--pf-v5-c-menu--m-nav--c-menu--Left);
}

.pf-v5-c-menu.pf-m-flyout.pf-m-nav.pf-m-top, .pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu.pf-m-top {
  --pf-v5-c-menu--m-nav--c-menu--Top: auto;
  inset-block-end: var(--pf-v5-c-menu--m-nav--c-menu--m-top--Bottom);
}

.pf-v5-c-menu.pf-m-flyout.pf-m-nav.pf-m-left, .pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu.pf-m-left {
  --pf-v5-c-menu--m-nav--c-menu--Left: auto;
  inset-inline-end: var(--pf-v5-c-menu--m-nav--c-menu--m-left--Right);
}

.pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu__list-item:first-child, .pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu .pf-v5-c-menu__list-item:first-child {
  --pf-v5-c-menu--m-nav__item--hover--after--Top: var(--pf-v5-c-menu--m-nav__item__list-item--first-child__item--hover--after--Top);
}

.pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu__list-item:first-child .pf-v5-c-menu, .pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu .pf-v5-c-menu__list-item:first-child .pf-v5-c-menu {
  --pf-v5-c-menu--m-nav--c-menu--Top: var(--pf-v5-c-menu--m-nav__list-item--first-child--c-menu--Top);
}

.pf-v5-c-menu__breadcrumb {
  min-height: var(--pf-v5-c-menu__breadcrumb--MinHeight);
  align-items: center;
  padding-block-start: var(--pf-v5-c-menu__breadcrumb--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__breadcrumb--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__breadcrumb--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__breadcrumb--PaddingRight);
  display: flex;
}

.pf-v5-c-menu__breadcrumb .pf-v5-c-breadcrumb {
  --pf-v5-c-breadcrumb__item--FontSize: var(--pf-v5-c-menu__breadcrumb--c-breadcrumb__item--FontSize);
  --pf-v5-c-breadcrumb__heading--FontSize: var(--pf-v5-c-menu__breadcrumb--c-breadcrumb__heading--FontSize);
}

.pf-v5-c-menu__content {
  height: var(--pf-v5-c-menu__content--Height);
  max-height: var(--pf-v5-c-menu__content--MaxHeight);
}

.pf-v5-c-menu__content .pf-v5-c-menu__content {
  --pf-v5-c-menu__content--Height: auto;
}

.pf-v5-c-menu__header {
  --pf-v5-c-menu__item--PaddingTop: var(--pf-v5-c-menu__header--c-menu__item--PaddingTop);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-c-menu__header--c-menu__item--PaddingRight);
  --pf-v5-c-menu__item--PaddingBottom: var(--pf-v5-c-menu__header--c-menu__item--PaddingBottom);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-c-menu__header--c-menu__item--PaddingLeft);
  padding-block-start: var(--pf-v5-c-menu__header--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__header--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__header--PaddingRight);
}

.pf-v5-c-menu__header > .pf-v5-c-menu__item {
  --pf-v5-c-menu__item--BackgroundColor: var(--pf-v5-c-menu__header--c-menu__item--BackgroundColor);
  margin-block-start: var(--pf-v5-c-menu__header--c-menu__item--MarginTop);
  margin-block-end: var(--pf-v5-c-menu__header--c-menu__item--MarginBottom);
  margin-inline-start: var(--pf-v5-c-menu__header--c-menu__item--MarginLeft);
  margin-inline-end: var(--pf-v5-c-menu__header--c-menu__item--MarginRight);
}

.pf-v5-c-menu__header > .pf-v5-c-menu__item:hover {
  --pf-v5-c-menu__item--BackgroundColor: var(--pf-v5-c-menu__header--c-menu__item--hover--BackgroundColor);
}

.pf-v5-c-menu__header > .pf-v5-c-menu__item:focus {
  --pf-v5-c-menu__item--BackgroundColor: var(--pf-v5-c-menu__header--c-menu__item--focus--BackgroundColor);
}

.pf-v5-c-menu__header + .pf-v5-c-menu__search {
  --pf-v5-c-menu__search--PaddingTop: var(--pf-v5-c-menu__header__search--PaddingTop);
}

.pf-v5-c-menu__search {
  padding-block-start: var(--pf-v5-c-menu__search--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__search--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__search--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__search--PaddingRight);
}

.pf-v5-c-menu__list {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-menu__list--Display);
  padding-block-start: var(--pf-v5-c-menu__list--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__list--PaddingBottom);
}

.pf-v5-c-menu__list > .pf-v5-c-divider {
  margin-block-start: var(--pf-v5-c-menu__list--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-menu__list--c-divider--MarginBottom);
}

.pf-v5-c-menu__list-item {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-menu__list-item--Display);
  color: var(--pf-v5-c-menu__list-item--Color);
  background-color: var(--pf-v5-c-menu__list-item--BackgroundColor);
  position: relative;
}

.pf-v5-c-menu__list-item:hover {
  --pf-v5-c-menu__list-item--BackgroundColor: var(--pf-v5-c-menu__list-item--hover--BackgroundColor);
  --pf-v5-c-menu__list-item--Color: var(--pf-v5-c-menu__list-item--hover--Color, inherit);
}

.pf-v5-c-menu__list-item:focus-within, .pf-v5-c-menu__list-item.pf-m-focus {
  --pf-v5-c-menu__list-item--BackgroundColor: var(--pf-v5-c-menu__list-item--focus-within--BackgroundColor);
  --pf-v5-c-menu__list-item--Color: var(--pf-v5-c-menu__list-item--focus-within--Color, inherit);
}

.pf-v5-c-menu__list-item.pf-m-disabled, .pf-v5-c-menu__list-item.pf-m-aria-disabled {
  --pf-v5-c-menu__list-item--hover--BackgroundColor: transparent;
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-menu__list-item--m-disabled__item--Color);
  --pf-v5-c-menu__item-toggle-icon: var(--pf-v5-c-menu__list-item--m-disabled__item-toggle-icon--Color);
}

.pf-v5-c-menu__list-item.pf-m-disabled {
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: transparent;
  pointer-events: none;
}

.pf-v5-c-menu__list-item.pf-m-aria-disabled .pf-v5-c-menu__item {
  cursor: default;
}

.pf-v5-c-menu__list-item.pf-m-load {
  --pf-v5-c-menu__list-item--hover--BackgroundColor: transparent;
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: transparent;
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-menu__list-item--m-load__item--Color);
}

.pf-v5-c-menu__list-item.pf-m-loading {
  --pf-v5-c-menu__list-item--hover--BackgroundColor: transparent;
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: transparent;
  justify-content: center;
  padding-block-start: var(--pf-v5-c-menu__list-item--m-loading--PaddingTop);
  overflow: hidden;
}

.pf-v5-c-menu__list-item.pf-m-danger {
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-menu__list-item--m-danger__item--Color, inherit);
}

.pf-v5-c-menu__item {
  font-size: var(--pf-v5-c-menu__item--FontSize);
  font-weight: var(--pf-v5-c-menu__item--FontWeight);
  line-height: var(--pf-v5-c-menu__item--LineHeight);
  color: var(--pf-v5-c-menu__item--Color);
  text-align: start;
  background-color: var(--pf-v5-c-menu__item--BackgroundColor);
  border: none;
  flex-direction: column;
  flex-basis: 100%;
  min-width: 0;
  padding-block-start: var(--pf-v5-c-menu__item--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__item--PaddingRight);
  display: flex;
}

.pf-v5-c-menu__item:hover {
  text-decoration: none;
}

.pf-v5-c-menu__item:hover, .pf-v5-c-menu__item:focus {
  --pf-v5-c-menu__item-external-icon--Opacity: 1;
}

.pf-v5-c-menu__item.pf-m-selected .pf-v5-c-menu__item-select-icon {
  opacity: 1;
}

label.pf-v5-c-menu__item:where(:not([disabled], .pf-m-disabled, .pf-m-aria-disabled)) {
  cursor: pointer;
}

.pf-v5-c-menu__item-main {
  align-items: center;
  width: 100%;
  display: flex;
}

.pf-v5-c-menu__item-external-icon {
  font-size: var(--pf-v5-c-menu__item-external-icon--FontSize);
  color: var(--pf-v5-c-menu__item-external-icon--Color);
  opacity: var(--pf-v5-c-menu__item-external-icon--Opacity);
  margin-inline-start: var(--pf-v5-c-menu__item-external-icon--MarginLeft);
}

.pf-v5-c-menu__item-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  overflow: hidden;
}

.pf-v5-c-menu__group {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-menu__group--Display);
}

.pf-v5-c-menu__group-title {
  font-size: var(--pf-v5-c-menu__group-title--FontSize);
  font-weight: var(--pf-v5-c-menu__group-title--FontWeight);
  color: var(--pf-v5-c-menu__group-title--Color);
  padding-block-start: var(--pf-v5-c-menu__group-title--PaddingTop);
  padding-inline-start: var(--pf-v5-c-menu__group-title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__group-title--PaddingRight);
}

.pf-v5-c-menu__item-description {
  font-size: var(--pf-v5-c-menu__item-description--FontSize);
  color: var(--pf-v5-c-menu__item-description--Color);
  word-break: break-word;
}

.pf-v5-c-menu__item-icon {
  margin-inline-end: var(--pf-v5-c-menu__item-icon--MarginRight);
}

.pf-v5-c-menu__item-check {
  align-items: center;
  margin-inline-end: var(--pf-v5-c-menu__item-check--MarginRight);
  display: flex;
}

.pf-v5-c-menu__item-check .pf-v5-c-check {
  --pf-v5-c-check__input--TranslateY: none;
}

.pf-v5-c-menu__item-toggle-icon {
  color: var(--pf-v5-c-menu__item-toggle-icon, inherit);
  padding-inline-start: var(--pf-v5-c-menu__item-toggle-icon--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__item-toggle-icon--PaddingRight);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-menu__item-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-menu__item-text + .pf-v5-c-menu__item-toggle-icon {
  margin-inline-start: var(--pf-v5-c-menu__item-text--item-toggle-icon--MarginLeft);
}

.pf-v5-c-menu__item-toggle-icon + .pf-v5-c-menu__item-text {
  margin-inline-start: var(--pf-v5-c-menu__item-toggle-icon--item-text--MarginLeft);
}

.pf-v5-c-menu__item-select-icon {
  font-size: var(--pf-v5-c-menu__item-select-icon--FontSize);
  color: var(--pf-v5-c-menu__item-select-icon--Color);
  opacity: 0;
  margin-inline-start: var(--pf-v5-c-menu__item-select-icon--MarginLeft);
}

.pf-v5-c-menu__item-action {
  color: var(--pf-v5-c-menu__item-action--Color);
  background-color: var(--pf-v5-c-menu__item-action--BackgroundColor);
  border: none;
  padding-block-start: var(--pf-v5-c-menu__item-action--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__item-action--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__item-action--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__item-action--PaddingRight);
  display: flex;
}

.pf-v5-c-menu__item-action.pf-m-favorite .pf-v5-c-menu__item-action-icon {
  font-size: var(--pf-v5-c-menu__item-action--m-favorite__icon--FontSize);
}

.pf-v5-c-menu__item-action.pf-m-favorited {
  --pf-v5-c-menu__item-action--Color: var(--pf-v5-c-menu__item-action--m-favorited--Color);
  --pf-v5-c-menu__item-action--hover--Color: var(--pf-v5-c-menu__item-action--m-favorited--hover--Color);
}

.pf-v5-c-menu__item-action:hover, .pf-v5-c-menu__item-action:focus {
  --pf-v5-c-menu__item-action--Color: var(--pf-v5-c-menu__item-action--hover--Color);
}

.pf-v5-c-menu__item-action:disabled, .pf-v5-c-menu__item-action.pf-m-disabled, .pf-v5-c-menu__item-action.pf-m-aria-disabled {
  --pf-v5-c-menu__item-action--Color: var(--pf-v5-c-menu__item-action--disabled--Color);
}

.pf-v5-c-menu__item-action-icon {
  height: var(--pf-v5-c-menu__item-action-icon--Height);
  align-items: center;
  display: flex;
}

.pf-v5-c-menu__footer {
  box-shadow: var(--pf-v5-c-menu__footer--BoxShadow);
  padding-block-start: var(--pf-v5-c-menu__footer--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__footer--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__footer--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__footer--PaddingRight);
  position: relative;
}

.pf-v5-c-menu__footer:after {
  pointer-events: none;
  content: "";
  border-block-start: var(--pf-v5-c-menu__footer--after--BorderTopWidth) solid var(--pf-v5-c-menu__footer--after--BorderTopColor);
  position: absolute;
  inset: 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-menu {
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-menu__list-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}

.pf-v5-c-breadcrumb {
  --pf-v5-c-breadcrumb__item--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-breadcrumb__item--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-breadcrumb__item--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-breadcrumb__item-divider--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-breadcrumb__item-divider--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-breadcrumb__item-divider--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-breadcrumb__link--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-breadcrumb__link--TextDecoration: var(--pf-v5-global--link--TextDecoration);
  --pf-v5-c-breadcrumb__link--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-breadcrumb__link--hover--TextDecoration: var(--pf-v5-global--link--TextDecoration--hover);
  --pf-v5-c-breadcrumb__link--m-current--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-breadcrumb__link--BackgroundColor: transparent;
  --pf-v5-c-breadcrumb__heading--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-breadcrumb__dropdown--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-breadcrumb__dropdown--MarginRight: calc(var(--pf-v5-c-breadcrumb__item--MarginRight) * -1);
  --pf-v5-c-breadcrumb__dropdown--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-breadcrumb__dropdown--MarginLeft: calc(var(--pf-v5-c-breadcrumb__item-divider--MarginRight) * -1);
  --pf-v5-c-breadcrumb__dropdown--c-dropdown__toggle--LineHeight: var(--pf-v5-global--LineHeight--sm);
  display: inline-flex;
}

.pf-v5-c-breadcrumb__list {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.pf-v5-c-breadcrumb__item {
  font-size: var(--pf-v5-c-breadcrumb__item--FontSize);
  font-weight: var(--pf-v5-c-breadcrumb__item--FontWeight);
  line-height: var(--pf-v5-c-breadcrumb__item--LineHeight);
  white-space: nowrap;
  align-items: baseline;
  list-style: none;
  display: flex;
}

.pf-v5-c-breadcrumb__item:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-breadcrumb__item--MarginRight);
}

.pf-v5-c-breadcrumb__item-divider {
  font-size: var(--pf-v5-c-breadcrumb__item-divider--FontSize);
  color: var(--pf-v5-c-breadcrumb__item-divider--Color);
  margin-inline-end: var(--pf-v5-c-breadcrumb__item-divider--MarginRight);
  line-height: 1;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-breadcrumb__item-divider {
  scale: -1 1;
}

.pf-v5-c-breadcrumb__link {
  font-size: inherit;
  font-weight: var(--pf-v5-c-breadcrumb__link--FontWeight);
  line-height: inherit;
  color: var(--pf-v5-c-breadcrumb__link--Color);
  -webkit-text-decoration: var(--pf-v5-c-breadcrumb__link--TextDecoration);
  text-decoration: var(--pf-v5-c-breadcrumb__link--TextDecoration);
  word-break: break-word;
  background-color: var(--pf-v5-c-breadcrumb__link--BackgroundColor);
}

.pf-v5-c-breadcrumb__link:hover {
  --pf-v5-c-breadcrumb__link--Color: var(--pf-v5-c-breadcrumb__link--hover--Color);
  --pf-v5-c-breadcrumb__link--TextDecoration: var(--pf-v5-c-breadcrumb__link--hover--TextDecoration);
}

.pf-v5-c-breadcrumb__link.pf-m-current {
  cursor: default;
}

.pf-v5-c-breadcrumb__link.pf-m-current, .pf-v5-c-breadcrumb__link.pf-m-current:hover {
  color: var(--pf-v5-c-breadcrumb__link--m-current--Color);
  text-decoration: none;
}

button.pf-v5-c-breadcrumb__link {
  border: none;
}

.pf-v5-c-breadcrumb__dropdown {
  margin-block-start: var(--pf-v5-c-breadcrumb__dropdown--MarginTop);
  margin-block-end: var(--pf-v5-c-breadcrumb__dropdown--MarginBottom);
  margin-inline-start: var(--pf-v5-c-breadcrumb__dropdown--MarginLeft);
  margin-inline-end: var(--pf-v5-c-breadcrumb__dropdown--MarginRight);
}

.pf-v5-c-breadcrumb__dropdown .pf-v5-c-dropdown__toggle {
  line-height: var(--pf-v5-c-breadcrumb__dropdown--c-dropdown__toggle--LineHeight);
}

.pf-v5-c-breadcrumb__heading {
  font-size: var(--pf-v5-c-breadcrumb__heading--FontSize);
  display: inline;
}

.pf-v5-c-breadcrumb__link, .pf-v5-c-breadcrumb__heading {
  white-space: normal;
}

.pf-v5-c-breadcrumb__list > :first-child .pf-v5-c-breadcrumb__item-divider {
  display: none;
}

.pf-v5-c-dropdown {
  --pf-v5-c-dropdown__toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-dropdown__toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-dropdown__toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--MinWidth: 0;
  --pf-v5-c-dropdown__toggle--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-dropdown__toggle--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-dropdown__toggle--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dropdown__toggle--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-dropdown__toggle--BackgroundColor: transparent;
  --pf-v5-c-dropdown__toggle--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-dropdown__toggle--before--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-dropdown__toggle--before--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-dropdown__toggle--before--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-dropdown__toggle--hover--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-dropdown__toggle--focus--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--focus--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-dropdown__toggle--active--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--active--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-dropdown__toggle--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-dropdown__toggle--m-plain--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-dropdown__toggle--m-plain--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dropdown__toggle--m-plain--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-dropdown__toggle--m-plain--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dropdown__toggle--m-plain--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dropdown__toggle--m-plain--child--LineHeight: normal;
  --pf-v5-c-dropdown__toggle--m-primary--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-dropdown__toggle--m-primary--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-dropdown__toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-primary--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-primary--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-primary--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown--m-expanded__toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-primary--disabled--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-dropdown__toggle--m-secondary--BackgroundColor: transparent;
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-dropdown__toggle--m-secondary--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--hover--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--focus--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--active--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown--m-expanded__toggle--m-secondary--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown--m-expanded__toggle--m-secondary--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle-button--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dropdown__toggle-button--BackgroundColor: transparent;
  --pf-v5-c-dropdown__toggle-progress--Visibility: hidden;
  --pf-v5-c-dropdown__toggle-progress--c-spinner--diameter: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-dropdown__toggle--m-split-button--child--BackgroundColor: transparent;
  --pf-v5-c-dropdown__toggle--m-split-button--first-child--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--last-child--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--m-action--child--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--m-action--child--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--m-action__toggle-button--MarginRight: calc(-1 * var(--pf-v5-global--BorderWidth--sm));
  --pf-v5-c-dropdown__toggle--m-split-button__toggle-text--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--m-expanded--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-dropdown--m-expanded__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor--base: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor--base);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth--base);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--hover--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--focus--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--active--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle-icon--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-dropdown__toggle-icon--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle-icon--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle-icon--MarginLeft: 0;
  --pf-v5-c-dropdown--m-top--m-expanded__toggle-icon--Rotate: 180deg;
  --pf-v5-c-dropdown--m-plain__toggle-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-dropdown--m-plain--hover__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dropdown__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-dropdown__menu--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-dropdown__menu--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__menu--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__menu--Top: calc(100% + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-dropdown__menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-dropdown--m-top__menu--Top: 0;
  --pf-v5-c-dropdown--m-top__menu--TranslateY: calc(-100% - var(--pf-v5-global--spacer--xs));
  --pf-v5-c-dropdown__menu-item--BackgroundColor: transparent;
  --pf-v5-c-dropdown__menu-item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__menu-item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dropdown__menu-item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__menu-item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dropdown__menu-item--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-dropdown__menu-item--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-dropdown__menu-item--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-dropdown__menu-item--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-dropdown__menu-item--hover--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-dropdown__menu-item--disabled--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-dropdown__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-dropdown__menu-item--disabled--BackgroundColor: transparent;
  --pf-v5-c-dropdown__menu-item--m-text--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-dropdown__menu-item-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__menu-item-icon--Width: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-dropdown__menu-item-icon--Height: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-dropdown__menu-item-description--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-dropdown__menu-item-description--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-dropdown__group--group--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__group-title--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__group-title--PaddingRight: var(--pf-v5-c-dropdown__menu-item--PaddingRight);
  --pf-v5-c-dropdown__group-title--PaddingBottom: var(--pf-v5-c-dropdown__menu-item--PaddingBottom);
  --pf-v5-c-dropdown__group-title--PaddingLeft: var(--pf-v5-c-dropdown__menu-item--PaddingLeft);
  --pf-v5-c-dropdown__group-title--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-dropdown__group-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-dropdown__group-title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-dropdown--c-divider--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown--c-divider--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--c-badge__toggle-icon--PaddingRight: 0;
  --pf-v5-c-dropdown__toggle--c-badge__toggle-icon--PaddingLeft: 0;
  --pf-v5-c-dropdown__toggle--c-badge__toggle-icon--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-dropdown__toggle--c-badge__toggle-icon--MarginRight: 0;
  --pf-v5-c-dropdown--c-menu--Top: calc(100% + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-dropdown--c-menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-dropdown--m-top--c-menu--Top: 0;
  --pf-v5-c-dropdown--m-top--c-menu--TranslateY: calc(-100% - var(--pf-v5-global--spacer--xs));
  --pf-v5-c-dropdown--m-full-height__toggle--before--BorderTopWidth: 0;
  --pf-v5-c-dropdown--m-full-height__toggle--expanded--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-dropdown--m-full-height__toggle--hover--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-dropdown--m-full-height__toggle--active--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-dropdown--m-full-height__toggle--focus--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-dropdown--m-full-height__toggle--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-dropdown--m-full-height__toggle--PaddingLeft: var(--pf-v5-global--spacer--lg);
  align-items: flex-start;
  max-width: 100%;
  display: inline-flex;
  position: relative;
}

.pf-v5-c-dropdown .pf-v5-c-divider {
  margin-block-start: var(--pf-v5-c-dropdown--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-dropdown--c-divider--MarginBottom);
}

.pf-v5-c-dropdown .pf-v5-c-divider:last-child {
  --pf-v5-c-dropdown--c-divider--MarginBottom: 0;
}

.pf-v5-c-dropdown > .pf-v5-c-menu {
  z-index: var(--pf-v5-c-dropdown--c-menu--ZIndex);
  position: absolute;
  inset-block-start: var(--pf-v5-c-dropdown--c-menu--Top);
}

.pf-v5-c-dropdown.pf-m-full-height {
  --pf-v5-c-dropdown__toggle--PaddingRight: var(--pf-v5-c-dropdown--m-full-height__toggle--PaddingRight);
  --pf-v5-c-dropdown__toggle--PaddingLeft: var(--pf-v5-c-dropdown--m-full-height__toggle--PaddingLeft);
  --pf-v5-c-dropdown__toggle--active--before--BorderBottomWidth: var(--pf-v5-c-dropdown--m-full-height__toggle--active--before--BorderBottomWidth);
  --pf-v5-c-dropdown__toggle--focus--before--BorderBottomWidth: var(--pf-v5-c-dropdown--m-full-height__toggle--focus--before--BorderBottomWidth);
  --pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomWidth: var(--pf-v5-c-dropdown--m-full-height__toggle--expanded--before--BorderBottomWidth);
  align-items: center;
  height: 100%;
  display: inline-flex;
}

.pf-v5-c-dropdown.pf-m-full-height .pf-v5-c-dropdown__toggle {
  align-self: stretch;
}

.pf-v5-c-dropdown.pf-m-full-height .pf-v5-c-dropdown__toggle:before {
  border-block-start-width: var(--pf-v5-c-dropdown--m-full-height__toggle--before--BorderTopWidth);
}

.pf-v5-c-dropdown.pf-m-full-height:hover .pf-v5-c-dropdown__toggle:before {
  border-block-end-width: var(--pf-v5-c-dropdown--m-full-height__toggle--hover--before--BorderBottomWidth);
}

.pf-v5-c-dropdown.pf-m-expanded {
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--m-expanded--BackgroundColor);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-c-dropdown--m-expanded__toggle--m-secondary--m-split-button--child--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle {
  column-gap: var(--pf-v5-c-dropdown__toggle--ColumnGap);
  min-width: var(--pf-v5-c-dropdown__toggle--MinWidth);
  font-size: var(--pf-v5-c-dropdown__toggle--FontSize);
  font-weight: var(--pf-v5-c-dropdown__toggle--FontWeight);
  line-height: var(--pf-v5-c-dropdown__toggle--LineHeight);
  color: var(--pf-v5-c-dropdown__toggle--Color);
  background-color: var(--pf-v5-c-dropdown__toggle--BackgroundColor);
  border: none;
  align-items: center;
  max-width: 100%;
  padding-block-start: var(--pf-v5-c-dropdown__toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-dropdown__toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-dropdown__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dropdown__toggle--PaddingRight);
  display: inline-flex;
  position: relative;
}

.pf-v5-c-dropdown__toggle:before, .pf-v5-c-dropdown__toggle.pf-m-action .pf-v5-c-dropdown__toggle-button:before {
  content: "";
  border: var(--pf-v5-c-dropdown__toggle--before--BorderWidth) solid;
  border-block-start-color: var(--pf-v5-c-dropdown__toggle--before--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-dropdown__toggle--before--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-dropdown__toggle--before--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-dropdown__toggle--before--BorderRightColor);
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-disabled, .pf-v5-c-dropdown__toggle:disabled {
  --pf-v5-c-dropdown__toggle--m-primary--Color: var(--pf-v5-c-dropdown__toggle--m-primary--disabled--Color);
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: transparent;
  pointer-events: none;
}

.pf-v5-c-dropdown__toggle.pf-m-disabled:not(.pf-m-plain), .pf-v5-c-dropdown__toggle.pf-m-disabled.pf-m-plain.pf-m-text, .pf-v5-c-dropdown__toggle:disabled:not(.pf-m-plain), .pf-v5-c-dropdown__toggle:disabled.pf-m-plain.pf-m-text {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--disabled--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-disabled:not(.pf-m-plain):before, .pf-v5-c-dropdown__toggle.pf-m-disabled.pf-m-plain.pf-m-text:before, .pf-v5-c-dropdown__toggle:disabled:not(.pf-m-plain):before, .pf-v5-c-dropdown__toggle:disabled.pf-m-plain.pf-m-text:before {
  border: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button {
  --pf-v5-c-dropdown__toggle--ColumnGap: 0;
  padding: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button:not(.pf-m-disabled) {
  background-color: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button > * {
  padding-block-start: var(--pf-v5-c-dropdown__toggle--m-split-button--child--PaddingTop);
  padding-block-end: var(--pf-v5-c-dropdown__toggle--m-split-button--child--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-dropdown__toggle--m-split-button--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dropdown__toggle--m-split-button--child--PaddingRight);
  position: relative;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button > :first-child {
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingLeft: var(--pf-v5-c-dropdown__toggle--m-split-button--first-child--PaddingLeft);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button > :last-child {
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingRight: var(--pf-v5-c-dropdown__toggle--m-split-button--last-child--PaddingRight);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action {
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingRight: var(--pf-v5-c-dropdown__toggle--m-split-button--m-action--child--PaddingRight);
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingLeft: var(--pf-v5-c-dropdown__toggle--m-split-button--m-action--child--PaddingLeft);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action .pf-v5-c-dropdown__toggle-button {
  margin-inline-end: var(--pf-v5-c-dropdown__toggle--m-split-button--m-action__toggle-button--MarginRight);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action .pf-v5-c-dropdown__toggle-button:before {
  border-inline-start: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action .pf-v5-c-dropdown__toggle-button:last-child {
  --pf-v5-c-dropdown__toggle--m-split-button--m-action__toggle-button--MarginRight: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action.pf-m-primary > :not(:first-child) {
  border-inline-start: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftWidth) solid var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftColor);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action.pf-m-primary.pf-m-disabled, .pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action.pf-m-primary[disabled] {
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftWidth: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-check {
  cursor: pointer;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-check.pf-m-in-progress {
  --pf-v5-c-dropdown__toggle--m-split-button__toggle-check__input--Visibility: hidden;
  --pf-v5-c-dropdown__toggle-progress--Visibility: visible;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-check > input, .pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-check .pf-v5-c-check {
  --pf-v5-c-check__input--TranslateY: none;
  align-self: revert;
  cursor: pointer;
  visibility: var(--pf-v5-c-dropdown__toggle--m-split-button__toggle-check__input--Visibility, unset);
  width: auto;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-button {
  color: var(--pf-v5-c-dropdown__toggle-button--Color);
  background-color: var(--pf-v5-c-dropdown__toggle-button--BackgroundColor);
  border: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-text {
  margin-inline-start: var(--pf-v5-c-dropdown__toggle--m-split-button__toggle-text--MarginLeft);
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled) {
  --pf-v5-c-dropdown__toggle--before--BorderWidth: 0;
  --pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomWidth: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled) > * {
  background-color: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor);
  border: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled) > :hover {
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--hover--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled) > :focus, .pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled) > :active, .pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled) > .pf-m-active {
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--focus--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled) > :first-child {
  border-start-start-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius);
  border-end-start-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled) > :last-child {
  border-start-end-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius);
  border-end-end-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius);
}

.pf-v5-c-dropdown__toggle:not(.pf-m-action):not(.pf-m-secondary):hover:before, .pf-v5-c-dropdown__toggle.pf-m-action .pf-v5-c-dropdown__toggle-button:hover:before {
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-c-dropdown__toggle--hover--before--BorderBottomColor);
}

.pf-v5-c-dropdown__toggle:not(.pf-m-action):not(.pf-m-secondary):focus:before, .pf-v5-c-dropdown__toggle.pf-m-action .pf-v5-c-dropdown__toggle-button:focus:before {
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-c-dropdown__toggle--focus--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-dropdown__toggle--focus--before--BorderBottomWidth);
}

.pf-v5-c-dropdown__toggle:not(.pf-m-action):not(.pf-m-secondary):active:before, .pf-v5-c-dropdown__toggle:not(.pf-m-action):not(.pf-m-secondary).pf-m-active:before, .pf-v5-c-dropdown__toggle.pf-m-action .pf-v5-c-dropdown__toggle-button:active:before {
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-c-dropdown__toggle--active--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-dropdown__toggle--active--before--BorderBottomWidth);
}

.pf-m-expanded > .pf-v5-c-dropdown__toggle:not(.pf-m-action):not(.pf-m-secondary):before, .pf-m-expanded > .pf-v5-c-dropdown__toggle.pf-m-action .pf-v5-c-dropdown__toggle-button:before {
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-plain {
  --pf-v5-c-dropdown__toggle-icon--Color: var(--pf-v5-c-dropdown--m-plain__toggle-icon--Color);
}

.pf-v5-c-dropdown__toggle.pf-m-plain:not(.pf-m-text) {
  --pf-v5-c-dropdown__toggle--PaddingRight: var(--pf-v5-c-dropdown__toggle--m-plain--PaddingRight);
  --pf-v5-c-dropdown__toggle--PaddingLeft: var(--pf-v5-c-dropdown__toggle--m-plain--PaddingLeft);
  color: var(--pf-v5-c-dropdown__toggle--m-plain--Color);
  display: inline-block;
}

.pf-v5-c-dropdown__toggle.pf-m-plain:not(.pf-m-text) > * {
  line-height: var(--pf-v5-c-dropdown__toggle--m-plain--child--LineHeight);
}

.pf-v5-c-dropdown__toggle.pf-m-plain:before {
  border: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-plain:hover, .pf-v5-c-dropdown__toggle.pf-m-plain:focus, .pf-v5-c-dropdown__toggle.pf-m-plain:active, .pf-v5-c-dropdown__toggle.pf-m-plain.pf-m-active, .pf-m-expanded > .pf-v5-c-dropdown__toggle.pf-m-plain {
  --pf-v5-c-dropdown__toggle--m-plain--Color: var(--pf-v5-c-dropdown__toggle--m-plain--hover--Color);
  --pf-v5-c-dropdown--m-plain__toggle-icon--Color: var(--pf-v5-c-dropdown--m-plain--hover__toggle-icon--Color);
}

.pf-v5-c-dropdown__toggle.pf-m-plain.pf-m-disabled, .pf-v5-c-dropdown__toggle.pf-m-plain:disabled {
  --pf-v5-c-dropdown__toggle--m-plain--Color: var(--pf-v5-c-dropdown__toggle--m-plain--disabled--Color);
}

.pf-v5-c-dropdown__toggle.pf-m-primary {
  --pf-v5-c-dropdown__toggle--Color: var(--pf-v5-c-dropdown__toggle--m-primary--Color);
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-primary--BackgroundColor);
  --pf-v5-c-dropdown__toggle-button--Color: var(--pf-v5-c-dropdown__toggle--m-primary--Color);
  border-radius: var(--pf-v5-c-dropdown__toggle--m-primary--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-primary:before, .pf-v5-c-dropdown__toggle.pf-m-primary .pf-v5-c-dropdown__toggle-button:before {
  border: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-primary:hover {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-primary--hover--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-primary:focus {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-primary--focus--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-primary:active, .pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-active {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-primary--active--BackgroundColor);
}

.pf-m-expanded > .pf-v5-c-dropdown__toggle.pf-m-primary {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown--m-expanded__toggle--m-primary--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary {
  border-radius: var(--pf-v5-c-dropdown__toggle--m-secondary--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary:not(.pf-m-disabled):not([disabled]) {
  --pf-v5-c-dropdown__toggle--Color: var(--pf-v5-c-dropdown__toggle--m-secondary--Color);
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-secondary--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary:not(.pf-m-disabled):not([disabled]):before {
  border: var(--pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth) solid var(--pf-v5-c-dropdown__toggle--m-secondary--before--BorderColor);
  border-radius: var(--pf-v5-c-dropdown__toggle--m-secondary--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary:hover {
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--hover--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary:focus {
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--focus--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary:active, .pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-active {
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--active--before--BorderWidth);
}

.pf-m-expanded > .pf-v5-c-dropdown__toggle.pf-m-secondary {
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-dropdown--m-expanded__toggle--m-secondary--before--BorderWidth);
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderColor: var(--pf-v5-c-dropdown--m-expanded__toggle--m-secondary--before--BorderColor);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled) {
  --pf-v5-c-dropdown__toggle-button--Color: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--Color);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled):hover {
  --pf-v5-c-dropdown__toggle--m-secondary--hover--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--hover--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled):focus {
  --pf-v5-c-dropdown__toggle--m-secondary--focus--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--focus--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled):active {
  --pf-v5-c-dropdown__toggle--m-secondary--active--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--active--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled) > :before {
  border: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth) solid var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled) > :hover {
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--hover--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled) > :focus {
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--focus--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled) > :active {
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--active--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled) > :first-child:before {
  border-start-start-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius);
  border-end-start-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled) > :last-child:before {
  border-start-end-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius);
  border-end-end-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled) > :not(:first-child):before {
  border-inline-start: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth--base) solid var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor--base);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled) > :not(:last-child):before {
  border-inline-end: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled).pf-m-action:before {
  border: 0;
}

.pf-v5-c-dropdown__toggle > .pf-v5-c-badge {
  --pf-v5-c-dropdown__toggle-icon--PaddingRight: var(--pf-v5-c-dropdown__toggle--c-badge__toggle-icon--PaddingRight);
  --pf-v5-c-dropdown__toggle-icon--PaddingLeft: var(--pf-v5-c-dropdown__toggle--c-badge__toggle-icon--PaddingLeft);
  --pf-v5-c-dropdown__toggle-icon--MarginLeft: var(--pf-v5-c-dropdown__toggle--c-badge__toggle-icon--MarginLeft);
}

.pf-v5-c-dropdown__toggle .pf-v5-c-dropdown__toggle-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pf-v5-c-dropdown__toggle-button {
  align-self: baseline;
}

.pf-v5-c-dropdown__toggle-text {
  text-align: start;
  flex: auto;
}

.pf-v5-c-dropdown__toggle-icon {
  line-height: var(--pf-v5-c-dropdown__toggle-icon--LineHeight);
  color: var(--pf-v5-c-dropdown__toggle-icon--Color, inherit);
  margin-inline-start: var(--pf-v5-c-dropdown__toggle-icon--MarginLeft);
  padding-inline-start: var(--pf-v5-c-dropdown__toggle-icon--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dropdown__toggle-icon--PaddingRight);
}

.pf-v5-c-dropdown.pf-m-top.pf-m-expanded .pf-v5-c-dropdown__toggle-icon {
  transform: rotate(var(--pf-v5-c-dropdown--m-top--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-dropdown__toggle-image {
  flex-shrink: 0;
  line-height: 1;
  display: inline-flex;
}

.pf-v5-c-dropdown__toggle-progress {
  visibility: var(--pf-v5-c-dropdown__toggle-progress--Visibility);
  position: absolute;
  inset-inline-start: var(--pf-v5-c-dropdown__toggle--m-split-button--child--PaddingLeft);
}

.pf-v5-c-dropdown__toggle-progress .pf-v5-c-spinner {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-dropdown__toggle-progress--c-spinner--diameter);
}

.pf-v5-c-dropdown__menu {
  z-index: var(--pf-v5-c-dropdown__menu--ZIndex);
  background: var(--pf-v5-c-dropdown__menu--BackgroundColor);
  box-shadow: var(--pf-v5-c-dropdown__menu--BoxShadow);
  background-clip: padding-box;
  padding-block-start: var(--pf-v5-c-dropdown__menu--PaddingTop);
  padding-block-end: var(--pf-v5-c-dropdown__menu--PaddingBottom);
  position: absolute;
  inset-block-start: var(--pf-v5-c-dropdown__menu--Top);
}

.pf-v5-c-dropdown__menu.pf-m-static {
  --pf-v5-c-dropdown--m-top__menu--TranslateY: 0;
  z-index: auto;
  min-width: min-content;
  position: static;
  inset-block: auto;
  inset-inline: auto;
}

.pf-v5-c-dropdown .pf-v5-c-menu, .pf-v5-c-dropdown__menu {
  min-width: 100%;
}

.pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right, .pf-v5-c-dropdown__menu.pf-m-align-right {
  inset-inline-end: 0;
}

.pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left, .pf-v5-c-dropdown__menu.pf-m-align-left {
  inset-inline-end: auto;
}

@media (width >= 576px) {
  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right-on-sm, .pf-v5-c-dropdown__menu.pf-m-align-right-on-sm {
    inset-inline-end: 0;
  }

  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left-on-sm, .pf-v5-c-dropdown__menu.pf-m-align-left-on-sm {
    inset-inline-end: auto;
  }
}

@media (width >= 768px) {
  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right-on-md, .pf-v5-c-dropdown__menu.pf-m-align-right-on-md {
    inset-inline-end: 0;
  }

  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left-on-md, .pf-v5-c-dropdown__menu.pf-m-align-left-on-md {
    inset-inline-end: auto;
  }
}

@media (width >= 992px) {
  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right-on-lg, .pf-v5-c-dropdown__menu.pf-m-align-right-on-lg {
    inset-inline-end: 0;
  }

  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left-on-lg, .pf-v5-c-dropdown__menu.pf-m-align-left-on-lg {
    inset-inline-end: auto;
  }
}

@media (width >= 1200px) {
  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right-on-xl, .pf-v5-c-dropdown__menu.pf-m-align-right-on-xl {
    inset-inline-end: 0;
  }

  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left-on-xl, .pf-v5-c-dropdown__menu.pf-m-align-left-on-xl {
    inset-inline-end: auto;
  }
}

@media (width >= 1450px) {
  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right-on-2xl, .pf-v5-c-dropdown__menu.pf-m-align-right-on-2xl {
    inset-inline-end: 0;
  }

  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left-on-2xl, .pf-v5-c-dropdown__menu.pf-m-align-left-on-2xl {
    inset-inline-end: auto;
  }
}

.pf-v5-c-dropdown.pf-m-top .pf-v5-c-dropdown__menu {
  --pf-v5-c-dropdown__menu--Top: var(--pf-v5-c-dropdown--m-top__menu--Top);
  transform: translateY(var(--pf-v5-c-dropdown--m-top__menu--TranslateY));
}

.pf-v5-c-dropdown.pf-m-top > .pf-v5-c-menu {
  --pf-v5-c-dropdown--c-menu--Top: var(--pf-v5-c-dropdown--m-top--c-menu--Top);
  transform: translateY(var(--pf-v5-c-dropdown--m-top--c-menu--TranslateY));
}

.pf-v5-c-dropdown__menu-item {
  font-size: var(--pf-v5-c-dropdown__menu-item--FontSize);
  font-weight: var(--pf-v5-c-dropdown__menu-item--FontWeight);
  line-height: var(--pf-v5-c-dropdown__menu-item--LineHeight);
  color: var(--pf-v5-c-dropdown__menu-item--Color);
  text-align: start;
  white-space: nowrap;
  background-color: var(--pf-v5-c-dropdown__menu-item--BackgroundColor);
  border: none;
  width: 100%;
  padding-block-start: var(--pf-v5-c-dropdown__menu-item--PaddingTop);
  padding-block-end: var(--pf-v5-c-dropdown__menu-item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-dropdown__menu-item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dropdown__menu-item--PaddingRight);
  display: block;
}

.pf-v5-c-dropdown__menu-item:hover, .pf-v5-c-dropdown__menu-item:focus {
  --pf-v5-c-dropdown__menu-item--Color: var(--pf-v5-c-dropdown__menu-item--hover--Color);
  --pf-v5-c-dropdown__menu-item--BackgroundColor: var(--pf-v5-c-dropdown__menu-item--hover--BackgroundColor);
  text-decoration: none;
}

.pf-v5-c-dropdown__menu-item:disabled, .pf-v5-c-dropdown__menu-item.pf-m-disabled, .pf-v5-c-dropdown__menu-item.pf-m-aria-disabled {
  --pf-v5-c-dropdown__menu-item--Color: var(--pf-v5-c-dropdown__menu-item--disabled--Color);
  --pf-v5-c-dropdown__menu-item--BackgroundColor: var(--pf-v5-c-dropdown__menu-item--disabled--BackgroundColor);
}

.pf-v5-c-dropdown__menu-item:disabled, .pf-v5-c-dropdown__menu-item.pf-m-disabled {
  pointer-events: none;
}

.pf-v5-c-dropdown__menu-item.pf-m-aria-disabled {
  cursor: default;
}

.pf-v5-c-dropdown__menu-item.pf-m-icon {
  align-items: center;
  display: flex;
}

.pf-v5-c-dropdown__menu-item.pf-m-icon.pf-m-description {
  flex-direction: column;
  align-items: flex-start;
}

.pf-v5-c-dropdown__menu-item.pf-m-icon .pf-v5-c-dropdown__menu-item-main {
  align-items: center;
  display: flex;
}

.pf-v5-c-dropdown__menu-item.pf-m-text {
  --pf-v5-c-dropdown__menu-item--Color: var(--pf-v5-c-dropdown__menu-item--m-text--Color);
}

.pf-v5-c-dropdown__menu-item.pf-m-text:hover, .pf-v5-c-dropdown__menu-item.pf-m-text:focus {
  --pf-v5-c-dropdown__menu-item--BackgroundColor: transparent;
}

.pf-v5-c-dropdown__menu-item-icon {
  width: var(--pf-v5-c-dropdown__menu-item-icon--Width);
  height: var(--pf-v5-c-dropdown__menu-item-icon--Height);
  justify-content: center;
  align-items: center;
  margin-inline-end: var(--pf-v5-c-dropdown__menu-item-icon--MarginRight);
  display: inline-flex;
}

.pf-v5-c-dropdown__menu-item-icon > * {
  max-width: 100%;
  max-height: 100%;
}

.pf-v5-c-dropdown__menu-item-description {
  font-size: var(--pf-v5-c-dropdown__menu-item-description--FontSize);
  color: var(--pf-v5-c-dropdown__menu-item-description--Color);
}

.pf-v5-c-dropdown__group + .pf-v5-c-dropdown__group {
  padding-block-start: var(--pf-v5-c-dropdown__group--group--PaddingTop);
}

.pf-v5-c-dropdown__group-title {
  font-size: var(--pf-v5-c-dropdown__group-title--FontSize);
  font-weight: var(--pf-v5-c-dropdown__group-title--FontWeight);
  color: var(--pf-v5-c-dropdown__group-title--Color);
  padding-block-start: var(--pf-v5-c-dropdown__group-title--PaddingTop);
  padding-block-end: var(--pf-v5-c-dropdown__group-title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-dropdown__group-title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dropdown__group-title--PaddingRight);
}

:where(.pf-v5-theme-dark) .pf-v5-c-dropdown {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-dropdown__toggle--before--BorderTopColor: transparent;
  --pf-v5-c-dropdown__toggle--before--BorderRightColor: transparent;
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-dropdown__toggle--before--BorderLeftColor: transparent;
  --pf-v5-c-dropdown__toggle--disabled--BackgroundColor: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-dropdown__toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-dropdown__toggle--m-primary--Color: var(--pf-v5-global--primary-color--400);
  --pf-v5-c-dropdown__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-dropdown__menu--Top: 100%;
  --pf-v5-c-dropdown--m-top__menu--TranslateY: -100%;
  --pf-v5-c-dropdown__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}

:where(.pf-v5-theme-dark) .pf-v5-c-dropdown__toggle:not(.pf-m-plain):disabled, :where(.pf-v5-theme-dark) .pf-v5-c-dropdown__toggle:not(.pf-m-plain).pf-m-disabled {
  color: var(--pf-v5-global--palette--black-100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-dropdown__toggle.pf-m-plain {
  background: none;
}

.pf-v5-c-popover[data-popper-reference-hidden="true"] {
  visibility: hidden;
  pointer-events: none;
}

.pf-v5-c-check {
  --pf-v5-c-check--GridGap: var(--pf-v5-global--spacer--xs) var(--pf-v5-global--spacer--sm);
  --pf-v5-c-check__label--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-check__label--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-check__label--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-check__label--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-check__label--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-check__input--TranslateY: calc((var(--pf-v5-c-check__label--LineHeight) * var(--pf-v5-c-check__label--FontSize) / 2 )  - 50%);
  --pf-v5-c-check__input--TranslateY--moz: var(--pf-v5-c-check__input--TranslateY);
  --pf-v5-c-check__description--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-check__description--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-check__body--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-check__label-required--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-check__label-required--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-check__label-required--Color: var(--pf-v5-global--danger-color--100);
  grid-gap: var(--pf-v5-c-check--GridGap);
  grid-template-columns: auto 1fr;
  align-items: baseline;
  display: grid;
}

.pf-v5-c-check.pf-m-standalone {
  --pf-v5-c-check--GridGap: 0;
  --pf-v5-c-check__input--TranslateY: none;
  display: inline-grid;
}

.pf-v5-c-check__input {
  -moz-transform: translateY(var(--pf-v5-c-check__input--TranslateY--moz));
  transform: translateY(var(--pf-v5-c-check__input--TranslateY));
  align-self: start;
}

.pf-v5-c-check__label {
  font-size: var(--pf-v5-c-check__label--FontSize);
  font-weight: var(--pf-v5-c-check__label--FontWeight);
  line-height: var(--pf-v5-c-check__label--LineHeight);
  color: var(--pf-v5-c-check__label--Color);
}

.pf-v5-c-check__description {
  font-size: var(--pf-v5-c-check__description--FontSize);
  color: var(--pf-v5-c-check__description--Color);
  grid-column: 2;
}

.pf-v5-c-check__body {
  grid-column: 2;
  margin-block-start: var(--pf-v5-c-check__body--MarginTop);
}

.pf-v5-c-check__label, .pf-v5-c-check__input {
  justify-self: start;
}

label.pf-v5-c-check, .pf-v5-c-check__label, .pf-v5-c-check__input {
  cursor: pointer;
}

.pf-v5-c-check__label:disabled, .pf-v5-c-check__label.pf-m-disabled, .pf-v5-c-check__input:disabled, .pf-v5-c-check__input.pf-m-disabled {
  --pf-v5-c-check__label--Color: var(--pf-v5-c-check__label--disabled--Color);
  cursor: not-allowed;
}

.pf-v5-c-check__label-required {
  font-size: var(--pf-v5-c-check__label-required--FontSize);
  color: var(--pf-v5-c-check__label-required--Color);
  margin-inline-start: var(--pf-v5-c-check__label-required--MarginLeft);
}

.pf-v5-c-tooltip {
  --pf-v5-c-tooltip--MaxWidth: 18.75rem;
  --pf-v5-c-tooltip--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-tooltip__content--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tooltip__content--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tooltip__content--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tooltip__content--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tooltip__content--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-tooltip__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-tooltip__content--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-tooltip__arrow--Width: .9375rem;
  --pf-v5-c-tooltip__arrow--Height: .9375rem;
  --pf-v5-c-tooltip__arrow--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-tooltip__arrow--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-tooltip__arrow--m-top--TranslateX: -50%;
  --pf-v5-c-tooltip__arrow--m-top--TranslateY: 50%;
  --pf-v5-c-tooltip__arrow--m-top--Rotate: 45deg;
  --pf-v5-c-tooltip__arrow--m-right--TranslateX: -50%;
  --pf-v5-c-tooltip__arrow--m-right--TranslateY: -50%;
  --pf-v5-c-tooltip__arrow--m-right--Rotate: 45deg;
  --pf-v5-c-tooltip__arrow--m-bottom--TranslateX: -50%;
  --pf-v5-c-tooltip__arrow--m-bottom--TranslateY: -50%;
  --pf-v5-c-tooltip__arrow--m-bottom--Rotate: 45deg;
  --pf-v5-c-tooltip__arrow--m-left--TranslateX: 50%;
  --pf-v5-c-tooltip__arrow--m-left--TranslateY: -50%;
  --pf-v5-c-tooltip__arrow--m-left--Rotate: 45deg;
  max-width: var(--pf-v5-c-tooltip--MaxWidth);
  box-shadow: var(--pf-v5-c-tooltip--BoxShadow);
  position: relative;
}

.pf-v5-c-tooltip:is(.pf-m-top, .pf-m-top-left, .pf-m-top-right) {
  --pf-v5-c-tooltip__arrow--Bottom: var(--pf-v5-c-tooltip--m-top--Bottom, 0);
  --pf-v5-c-tooltip__arrow--Left: var(--pf-v5-c-tooltip--m-top--Left, 50%);
  --pf-v5-c-tooltip__arrow--TranslateX: var(--pf-v5-c-tooltip__arrow--m-top--TranslateX);
  --pf-v5-c-tooltip__arrow--TranslateY: var(--pf-v5-c-tooltip__arrow--m-top--TranslateY);
  --pf-v5-c-tooltip__arrow--Rotate: var(--pf-v5-c-tooltip__arrow--m-top--Rotate);
}

.pf-v5-c-tooltip:is(.pf-m-bottom, .pf-m-bottom-left, .pf-m-bottom-right) {
  --pf-v5-c-tooltip__arrow--Top: var(--pf-v5-c-tooltip--m-bottom--Top, 0);
  --pf-v5-c-tooltip__arrow--Left: var(--pf-v5-c-tooltip--m-bottom--Left, 50%);
  --pf-v5-c-tooltip__arrow--TranslateX: var(--pf-v5-c-tooltip__arrow--m-bottom--TranslateX);
  --pf-v5-c-tooltip__arrow--TranslateY: var(--pf-v5-c-tooltip__arrow--m-bottom--TranslateY);
  --pf-v5-c-tooltip__arrow--Rotate: var(--pf-v5-c-tooltip__arrow--m-bottom--Rotate);
}

.pf-v5-c-tooltip:is(.pf-m-left, .pf-m-left-top, .pf-m-left-bottom) {
  --pf-v5-c-tooltip__arrow--Top: var(--pf-v5-c-tooltip--m-left--Top, 50%);
  --pf-v5-c-tooltip__arrow--Right: var(--pf-v5-c-tooltip--m-left--Right, 0);
  --pf-v5-c-tooltip__arrow--TranslateX: var(--pf-v5-c-tooltip__arrow--m-left--TranslateX);
  --pf-v5-c-tooltip__arrow--TranslateY: var(--pf-v5-c-tooltip__arrow--m-left--TranslateY);
  --pf-v5-c-tooltip__arrow--Rotate: var(--pf-v5-c-tooltip__arrow--m-left--Rotate);
}

.pf-v5-c-tooltip:is(.pf-m-right, .pf-m-right-top, .pf-m-right-bottom) {
  --pf-v5-c-tooltip__arrow--Top: var(--pf-v5-c-tooltip--m-right--Top, 50%);
  --pf-v5-c-tooltip__arrow--Left: var(--pf-v5-c-tooltip--m-right--Left, 0);
  --pf-v5-c-tooltip__arrow--TranslateX: var(--pf-v5-c-tooltip__arrow--m-right--TranslateX);
  --pf-v5-c-tooltip__arrow--TranslateY: var(--pf-v5-c-tooltip__arrow--m-right--TranslateY);
  --pf-v5-c-tooltip__arrow--Rotate: var(--pf-v5-c-tooltip__arrow--m-right--Rotate);
}

.pf-v5-c-tooltip:is(.pf-m-left-top, .pf-m-right-top) {
  --pf-v5-c-tooltip__arrow--Top: 0;
  --pf-v5-c-tooltip__arrow--TranslateY: var(--pf-v5-c-tooltip__arrow--m-top--TranslateY);
}

.pf-v5-c-tooltip:is(.pf-m-left-bottom, .pf-m-right-bottom) {
  --pf-v5-c-tooltip__arrow--Top: auto;
  --pf-v5-c-tooltip__arrow--Bottom: 0;
}

.pf-v5-c-tooltip:is(.pf-m-top-left, .pf-m-bottom-left) {
  --pf-v5-c-tooltip__arrow--Left: 0;
  --pf-v5-c-tooltip__arrow--TranslateX: var(--pf-v5-c-tooltip__arrow--m-left--TranslateX);
}

.pf-v5-c-tooltip:is(.pf-m-top-right, .pf-m-bottom-right) {
  --pf-v5-c-tooltip__arrow--Right: 0;
  --pf-v5-c-tooltip__arrow--Left: auto;
}

.pf-v5-c-tooltip__content {
  font-size: var(--pf-v5-c-tooltip__content--FontSize);
  color: var(--pf-v5-c-tooltip__content--Color);
  text-align: center;
  word-break: break-word;
  background-color: var(--pf-v5-c-tooltip__content--BackgroundColor);
  padding-block-start: var(--pf-v5-c-tooltip__content--PaddingTop);
  padding-block-end: var(--pf-v5-c-tooltip__content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tooltip__content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tooltip__content--PaddingRight);
  position: relative;
}

.pf-v5-c-tooltip__content.pf-m-text-align-left {
  text-align: start;
}

.pf-v5-c-tooltip__arrow {
  top: var(--pf-v5-c-tooltip__arrow--Top, auto);
  right: var(--pf-v5-c-tooltip__arrow--Right, auto);
  bottom: var(--pf-v5-c-tooltip__arrow--Bottom, auto);
  left: var(--pf-v5-c-tooltip__arrow--Left, auto);
  width: var(--pf-v5-c-tooltip__arrow--Width);
  height: var(--pf-v5-c-tooltip__arrow--Height);
  pointer-events: none;
  background-color: var(--pf-v5-c-tooltip__arrow--BackgroundColor);
  box-shadow: var(--pf-v5-c-tooltip__arrow--BoxShadow);
  transform: translateX(var(--pf-v5-c-tooltip__arrow--TranslateX, 0)) translateY(var(--pf-v5-c-tooltip__arrow--TranslateY, 0)) rotate(var(--pf-v5-c-tooltip__arrow--Rotate, 0));
  position: absolute;
}

:where(.pf-v5-theme-dark) .pf-v5-c-tooltip {
  --pf-v5-c-tooltip__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-tooltip__arrow--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-c-list {
  --pf-v5-c-list--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-list--nested--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-list--nested--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-list--ul--ListStyle: var(--pf-v5-global--ListStyle);
  --pf-v5-c-list--li--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-list--m-inline--li--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-list--m-bordered--li--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-list--m-bordered--li--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-list--m-bordered--li--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-list__item-icon--MinWidth: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-list__item-icon--MarginTop: .375rem;
  --pf-v5-c-list__item-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-list__item-icon--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-list__item-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-list--m-icon-lg__item-icon--MinWidth: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-list--m-icon-lg__item-icon--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-list--m-icon-lg__item-icon--FontSize: var(--pf-v5-global--icon--FontSize--lg);
  padding-inline-start: var(--pf-v5-c-list--PaddingLeft);
}

.pf-v5-c-list ol, .pf-v5-c-list ul {
  margin-block-start: var(--pf-v5-c-list--nested--MarginTop);
  margin-inline-start: var(--pf-v5-c-list--nested--MarginLeft);
}

.pf-v5-c-list li + li {
  margin-block-start: var(--pf-v5-c-list--li--MarginTop);
}

ul.pf-v5-c-list {
  list-style: var(--pf-v5-c-list--ul--ListStyle);
}

.pf-v5-c-list .pf-v5-c-list__item {
  display: flex;
}

.pf-v5-c-list .pf-v5-c-list__item-icon {
  min-width: var(--pf-v5-c-list__item-icon--MinWidth);
  font-size: var(--pf-v5-c-list__item-icon--FontSize);
  color: var(--pf-v5-c-list__item-icon--Color);
  flex-shrink: 0;
  margin-block-start: var(--pf-v5-c-list__item-icon--MarginTop);
  margin-inline-end: var(--pf-v5-c-list__item-icon--MarginRight);
  line-height: 1;
}

.pf-v5-c-list.pf-m-icon-lg {
  --pf-v5-c-list__item-icon--MinWidth: var(--pf-v5-c-list--m-icon-lg__item-icon--MinWidth);
  --pf-v5-c-list__item-icon--MarginTop: 0;
  --pf-v5-c-list__item-icon--MarginRight: var(--pf-v5-c-list--m-icon-lg__item-icon--MarginRight);
  --pf-v5-c-list__item-icon--FontSize: var(--pf-v5-c-list--m-icon-lg__item-icon--FontSize);
}

.pf-v5-c-list.pf-m-plain {
  --pf-v5-c-list--PaddingLeft: 0;
  list-style: none;
}

.pf-v5-c-list.pf-m-inline {
  --pf-v5-c-list--PaddingLeft: 0;
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.pf-v5-c-list.pf-m-inline li {
  --pf-v5-c-list--li--MarginTop: 0;
}

.pf-v5-c-list.pf-m-inline li:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-list--m-inline--li--MarginRight);
}

.pf-v5-c-list.pf-m-bordered > * {
  border-block-end: var(--pf-v5-c-list--m-bordered--li--BorderBottomWidth) solid var(--pf-v5-c-list--m-bordered--li--BorderBottomColor);
  padding-block-end: var(--pf-v5-c-list--m-bordered--li--PaddingBottom);
}

.pf-v5-c-list.pf-m-bordered > :last-child {
  --pf-v5-c-list--m-bordered--li--PaddingBottom: 0;
  --pf-v5-c-list--m-bordered--li--BorderBottomWidth: 0;
}

.pf-v5-c-masthead.pf-m-light-200, .pf-v5-c-masthead.pf-m-light {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain, .pf-v5-c-masthead {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--light-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--light-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--light-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--light-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--light);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--light);
}

.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain .pf-v5-c-button, .pf-v5-c-masthead .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--dark-100);
}

.pf-v5-c-masthead {
  --pf-v5-c-masthead--PaddingLeft: var(--pf-v5-c-masthead--inset);
  --pf-v5-c-masthead--PaddingRight: var(--pf-v5-c-masthead--inset);
  --pf-v5-c-masthead--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-masthead--xl--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-masthead--m-display-stack--GridTemplateColumns: max-content 1fr;
  --pf-v5-c-masthead--m-display-stack__main--GridColumn: -1 / 1;
  --pf-v5-c-masthead--m-display-stack__main--MinHeight: 4.375rem;
  --pf-v5-c-masthead--m-display-stack__main--Order: -1;
  --pf-v5-c-masthead--m-display-stack__main--FlexBasis: 100%;
  --pf-v5-c-masthead--m-display-stack__main--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-masthead--m-display-stack__main--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-masthead--m-display-stack__main--MarginRight: 0;
  --pf-v5-c-masthead--m-display-stack__main--before--BorderBottom: var(--pf-v5-c-masthead__main--before--BorderBottomWidth) solid var(--pf-v5-c-masthead__main--before--BorderBottomColor);
  --pf-v5-c-masthead--m-display-stack__toggle--GridColumn: 1;
  --pf-v5-c-masthead--m-display-stack__content--GridColumn: 2;
  --pf-v5-c-masthead--m-display-stack__content--MinHeight: auto;
  --pf-v5-c-masthead--m-display-stack__content--Order: 1;
  --pf-v5-c-masthead--m-display-stack__content--PaddingTop: 0;
  --pf-v5-c-masthead--m-display-stack__content--PaddingBottom: 0;
  --pf-v5-c-masthead--m-display-stack__content--MarginLeft: 0;
  --pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight: calc(var(--pf-v5-c-masthead--inset) * -1);
  --pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft: calc(var(--pf-v5-c-masthead--inset) * -1);
  --pf-v5-c-masthead--m-display-inline--GridTemplateColumns: max-content max-content 1fr;
  --pf-v5-c-masthead--m-display-inline__main--GridColumn: 2;
  --pf-v5-c-masthead--m-display-inline__main--MinHeight: 4.375rem;
  --pf-v5-c-masthead--m-display-inline__main--Order: 0;
  --pf-v5-c-masthead--m-display-inline__main--FlexBasis: auto;
  --pf-v5-c-masthead--m-display-inline__main--PaddingTop: 0;
  --pf-v5-c-masthead--m-display-inline__main--PaddingBottom: 0;
  --pf-v5-c-masthead--m-display-inline__main--MarginRight: calc(var(--pf-v5-global--spacer--lg) / 2);
  --pf-v5-c-masthead--m-display-inline__main--BorderBottom: 0;
  --pf-v5-c-masthead--m-display-inline__toggle--GridColumn: 1;
  --pf-v5-c-masthead--m-display-inline__content--GridColumn: 3;
  --pf-v5-c-masthead--m-display-inline__content--MinHeight: 4.375rem;
  --pf-v5-c-masthead--m-display-inline__content--Order: 0;
  --pf-v5-c-masthead--m-display-inline__content--PaddingTop: 0;
  --pf-v5-c-masthead--m-display-inline__content--PaddingBottom: 0;
  --pf-v5-c-masthead--m-display-inline__content--MarginLeft: calc(var(--pf-v5-global--spacer--lg) / 2);
  --pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight: 0;
  --pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft: 0;
  --pf-v5-c-masthead__main--before--Right: calc(var(--pf-v5-c-masthead--inset) * -1);
  --pf-v5-c-masthead__main--before--Left: calc(var(--pf-v5-c-masthead--inset) * -1);
  --pf-v5-c-masthead__main--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-masthead__main--before--BorderBottomColor: var(--pf-v5-global--palette--black-600);
  --pf-v5-c-masthead__toggle--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-masthead__toggle--MarginLeft: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-masthead__toggle--c-button--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-masthead--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-masthead--m-light__main--BorderBottomColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-masthead--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-masthead--m-light-200__main--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-masthead--c-toolbar__item--Display: flex;
  --pf-v5-c-masthead--item-border-color--base: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-masthead--c-context-selector--Width: auto;
  --pf-v5-c-masthead--c-context-selector__toggle--BorderTopColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-context-selector__toggle--BorderRightColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-context-selector__toggle--BorderLeftColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-context-selector--m-full-height__toggle--BorderTopColor: transparent;
  --pf-v5-c-masthead--c-context-selector--m-full-height__toggle--BorderBottomColor: transparent;
  --pf-v5-c-masthead--c-dropdown__toggle--before--BorderTopColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-dropdown__toggle--before--BorderRightColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-dropdown__toggle--before--BorderLeftColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-dropdown--m-full-height__toggle--before--BorderTopColor: transparent;
  --pf-v5-c-masthead--c-dropdown--m-full-height__toggle--before--BorderBottomColor: transparent;
  --pf-v5-c-masthead--c-menu-toggle--before--BorderTopColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-menu-toggle--before--BorderRightColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-menu-toggle--before--BorderLeftColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-toolbar--BackgroundColor: var(--pf-v5-c-masthead--BackgroundColor);
  --pf-v5-c-masthead--c-toolbar--AlignItems--base: center;
  --pf-v5-c-masthead--c-toolbar__content--PaddingRight: 0;
  --pf-v5-c-masthead--c-toolbar__content--PaddingLeft: 0;
  --pf-v5-c-masthead--c-toolbar__expandable-content--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-masthead--c-toolbar__expandable-content--PaddingRight: var(--pf-v5-c-masthead--inset);
  --pf-v5-c-masthead--c-toolbar__expandable-content--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-masthead--c-toolbar__expandable-content--PaddingLeft: var(--pf-v5-c-masthead--inset);
  --pf-v5-c-masthead--c-toolbar__expandable-content--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-masthead--c-toolbar__expandable-content--before--BorderTopColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
  --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
  --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
  --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
  --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
  --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
  --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
  --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
  --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
  --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
  --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
  --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
  --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
  --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
  --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  color: var(--pf-v5-global--Color--100);
  grid-template-columns: var(--pf-v5-c-masthead--GridTemplateColumns);
  background-color: var(--pf-v5-c-masthead--BackgroundColor);
  align-items: center;
  min-width: 0;
  padding-inline-start: var(--pf-v5-c-masthead--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-masthead--PaddingRight);
  display: grid;
  position: relative;
}

@media screen and (width >= 768px) {
  .pf-v5-c-page:where(:not(.pf-m-resize-observer)) .pf-v5-c-masthead {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }
}

@media screen and (width >= 1200px) {
  .pf-v5-c-masthead {
    --pf-v5-c-masthead--inset: var(--pf-v5-c-masthead--xl--inset);
  }
}

.pf-v5-c-masthead.pf-m-light {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-masthead--BackgroundColor: var(--pf-v5-c-masthead--m-light--BackgroundColor);
  --pf-v5-c-masthead__main--BorderBottomColor: var(--pf-v5-c-masthead--m-light__main--BorderBottomColor);
}

.pf-v5-c-masthead.pf-m-light-200 {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-masthead--BackgroundColor: var(--pf-v5-c-masthead--m-light-200--BackgroundColor);
  --pf-v5-c-masthead__main--BorderBottomColor: var(--pf-v5-c-masthead--m-light-200__main--BorderBottomColor);
}

.pf-v5-c-masthead .pf-v5-c-toolbar {
  --pf-v5-c-toolbar--BackgroundColor: var(--pf-v5-c-masthead--c-toolbar--BackgroundColor);
  --pf-v5-c-toolbar--AlignItems--base: var(--pf-v5-c-masthead--c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-masthead--c-toolbar__content--PaddingRight);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-masthead--c-toolbar__content--PaddingLeft);
  --pf-v5-c-toolbar__expandable-content--PaddingTop: var(--pf-v5-c-masthead--c-toolbar__expandable-content--PaddingTop);
  --pf-v5-c-toolbar__expandable-content--PaddingRight: var(--pf-v5-c-masthead--c-toolbar__expandable-content--PaddingRight);
  --pf-v5-c-toolbar__expandable-content--PaddingBottom: var(--pf-v5-c-masthead--c-toolbar__expandable-content--PaddingBottom);
  --pf-v5-c-toolbar__expandable-content--PaddingLeft: var(--pf-v5-c-masthead--c-toolbar__expandable-content--PaddingLeft);
  width: 100%;
}

.pf-v5-c-masthead .pf-v5-c-toolbar__content-section {
  flex-wrap: nowrap;
}

.pf-v5-c-masthead .pf-v5-c-toolbar__expandable-content {
  inset-block-start: 100%;
}

.pf-v5-c-masthead .pf-v5-c-toolbar__expandable-content:before {
  content: "";
  border-block-start: var(--pf-v5-c-masthead--c-toolbar__expandable-content--before--BorderTopWidth) solid var(--pf-v5-c-masthead--c-toolbar__expandable-content--before--BorderTopColor);
  box-shadow: var(--pf-v5-c-toolbar__expandable-content--before--BoxShadow);
  position: absolute;
  inset: 0;
}

.pf-v5-c-masthead .pf-v5-c-menu-toggle {
  --pf-v5-c-menu-toggle--before--BorderTopColor: var(--pf-v5-c-masthead--c-menu-toggle--before--BorderTopColor);
  --pf-v5-c-menu-toggle--before--BorderRightColor: var(--pf-v5-c-masthead--c-menu-toggle--before--BorderRightColor);
  --pf-v5-c-menu-toggle--before--BorderLeftColor: var(--pf-v5-c-masthead--c-menu-toggle--before--BorderLeftColor);
  --pf-v5-c-menu-toggle--m-full-height__toggle--after--BorderBottomWidth: 0;
}

.pf-v5-c-masthead .pf-v5-c-context-selector {
  --pf-v5-c-context-selector--Width: var(--pf-v5-c-masthead--c-context-selector--Width);
  --pf-v5-c-context-selector__toggle--BorderTopColor: var(--pf-v5-c-masthead--c-context-selector__toggle--BorderTopColor);
  --pf-v5-c-context-selector__toggle--BorderRightColor: var(--pf-v5-c-masthead--c-context-selector__toggle--BorderRightColor);
  --pf-v5-c-context-selector__toggle--BorderLeftColor: var(--pf-v5-c-masthead--c-context-selector__toggle--BorderLeftColor);
}

.pf-v5-c-masthead .pf-v5-c-context-selector.pf-m-full-height {
  --pf-v5-c-context-selector__toggle--BorderTopColor: var(--pf-v5-c-masthead--c-context-selector--m-full-height__toggle--BorderTopColor);
  --pf-v5-c-context-selector__toggle--BorderBottomColor: var(--pf-v5-c-masthead--c-context-selector--m-full-height__toggle--BorderBottomColor);
}

.pf-v5-c-masthead .pf-v5-c-dropdown {
  --pf-v5-c-dropdown__toggle--before--BorderTopColor: var(--pf-v5-c-masthead--c-dropdown__toggle--before--BorderTopColor);
  --pf-v5-c-dropdown__toggle--before--BorderRightColor: var(--pf-v5-c-masthead--c-dropdown__toggle--before--BorderRightColor);
  --pf-v5-c-dropdown__toggle--before--BorderLeftColor: var(--pf-v5-c-masthead--c-dropdown__toggle--before--BorderLeftColor);
}

.pf-v5-c-masthead .pf-v5-c-dropdown.pf-m-full-height {
  --pf-v5-c-dropdown__toggle--before--BorderTopColor: var(--pf-v5-c-masthead--c-dropdown--m-full-height__toggle--before--BorderTopColor);
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-c-masthead--c-dropdown--m-full-height__toggle--before--BorderBottomColor);
}

.pf-v5-c-masthead .pf-v5-c-nav {
  align-self: stretch;
}

.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain {
  color: var(--pf-v5-c-button--m-plain--Color);
}

.pf-v5-c-masthead__main {
  flex-basis: var(--pf-v5-c-masthead__main--FlexBasis);
  grid-column: var(--pf-v5-c-masthead__main--GridColumn);
  order: var(--pf-v5-c-masthead__main--Order);
  min-height: var(--pf-v5-c-masthead__main--MinHeight);
  align-self: stretch;
  align-items: center;
  margin-inline-end: var(--pf-v5-c-masthead__main--MarginRight);
  padding-block-start: var(--pf-v5-c-masthead__main--PaddingTop);
  padding-block-end: var(--pf-v5-c-masthead__main--PaddingBottom);
  display: flex;
  position: relative;
}

.pf-v5-c-masthead__main:before {
  content: "";
  border-block-end: var(--pf-v5-c-masthead__main--before--BorderBottom);
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: var(--pf-v5-c-masthead__main--before--Left);
  inset-inline-end: var(--pf-v5-c-masthead__main--before--Right);
}

.pf-v5-c-masthead__main:last-child {
  --pf-v5-c-masthead__main--MarginRight: 0;
}

.pf-v5-c-masthead__content {
  grid-column: var(--pf-v5-c-masthead__content--GridColumn);
  flex-grow: 1;
  flex-shrink: 1;
  order: var(--pf-v5-c-masthead__content--Order);
  min-height: var(--pf-v5-c-masthead__content--MinHeight);
  grid-column-end: -1;
  align-self: stretch;
  align-items: center;
  margin-inline-start: var(--pf-v5-c-masthead__content--MarginLeft);
  padding-block-start: var(--pf-v5-c-masthead__content--PaddingTop);
  padding-block-end: var(--pf-v5-c-masthead__content--PaddingBottom);
  display: flex;
}

.pf-v5-c-masthead__content:only-child {
  --pf-v5-c-masthead__content--MarginLeft: 0;
}

.pf-v5-c-masthead__content .pf-v5-c-nav.pf-m-horizontal {
  margin-inline-start: var(--pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft);
  margin-inline-end: var(--pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight);
}

.pf-v5-c-masthead__toggle ~ .pf-v5-c-masthead__content {
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: calc(var(--pf-v5-c-masthead__content--MarginLeft) * -1);
}

.pf-v5-c-masthead__main, .pf-v5-c-masthead__brand, .pf-v5-c-masthead__content {
  min-width: 0;
}

.pf-v5-c-masthead__brand {
  align-self: center;
  display: inline-flex;
}

.pf-v5-c-masthead__toggle {
  grid-column: var(--pf-v5-c-masthead__toggle--GridColumn);
  align-self: center;
  margin-inline-start: var(--pf-v5-c-masthead__toggle--MarginLeft);
  margin-inline-end: var(--pf-v5-c-masthead__toggle--MarginRight);
}

.pf-v5-c-masthead__toggle .pf-v5-c-button {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-masthead__toggle--c-button--FontSize);
}

.pf-v5-c-masthead.pf-m-display-stack {
  --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
  --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
  --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
  --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
  --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
  --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
  --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
  --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
  --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
  --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
  --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
  --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
  --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
  --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
  --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
}

.pf-v5-c-masthead.pf-m-display-inline {
  --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
  --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
  --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
  --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
  --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
  --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
  --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
  --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
  --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
  --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
  --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
  --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
  --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
  --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
  --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
}

.pf-v5-c-masthead.pf-m-inset-none {
  --pf-v5-c-masthead--inset: 0;
}

.pf-v5-c-masthead.pf-m-inset-sm {
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-masthead.pf-m-inset-md {
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-masthead.pf-m-inset-lg {
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-masthead.pf-m-inset-xl {
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
}

.pf-v5-c-masthead.pf-m-inset-2xl {
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
}

@media (width >= 576px) {
  .pf-v5-c-masthead.pf-m-display-stack-on-sm {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-display-inline-on-sm {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-inset-none-on-sm {
    --pf-v5-c-masthead--inset: 0;
  }

  .pf-v5-c-masthead.pf-m-inset-sm-on-sm {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-masthead.pf-m-inset-md-on-sm {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-masthead.pf-m-inset-lg-on-sm {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-masthead.pf-m-inset-xl-on-sm {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-masthead.pf-m-inset-2xl-on-sm {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (width >= 768px) {
  .pf-v5-c-masthead.pf-m-display-stack-on-md {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-display-inline-on-md {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-inset-none-on-md {
    --pf-v5-c-masthead--inset: 0;
  }

  .pf-v5-c-masthead.pf-m-inset-sm-on-md {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-masthead.pf-m-inset-md-on-md {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-masthead.pf-m-inset-lg-on-md {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-masthead.pf-m-inset-xl-on-md {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-masthead.pf-m-inset-2xl-on-md {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (width >= 992px) {
  .pf-v5-c-masthead.pf-m-display-stack-on-lg {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-display-inline-on-lg {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-inset-none-on-lg {
    --pf-v5-c-masthead--inset: 0;
  }

  .pf-v5-c-masthead.pf-m-inset-sm-on-lg {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-masthead.pf-m-inset-md-on-lg {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-masthead.pf-m-inset-lg-on-lg {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-masthead.pf-m-inset-xl-on-lg {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-masthead.pf-m-inset-2xl-on-lg {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (width >= 1200px) {
  .pf-v5-c-masthead.pf-m-display-stack-on-xl {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-display-inline-on-xl {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-inset-none-on-xl {
    --pf-v5-c-masthead--inset: 0;
  }

  .pf-v5-c-masthead.pf-m-inset-sm-on-xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-masthead.pf-m-inset-md-on-xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-masthead.pf-m-inset-lg-on-xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-masthead.pf-m-inset-xl-on-xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-masthead.pf-m-inset-2xl-on-xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (width >= 1450px) {
  .pf-v5-c-masthead.pf-m-display-stack-on-2xl {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-display-inline-on-2xl {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-inset-none-on-2xl {
    --pf-v5-c-masthead--inset: 0;
  }

  .pf-v5-c-masthead.pf-m-inset-sm-on-2xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-masthead.pf-m-inset-md-on-2xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-masthead.pf-m-inset-lg-on-2xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-masthead.pf-m-inset-xl-on-2xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-masthead.pf-m-inset-2xl-on-2xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
  }
}

:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-masthead {
  --pf-v5-c-masthead--BackgroundColor: var(--pf-v5-global--palette--black-1000);
  --pf-v5-c-masthead--item-border-color--base: var(--pf-v5-global--BorderColor--100);
  color: var(--pf-v5-global--Color--100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-toolbar {
  --pf-v5-c-toolbar--BackgroundColor: var(--pf-v5-global--palette--black-1000);
}

.pf-v5-c-menu-toggle {
  --pf-v5-c-menu-toggle--BorderRadius: 0;
  --pf-v5-c-menu-toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-menu-toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-menu-toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-menu-toggle--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--before--BorderBottomWidth: 0;
  --pf-v5-c-menu-toggle--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--before--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--before--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--before--BorderBottomColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--hover--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--focus--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--active--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--active--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-expanded--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-expanded--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-menu-toggle--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-primary--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-menu-toggle--m-primary--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-menu-toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-primary--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--m-expanded--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--m-expanded--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-menu-toggle--m-secondary--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-menu-toggle--m-secondary--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--hover--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--focus--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--active--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-secondary--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-secondary--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-secondary--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--hover--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--focus--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--active--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--m-expanded--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--m-expanded--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu-toggle--m-plain--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle--m-plain--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle--m-plain--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-menu-toggle--m-plain--m-expanded--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle__icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__count--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__controls--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle__controls--MarginLeft: auto;
  --pf-v5-c-menu-toggle__controls--MarginRight: 0;
  --pf-v5-c-menu-toggle__toggle-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu-toggle--m-plain--hover__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--active__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--focus__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--m-expanded__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-full-height--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-menu-toggle--m-full-height--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-menu-toggle--m-full-height__toggle--before--BorderTopWidth: 0;
  --pf-v5-c-menu-toggle--m-full-height__toggle--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-full-height--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-full-height--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-full-height--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-full-height--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-split-button--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-menu-toggle--m-split-button--child--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-split-button--first-child--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-split-button--last-child--PaddingLeft: 0;
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--Left: 0;
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BorderLeftColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--m-expanded--child--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-secondary--child--BorderLeftColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle__button--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle__button--AlignSelf: baseline;
  --pf-v5-c-menu-toggle__button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button__controls--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button__controls--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button--m-text--PaddingInlineStart: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-menu-toggle--m-typeahead__controls--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-typeahead__controls--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-typeahead--c-text-input-group__utilities--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-typeahead__button--AlignSelf: center;
  --pf-v5-c-menu-toggle__status-icon--MarginInlineEnd: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle--m-success--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-success--after--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-menu-toggle--m-success__status-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-menu-toggle--m-warning--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-warning--after--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-menu-toggle--m-warning__status-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-menu-toggle--m-danger--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-danger--after--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-menu-toggle--m-danger__status-icon--Color: var(--pf-v5-global--danger-color--100);
  font-size: var(--pf-v5-c-menu-toggle--FontSize);
  line-height: var(--pf-v5-c-menu-toggle--LineHeight);
  color: var(--pf-v5-c-menu-toggle--Color);
  cursor: pointer;
  background-color: var(--pf-v5-c-menu-toggle--BackgroundColor);
  border-radius: var(--pf-v5-c-menu-toggle--BorderRadius);
  border: 0;
  align-items: center;
  max-width: 100%;
  padding-block-start: var(--pf-v5-c-menu-toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu-toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu-toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu-toggle--PaddingRight);
  display: inline-flex;
  position: relative;
}

.pf-v5-c-menu-toggle:before, .pf-v5-c-menu-toggle:after {
  pointer-events: none;
  content: "";
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
}

.pf-v5-c-menu-toggle:before {
  border-style: solid;
  border-block-start-color: var(--pf-v5-c-menu-toggle--before--BorderTopColor);
  border-block-start-width: var(--pf-v5-c-menu-toggle--before--BorderTopWidth);
  border-block-end-color: var(--pf-v5-c-menu-toggle--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-menu-toggle--before--BorderBottomWidth);
  border-inline-start-color: var(--pf-v5-c-menu-toggle--before--BorderLeftColor);
  border-inline-start-width: var(--pf-v5-c-menu-toggle--before--BorderLeftWidth);
  border-inline-end-color: var(--pf-v5-c-menu-toggle--before--BorderRightColor);
  border-inline-end-width: var(--pf-v5-c-menu-toggle--before--BorderRightWidth);
}

.pf-v5-c-menu-toggle:after {
  border-block-end: var(--pf-v5-c-menu-toggle--after--BorderBottomWidth) solid var(--pf-v5-c-menu-toggle--after--BorderBottomColor);
}

.pf-v5-c-menu-toggle.pf-m-primary {
  --pf-v5-c-menu-toggle--BorderRadius: var(--pf-v5-c-menu-toggle--m-primary--BorderRadius);
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--m-primary--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--BackgroundColor);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--hover--BackgroundColor);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--focus--BackgroundColor);
  --pf-v5-c-menu-toggle--active--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--active--BackgroundColor);
  --pf-v5-c-menu-toggle--m-expanded--Color: var(--pf-v5-c-menu-toggle--m-primary--m-expanded--Color);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--m-expanded--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-primary, .pf-v5-c-menu-toggle.pf-m-primary:before {
  border-radius: var(--pf-v5-c-menu-toggle--m-primary--BorderRadius);
}

.pf-v5-c-menu-toggle.pf-m-secondary {
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--m-secondary--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--BackgroundColor);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--hover--BackgroundColor);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--focus--BackgroundColor);
  --pf-v5-c-menu-toggle--active--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--active--BackgroundColor);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--m-expanded--BackgroundColor);
  --pf-v5-c-menu-toggle--m-expanded--Color: var(--pf-v5-c-menu-toggle--m-secondary--m-expanded--Color);
}

.pf-v5-c-menu-toggle.pf-m-secondary, .pf-v5-c-menu-toggle.pf-m-secondary:before {
  border-radius: var(--pf-v5-c-menu-toggle--m-secondary--BorderRadius);
}

.pf-v5-c-menu-toggle.pf-m-secondary:before {
  border-color: var(--pf-v5-c-menu-toggle--m-secondary--before--BorderColor);
  border-width: var(--pf-v5-c-menu-toggle--m-secondary--before--BorderWidth);
}

.pf-v5-c-menu-toggle.pf-m-secondary:after {
  border: 0;
}

.pf-v5-c-menu-toggle.pf-m-secondary:hover {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-secondary--hover--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-secondary--hover--before--BorderWidth);
}

.pf-v5-c-menu-toggle.pf-m-secondary:focus {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-secondary--focus--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-secondary--focus--before--BorderWidth);
}

.pf-v5-c-menu-toggle.pf-m-secondary:active {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-secondary--active--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-secondary--active--before--BorderWidth);
}

.pf-v5-c-menu-toggle.pf-m-secondary.pf-m-expanded {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderWidth);
}

.pf-v5-c-menu-toggle.pf-m-plain {
  --pf-v5-c-menu-toggle__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain__toggle-icon--Color);
}

.pf-v5-c-menu-toggle.pf-m-plain:not(.pf-m-text) {
  --pf-v5-c-menu-toggle--PaddingRight: var(--pf-v5-c-menu-toggle--m-plain--PaddingRight);
  --pf-v5-c-menu-toggle--PaddingLeft: var(--pf-v5-c-menu-toggle--m-plain--PaddingLeft);
  --pf-v5-c-menu-toggle--disabled--BackgroundColor: transparent;
  color: var(--pf-v5-c-menu-toggle--m-plain--Color);
  display: inline-block;
}

.pf-v5-c-menu-toggle.pf-m-full-height {
  --pf-v5-c-menu-toggle--PaddingRight: var(--pf-v5-c-menu-toggle--m-full-height--PaddingRight);
  --pf-v5-c-menu-toggle--PaddingLeft: var(--pf-v5-c-menu-toggle--m-full-height--PaddingLeft);
  --pf-v5-c-menu-toggle--before--BorderTopWidth: var(--pf-v5-c-menu-toggle--m-full-height__toggle--before--BorderTopWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height__toggle--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-expanded--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--m-expanded--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--hover--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--hover--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--focus--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--focus--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--active--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--active--after--BorderBottomWidth);
  align-items: center;
  height: 100%;
}

.pf-v5-c-menu-toggle:hover {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--hover--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--hover--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--hover--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--hover__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--hover--Color);
}

.pf-v5-c-menu-toggle:focus, .pf-v5-c-menu-toggle:focus-within {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--focus--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--focus--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--focus--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--focus__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--focus--Color);
}

.pf-v5-c-menu-toggle:active {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--active--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--active--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--active--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--active__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--active--Color);
}

.pf-v5-c-menu-toggle.pf-m-expanded {
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--m-expanded--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--m-expanded--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-expanded--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-expanded--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--m-expanded__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--m-expanded--Color);
}

.pf-v5-c-menu-toggle:disabled, .pf-v5-c-menu-toggle.pf-m-disabled {
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--disabled--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--disabled--BackgroundColor);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--disabled--Color);
  pointer-events: none;
}

.pf-v5-c-menu-toggle.pf-m-primary:before, .pf-v5-c-menu-toggle.pf-m-primary:after, .pf-v5-c-menu-toggle.pf-m-plain:before, .pf-v5-c-menu-toggle.pf-m-plain:after, .pf-v5-c-menu-toggle:disabled:before, .pf-v5-c-menu-toggle:disabled:after {
  border: 0;
}

.pf-v5-c-menu-toggle.pf-m-typeahead {
  --pf-v5-c-menu-toggle__button__controls--MarginRight: var(--pf-v5-c-menu-toggle--m-typeahead__controls--MarginRight);
  --pf-v5-c-menu-toggle__button__controls--MarginLeft: var(--pf-v5-c-menu-toggle--m-typeahead__controls--MarginLeft);
  --pf-v5-c-menu-toggle__button--AlignSelf: var(--pf-v5-c-menu-toggle--m-typeahead__button--AlignSelf);
  align-items: stretch;
  padding: 0;
}

.pf-v5-c-menu-toggle.pf-m-typeahead .pf-v5-c-text-input-group {
  --pf-v5-c-text-input-group__utilities--c-button--PaddingRight: var(--pf-v5-c-menu-toggle--m-typeahead--c-text-input-group__utilities--c-button--PaddingRight);
  --pf-v5-c-text-input-group__utilities--MarginRight: 0;
  flex: 1;
}

.pf-v5-c-menu-toggle.pf-m-split-button {
  padding: 0;
}

.pf-v5-c-menu-toggle.pf-m-split-button > * {
  padding-block-start: var(--pf-v5-c-menu-toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu-toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu-toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu-toggle--PaddingRight);
  position: relative;
}

.pf-v5-c-menu-toggle.pf-m-split-button > :first-child {
  padding-inline-end: var(--pf-v5-c-menu-toggle--m-split-button--first-child--PaddingRight);
}

.pf-v5-c-menu-toggle.pf-m-split-button:where(:not(.pf-m-action)) > :last-child {
  padding-inline-start: var(--pf-v5-c-menu-toggle--m-split-button--last-child--PaddingLeft);
}

.pf-v5-c-menu-toggle.pf-m-split-button > .pf-v5-c-check {
  --pf-v5-c-menu-toggle--PaddingRight: 0;
  --pf-v5-c-check__label--Color: currentcolor;
  --pf-v5-c-check__label--disabled--Color: currentcolor;
  align-self: stretch;
  align-items: center;
}

.pf-v5-c-menu-toggle.pf-m-split-button > .pf-v5-c-check .pf-v5-c-check__input {
  --pf-v5-c-check__input--TranslateY: 0;
  align-self: center;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled)) > :after {
  pointer-events: none;
  content: "";
  border-block-end: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth) solid var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor);
  position: absolute;
  inset-block: 0;
  inset-inline-start: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--after--Left);
  inset-inline-end: 0;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled)) > :hover {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled)) > :focus {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled)) > :active, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled)) > .pf-m-active {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BorderLeftColor);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: 0;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :where(:not(.pf-m-disabled):not([disabled])) {
  background-color: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :where(:not(.pf-m-disabled):not([disabled])):hover {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--hover--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :where(:not(.pf-m-disabled):not([disabled])):focus {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--focus--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :where(:not(.pf-m-disabled):not([disabled])):active, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :where(:not(.pf-m-disabled):not([disabled])).pf-m-active {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--active--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary.pf-m-expanded {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--m-expanded--child--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-secondary--child--BorderLeftColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: 0;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :first-child, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary > :first-child {
  border-start-start-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
  border-end-start-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :last-child, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary > :last-child {
  border-start-end-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
  border-end-end-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action > :not(:first-child) {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--Left: calc(var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftWidth) * -1);
  border-inline-start: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftWidth) solid var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:not(.pf-m-expanded) {
  --pf-v5-c-menu-toggle--after--BorderBottomColor: transparent;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-disabled, .pf-v5-c-menu-toggle.pf-m-split-button:disabled {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: transparent;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-disabled:before, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-disabled:after, .pf-v5-c-menu-toggle.pf-m-split-button:disabled:before, .pf-v5-c-menu-toggle.pf-m-split-button:disabled:after {
  content: none;
}

.pf-v5-c-menu-toggle.pf-m-split-button > .pf-m-disabled, .pf-v5-c-menu-toggle.pf-m-split-button > :disabled {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: transparent;
  color: var(--pf-v5-c-menu-toggle--m-split-button--child--disabled--Color);
  background-color: var(--pf-v5-c-menu-toggle--m-split-button--child--disabled--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-typeahead, .pf-v5-c-menu-toggle.pf-m-split-button {
  --pf-v5-c-menu-toggle__toggle-icon--MarginRight: 0;
}

.pf-v5-c-menu-toggle.pf-m-full-width {
  width: 100%;
}

.pf-v5-c-menu-toggle.pf-m-success {
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-success--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-success--after--BorderBottomColor);
  --pf-v5-c-menu-toggle__status-icon--Color: var(--pf-v5-c-menu-toggle--m-success__status-icon--Color);
}

.pf-v5-c-menu-toggle.pf-m-warning {
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-warning--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-warning--after--BorderBottomColor);
  --pf-v5-c-menu-toggle__status-icon--Color: var(--pf-v5-c-menu-toggle--m-warning__status-icon--Color);
}

.pf-v5-c-menu-toggle.pf-m-danger {
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-danger--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-danger--after--BorderBottomColor);
  --pf-v5-c-menu-toggle__status-icon--Color: var(--pf-v5-c-menu-toggle--m-danger__status-icon--Color);
}

.pf-v5-c-menu-toggle__button {
  --pf-v5-c-menu-toggle__controls--PaddingLeft: 0;
  --pf-v5-c-menu-toggle__controls--MarginRight: var(--pf-v5-c-menu-toggle__button__controls--MarginRight);
  --pf-v5-c-menu-toggle__controls--MarginLeft: var(--pf-v5-c-menu-toggle__button__controls--MarginLeft);
  align-self: var(--pf-v5-c-menu-toggle__button--AlignSelf);
  color: inherit;
  background-color: var(--pf-v5-c-menu-toggle__button--BackgroundColor);
  border: 0;
  padding-inline-start: var(--pf-v5-c-menu-toggle__button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu-toggle__button--PaddingRight);
}

.pf-v5-c-menu-toggle__button.pf-m-text {
  --pf-v5-c-menu-toggle--m-split-button--last-child--PaddingLeft: var(--pf-v5-c-menu-toggle__button--m-text--PaddingInlineStart);
  align-items: baseline;
  padding-inline-start: var(--pf-v5-c-menu-toggle__button--m-text--PaddingInlineStart);
  display: inline-flex;
}

.pf-v5-c-menu-toggle__icon {
  flex-shrink: 0;
  align-self: center;
  margin-inline-end: var(--pf-v5-c-menu-toggle__icon--MarginRight);
  line-height: 1;
}

.pf-v5-c-menu-toggle__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
}

.pf-v5-c-menu-toggle__count {
  flex-wrap: nowrap;
  margin-inline-start: var(--pf-v5-c-menu-toggle__count--MarginLeft);
  display: flex;
}

.pf-v5-c-menu-toggle__controls {
  margin-inline-start: var(--pf-v5-c-menu-toggle__controls--MarginLeft);
  margin-inline-end: var(--pf-v5-c-menu-toggle__controls--MarginRight);
  padding-inline-start: var(--pf-v5-c-menu-toggle__controls--PaddingLeft);
  display: flex;
}

.pf-v5-c-menu-toggle__toggle-icon {
  color: var(--pf-v5-c-menu-toggle__toggle-icon--Color, inherit);
  margin-inline-end: var(--pf-v5-c-menu-toggle__toggle-icon--MarginRight);
}

.pf-v5-c-menu-toggle__status-icon {
  color: var(--pf-v5-c-menu-toggle__status-icon--Color, inherit);
  margin-inline-end: var(--pf-v5-c-menu-toggle__status-icon--MarginInlineEnd);
}

:where(.pf-v5-theme-dark) .pf-v5-c-menu-toggle {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--before--BorderTopColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderRightColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderBottomColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderLeftColor: transparent;
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-menu-toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-menu-toggle--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-menu-toggle--disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
}

:where(.pf-v5-theme-dark) .pf-v5-c-menu-toggle.pf-m-plain, :where(.pf-v5-theme-dark) .pf-v5-c-menu-toggle.pf-m-full-height {
  background: none;
}

.pf-v5-c-nav {
  --pf-v5-c-nav--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-nav--m-light__item--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-nav--m-light__item--m-current--not--m-expanded__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-nav--m-light__link--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-light__link--hover--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-light__link--focus--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-light__link--active--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-light__link--m-current--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-light__link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-nav--m-light__link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-nav--m-light__link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-nav--m-light__link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-nav--m-light__link--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-nav--m-light__link--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-light__link--m-current--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-light__section-title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-nav--m-light__section-title--BorderBottomColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-nav--m-light--c-divider--BackgroundColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-nav--m-light__subnav__link--hover--after--BorderColor: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-c-nav--m-light__subnav__link--focus--after--BorderColor: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-c-nav--m-light__subnav__link--active--after--BorderColor: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-c-nav--m-light__subnav__link--m-current--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav__list--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__list--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__item--MarginTop: 0;
  --pf-v5-c-nav__item--m-current--not--m-expanded__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-400);
  --pf-v5-c-nav__link--m-current--not--m-expanded__link--after--BorderWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-nav__item__item__link--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-nav__item__item__toggle--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav__item__toggle-icon--Rotate: 0;
  --pf-v5-c-nav__item--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-nav__item--before--BorderColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__item--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__link--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-nav__link--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__link--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__link--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__link--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__link--hover--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__link--focus--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__link--active--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__link--m-current--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__link--BackgroundColor: transparent;
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-400);
  --pf-v5-c-nav__link--OutlineOffset: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__link--hover--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--focus--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--active--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--m-current--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__link--hover--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__link--focus--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__link--active--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__link--m-current--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__link--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--hover--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--focus--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--active--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-nav--m-horizontal__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-horizontal__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-horizontal__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal__link--lg--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--m-horizontal__link--lg--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--m-horizontal__link--Right: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal__link--Left: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal__link--Color: var(--pf-v5-global--Color--light-200);
  --pf-v5-c-nav--m-horizontal__link--hover--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--m-horizontal__link--focus--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--m-horizontal__link--active--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--m-horizontal__link--m-current--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--m-horizontal__link--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal__link--hover--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal__link--focus--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal__link--active--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal__link--m-current--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal__link--before--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--m-horizontal__link--before--BorderWidth: 0;
  --pf-v5-c-nav--m-horizontal__link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-horizontal__link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-horizontal__link--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-horizontal__link--m-current--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-tertiary__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-tertiary__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-tertiary__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-tertiary__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-tertiary__link--Right: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-tertiary__link--Left: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-tertiary__link--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-tertiary__link--hover--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__link--focus--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__link--active--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__link--m-current--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__link--BackgroundColor: transparent;
  --pf-v5-c-nav--m-tertiary__link--hover--BackgroundColor: transparent;
  --pf-v5-c-nav--m-tertiary__link--focus--BackgroundColor: transparent;
  --pf-v5-c-nav--m-tertiary__link--active--BackgroundColor: transparent;
  --pf-v5-c-nav--m-tertiary__link--m-current--BackgroundColor: transparent;
  --pf-v5-c-nav--m-tertiary__link--before--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__link--before--BorderWidth: 0;
  --pf-v5-c-nav--m-tertiary__link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-tertiary__link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-tertiary__link--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-tertiary__link--m-current--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-tertiary__scroll-button--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-tertiary__scroll-button--hover--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__scroll-button--focus--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__scroll-button--active--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__scroll-button--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-nav--m-tertiary__scroll-button--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-nav--m-tertiary__scroll-button--disabled--before--BorderColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-nav--m-horizontal-subnav__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal-subnav__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--m-horizontal-subnav__link--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--Color: var(--pf-v5-global--Color--light-200);
  --pf-v5-c-nav--m-horizontal-subnav__link--hover--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--m-horizontal-subnav__link--focus--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--m-horizontal-subnav__link--active--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--m-horizontal-subnav__link--m-current--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--m-horizontal-subnav__link--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal-subnav__link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--m-horizontal-subnav__link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--m-horizontal-subnav__link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--m-horizontal-subnav__link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-400);
  --pf-v5-c-nav--m-horizontal-subnav__link--before--BorderColor: transparent;
  --pf-v5-c-nav--m-horizontal-subnav__link--after--BorderColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-nav--m-horizontal-subnav__link--hover--after--BorderColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-nav--m-horizontal-subnav__link--active--after--BorderColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-nav--m-horizontal-subnav__link--m-current--after--BorderColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-nav--m-horizontal-subnav__link--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--hover--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--active--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--m-current--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__subnav--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__subnav--xl--PaddingLeft: var(--pf-v5-c-nav__link--PaddingLeft);
  --pf-v5-c-nav__subnav__link--MarginTop: 0;
  --pf-v5-c-nav__subnav__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__subnav__link--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__subnav__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__subnav__link--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__subnav__link--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav__subnav__link--hover--after--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-nav__subnav__link--focus--after--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-nav__subnav__link--active--after--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-nav__subnav__link--m-current--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__subnav__link--hover--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__subnav__link--focus--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__subnav__link--active--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__subnav__link--m-current--after--BorderWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-nav__subnav--MaxHeight: 0;
  --pf-v5-c-nav__subnav__subnav--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__subnav__subnav__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__subnav__subnav__link--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-nav__item--m-expanded__subnav--MaxHeight: 100%;
  --pf-v5-c-nav__subnav--c-divider--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__subnav--c-divider--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__section--first-child--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section--last-child--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section__item--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__section__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__section__link--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__section__link--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__section__link--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-nav__section__link--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__section__link--hover--after--BorderColor: transparent;
  --pf-v5-c-nav__section__link--focus--after--BorderColor: transparent;
  --pf-v5-c-nav__section__link--active--after--BorderColor: transparent;
  --pf-v5-c-nav__section__link--m-current--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__section__link--hover--after--BorderWidth: 0;
  --pf-v5-c-nav__section__link--focus--after--BorderWidth: 0;
  --pf-v5-c-nav__section__link--active--after--BorderWidth: 0;
  --pf-v5-c-nav__section__link--m-current--after--BorderWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-nav__section--section--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-nav__section-title--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section-title--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__section-title--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section-title--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__section-title--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section-title--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__section-title--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__section-title--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav__section-title--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__section-title--BorderBottomColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__section-title--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__scroll-button--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__scroll-button--hover--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__scroll-button--focus--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__scroll-button--active--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__scroll-button--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-nav__scroll-button--BackgroundColor: transparent;
  --pf-v5-c-nav__scroll-button--Width: var(--pf-v5-global--target-size--MinWidth);
  --pf-v5-c-nav__scroll-button--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-nav__scroll-button--Transition: margin .125s, transform .125s, opacity .125s;
  --pf-v5-c-nav__scroll-button--before--BorderColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__scroll-button--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__scroll-button--before--BorderRightWidth: 0;
  --pf-v5-c-nav__scroll-button--before--BorderLeftWidth: 0;
  --pf-v5-c-nav__scroll-button--disabled--before--BorderColor: transparent;
  --pf-v5-c-nav__toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__toggle--FontSize: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-nav__toggle-icon--Transition: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-nav--c-divider--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--c-divider--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--c-divider--PaddingRight: 0;
  --pf-v5-c-nav--c-divider--PaddingLeft: 0;
  --pf-v5-c-nav--c-divider--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__list--ScrollSnapTypeAxis: x;
  --pf-v5-c-nav__list--ScrollSnapTypeStrictness: proximity;
  --pf-v5-c-nav__list--ScrollSnapType: var(--pf-v5-c-nav__list--ScrollSnapTypeAxis) var(--pf-v5-c-nav__list--ScrollSnapTypeStrictness);
  --pf-v5-c-nav__item--ScrollSnapAlign: end;
  --pf-v5-c-nav__item--m-flyout--c-menu--top-offset: 0px;
  --pf-v5-c-nav__item--m-flyout--c-menu--left-offset: .25rem;
  --pf-v5-c-nav__item--m-flyout--c-menu--m-top--bottom-offset: 0px;
  --pf-v5-c-nav__item--m-flyout--c-menu--m-left--right-offset: .25rem;
  --pf-v5-c-nav__item--m-flyout--MarginTop: 0;
  --pf-v5-c-nav__item--m-flyout--hover__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__item--m-flyout--focus__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__item--m-flyout--active__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__item--m-flyout--hover__link--before--BorderWidth: 0;
  --pf-v5-c-nav__item--m-flyout--focus__link--before--BorderWidth: 0;
  --pf-v5-c-nav__item--m-flyout--active__link--before--BorderWidth: 0;
  --pf-v5-c-nav__item--m-flyout__item--last-child__link--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__item--m-flyout--c-menu--Top: calc(0px + var(--pf-v5-c-nav__item--m-flyout--c-menu--top-offset));
  --pf-v5-c-nav__item--m-flyout--c-menu--c-menu--Top: calc(var(--pf-v5-c-nav__item--m-flyout--c-menu--Top)  - var(--pf-v5-c-nav__item--before--BorderWidth));
  --pf-v5-c-nav__item--m-flyout--c-menu__list-item--first-child--c-menu--Top: 0;
  --pf-v5-c-nav__item--m-flyout--c-menu--Left: calc(100% - var(--pf-v5-c-nav__item--m-flyout--c-menu--left-offset));
  --pf-v5-c-nav__item--m-flyout--c-menu--m-left--Right: calc(100% - var(--pf-v5-c-nav__item--m-flyout--c-menu--m-left--right-offset));
  --pf-v5-c-nav__item--m-flyout--c-menu--m-top--Bottom: calc(0 + var(--pf-v5-c-nav__item--m-flyout--c-menu--m-top--bottom-offset));
  --pf-v5-c-nav__item--m-flyout--c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--Color: var(--pf-v5-c-nav__link--Color);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__item--m-flyout--c-menu__item-description--Color: var(--pf-v5-global--Color--light-200);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--OutlineOffset: var(--pf-v5-c-nav__link--OutlineOffset);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--before--BorderBottomWidth: var(--pf-v5-c-nav__item--before--BorderWidth);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--before--BorderBottomColor: var(--pf-v5-c-nav__item--before--BorderColor);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--Top: calc(-1 * var(--pf-v5-c-nav__item--m-flyout--c-menu__item--before--BorderBottomWidth));
  --pf-v5-c-nav__item--m-flyout--c-menu__list-item--hover--BackgroundColor: var(--pf-v5-c-nav__link--hover--BackgroundColor);
  --pf-v5-c-nav__item--m-flyout--c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-c-nav__link--focus--BackgroundColor);
  --pf-v5-c-nav__item--m-flyout--c-menu__list-item--active--BackgroundColor: var(--pf-v5-c-nav__link--active--BackgroundColor);
  --pf-v5-c-nav__item--m-flyout--c-menu__list-item--first-child__item--hover--after--Top: 0;
  --pf-v5-c-nav__item--m-flyout--c-menu--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-nav--c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-nav--c-menu__list--PaddingTop: 0;
  --pf-v5-c-nav--c-menu__list--PaddingBottom: 0;
  --pf-v5-c-nav--c-menu__list-item--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--c-menu__list-item--hover--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--c-menu__list-item--active--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--c-menu__list-item--focus-within--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--c-menu__list-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--c-menu__list-item--m-drill-up--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--c-menu__list-item--m-drill-up__item--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-nav--c-menu__item--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--c-menu__item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--c-menu__item--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--c-menu__item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--c-menu__item--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--c-menu__item--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--c-menu__item--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav--c-menu__item--OutlineOffset: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-nav--c-menu__item--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav--c-menu__item--before--BorderBottomColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--c-menu__item--after--BorderLeftWidth: 0;
  --pf-v5-c-nav--c-menu__item--after--BorderLeftColor: transparent;
  --pf-v5-c-nav--c-menu__item--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-400);
  --pf-v5-c-nav--c-menu__item--m-current--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-nav--c-menu__item--m-current--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--c-menu__item-description--Color: var(--pf-v5-global--Color--400);
  --pf-v5-c-nav--c-menu--m-flyout--left-offset: .25rem;
  --pf-v5-c-nav--c-menu--m-flyout--m-left--right-offset: .25rem;
  --pf-v5-c-nav--c-menu--m-flyout--Top: 0;
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Top: calc(var(--pf-v5-c-nav--c-menu--m-flyout--Top) * -1);
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Right: auto;
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Bottom: auto;
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Left: calc(100% - var(--pf-v5-c-nav--c-menu--m-flyout--left-offset));
  --pf-v5-c-nav--c-menu--m-flyout--m-left--Right: calc(100% - var(--pf-v5-c-nav--c-menu--m-flyout--m-left--right-offset));
  --pf-v5-c-nav--c-menu--m-flyout--m-top--Bottom: 0;
  --pf-v5-c-nav--c-menu--m-flyout--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-nav--c-menu--m-flyout__item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--c-menu--m-flyout__item--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--c-menu--m-flyout__item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--c-menu--m-flyout__item--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-nav__toggle--m-start--MarginRight: var(--pf-v5-global--spacer--sm);
  position: relative;
}

@media screen and (width >= 1200px) {
  .pf-v5-c-nav {
    --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav__link--xl--PaddingRight);
    --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav__link--xl--PaddingLeft);
    --pf-v5-c-nav--m-horizontal-subnav__link--PaddingTop: var(--pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingTop);
    --pf-v5-c-nav--m-horizontal-subnav__link--PaddingRight: var(--pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingRight);
    --pf-v5-c-nav--m-horizontal-subnav__link--PaddingBottom: var(--pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingBottom);
    --pf-v5-c-nav--m-horizontal-subnav__link--PaddingLeft: var(--pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingLeft);
    --pf-v5-c-nav__section__link--PaddingRight: var(--pf-v5-c-nav__section__link--xl--PaddingRight);
    --pf-v5-c-nav__section__link--PaddingLeft: var(--pf-v5-c-nav__section__link--xl--PaddingLeft);
    --pf-v5-c-nav__section-title--PaddingRight: var(--pf-v5-c-nav__section-title--xl--PaddingRight);
    --pf-v5-c-nav__section-title--PaddingLeft: var(--pf-v5-c-nav__section-title--xl--PaddingLeft);
    --pf-v5-c-nav__subnav--PaddingLeft: var(--pf-v5-c-nav__subnav--xl--PaddingLeft);
    --pf-v5-c-nav--c-menu__item--PaddingRight: var(--pf-v5-c-nav--c-menu__item--xl--PaddingRight);
    --pf-v5-c-nav--c-menu__item--PaddingLeft: var(--pf-v5-c-nav--c-menu__item--xl--PaddingLeft);
  }
}

.pf-v5-c-nav .pf-v5-c-menu {
  --pf-v5-c-menu--MinWidth: 100%;
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-c-nav--c-menu--BackgroundColor);
  --pf-v5-c-menu__list--PaddingTop: var(--pf-v5-c-nav--c-menu__list--PaddingTop);
  --pf-v5-c-menu__list--PaddingBottom: var(--pf-v5-c-nav--c-menu__list--PaddingBottom);
  --pf-v5-c-menu__item--PaddingTop: var(--pf-v5-c-nav--c-menu__item--PaddingTop);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-c-nav--c-menu__item--PaddingRight);
  --pf-v5-c-menu__item--PaddingBottom: var(--pf-v5-c-nav--c-menu__item--PaddingBottom);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-c-nav--c-menu__item--PaddingLeft);
  --pf-v5-c-menu__list-item--Color: var(--pf-v5-c-nav--c-menu__list-item--Color);
  --pf-v5-c-menu__list-item--hover--Color: var(--pf-v5-c-nav--c-menu__list-item--hover--Color);
  --pf-v5-c-menu__list-item--active--Color: var(--pf-v5-c-nav--c-menu__list-item--active--Color);
  --pf-v5-c-menu__list-item--focus-within--Color: var(--pf-v5-c-nav--c-menu__list-item--focus-within--Color);
  --pf-v5-c-menu__list-item--hover--BackgroundColor: var(--pf-v5-c-nav--c-menu__list-item--hover--BackgroundColor);
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-c-nav--c-menu__list-item--focus-within--BackgroundColor);
  --pf-v5-c-menu__item-description--Color: var(--pf-v5-c-nav--c-menu__item-description--Color);
}

.pf-v5-c-nav .pf-v5-c-menu:first-child > .pf-v5-c-menu__content {
  border-block-end: var(--pf-v5-c-nav--c-menu__item--before--BorderBottomWidth) solid var(--pf-v5-c-nav--c-menu__item--before--BorderBottomColor);
}

.pf-v5-c-nav .pf-v5-c-menu.pf-m-flyout, .pf-v5-c-nav .pf-v5-c-menu.pf-m-flyout .pf-v5-c-menu {
  --pf-v5-c-menu--BoxShadow: var(--pf-v5-c-nav--c-menu--m-flyout--BoxShadow);
  --pf-v5-c-menu__list--PaddingTop: 0;
  --pf-v5-c-menu__list--PaddingBottom: 0;
  --pf-v5-c-menu__item--FontSize: var(--pf-v5-c-nav--c-menu__item--FontSize);
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-nav--c-menu__item--Color, inherit);
  --pf-v5-c-menu__item--PaddingTop: var(--pf-v5-c-nav--c-menu--m-flyout__item--PaddingTop);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-c-nav--c-menu--m-flyout__item--PaddingRight);
  --pf-v5-c-menu__item--PaddingBottom: var(--pf-v5-c-nav--c-menu--m-flyout__item--PaddingBottom);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-c-nav--c-menu--m-flyout__item--PaddingLeft);
  inset-block-start: var(--pf-v5-c-nav--c-menu--m-flyout--c-menu--Top);
  inset-block-end: var(--pf-v5-c-nav--c-menu--m-flyout--c-menu--Bottom);
  inset-inline-start: var(--pf-v5-c-nav--c-menu--m-flyout--c-menu--Left);
  inset-inline-end: var(--pf-v5-c-nav--c-menu--m-flyout--c-menu--Right);
}

.pf-v5-c-nav .pf-v5-c-menu.pf-m-flyout .pf-v5-c-menu__item:hover {
  --pf-v5-c-nav--c-menu__item--after--BorderLeftWidth: var(--pf-v5-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftWidth);
  --pf-v5-c-nav--c-menu__item--after--BorderLeftColor: var(--pf-v5-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftColor);
}

.pf-v5-c-nav .pf-v5-c-menu.pf-m-top {
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Top: auto;
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Bottom: var(--pf-v5-c-nav--c-menu--m-flyout--m-top--Bottom);
}

.pf-v5-c-nav .pf-v5-c-menu.pf-m-left {
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Right: var(--pf-v5-c-nav--c-menu--m-flyout--m-left--Right);
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Left: auto;
}

.pf-v5-c-nav .pf-v5-c-menu .pf-v5-c-divider {
  margin-block: 0;
}

.pf-v5-c-nav .pf-v5-c-menu__item {
  outline-offset: var(--pf-v5-c-nav--c-menu__item--OutlineOffset);
  position: relative;
}

.pf-v5-c-nav .pf-v5-c-menu__item:before, .pf-v5-c-nav .pf-v5-c-menu__item:after {
  content: "";
  position: absolute;
  inset-block-start: 0;
}

.pf-v5-c-nav .pf-v5-c-menu__item:before {
  border-block-end: var(--pf-v5-c-nav--c-menu__item--before--BorderBottomWidth) solid var(--pf-v5-c-nav--c-menu__item--before--BorderBottomColor);
  inset-block-end: calc(var(--pf-v5-c-nav--c-menu__item--before--BorderBottomWidth) * -1);
  inset-inline: 0;
}

.pf-v5-c-nav .pf-v5-c-menu__item:after {
  border-inline-start: var(--pf-v5-c-nav--c-menu__item--after--BorderLeftWidth) solid var(--pf-v5-c-nav--c-menu__item--after--BorderLeftColor);
  inset-block-end: 0;
  inset-inline-start: 0;
}

.pf-v5-c-nav .pf-v5-c-menu__item.pf-m-current {
  --pf-v5-c-nav--c-menu__item--after--BorderLeftWidth: var(--pf-v5-c-nav--c-menu__item--m-current--after--BorderLeftWidth);
  --pf-v5-c-nav--c-menu__item--after--BorderLeftColor: var(--pf-v5-c-nav--c-menu__item--m-current--after--BorderColor);
  background-color: var(--pf-v5-c-nav--c-menu__item--m-current--BackgroundColor);
}

.pf-v5-c-nav .pf-v5-c-menu__list-item:where(.pf-m-drill-up) {
  --pf-v5-c-nav--c-menu__item--before--BorderBottomWidth: var(--pf-v5-c-nav--c-menu__list-item--m-drill-up--before--BorderBottomWidth);
}

.pf-v5-c-nav .pf-v5-c-menu__list-item:where(.pf-m-drill-up) > .pf-v5-c-menu__item {
  --pf-v5-c-menu__item--FontWeight: var(--pf-v5-c-nav--c-menu__list-item--m-drill-up__item--FontWeight);
}

.pf-v5-c-nav .pf-v5-c-menu__item-toggle-icon {
  margin-inline-start: calc(var(--pf-v5-c-menu__item-toggle-icon--PaddingLeft) * -1);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-nav .pf-v5-c-menu__item-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__link:after, .pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__link:after {
  content: none;
}

.pf-v5-c-nav.pf-m-horizontal, .pf-v5-c-nav.pf-m-tertiary, .pf-v5-c-nav.pf-m-horizontal-subnav {
  overflow: hidden;
}

.pf-v5-c-nav.pf-m-horizontal, .pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__list, .pf-v5-c-nav.pf-m-tertiary, .pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__list, .pf-v5-c-nav.pf-m-horizontal-subnav, .pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__list {
  display: flex;
  position: relative;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__list, .pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__list, .pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__list {
  --pf-v5-c-nav__list--PaddingTop: 0;
  --pf-v5-c-nav__list--PaddingBottom: 0;
  white-space: nowrap;
  scroll-snap-type: var(--pf-v5-c-nav__list--ScrollSnapType);
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex: 1;
  max-width: 100%;
  overflow-x: auto;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__list::-webkit-scrollbar {
  display: none;
}

.pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__list::-webkit-scrollbar {
  display: none;
}

.pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__list::-webkit-scrollbar {
  display: none;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__item, .pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__item, .pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__item {
  scroll-snap-align: var(--pf-v5-c-nav__item--ScrollSnapAlign);
  display: flex;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__link, .pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__link, .pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__link {
  white-space: nowrap;
  align-self: stretch;
  align-items: center;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__link:before, .pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__link:before, .pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__link:before {
  inset-block: auto 0;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__link:before {
  inset-inline-start: var(--pf-v5-c-nav--m-horizontal__link--Left);
  inset-inline-end: var(--pf-v5-c-nav--m-horizontal__link--Right);
}

.pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__link:before {
  inset-inline-start: var(--pf-v5-c-nav--m-tertiary__link--Left);
  inset-inline-end: var(--pf-v5-c-nav--m-tertiary__link--Right);
}

.pf-v5-c-nav.pf-m-light {
  --pf-v5-c-nav__item--before--BorderColor: var(--pf-v5-c-nav--m-light__item--before--BorderColor);
  --pf-v5-c-nav__item--m-current--not--m-expanded__link--BackgroundColor: var(--pf-v5-c-nav--m-light__item--m-current--not--m-expanded__link--BackgroundColor);
  --pf-v5-c-nav__link--Color: var(--pf-v5-c-nav--m-light__link--Color);
  --pf-v5-c-nav__link--hover--Color: var(--pf-v5-c-nav--m-light__link--hover--Color);
  --pf-v5-c-nav__link--focus--Color: var(--pf-v5-c-nav--m-light__link--focus--Color);
  --pf-v5-c-nav__link--active--Color: var(--pf-v5-c-nav--m-light__link--active--Color);
  --pf-v5-c-nav__link--m-current--Color: var(--pf-v5-c-nav--m-light__link--m-current--Color);
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-c-nav--m-light__link--hover--BackgroundColor);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-c-nav--m-light__link--focus--BackgroundColor);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-c-nav--m-light__link--active--BackgroundColor);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-c-nav--m-light__link--m-current--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-c-nav--m-light__link--before--BorderColor);
  --pf-v5-c-nav__link--after--BorderColor: var(--pf-v5-c-nav--m-light__link--after--BorderColor);
  --pf-v5-c-nav__link--m-current--after--BorderColor: var(--pf-v5-c-nav--m-light__link--m-current--after--BorderColor);
  --pf-v5-c-nav__subnav__link--hover--after--BorderColor: var(--pf-v5-c-nav--m-light__subnav__link--hover--after--BorderColor);
  --pf-v5-c-nav__subnav__link--focus--after--BorderColor: var(--pf-v5-c-nav--m-light__subnav__link--focus--after--BorderColor);
  --pf-v5-c-nav__subnav__link--active--after--BorderColor: var(--pf-v5-c-nav--m-light__subnav__link--active--after--BorderColor);
  --pf-v5-c-nav__subnav__link--m-current--after--BorderColor: var(--pf-v5-c-nav--m-light__subnav__link--m-current--after--BorderColor);
  --pf-v5-c-nav__section-title--Color: var(--pf-v5-c-nav--m-light__section-title--Color);
  --pf-v5-c-nav__section-title--BorderBottomColor: var(--pf-v5-c-nav--m-light__section-title--BorderBottomColor);
}

.pf-v5-c-nav.pf-m-light .pf-v5-c-divider {
  --pf-v5-c-divider--after--BackgroundColor: var(--pf-v5-c-nav--m-light--c-divider--BackgroundColor);
}

.pf-v5-c-nav.pf-m-horizontal {
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-c-nav--m-horizontal__link--PaddingTop);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav--m-horizontal__link--PaddingRight);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-c-nav--m-horizontal__link--PaddingBottom);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav--m-horizontal__link--PaddingLeft);
  --pf-v5-c-nav__link--Right: var(--pf-v5-c-nav--m-horizontal__link--Right);
  --pf-v5-c-nav__link--Left: var(--pf-v5-c-nav--m-horizontal__link--Left);
  --pf-v5-c-nav__link--Color: var(--pf-v5-c-nav--m-horizontal__link--Color);
  --pf-v5-c-nav__link--hover--Color: var(--pf-v5-c-nav--m-horizontal__link--hover--Color);
  --pf-v5-c-nav__link--active--Color: var(--pf-v5-c-nav--m-horizontal__link--active--Color);
  --pf-v5-c-nav__link--focus--Color: var(--pf-v5-c-nav--m-horizontal__link--focus--Color);
  --pf-v5-c-nav__link--m-current--Color: var(--pf-v5-c-nav--m-horizontal__link--m-current--Color);
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav--m-horizontal__link--BackgroundColor);
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-c-nav--m-horizontal__link--hover--BackgroundColor);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-c-nav--m-horizontal__link--focus--BackgroundColor);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-c-nav--m-horizontal__link--active--BackgroundColor);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-c-nav--m-horizontal__link--m-current--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-c-nav--m-horizontal__link--before--BorderColor);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav--m-horizontal__link--before--BorderWidth);
  --pf-v5-c-nav__link--hover--before--BorderBottomWidth: var(--pf-v5-c-nav--m-horizontal__link--hover--before--BorderWidth);
  --pf-v5-c-nav__link--focus--before--BorderBottomWidth: var(--pf-v5-c-nav--m-horizontal__link--focus--before--BorderWidth);
  --pf-v5-c-nav__link--active--before--BorderBottomWidth: var(--pf-v5-c-nav--m-horizontal__link--active--before--BorderWidth);
  --pf-v5-c-nav__link--m-current--before--BorderBottomWidth: var(--pf-v5-c-nav--m-horizontal__link--m-current--before--BorderWidth);
}

.pf-v5-c-nav.pf-m-tertiary {
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-c-nav--m-tertiary__link--PaddingTop);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav--m-tertiary__link--PaddingRight);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-c-nav--m-tertiary__link--PaddingBottom);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav--m-tertiary__link--PaddingLeft);
  --pf-v5-c-nav__link--Right: var(--pf-v5-c-nav--m-tertiary__link--Right);
  --pf-v5-c-nav__link--Left: var(--pf-v5-c-nav--m-tertiary__link--Left);
  --pf-v5-c-nav__link--Color: var(--pf-v5-c-nav--m-tertiary__link--Color);
  --pf-v5-c-nav__link--hover--Color: var(--pf-v5-c-nav--m-tertiary__link--hover--Color);
  --pf-v5-c-nav__link--active--Color: var(--pf-v5-c-nav--m-tertiary__link--active--Color);
  --pf-v5-c-nav__link--focus--Color: var(--pf-v5-c-nav--m-tertiary__link--focus--Color);
  --pf-v5-c-nav__link--m-current--Color: var(--pf-v5-c-nav--m-tertiary__link--m-current--Color);
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav--m-tertiary__link--BackgroundColor);
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-c-nav--m-tertiary__link--hover--BackgroundColor);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-c-nav--m-tertiary__link--focus--BackgroundColor);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-c-nav--m-tertiary__link--active--BackgroundColor);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-c-nav--m-tertiary__link--m-current--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-c-nav--m-tertiary__link--before--BorderColor);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav--m-tertiary__link--before--BorderWidth);
  --pf-v5-c-nav__link--hover--before--BorderBottomWidth: var(--pf-v5-c-nav--m-tertiary__link--hover--before--BorderWidth);
  --pf-v5-c-nav__link--focus--before--BorderBottomWidth: var(--pf-v5-c-nav--m-tertiary__link--focus--before--BorderWidth);
  --pf-v5-c-nav__link--active--before--BorderBottomWidth: var(--pf-v5-c-nav--m-tertiary__link--active--before--BorderWidth);
  --pf-v5-c-nav__link--m-current--before--BorderBottomWidth: var(--pf-v5-c-nav--m-tertiary__link--m-current--before--BorderWidth);
  --pf-v5-c-nav__scroll-button--Color: var(--pf-v5-c-nav--m-tertiary__scroll-button--Color);
  --pf-v5-c-nav__scroll-button--hover--Color: var(--pf-v5-c-nav--m-tertiary__scroll-button--hover--Color);
  --pf-v5-c-nav__scroll-button--focus--Color: var(--pf-v5-c-nav--m-tertiary__scroll-button--focus--Color);
  --pf-v5-c-nav__scroll-button--active--Color: var(--pf-v5-c-nav--m-tertiary__scroll-button--active--Color);
  --pf-v5-c-nav__scroll-button--disabled--Color: var(--pf-v5-c-nav--m-tertiary__scroll-button--disabled--Color);
  --pf-v5-c-nav__scroll-button--before--BorderColor: var(--pf-v5-c-nav--m-tertiary__scroll-button--before--BorderColor);
  --pf-v5-c-nav__scroll-button--disabled--before--BorderColor: var(--pf-v5-c-nav--m-tertiary__scroll-button--disabled--before--BorderColor);
}

.pf-v5-c-nav.pf-m-horizontal-subnav {
  --pf-v5-c-nav__link--FontSize: var(--pf-v5-c-nav--m-horizontal-subnav__link--FontSize);
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-c-nav--m-horizontal-subnav__link--PaddingTop);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav--m-horizontal-subnav__link--PaddingRight);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-c-nav--m-horizontal-subnav__link--PaddingBottom);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav--m-horizontal-subnav__link--PaddingLeft);
  --pf-v5-c-nav__link--Color: var(--pf-v5-c-nav--m-horizontal-subnav__link--Color);
  --pf-v5-c-nav__link--Right: var(--pf-v5-c-nav--m-horizontal-subnav__link--Right);
  --pf-v5-c-nav__link--Left: var(--pf-v5-c-nav--m-horizontal-subnav__link--Left);
  --pf-v5-c-nav__link--hover--Color: var(--pf-v5-c-nav--m-horizontal-subnav__link--hover--Color);
  --pf-v5-c-nav__link--active--Color: var(--pf-v5-c-nav--m-horizontal-subnav__link--active--Color);
  --pf-v5-c-nav__link--focus--Color: var(--pf-v5-c-nav--m-horizontal-subnav__link--focus--Color);
  --pf-v5-c-nav__link--m-current--Color: var(--pf-v5-c-nav--m-horizontal-subnav__link--m-current--Color);
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--BackgroundColor);
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--hover--BackgroundColor);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--focus--BackgroundColor);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--active--BackgroundColor);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--m-current--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--before--BorderColor);
  --pf-v5-c-nav__link--after--BorderColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--after--BorderColor);
  --pf-v5-c-nav__link--hover--after--BorderColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--hover--after--BorderColor);
  --pf-v5-c-nav__link--active--after--BorderColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--active--after--BorderColor);
  --pf-v5-c-nav__link--m-current--after--BorderColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--m-current--after--BorderColor);
  --pf-v5-c-nav__link--after--BorderLeftWidth: var(--pf-v5-c-nav--m-horizontal-subnav__link--after--BorderLeftWidth);
  --pf-v5-c-nav__link--hover--after--BorderLeftWidth: var(--pf-v5-c-nav--m-horizontal-subnav__link--hover--after--BorderLeftWidth);
  --pf-v5-c-nav__link--active--after--BorderLeftWidth: var(--pf-v5-c-nav--m-horizontal-subnav__link--active--after--BorderLeftWidth);
  --pf-v5-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-v5-c-nav--m-horizontal-subnav__link--m-current--after--BorderLeftWidth);
}

.pf-v5-c-nav.pf-m-horizontal-subnav.pf-m-scrollable .pf-v5-c-nav__item:first-child {
  --pf-v5-c-nav__link--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--hover--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--active--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--m-current--after--BorderLeftWidth: 0;
}

.pf-v5-c-nav .pf-v5-c-divider {
  --pf-v5-c-divider--after--BackgroundColor: var(--pf-v5-c-nav--c-divider--BackgroundColor);
  margin-block-start: var(--pf-v5-c-nav--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-nav--c-divider--MarginBottom);
  padding-inline-start: var(--pf-v5-c-nav--c-divider--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-nav--c-divider--PaddingRight);
}

.pf-v5-c-nav.pf-m-scrollable .pf-v5-c-nav__scroll-button {
  opacity: 1;
}

.pf-v5-c-nav.pf-m-scrollable .pf-v5-c-nav__scroll-button:first-of-type {
  margin-inline-end: 0;
  transform: translateX(0);
}

.pf-v5-c-nav.pf-m-scrollable .pf-v5-c-nav__scroll-button:nth-of-type(2) {
  margin-inline-start: 0;
  transform: translateX(0);
}

.pf-v5-c-nav.pf-m-overflow-hidden {
  overflow: hidden;
}

.pf-v5-c-nav__list {
  padding-block-start: var(--pf-v5-c-nav__list--PaddingTop);
  padding-block-end: var(--pf-v5-c-nav__list--PaddingBottom);
  display: block;
  position: relative;
}

.pf-v5-c-nav__item {
  position: relative;
}

.pf-v5-c-nav__item.pf-m-expandable {
  --pf-v5-c-nav__link--before--BorderBottomWidth: 0;
}

.pf-v5-c-nav__item.pf-m-expandable:before {
  content: "";
  border-block-end: var(--pf-v5-c-nav__item--before--BorderWidth) solid var(--pf-v5-c-nav__item--before--BorderColor);
  position: absolute;
  inset-block-end: calc(var(--pf-v5-c-nav__item--before--BorderWidth) * -1);
  inset-inline: 0;
}

.pf-v5-c-nav__item:not(:first-child) {
  margin-block-start: var(--pf-v5-c-nav__item--MarginTop);
}

.pf-v5-c-nav__item .pf-v5-c-nav__item:not(.pf-m-expanded) .pf-v5-c-nav__toggle-icon {
  transform: rotate(0);
}

.pf-v5-c-nav__item .pf-v5-c-nav__item.pf-m-expandable {
  --pf-v5-c-nav__toggle--FontSize: var(--pf-v5-c-nav__item__item__toggle--FontSize);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav__item__item__link--PaddingRight);
}

@media screen and (width >= 1200px) {
  .pf-v5-c-nav__item .pf-v5-c-nav__item.pf-m-expandable:before {
    border-block-end: none;
  }
}

.pf-v5-c-nav__item .pf-v5-c-nav__item.pf-m-expandable .pf-v5-c-nav__list:before {
  pointer-events: none;
  content: "";
  border-inline-start: var(--pf-v5-c-nav__item--before--BorderWidth) solid var(--pf-v5-c-nav__item--before--BorderColor);
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
}

.pf-v5-c-nav__item .pf-v5-c-nav__toggle-icon {
  transform: rotate(var(--pf-v5-c-nav__item__toggle-icon--Rotate));
}

.pf-v5-c-nav__item.pf-m-flyout:hover {
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--hover__link--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav__item--m-flyout--hover__link--before--BorderWidth);
}

.pf-v5-c-nav__item.pf-m-flyout:focus {
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--focus__link--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav__item--m-flyout--focus__link--before--BorderWidth);
}

.pf-v5-c-nav__item.pf-m-flyout:active {
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--active__link--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav__item--m-flyout--active__link--before--BorderWidth);
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu {
  --pf-v5-c-menu--MinWidth: 100%;
  --pf-v5-c-menu--BoxShadow: var(--pf-v5-c-nav__item--m-flyout--c-menu--BoxShadow);
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--c-menu--BackgroundColor);
  --pf-v5-c-menu__list--PaddingTop: 0;
  --pf-v5-c-menu__list--PaddingBottom: 0;
  --pf-v5-c-menu__list-item--hover--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--c-menu__list-item--hover--BackgroundColor);
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--c-menu__list-item--focus-within--BackgroundColor);
  --pf-v5-c-menu__list-item--active--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--c-menu__list-item--active--BackgroundColor);
  --pf-v5-c-menu__item--FontSize: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--FontSize);
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--Color);
  --pf-v5-c-menu__item--PaddingTop: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingTop);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingRight);
  --pf-v5-c-menu__item--PaddingBottom: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingBottom);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingLeft);
  --pf-v5-c-menu__item-description--Color: var(--pf-v5-c-nav__item--m-flyout--c-menu__item-description--Color);
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Bottom: var(--pf-v5-c-nav__item--m-flyout--c-menu--m-top--Bottom);
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Right: var(--pf-v5-c-nav__item--m-flyout--c-menu--m-left--Right);
  position: absolute;
  inset-block-start: var(--pf-v5-c-nav__item--m-flyout--c-menu--Top);
  inset-inline-start: var(--pf-v5-c-nav__item--m-flyout--c-menu--Left);
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu {
  inset-block-start: var(--pf-v5-c-nav__item--m-flyout--c-menu--c-menu--Top);
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu__list-item:first-child {
  --pf-v5-c-nav__item--m-flyout--c-menu--c-menu--Top: var(--pf-v5-c-nav__item--m-flyout--c-menu__list-item--first-child--c-menu--Top);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--Top: var(--pf-v5-c-nav__item--m-flyout--c-menu__list-item--first-child__item--hover--after--Top);
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu__item {
  outline-offset: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--OutlineOffset);
  position: relative;
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu__item:before {
  content: "";
  border-block-end: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--before--BorderBottomWidth) solid var(--pf-v5-c-nav__item--m-flyout--c-menu__item--before--BorderBottomColor);
  position: absolute;
  inset-block-end: 0;
  inset-inline: 0;
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu__item:hover:after {
  content: "";
  border-inline-start: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftWidth) solid var(--pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftColor);
  position: absolute;
  inset-block-start: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--Top);
  inset-block-end: 0;
  inset-inline-start: 0;
}

.pf-v5-c-nav__item.pf-m-drilldown.pf-m-expanded > .pf-v5-c-nav__subnav {
  z-index: var(--pf-v5-c-nav__item--m-drilldown--m-expanded__subnav--ZIndex);
  inset-inline-start: 0;
}

.pf-v5-c-nav__link {
  font-size: var(--pf-v5-c-nav__link--FontSize);
  font-weight: var(--pf-v5-c-nav__link--FontWeight);
  color: var(--pf-v5-c-nav__link--Color);
  background-color: var(--pf-v5-c-nav__link--BackgroundColor);
  outline-offset: var(--pf-v5-c-nav__link--OutlineOffset);
  align-items: baseline;
  padding-block-start: var(--pf-v5-c-nav__link--PaddingTop);
  padding-block-end: var(--pf-v5-c-nav__link--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-nav__link--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-nav__link--PaddingRight);
  display: flex;
  position: relative;
}

.pf-v5-c-nav__link:after, .pf-v5-c-nav__link:before {
  content: "";
  border: 0 solid;
  position: absolute;
}

.pf-v5-c-nav__link:before {
  border-color: var(--pf-v5-c-nav__link--before--BorderColor);
  border-block-end-width: var(--pf-v5-c-nav__link--before--BorderBottomWidth);
  inset-block-end: calc(var(--pf-v5-c-nav__link--before--BorderBottomWidth) * -1);
  inset-inline: 0;
}

.pf-v5-c-nav__link:after {
  border: 0 solid;
  border-color: var(--pf-v5-c-nav__link--after--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__link--after--BorderLeftWidth);
  inset-block: 0;
  inset-inline-start: 0;
}

.pf-v5-c-nav__link:hover, .pf-v5-c-nav__link.pf-m-hover {
  color: var(--pf-v5-c-nav__link--hover--Color);
  background-color: var(--pf-v5-c-nav__link--hover--BackgroundColor);
}

.pf-v5-c-nav__link:hover:before, .pf-v5-c-nav__link.pf-m-hover:before {
  border-block-end-width: var(--pf-v5-c-nav__link--hover--before--BorderBottomWidth);
}

.pf-v5-c-nav__link:hover:after, .pf-v5-c-nav__link.pf-m-hover:after {
  border-color: var(--pf-v5-c-nav__link--hover--after--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__link--hover--after--BorderLeftWidth);
}

.pf-v5-c-nav__link:focus {
  color: var(--pf-v5-c-nav__link--focus--Color);
  background-color: var(--pf-v5-c-nav__link--focus--BackgroundColor);
}

.pf-v5-c-nav__link:focus:before {
  border-block-end-width: var(--pf-v5-c-nav__link--focus--before--BorderBottomWidth);
}

.pf-v5-c-nav__link:focus:after {
  border-color: var(--pf-v5-c-nav__link--focus--after--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__link--focus--after--BorderLeftWidth);
}

.pf-v5-c-nav__link:active {
  color: var(--pf-v5-c-nav__link--active--Color);
  background-color: var(--pf-v5-c-nav__link--active--BackgroundColor);
}

.pf-v5-c-nav__link:active:before {
  border-block-end-width: var(--pf-v5-c-nav__link--active--before--BorderBottomWidth);
}

.pf-v5-c-nav__link:active:after {
  border-color: var(--pf-v5-c-nav__link--active--after--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__link--active--after--BorderLeftWidth);
}

.pf-v5-c-nav__link.pf-m-current, .pf-v5-c-nav__link.pf-m-current:hover, .pf-v5-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-v5-c-nav__link {
  color: var(--pf-v5-c-nav__link--m-current--Color);
  background-color: var(--pf-v5-c-nav__link--m-current--BackgroundColor);
}

.pf-v5-c-nav__link.pf-m-current:before, .pf-v5-c-nav__link.pf-m-current:hover:before, .pf-v5-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-v5-c-nav__link:before {
  border-block-end-width: var(--pf-v5-c-nav__link--m-current--before--BorderBottomWidth);
}

.pf-v5-c-nav__link.pf-m-current:after, .pf-v5-c-nav__link.pf-m-current:hover:after, .pf-v5-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-v5-c-nav__link:after {
  border-color: var(--pf-v5-c-nav__link--m-current--after--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__link--m-current--after--BorderLeftWidth);
}

.pf-v5-c-nav__link, .pf-v5-c-nav__link:hover, .pf-v5-c-nav__link:focus, .pf-v5-c-nav__link:active {
  border: none;
  width: 100%;
  text-decoration: none;
}

.pf-v5-c-nav__subnav {
  --pf-v5-c-nav__list--PaddingTop: 0;
  --pf-v5-c-nav__list--PaddingBottom: 0;
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-c-nav__subnav__link--PaddingTop);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav__subnav__link--PaddingRight);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-c-nav__subnav__link--PaddingBottom);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav__subnav__link--PaddingLeft);
  --pf-v5-c-nav__link--FontSize: var(--pf-v5-c-nav__subnav__link--FontSize);
  --pf-v5-c-nav__link--hover--after--BorderColor: var(--pf-v5-c-nav__subnav__link--hover--after--BorderColor);
  --pf-v5-c-nav__link--focus--after--BorderColor: var(--pf-v5-c-nav__subnav__link--focus--after--BorderColor);
  --pf-v5-c-nav__link--active--after--BorderColor: var(--pf-v5-c-nav__subnav__link--active--after--BorderColor);
  --pf-v5-c-nav__link--m-current--after--BorderColor: var(--pf-v5-c-nav__subnav__link--m-current--after--BorderColor);
  --pf-v5-c-nav__link--hover--after--BorderLeftWidth: var(--pf-v5-c-nav__subnav__link--hover--after--BorderWidth);
  --pf-v5-c-nav__link--focus--after--BorderLeftWidth: var(--pf-v5-c-nav__subnav__link--focus--after--BorderWidth);
  --pf-v5-c-nav__link--active--after--BorderLeftWidth: var(--pf-v5-c-nav__subnav__link--active--after--BorderWidth);
  --pf-v5-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-v5-c-nav__subnav__link--m-current--after--BorderWidth);
  --pf-v5-c-nav--c-divider--PaddingRight: var(--pf-v5-c-nav__subnav--c-divider--PaddingRight);
  --pf-v5-c-nav--c-divider--PaddingLeft: var(--pf-v5-c-nav__subnav--c-divider--PaddingLeft);
  max-height: var(--pf-v5-c-nav__subnav--MaxHeight);
  transition: var(--pf-v5-c-nav--Transition);
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-block-end: var(--pf-v5-c-nav__subnav--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-nav__subnav--PaddingLeft);
}

.pf-v5-c-nav__subnav .pf-v5-c-nav__subnav {
  --pf-v5-c-nav__link--FontSize: var(--pf-v5-c-nav__subnav__subnav__link--FontSize);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav__subnav__subnav__link--PaddingLeft);
  --pf-v5-c-nav__subnav--PaddingLeft: var(--pf-v5-c-nav__subnav__subnav--PaddingLeft);
}

.pf-v5-c-nav__subnav.pf-m-flyout {
  width: 100%;
}

.pf-v5-c-nav__item.pf-m-expanded .pf-v5-c-nav__subnav {
  --pf-v5-c-nav__subnav--MaxHeight: var(--pf-v5-c-nav__item--m-expanded__subnav--MaxHeight);
  opacity: 1;
  overflow-y: auto;
}

.pf-v5-c-nav__subnav::-webkit-scrollbar {
  display: none;
}

.pf-v5-c-nav__toggle {
  font-size: var(--pf-v5-c-nav__toggle--FontSize);
  flex: none;
  margin-inline-start: auto;
  padding-inline-start: var(--pf-v5-c-nav__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-nav__toggle--PaddingRight);
  line-height: 1;
}

.pf-v5-c-nav__toggle.pf-m-start {
  margin-inline-start: calc(var(--pf-v5-c-nav__toggle--PaddingRight) * -1);
  margin-inline-end: var(--pf-v5-c-nav__toggle--m-start--MarginRight);
}

.pf-v5-c-nav__toggle-icon {
  transition: var(--pf-v5-c-nav__toggle-icon--Transition);
  display: inline-block;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-nav__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-nav__item.pf-m-expanded .pf-v5-c-nav__toggle-icon {
  transform: rotate(var(--pf-v5-c-nav__item--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-nav__section {
  --pf-v5-c-nav__item--MarginTop: var(--pf-v5-c-nav__section__item--MarginTop);
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-c-nav__section__link--PaddingTop);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav__section__link--PaddingRight);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-c-nav__section__link--PaddingBottom);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav__section__link--PaddingLeft);
  --pf-v5-c-nav__link--FontSize: var(--pf-v5-c-nav__section__link--FontSize);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav__section__link--before--BorderBottomWidth);
  --pf-v5-c-nav__link--hover--after--BorderColor: var(--pf-v5-c-nav__section__link--hover--after--BorderColor);
  --pf-v5-c-nav__link--focus--after--BorderColor: var(--pf-v5-c-nav__section__link--focus--after--BorderColor);
  --pf-v5-c-nav__link--active--after--BorderColor: var(--pf-v5-c-nav__section__link--active--after--BorderColor);
  --pf-v5-c-nav__link--m-current--after--BorderColor: var(--pf-v5-c-nav__section__link--m-current--after--BorderColor);
  --pf-v5-c-nav__link--hover--after--BorderLeftWidth: var(--pf-v5-c-nav__section__link--hover--after--BorderWidth);
  --pf-v5-c-nav__link--focus--after--BorderLeftWidth: var(--pf-v5-c-nav__section__link--focus--after--BorderWidth);
  --pf-v5-c-nav__link--active--after--BorderLeftWidth: var(--pf-v5-c-nav__section__link--active--after--BorderWidth);
  --pf-v5-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-v5-c-nav__section__link--m-current--after--BorderWidth);
  --pf-v5-c-nav__list--PaddingTop: 0;
  --pf-v5-c-nav__list--PaddingBottom: 0;
  --pf-v5-c-nav--c-divider--MarginBottom: 0;
}

.pf-v5-c-nav__section:first-child {
  padding-block-start: var(--pf-v5-c-nav__section--first-child--PaddingTop);
}

.pf-v5-c-nav__section + .pf-v5-c-nav__section {
  margin-block-start: var(--pf-v5-c-nav__section--section--MarginTop);
}

.pf-v5-c-nav__section:last-child {
  padding-block-end: var(--pf-v5-c-nav__section--last-child--PaddingBottom);
}

.pf-v5-c-nav__section-title {
  font-size: var(--pf-v5-c-nav__section-title--FontSize);
  color: var(--pf-v5-c-nav__section-title--Color);
  border-block-end: var(--pf-v5-c-nav__section-title--BorderBottomWidth) solid var(--pf-v5-c-nav__section-title--BorderBottomColor);
  margin-block-end: var(--pf-v5-c-nav__section-title--MarginBottom);
  padding-block-start: var(--pf-v5-c-nav__section-title--PaddingTop);
  padding-block-end: var(--pf-v5-c-nav__section-title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-nav__section-title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-nav__section-title--PaddingRight);
}

.pf-v5-c-nav__scroll-button {
  width: var(--pf-v5-c-nav__scroll-button--Width);
  color: var(--pf-v5-c-nav__scroll-button--Color);
  background-color: var(--pf-v5-c-nav__scroll-button--BackgroundColor);
  outline-offset: var(--pf-v5-c-nav__scroll-button--OutlineOffset);
  opacity: 0;
  transition: var(--pf-v5-c-nav__scroll-button--Transition);
  border: 0;
  flex: none;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-nav__scroll-button > * {
  scale: -1 1;
}

.pf-v5-c-nav__scroll-button:before {
  content: "";
  border: 0 solid var(--pf-v5-c-nav__scroll-button--before--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__scroll-button--before--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-nav__scroll-button--before--BorderRightWidth);
  position: absolute;
  inset-block: 0;
}

.pf-v5-c-nav__scroll-button:hover {
  color: var(--pf-v5-c-nav__scroll-button--hover--Color);
}

.pf-v5-c-nav__scroll-button:focus {
  color: var(--pf-v5-c-nav__scroll-button--focus--Color);
}

.pf-v5-c-nav__scroll-button:active {
  color: var(--pf-v5-c-nav__scroll-button--active--Color);
}

.pf-v5-c-nav__scroll-button:disabled {
  color: var(--pf-v5-c-nav__scroll-button--disabled--Color);
  border-color: var(--pf-v5-c-nav__scroll-button--disabled--before--BorderColor);
}

.pf-v5-c-nav__scroll-button:first-of-type {
  --pf-v5-c-nav__scroll-button--before--BorderRightWidth: var(--pf-v5-c-nav__scroll-button--before--BorderWidth);
  margin-inline-end: calc(var(--pf-v5-c-nav__scroll-button--Width) * -1);
  transform: translateX(-100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-nav__scroll-button:first-of-type {
  transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-nav__scroll-button:first-of-type:before {
  inset-inline-end: 0;
}

.pf-v5-c-nav__scroll-button:nth-of-type(2) {
  --pf-v5-c-nav__scroll-button--before--BorderLeftWidth: var(--pf-v5-c-nav__scroll-button--before--BorderWidth);
  margin-inline-start: calc(var(--pf-v5-c-nav__scroll-button--Width) * -1);
  transform: translateX(100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-nav__scroll-button:nth-of-type(2) {
  transform: translateX(calc(100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-nav__scroll-button:nth-of-type(2):before {
  inset-inline-start: 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav {
  --pf-v5-c-nav__item--item__link--after--Top: -1px;
  --pf-v5-c-nav__item--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav--m-tertiary__scroll-button--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav__section-title--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-nav__section-title--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav__scroll-button--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav--c-divider--BackgroundColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav__link--hover--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--focus--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--active--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--m-current--before--BorderBottomWidth: 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav__link:before {
  inset-block-end: 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav__link:after {
  inset-block-start: var(--pf-v5-c-nav__item--item__link--after--Top);
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav__item + .pf-v5-c-nav__item {
  --pf-v5-c-nav__link--after--Top: var(--pf-v5-c-nav__item--item__link--after--Top);
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav__subnav, :where(.pf-v5-theme-dark) .pf-v5-c-nav__section {
  --pf-v5-c-nav__item--item__link--after--Top: 0;
}

.pf-v5-c-page__sidebar.pf-m-light {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-page__main-section[class*="pf-m-dark-"], .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector, .pf-v5-c-page__sidebar, .pf-v5-c-page__header {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--light-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--light-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--light-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--light-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--light);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--light);
}

.pf-v5-c-page__main-section[class*="pf-m-dark-"] .pf-v5-c-button, .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button, .pf-v5-c-page__sidebar .pf-v5-c-button, .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--dark-100);
}

.pf-v5-c-page__header-tools-item, .pf-v5-c-page__header-tools-group {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}

.pf-m-hidden.pf-v5-c-page__header-tools-item, .pf-m-hidden.pf-v5-c-page__header-tools-group {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}

@media screen and (width >= 576px) {
  .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-item, .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-sm.pf-v5-c-page__header-tools-item, .pf-m-visible-on-sm.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 768px) {
  .pf-m-hidden-on-md.pf-v5-c-page__header-tools-item, .pf-m-hidden-on-md.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-md.pf-v5-c-page__header-tools-item, .pf-m-visible-on-md.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 992px) {
  .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-item, .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-lg.pf-v5-c-page__header-tools-item, .pf-m-visible-on-lg.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 1200px) {
  .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-item, .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-xl.pf-v5-c-page__header-tools-item, .pf-m-visible-on-xl.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 1450px) {
  .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-item, .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-item, .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

:root {
  --pf-v5-c-page--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-page--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page--xl--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__header--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-page__header--ZIndex: var(--pf-v5-global--ZIndex--md);
  --pf-v5-c-page__header--MinHeight: 4.75rem;
  --pf-v5-c-page__header-brand--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-brand--xl--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-brand--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-sidebar-toggle__c-button--MarginLeft: calc(var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingLeft) * -1);
  --pf-v5-c-page__header-sidebar-toggle__c-button--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-page__header-brand-link--c-brand--MaxHeight: 3.75rem;
  --pf-v5-c-page__header-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-page__header-nav--xl--BackgroundColor: transparent;
  --pf-v5-c-page__header-nav--xl--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-nav--xl--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-tools--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-tools--xl--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__header-tools--c-avatar--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-tools-group--MarginLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-tools-group--Display: flex;
  --pf-v5-c-page__header-tools-item--Display: block;
  --pf-v5-c-page__header-tools-item--c-notification-badge--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__header-tools--c-button--notification-badge--m-unread--after--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-page__header-tools--c-button--notification-badge--m-attention--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--notification-badge--m-unread--after--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--notification-badge--m-attention--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--before--Width: auto;
  --pf-v5-c-page__header-tools--c-button--m-selected--before--Height: auto;
  --pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__header-tools--c-button--m-selected--before--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-page__header-tools--c-button--m-selected--c-notification-badge--m-unread--after--BorderColor: transparent;
  --pf-v5-c-page__sidebar--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-page__sidebar--Width: 18.125rem;
  --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-page__sidebar--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__sidebar--m-light--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__sidebar--m-light--BorderRightColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-page__sidebar--BoxShadow: var(--pf-v5-global--BoxShadow--lg-right);
  --pf-v5-c-page__sidebar--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-page__sidebar--TranslateX: -100%;
  --pf-v5-c-page__sidebar--TranslateZ: 0;
  --pf-v5-c-page__sidebar--m-expanded--TranslateX: 0;
  --pf-v5-c-page__sidebar--xl--TranslateX: 0;
  --pf-v5-c-page__sidebar-body--PaddingRight: 0;
  --pf-v5-c-page__sidebar-body--PaddingLeft: 0;
  --pf-v5-c-page__sidebar-body--m-page-insets--PaddingRight: var(--pf-v5-c-page--inset);
  --pf-v5-c-page__sidebar-body--m-page-insets--PaddingLeft: var(--pf-v5-c-page--inset);
  --pf-v5-c-page__sidebar-body--m-menu--BorderTopColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__sidebar-body--m-menu--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__sidebar-body--m-menu--c-context-selector--BorderBottomColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__main--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-page__main-section--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--xl--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-section--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-section--xl--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-section--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-breadcrumb--main-section--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-page__main-section--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-section--m-light-100--BackgroundColor: var(--pf-v5-global--BackgroundColor--150);
  --pf-v5-c-page__main-section--m-dark-100--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-transparent-100);
  --pf-v5-c-page__main-section--m-dark-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-transparent-200);
  --pf-v5-c-page__main-breadcrumb--page__main-tabs--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-nav--page__main-tabs--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page--section--m-limit-width--MaxWidth: calc(125rem - var(--pf-v5-c-page__sidebar--Width));
  --pf-v5-c-page--section--m-sticky-top--ZIndex: var(--pf-v5-global--ZIndex--md);
  --pf-v5-c-page--section--m-sticky-top--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-page--section--m-sticky-bottom--ZIndex: var(--pf-v5-global--ZIndex--md);
  --pf-v5-c-page--section--m-sticky-bottom--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-page--section--m-shadow-bottom--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-page--section--m-shadow-bottom--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-page--section--m-shadow-top--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-page--section--m-shadow-top--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-page__main-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-nav--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-nav--PaddingRight: 0;
  --pf-v5-c-page__main-nav--PaddingLeft: 0;
  --pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-nav--xl--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__main-nav--xl--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__main-subnav--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-page__main-subnav--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__main-subnav--BorderTopColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-page__main-subnav--BorderLeftWidth: 0;
  --pf-v5-c-page__main-subnav--BorderLeftColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-page__sidebar--main__main-subnav--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__sidebar--m-collapsed--main__main-subnav--BorderLeftWidth: 0;
  --pf-v5-c-page__main-breadcrumb--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-breadcrumb--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--PaddingBottom: 0;
  --pf-v5-c-page__main-breadcrumb--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-breadcrumb--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-tabs--PaddingTop: 0;
  --pf-v5-c-page__main-tabs--PaddingRight: 0;
  --pf-v5-c-page__main-tabs--PaddingBottom: 0;
  --pf-v5-c-page__main-tabs--PaddingLeft: 0;
  --pf-v5-c-page__main-tabs--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-wizard--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-wizard--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-page__main-wizard--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__main-wizard--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
}

@media (width >= 1200px) {
  :root {
    --pf-v5-c-page__header-brand--PaddingLeft: var(--pf-v5-c-page__header-brand--xl--PaddingLeft);
    --pf-v5-c-page--inset: var(--pf-v5-c-page--xl--inset);
  }
}

@media screen and (width >= 1200px) {
  :root {
    --pf-v5-c-page__header-nav--BackgroundColor: var(--pf-v5-c-page__header-nav--xl--BackgroundColor);
    --pf-v5-c-page__header-nav--PaddingRight: var(--pf-v5-c-page__header-nav--xl--PaddingRight);
    --pf-v5-c-page__header-nav--PaddingLeft: var(--pf-v5-c-page__header-nav--xl--PaddingLeft);
    --pf-v5-c-page__header-tools--MarginRight: var(--pf-v5-c-page__header-tools--xl--MarginRight);
    --pf-v5-c-page__sidebar--TranslateX: var(--pf-v5-c-page__sidebar--xl--TranslateX);
    --pf-v5-c-page__main-section--PaddingTop: var(--pf-v5-c-page__main-section--xl--PaddingTop);
    --pf-v5-c-page__main-section--PaddingRight: var(--pf-v5-c-page__main-section--xl--PaddingRight);
    --pf-v5-c-page__main-section--PaddingBottom: var(--pf-v5-c-page__main-section--xl--PaddingBottom);
    --pf-v5-c-page__main-section--PaddingLeft: var(--pf-v5-c-page__main-section--xl--PaddingLeft);
    --pf-v5-c-page__main-nav--PaddingRight: var(--pf-v5-c-page__main-nav--xl--PaddingRight);
    --pf-v5-c-page__main-nav--PaddingLeft: var(--pf-v5-c-page__main-nav--xl--PaddingLeft);
    --pf-v5-c-page__main-breadcrumb--PaddingRight: var(--pf-v5-c-page__main-breadcrumb--xl--PaddingRight);
    --pf-v5-c-page__main-breadcrumb--PaddingLeft: var(--pf-v5-c-page__main-breadcrumb--xl--PaddingLeft);
  }
}

.pf-v5-c-page {
  background-color: var(--pf-v5-c-page--BackgroundColor);
  grid-template: "header" max-content
                 "main" 1fr
                 / 1fr;
  height: 100dvh;
  max-height: 100%;
  display: grid;
}

@media (width >= 1200px) {
  .pf-v5-c-page {
    grid-template-columns: max-content 1fr;
    grid-template-areas: "header header"
                         "nav main";
  }
}

.pf-v5-c-page__header, .pf-v5-c-page > .pf-v5-c-masthead {
  z-index: var(--pf-v5-c-page__header--ZIndex);
  grid-area: header;
}

.pf-v5-c-page__header {
  color: var(--pf-v5-global--Color--100);
  min-height: var(--pf-v5-c-page__header--MinHeight);
  background-color: var(--pf-v5-c-page__header--BackgroundColor);
  grid-template-columns: auto auto;
  align-items: center;
  min-width: 0;
  display: grid;
}

.pf-v5-c-page__header > * {
  align-items: center;
  display: flex;
}

@media screen and (width >= 992px) {
  .pf-v5-c-page__header {
    grid-template-columns: auto 1fr auto;
  }
}

.pf-v5-c-page__header .pf-v5-c-masthead {
  grid-column: span 2;
  align-self: stretch;
}

.pf-v5-c-page__header-brand {
  grid-column: 1 / 2;
  padding-inline-start: var(--pf-v5-c-page__header-brand--PaddingLeft);
}

@media (width >= 1200px) {
  .pf-v5-c-page__header-brand {
    padding-inline-end: var(--pf-v5-c-page__header-brand--xl--PaddingRight);
  }
}

.pf-v5-c-page__header-brand-link {
  flex: 1;
  align-items: center;
  display: flex;
}

.pf-v5-c-page__header-brand-link .pf-v5-c-brand {
  max-height: var(--pf-v5-c-page__header-brand-link--c-brand--MaxHeight);
}

.pf-v5-c-page__header-brand-toggle .pf-v5-c-button {
  font-size: var(--pf-v5-c-page__header-sidebar-toggle__c-button--FontSize);
  margin-inline-start: var(--pf-v5-c-page__header-sidebar-toggle__c-button--MarginLeft);
  margin-inline-end: var(--pf-v5-c-page__header-sidebar-toggle__c-button--MarginRight);
  padding-block-start: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingRight);
  line-height: 1;
}

.pf-v5-c-page__header-nav {
  background-color: var(--pf-v5-c-page__header-nav--BackgroundColor);
  grid-area: 2 / 1 / 3 / -1;
  align-self: stretch;
  min-width: 0;
  padding-inline-start: var(--pf-v5-c-page__header-nav--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__header-nav--PaddingRight);
}

@media screen and (width >= 1200px) {
  .pf-v5-c-page__header-nav {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.pf-v5-c-page__header-nav .pf-v5-c-nav {
  align-self: stretch;
}

.pf-v5-c-page__header-tools {
  grid-column: 2 / 3;
  margin-inline-start: auto;
  margin-inline-end: var(--pf-v5-c-page__header-tools--MarginRight);
}

.pf-v5-c-page__header-tools .pf-v5-c-avatar {
  margin-inline-start: var(--pf-v5-c-page__header-tools--c-avatar--MarginLeft);
}

@media screen and (width >= 992px) {
  .pf-v5-c-page__header-tools {
    grid-column: 3 / 4;
  }
}

.pf-v5-c-page__header-tools-group {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-page__header-tools-group--Display);
  align-items: center;
}

.pf-v5-c-page__header-tools-group + .pf-v5-c-page__header-tools-group {
  margin-inline-start: var(--pf-v5-c-page__header-tools-group--MarginLeft);
}

.pf-v5-c-page__header-tools-item {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-page__header-tools-item--Display);
}

.pf-v5-c-page__header-tools-item .pf-v5-c-notification-badge.pf-m-read:hover {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools-item--c-notification-badge--hover--BackgroundColor);
}

.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button {
  background-color: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor);
  border-radius: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--BorderRadius);
}

.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button:before {
  width: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--Width);
  height: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--Height);
  content: "";
  position: absolute;
  inset: 0;
}

.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge.pf-m-unread {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools--c-button--m-selected--notification-badge--m-unread--after--BackgroundColor);
}

.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge.pf-m-unread:after {
  border-color: var(--pf-v5-c-page__header-tools--c-button--m-selected--c-notification-badge--m-unread--after--BorderColor);
}

.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge.pf-m-attention {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
}

.pf-v5-c-page__header-tools-item .pf-v5-c-button:focus .pf-v5-c-notification-badge.pf-m-unread {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools--c-button--notification-badge--m-unread--after--BackgroundColor);
}

.pf-v5-c-page__header-tools-item .pf-v5-c-button:focus .pf-v5-c-notification-badge.pf-m-attention {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools--c-button--notification-badge--m-attention--after--BackgroundColor);
}

.pf-v5-c-page__sidebar {
  color: var(--pf-v5-global--Color--100);
  z-index: var(--pf-v5-c-page__sidebar--ZIndex);
  width: var(--pf-v5-c-page__sidebar--Width);
  -webkit-overflow-scrolling: touch;
  background-color: var(--pf-v5-c-page__sidebar--BackgroundColor);
  transition: var(--pf-v5-c-page__sidebar--Transition);
  transform: translateX(var(--pf-v5-c-page__sidebar--TranslateX)) translateZ(var(--pf-v5-c-page__sidebar--TranslateZ));
  flex-direction: column;
  grid-area: 2 / 1 / nav / nav;
  display: flex;
  overflow: hidden auto;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-page__sidebar {
  transform: translateX(calc(var(--pf-v5-c-page__sidebar--TranslateX) * var(--pf-v5-global--inverse--multiplier))) translateZ(var(--pf-v5-c-page__sidebar--TranslateZ));
}

.pf-v5-c-page__sidebar.pf-m-expanded {
  --pf-v5-c-page__sidebar--TranslateX: var(--pf-v5-c-page__sidebar--m-expanded--TranslateX);
  box-shadow: var(--pf-v5-c-page__sidebar--BoxShadow);
}

@media screen and (width >= 1200px) {
  .pf-v5-c-page__sidebar.pf-m-expanded {
    --pf-v5-c-page__sidebar--BoxShadow: none;
  }
}

.pf-v5-c-page__sidebar.pf-m-collapsed {
  max-width: 0;
  overflow: hidden;
}

.pf-v5-c-page__sidebar.pf-m-light {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-c-page__sidebar--m-light--BackgroundColor);
  border-inline-end: var(--pf-v5-c-page__sidebar--m-light--BorderRightWidth) solid var(--pf-v5-c-page__sidebar--m-light--BorderRightColor);
}

.pf-v5-c-page__sidebar-body {
  padding-inline-start: var(--pf-v5-c-page__sidebar-body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__sidebar-body--PaddingRight);
}

.pf-v5-c-page__sidebar-body:last-child {
  flex-grow: 1;
}

.pf-v5-c-page__sidebar-body.pf-m-menu {
  background-color: var(--pf-v5-global--palette--black-900);
  border-block-start: var(--pf-v5-c-page__sidebar-body--m-menu--BorderTopWidth) solid var(--pf-v5-c-page__sidebar-body--m-menu--BorderTopColor);
}

.pf-v5-c-page__sidebar-body.pf-m-menu + .pf-v5-c-page__sidebar-body.pf-m-menu {
  --pf-v5-c-page__sidebar-body--m-menu--BorderTopWidth: 0;
}

.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector {
  --pf-v5-c-context-selector__toggle--BorderTopColor: transparent;
  --pf-v5-c-context-selector__toggle--BorderRightColor: transparent;
  --pf-v5-c-context-selector__toggle--BorderBottomColor: var(--pf-v5-c-page__sidebar-body--m-menu--c-context-selector--BorderBottomColor);
  --pf-v5-c-context-selector__toggle--BorderLeftColor: transparent;
  --pf-v5-c-context-selector__menu--Top: 100%;
  color: var(--pf-v5-global--Color--100);
  width: 100%;
}

.pf-v5-c-page__sidebar-body.pf-m-page-insets {
  --pf-v5-c-page__sidebar-body--PaddingRight: var(--pf-v5-c-page__sidebar-body--m-page-insets--PaddingRight);
  --pf-v5-c-page__sidebar-body--PaddingLeft: var(--pf-v5-c-page__sidebar-body--m-page-insets--PaddingLeft);
}

.pf-v5-c-page__sidebar-body.pf-m-inset-none {
  --pf-v5-c-page__sidebar-body--PaddingRight: 0;
  --pf-v5-c-page__sidebar-body--PaddingLeft: 0;
}

.pf-v5-c-page__sidebar-body.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-c-page__sidebar-body.pf-m-no-fill {
  flex-grow: 0;
}

.pf-v5-c-page__main-nav.pf-m-limit-width, .pf-v5-c-page__main-breadcrumb.pf-m-limit-width, .pf-v5-c-page__main-tabs.pf-m-limit-width, .pf-v5-c-page__main-section.pf-m-limit-width, .pf-v5-c-page__main-wizard.pf-m-limit-width {
  flex-direction: column;
  padding: 0;
  display: flex;
}

.pf-v5-c-page__main-nav.pf-m-limit-width > .pf-v5-c-page__main-body, .pf-v5-c-page__main-breadcrumb.pf-m-limit-width > .pf-v5-c-page__main-body, .pf-v5-c-page__main-tabs.pf-m-limit-width > .pf-v5-c-page__main-body, .pf-v5-c-page__main-section.pf-m-limit-width > .pf-v5-c-page__main-body, .pf-v5-c-page__main-wizard.pf-m-limit-width > .pf-v5-c-page__main-body {
  max-width: var(--pf-v5-c-page--section--m-limit-width--MaxWidth);
  flex: 1;
}

.pf-v5-c-page__main-nav.pf-m-limit-width.pf-m-align-center, .pf-v5-c-page__main-breadcrumb.pf-m-limit-width.pf-m-align-center, .pf-v5-c-page__main-tabs.pf-m-limit-width.pf-m-align-center, .pf-v5-c-page__main-section.pf-m-limit-width.pf-m-align-center, .pf-v5-c-page__main-wizard.pf-m-limit-width.pf-m-align-center {
  align-items: center;
}

.pf-v5-c-page__main-nav.pf-m-limit-width.pf-m-align-center > .pf-v5-c-page__main-body, .pf-v5-c-page__main-breadcrumb.pf-m-limit-width.pf-m-align-center > .pf-v5-c-page__main-body, .pf-v5-c-page__main-tabs.pf-m-limit-width.pf-m-align-center > .pf-v5-c-page__main-body, .pf-v5-c-page__main-section.pf-m-limit-width.pf-m-align-center > .pf-v5-c-page__main-body, .pf-v5-c-page__main-wizard.pf-m-limit-width.pf-m-align-center > .pf-v5-c-page__main-body {
  width: 100%;
}

.pf-v5-c-page__main-nav, .pf-v5-c-page__main-breadcrumb, .pf-v5-c-page__main-tabs, .pf-v5-c-page__main-section, .pf-v5-c-page__main-wizard, .pf-v5-c-page__main-group, .pf-v5-c-page__main-subnav {
  flex-shrink: 0;
}

.pf-v5-c-page__main-nav.pf-m-overflow-scroll, .pf-v5-c-page__main-breadcrumb.pf-m-overflow-scroll, .pf-v5-c-page__main-tabs.pf-m-overflow-scroll, .pf-v5-c-page__main-section.pf-m-overflow-scroll, .pf-v5-c-page__main-wizard.pf-m-overflow-scroll, .pf-v5-c-page__main-group.pf-m-overflow-scroll, .pf-v5-c-page__main-subnav.pf-m-overflow-scroll {
  flex-shrink: 1;
  position: relative;
  overflow: auto;
}

.pf-v5-c-page__main-nav.pf-m-shadow-bottom, .pf-v5-c-page__main-breadcrumb.pf-m-shadow-bottom, .pf-v5-c-page__main-tabs.pf-m-shadow-bottom, .pf-v5-c-page__main-section.pf-m-shadow-bottom, .pf-v5-c-page__main-wizard.pf-m-shadow-bottom, .pf-v5-c-page__main-group.pf-m-shadow-bottom, .pf-v5-c-page__main-subnav.pf-m-shadow-bottom {
  z-index: var(--pf-v5-c-page--section--m-shadow-bottom--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-shadow-bottom--BoxShadow);
}

.pf-v5-c-page__main-nav.pf-m-shadow-top, .pf-v5-c-page__main-breadcrumb.pf-m-shadow-top, .pf-v5-c-page__main-tabs.pf-m-shadow-top, .pf-v5-c-page__main-section.pf-m-shadow-top, .pf-v5-c-page__main-wizard.pf-m-shadow-top, .pf-v5-c-page__main-group.pf-m-shadow-top, .pf-v5-c-page__main-subnav.pf-m-shadow-top {
  z-index: var(--pf-v5-c-page--section--m-shadow-top--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-shadow-top--BoxShadow);
}

.pf-v5-c-page__main-nav.pf-m-sticky-top, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top, .pf-v5-c-page__main-tabs.pf-m-sticky-top, .pf-v5-c-page__main-section.pf-m-sticky-top, .pf-v5-c-page__main-wizard.pf-m-sticky-top, .pf-v5-c-page__main-group.pf-m-sticky-top, .pf-v5-c-page__main-subnav.pf-m-sticky-top {
  z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  position: sticky;
  inset-block-start: 0;
}

.pf-v5-c-page__main-nav.pf-m-sticky-bottom, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom, .pf-v5-c-page__main-tabs.pf-m-sticky-bottom, .pf-v5-c-page__main-section.pf-m-sticky-bottom, .pf-v5-c-page__main-wizard.pf-m-sticky-bottom, .pf-v5-c-page__main-group.pf-m-sticky-bottom, .pf-v5-c-page__main-subnav.pf-m-sticky-bottom {
  z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  position: sticky;
  inset-block-end: 0;
}

@media (height >= 0) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-sm-height, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-sm-height, .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-sm-height, .pf-v5-c-page__main-section.pf-m-sticky-top-on-sm-height, .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-sm-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-sm-height, .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-sm-height {
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
    position: sticky;
    inset-block-start: 0;
  }

  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-sm-height, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-sm-height, .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-sm-height, .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-sm-height, .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-sm-height, .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-sm-height, .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-sm-height {
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
    position: sticky;
    inset-block-end: 0;
  }
}

@media (height >= 40rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-md-height, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-md-height, .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-md-height, .pf-v5-c-page__main-section.pf-m-sticky-top-on-md-height, .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-md-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-md-height, .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-md-height {
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
    position: sticky;
    inset-block-start: 0;
  }

  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-md-height, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-md-height, .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-md-height, .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-md-height, .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-md-height, .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-md-height, .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-md-height {
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
    position: sticky;
    inset-block-end: 0;
  }
}

@media (height >= 48rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-lg-height, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-lg-height, .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-lg-height, .pf-v5-c-page__main-section.pf-m-sticky-top-on-lg-height, .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-lg-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-lg-height, .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-lg-height {
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
    position: sticky;
    inset-block-start: 0;
  }

  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-lg-height, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-lg-height, .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-lg-height, .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-lg-height, .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-lg-height, .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-lg-height, .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-lg-height {
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
    position: sticky;
    inset-block-end: 0;
  }
}

@media (height >= 60rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-xl-height, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-xl-height, .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-xl-height, .pf-v5-c-page__main-section.pf-m-sticky-top-on-xl-height, .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-xl-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-xl-height, .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-xl-height {
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
    position: sticky;
    inset-block-start: 0;
  }

  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-xl-height, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-xl-height, .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-xl-height, .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-xl-height, .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-xl-height, .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-xl-height, .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-xl-height {
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
    position: sticky;
    inset-block-end: 0;
  }
}

@media (height >= 80rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-2xl-height, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-2xl-height, .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-2xl-height, .pf-v5-c-page__main-section.pf-m-sticky-top-on-2xl-height, .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-2xl-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-2xl-height, .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-2xl-height {
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
    position: sticky;
    inset-block-start: 0;
  }

  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-2xl-height, .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-2xl-height, .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-2xl-height, .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-2xl-height, .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-2xl-height, .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-2xl-height, .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-2xl-height {
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
    position: sticky;
    inset-block-end: 0;
  }
}

.pf-v5-c-page__main, .pf-v5-c-page__drawer {
  z-index: var(--pf-v5-c-page__main--ZIndex);
  -webkit-overflow-scrolling: touch;
  grid-area: main;
  overflow: hidden auto;
}

.pf-v5-c-page__main:focus, .pf-v5-c-page__drawer:focus {
  outline: 0;
}

.pf-v5-c-page__main, .pf-v5-c-page__main-drawer, .pf-v5-c-page__main-group {
  flex-direction: column;
  display: flex;
}

.pf-v5-c-page__main-nav {
  background-color: var(--pf-v5-c-page__main-nav--BackgroundColor);
  padding-block-start: var(--pf-v5-c-page__main-nav--PaddingTop);
  padding-inline-start: var(--pf-v5-c-page__main-nav--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-nav--PaddingRight);
}

.pf-v5-c-page__main-nav.pf-m-sticky-top, .pf-v5-c-page__main-group.pf-m-sticky-top .pf-v5-c-page__main-nav:last-child {
  padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
}

@media (height >= 0) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-sm-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-sm-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

@media (height >= 40rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-md-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-md-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

@media (height >= 48rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-lg-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-lg-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

@media (height >= 60rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-xl-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-xl-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

@media (height >= 80rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-2xl-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-2xl-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

.pf-v5-c-page__main-subnav {
  background-color: var(--pf-v5-c-page__main-subnav--BackgroundColor);
  border-block-start: var(--pf-v5-c-page__main-subnav--BorderTopWidth) solid var(--pf-v5-c-page__main-subnav--BorderTopColor);
  border-inline-start: var(--pf-v5-c-page__main-subnav--BorderLeftWidth) solid var(--pf-v5-c-page__main-subnav--BorderLeftColor);
}

@media screen and (width >= 1200px) {
  .pf-v5-c-page__sidebar ~ .pf-v5-c-page__main {
    --pf-v5-c-page__main-subnav--BorderLeftWidth: var(--pf-v5-c-page__sidebar--main__main-subnav--BorderLeftWidth);
  }
}

.pf-v5-c-page__sidebar.pf-m-collapsed ~ .pf-v5-c-page__main {
  --pf-v5-c-page__main-subnav--BorderLeftWidth: var(--pf-v5-c-page__sidebar--m-collapsed--main__main-subnav--BorderLeftWidth);
}

.pf-v5-c-page__main-breadcrumb {
  background-color: var(--pf-v5-c-page__main-breadcrumb--BackgroundColor);
  padding-block-start: var(--pf-v5-c-page__main-breadcrumb--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-breadcrumb--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-breadcrumb--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-breadcrumb--PaddingRight);
}

.pf-v5-c-page__main-breadcrumb + .pf-v5-c-page__main-section {
  --pf-v5-c-page__main-section--PaddingTop: var(--pf-v5-c-page__main-breadcrumb--main-section--PaddingTop);
}

.pf-v5-c-page__main-breadcrumb.pf-m-sticky-top, .pf-v5-c-page__main-group.pf-m-sticky-top .pf-v5-c-page__main-breadcrumb:last-child {
  --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
}

@media (height >= 0) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-sm-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-sm-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

@media (height >= 40rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-md-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-md-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

@media (height >= 48rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-lg-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-lg-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

@media (height >= 60rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-xl-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-xl-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

@media (height >= 80rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-2xl-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-2xl-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

.pf-v5-c-page__main-tabs {
  background-color: var(--pf-v5-c-page__main-tabs--BackgroundColor);
  padding-block-start: var(--pf-v5-c-page__main-tabs--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-tabs--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-tabs--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-tabs--PaddingRight);
}

.pf-v5-c-page__main-nav + .pf-v5-c-page__main-tabs {
  --pf-v5-c-page__main-tabs--PaddingTop: var(--pf-v5-c-page__main-nav--page__main-tabs--PaddingTop);
}

.pf-v5-c-page__main-breadcrumb + .pf-v5-c-page__main-tabs {
  --pf-v5-c-page__main-tabs--PaddingTop: var(--pf-v5-c-page__main-breadcrumb--page__main-tabs--PaddingTop);
}

.pf-v5-c-page__main-section:last-child, .pf-v5-c-page__main-section:only-child, .pf-v5-c-page__main-section.pf-m-fill, .pf-v5-c-page__main-group:last-child, .pf-v5-c-page__main-group:only-child, .pf-v5-c-page__main-group.pf-m-fill, .pf-v5-c-page__main-wizard:last-child, .pf-v5-c-page__main-wizard:only-child, .pf-v5-c-page__main-wizard.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-c-page__main-section.pf-m-no-fill, .pf-v5-c-page__main-group.pf-m-no-fill, .pf-v5-c-page__main-wizard.pf-m-no-fill {
  flex-grow: 0;
}

.pf-v5-c-page__main-section {
  background-color: var(--pf-v5-c-page__main-section--BackgroundColor);
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
}

.pf-v5-c-page__main-section.pf-m-light {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-light--BackgroundColor);
}

.pf-v5-c-page__main-section.pf-m-light-100 {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-light-100--BackgroundColor);
}

.pf-v5-c-page__main-section[class*="pf-m-dark-"] {
  color: var(--pf-v5-global--Color--100);
}

.pf-v5-c-page__main-section.pf-m-dark-100 {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-dark-100--BackgroundColor);
}

.pf-v5-c-page__main-section.pf-m-dark-200 {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-dark-200--BackgroundColor);
}

.pf-v5-c-page__main-section.pf-m-padding {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
}

.pf-v5-c-page__main-section.pf-m-padding.pf-m-limit-width {
  padding: 0;
}

.pf-v5-c-page__main-section.pf-m-padding.pf-m-limit-width .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
}

.pf-v5-c-page__main-section.pf-m-no-padding, .pf-v5-c-page__main-section.pf-m-no-padding.pf-m-limit-width .pf-v5-c-page__main-body {
  padding: 0;
}

@media (width >= 576px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-sm {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-sm.pf-m-limit-width {
    padding: 0;
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-sm.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-no-padding-on-sm, .pf-v5-c-page__main-section.pf-m-no-padding-on-sm.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

@media (width >= 768px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-md {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-md.pf-m-limit-width {
    padding: 0;
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-md.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-no-padding-on-md, .pf-v5-c-page__main-section.pf-m-no-padding-on-md.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

@media (width >= 992px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-lg {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-lg.pf-m-limit-width {
    padding: 0;
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-lg.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-no-padding-on-lg, .pf-v5-c-page__main-section.pf-m-no-padding-on-lg.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

@media (width >= 1200px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-xl {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-xl.pf-m-limit-width {
    padding: 0;
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-no-padding-on-xl, .pf-v5-c-page__main-section.pf-m-no-padding-on-xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

@media (width >= 1450px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-2xl {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-2xl.pf-m-limit-width {
    padding: 0;
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-2xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-no-padding-on-2xl, .pf-v5-c-page__main-section.pf-m-no-padding-on-2xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

.pf-v5-c-page__main-wizard {
  background-color: var(--pf-v5-c-page__main-wizard--BackgroundColor);
  border-block-start: var(--pf-v5-c-page__main-wizard--BorderTopWidth) solid var(--pf-v5-c-page__main-wizard--BorderTopColor);
  flex: 1;
  min-height: 0;
}

.pf-v5-c-page__main-wizard:first-child {
  --pf-v5-c-page__main-wizard--BorderTopWidth: 0;
}

.pf-v5-c-page__main-wizard.pf-m-light-200 {
  --pf-v5-c-page__main-wizard--BackgroundColor: var(--pf-v5-c-page__main-wizard--m-light-200--BackgroundColor);
}

.pf-v5-c-page__main-wizard .pf-v5-c-page__main-body {
  min-height: 0;
}

.pf-v5-c-page__main-group {
  flex-shrink: 0;
}

.pf-v5-c-page__main-nav .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-nav--PaddingTop);
  padding-inline-start: var(--pf-v5-c-page__main-nav--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-nav--PaddingRight);
}

.pf-v5-c-page__main-breadcrumb .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-breadcrumb--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-breadcrumb--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-breadcrumb--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-breadcrumb--PaddingRight);
}

.pf-v5-c-page__main-section .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
}

.pf-v5-c-page__main-tabs .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-tabs--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-tabs--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-tabs--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-tabs--PaddingRight);
}

.pf-v5-c-page__drawer {
  grid-area: main;
}

.pf-v5-c-page__drawer > .pf-v5-c-drawer {
  flex: 1 0 auto;
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button, :where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*="pf-m-dark-"] .pf-v5-c-button, :where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark):root {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-page__header--BackgroundColor: var(--pf-v5-global--palette--black-1000);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button {
  color: var(--pf-v5-global--Color--100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge:hover:before {
  background-color: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar {
  border-inline-end: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__main-nav.pf-m-sticky-top, :where(.pf-v5-theme-dark) .pf-v5-c-page__main-nav.pf-m-sticky-bottom {
  --pf-v5-c-page__main-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top, :where(.pf-v5-theme-dark) .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom {
  --pf-v5-c-page__main-breadcrumb--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__main-group.pf-m-sticky-top, :where(.pf-v5-theme-dark) .pf-v5-c-page__main-group.pf-m-sticky-bottom {
  --pf-v5-c-page__main-section--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__main-breadcrumb--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__main-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section.pf-m-sticky-top, :where(.pf-v5-theme-dark) .pf-v5-c-page__main-section.pf-m-sticky-bottom {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector, :where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*="pf-m-dark-"], :where(.pf-v5-theme-dark) .pf-v5-c-page__header {
  color: var(--pf-v5-global--Color--100);
}

.pf-v5-c-drawer {
  --pf-v5-c-drawer__section--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-drawer__section--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-drawer__content--FlexBasis: 100%;
  --pf-v5-c-drawer__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-drawer__content--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-drawer__content--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-drawer__panel--MinWidth: 50%;
  --pf-v5-c-drawer__panel--MaxHeight: auto;
  --pf-v5-c-drawer__panel--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-drawer__panel--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-drawer__panel--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-drawer__panel--TransitionDuration: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-drawer__panel--TransitionProperty: margin, transform, box-shadow, flex-basis;
  --pf-v5-c-drawer__panel--FlexBasis: 100%;
  --pf-v5-c-drawer__panel--md--FlexBasis--min: 1.5rem;
  --pf-v5-c-drawer__panel--md--FlexBasis: 50%;
  --pf-v5-c-drawer__panel--md--FlexBasis--max: 100%;
  --pf-v5-c-drawer__panel--xl--MinWidth: 28.125rem;
  --pf-v5-c-drawer__panel--xl--FlexBasis: 28.125rem;
  --pf-v5-c-drawer--m-panel-bottom__panel--md--MinHeight: 50%;
  --pf-v5-c-drawer--m-panel-bottom__panel--xl--MinHeight: 18.75rem;
  --pf-v5-c-drawer--m-panel-bottom__panel--xl--FlexBasis: 18.75rem;
  --pf-v5-c-drawer__panel--m-resizable--FlexDirection: row;
  --pf-v5-c-drawer__panel--m-resizable--md--FlexBasis--min: var(--pf-v5-c-drawer__splitter--m-vertical--Width);
  --pf-v5-c-drawer__panel--m-resizable--MinWidth: 1.5rem;
  --pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--FlexDirection: column;
  --pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--md--FlexBasis--min: 1.5rem;
  --pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--MinHeight: 1.5rem;
  --pf-v5-c-drawer--child--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--md--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--md--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--md--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--md--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--m-padding--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--m-padding--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--m-padding--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--m-padding--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--m-padding--md--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--m-padding--md--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--m-padding--md--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--m-padding--md--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer__content--child--PaddingTop: 0;
  --pf-v5-c-drawer__content--child--PaddingRight: 0;
  --pf-v5-c-drawer__content--child--PaddingBottom: 0;
  --pf-v5-c-drawer__content--child--PaddingLeft: 0;
  --pf-v5-c-drawer__splitter--Height: .5625rem;
  --pf-v5-c-drawer__splitter--Width: 100%;
  --pf-v5-c-drawer__splitter--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-drawer__splitter--Cursor: row-resize;
  --pf-v5-c-drawer__splitter--m-vertical--Height: 100%;
  --pf-v5-c-drawer__splitter--m-vertical--Width: .5625rem;
  --pf-v5-c-drawer__splitter--m-vertical--Cursor: col-resize;
  --pf-v5-c-drawer--m-inline__splitter--focus--OutlineOffset: -.0625rem;
  --pf-v5-c-drawer__splitter--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-drawer__splitter--after--border-width--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__splitter--after--BorderTopWidth: 0;
  --pf-v5-c-drawer__splitter--after--BorderRightWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer__splitter--after--BorderBottomWidth: 0;
  --pf-v5-c-drawer__splitter--after--BorderLeftWidth: 0;
  --pf-v5-c-drawer--m-panel-left__splitter--after--BorderLeftWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer--m-panel-bottom__splitter--after--BorderBottomWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer--m-inline__splitter--m-vertical--Width: .625rem;
  --pf-v5-c-drawer--m-inline__splitter-handle--Left: 50%;
  --pf-v5-c-drawer--m-inline__splitter--after--BorderRightWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer--m-inline__splitter--after--BorderLeftWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer--m-inline--m-panel-bottom__splitter--Height: .625rem;
  --pf-v5-c-drawer--m-inline--m-panel-bottom__splitter-handle--Top: 50%;
  --pf-v5-c-drawer--m-inline--m-panel-bottom__splitter--after--BorderTopWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer__splitter-handle--Top: 50%;
  --pf-v5-c-drawer__splitter-handle--Left: calc(50% - var(--pf-v5-c-drawer__splitter--after--border-width--base));
  --pf-v5-c-drawer--m-panel-left__splitter-handle--Left: 50%;
  --pf-v5-c-drawer--m-panel-bottom__splitter-handle--Top: calc(50% - var(--pf-v5-c-drawer__splitter--after--border-width--base));
  --pf-v5-c-drawer__splitter-handle--after--BorderColor: var(--pf-v5-global--Color--200);
  --pf-v5-c-drawer__splitter-handle--after--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__splitter-handle--after--BorderRightWidth: 0;
  --pf-v5-c-drawer__splitter-handle--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__splitter-handle--after--BorderLeftWidth: 0;
  --pf-v5-c-drawer__splitter--hover__splitter-handle--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-drawer__splitter--focus__splitter-handle--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderTopWidth: 0;
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderBottomWidth: 0;
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__splitter-handle--after--Width: .75rem;
  --pf-v5-c-drawer__splitter-handle--after--Height: .25rem;
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--Width: .25rem;
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--Height: .75rem;
  --pf-v5-c-drawer__actions--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-drawer__actions--MarginRight: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-drawer__panel--BoxShadow: none;
  --pf-v5-c-drawer--m-expanded--m-panel-bottom__panel--BoxShadow: var(--pf-v5-global--BoxShadow--lg-top);
  --pf-v5-c-drawer--m-expanded__panel--BoxShadow: var(--pf-v5-global--BoxShadow--lg-left);
  --pf-v5-c-drawer--m-expanded--m-panel-left__panel--BoxShadow: var(--pf-v5-global--BoxShadow--lg-right);
  --pf-v5-c-drawer__panel--after--Width: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer--m-panel-bottom__panel--after--Height: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__panel--after--BackgroundColor: transparent;
  --pf-v5-c-drawer--m-inline--m-expanded__panel--after--BackgroundColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-drawer--m-inline__panel--PaddingLeft: var(--pf-v5-c-drawer__panel--after--Width);
  --pf-v5-c-drawer--m-panel-left--m-inline__panel--PaddingRight: var(--pf-v5-c-drawer__panel--after--Width);
  --pf-v5-c-drawer--m-panel-bottom--m-inline__panel--PaddingTop: var(--pf-v5-c-drawer__panel--after--Width);
  flex-direction: column;
  height: 100%;
  display: flex;
  overflow-x: hidden;
}

@media screen and (width >= 768px) {
  .pf-v5-c-drawer {
    --pf-v5-c-drawer--child--PaddingTop: var(--pf-v5-c-drawer--child--md--PaddingTop);
    --pf-v5-c-drawer--child--PaddingRight: var(--pf-v5-c-drawer--child--md--PaddingRight);
    --pf-v5-c-drawer--child--PaddingBottom: var(--pf-v5-c-drawer--child--md--PaddingBottom);
    --pf-v5-c-drawer--child--PaddingLeft: var(--pf-v5-c-drawer--child--md--PaddingLeft);
    --pf-v5-c-drawer--child--m-padding--PaddingTop: var(--pf-v5-c-drawer--child--m-padding--md--PaddingTop);
    --pf-v5-c-drawer--child--m-padding--PaddingRight: var(--pf-v5-c-drawer--child--m-padding--md--PaddingRight);
    --pf-v5-c-drawer--child--m-padding--PaddingBottom: var(--pf-v5-c-drawer--child--m-padding--md--PaddingBottom);
    --pf-v5-c-drawer--child--m-padding--PaddingLeft: var(--pf-v5-c-drawer--child--m-padding--md--PaddingLeft);
  }
}

@media screen and (width >= 1200px) {
  .pf-v5-c-drawer {
    --pf-v5-c-drawer__panel--MinWidth: var(--pf-v5-c-drawer__panel--xl--MinWidth);
  }

  .pf-v5-c-drawer.pf-m-panel-bottom {
    --pf-v5-c-drawer__panel--MinWidth: auto;
    --pf-v5-c-drawer__panel--MinHeight: var(--pf-v5-c-drawer--m-panel-bottom__panel--xl--MinHeight);
  }
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer {
  --pf-v5-c-drawer--m-expanded__panel--BoxShadow: var(--pf-v5-global--BoxShadow--lg-right);
  --pf-v5-c-drawer--m-expanded--m-panel-left__panel--BoxShadow: var(--pf-v5-global--BoxShadow--lg-left);
}

.pf-v5-c-drawer.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border, .pf-m-resizable), .pf-v5-c-drawer.pf-m-static > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border, .pf-m-resizable) {
  padding-inline-start: var(--pf-v5-c-drawer--m-inline__panel--PaddingLeft);
}

.pf-v5-c-drawer.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
  order: 0;
  margin-inline-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
  transform: translateX(-100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
  transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-drawer.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content {
  order: 1;
}

.pf-v5-c-drawer.pf-m-panel-bottom > .pf-v5-c-drawer__main {
  flex-direction: column;
}

.pf-v5-c-drawer.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
  transform: translateX(-100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
  transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-drawer.pf-m-expanded.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
  transform: translateX(0);
}

.pf-v5-c-drawer.pf-m-expanded.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
  transform: translate(0, -100%);
}

.pf-v5-c-drawer.pf-m-resizing {
  --pf-v5-c-drawer__panel--TransitionProperty: none;
  pointer-events: none;
}

.pf-v5-c-drawer.pf-m-resizing .pf-v5-c-drawer__splitter {
  pointer-events: auto;
}

.pf-v5-c-drawer__section {
  background-color: var(--pf-v5-c-drawer__section--BackgroundColor);
  flex-grow: 0;
}

.pf-v5-c-drawer__section.pf-m-no-background {
  --pf-v5-c-drawer__section--BackgroundColor: transparent;
}

.pf-v5-c-drawer__section.pf-m-light-200 {
  --pf-v5-c-drawer__section--BackgroundColor: var(--pf-v5-c-drawer__section--m-light-200--BackgroundColor);
}

.pf-v5-c-drawer__main {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.pf-v5-c-drawer__content, .pf-v5-c-drawer__panel, .pf-v5-c-drawer__panel-main {
  flex-direction: column;
  flex-shrink: 0;
  display: flex;
  overflow: auto;
}

.pf-v5-c-drawer__content {
  z-index: var(--pf-v5-c-drawer__content--ZIndex);
  flex-basis: var(--pf-v5-c-drawer__content--FlexBasis);
  background-color: var(--pf-v5-c-drawer__content--BackgroundColor);
  order: 0;
}

.pf-v5-c-drawer__content.pf-m-no-background {
  --pf-v5-c-drawer__content--BackgroundColor: transparent;
}

.pf-v5-c-drawer__content.pf-m-light-200 {
  --pf-v5-c-drawer__content--BackgroundColor: var(--pf-v5-c-drawer__content--m-light-200--BackgroundColor);
}

.pf-v5-c-drawer__content > .pf-v5-c-drawer__body {
  padding-block-start: var(--pf-v5-c-drawer__content--child--PaddingTop);
  padding-block-end: var(--pf-v5-c-drawer__content--child--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-drawer__content--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-drawer__content--child--PaddingRight);
}

.pf-v5-c-drawer__panel {
  z-index: var(--pf-v5-c-drawer__panel--ZIndex);
  flex-basis: var(--pf-v5-c-drawer__panel--FlexBasis);
  max-height: var(--pf-v5-c-drawer__panel--MaxHeight);
  background-color: var(--pf-v5-c-drawer__panel--BackgroundColor);
  box-shadow: var(--pf-v5-c-drawer__panel--BoxShadow);
  transition-duration: var(--pf-v5-c-drawer__panel--TransitionDuration);
  transition-property: var(--pf-v5-c-drawer__panel--TransitionProperty);
  -webkit-overflow-scrolling: touch;
  order: 1;
  position: relative;
  overflow: auto;
}

.pf-v5-c-drawer__panel:after {
  width: var(--pf-v5-c-drawer__panel--after--Width);
  content: "";
  background-color: var(--pf-v5-c-drawer__panel--after--BackgroundColor);
  height: 100%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.pf-v5-c-drawer__panel.pf-m-no-background {
  --pf-v5-c-drawer__panel--BackgroundColor: transparent;
}

.pf-v5-c-drawer__panel.pf-m-light-200 {
  --pf-v5-c-drawer__panel--BackgroundColor: var(--pf-v5-c-drawer__panel--m-light-200--BackgroundColor);
}

@media screen and (width >= 768px) {
  .pf-v5-c-drawer__panel {
    --pf-v5-c-drawer__panel--FlexBasis: max(var(--pf-v5-c-drawer__panel--md--FlexBasis--min), min(var(--pf-v5-c-drawer__panel--md--FlexBasis), var(--pf-v5-c-drawer__panel--md--FlexBasis--max)));
  }
}

@media screen and (width >= 1200px) {
  .pf-v5-c-drawer__panel {
    --pf-v5-c-drawer__panel--md--FlexBasis: var(--pf-v5-c-drawer__panel--xl--FlexBasis);
  }

  .pf-v5-c-drawer.pf-m-panel-bottom .pf-v5-c-drawer__panel {
    --pf-v5-c-drawer__panel--md--FlexBasis: var(--pf-v5-c-drawer--m-panel-bottom__panel--xl--FlexBasis);
  }
}

.pf-v5-c-drawer__panel-main {
  flex-grow: 1;
}

@keyframes pf-remove-tab-focus {
  to {
    visibility: hidden;
  }
}

.pf-v5-c-drawer__panel[hidden] {
  animation-name: pf-remove-tab-focus;
  animation-delay: var(--pf-v5-c-drawer__panel--TransitionDuration);
  animation-fill-mode: forwards;
}

.pf-v5-c-drawer__head {
  grid-template-columns: auto;
  grid-auto-columns: max-content;
  display: grid;
}

.pf-v5-c-drawer__head > * {
  grid-column: 1;
}

.pf-v5-c-drawer__actions {
  grid-area: 1 / 2;
  align-self: baseline;
  margin-block-start: var(--pf-v5-c-drawer__actions--MarginTop);
  margin-inline-end: var(--pf-v5-c-drawer__actions--MarginRight);
  display: flex;
}

.pf-v5-c-drawer__body {
  padding-block-start: var(--pf-v5-c-drawer--child--PaddingTop);
  padding-block-end: var(--pf-v5-c-drawer--child--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-drawer--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-drawer--child--PaddingRight);
}

.pf-v5-c-drawer__body.pf-m-no-padding {
  padding: 0;
}

.pf-v5-c-drawer__body.pf-m-no-padding > .pf-v5-c-drawer__actions, .pf-v5-c-drawer__body.pf-m-no-padding > .pf-v5-c-drawer__head > .pf-v5-c-drawer__actions {
  margin-block-start: 0;
  margin-inline-end: 0;
}

.pf-v5-c-drawer__body.pf-m-padding {
  padding-block-start: var(--pf-v5-c-drawer--child--m-padding--PaddingTop);
  padding-block-end: var(--pf-v5-c-drawer--child--m-padding--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-drawer--child--m-padding--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-drawer--child--m-padding--PaddingRight);
}

.pf-v5-c-drawer__body:not(.pf-m-no-padding) + * {
  padding-block-start: 0;
}

.pf-v5-c-drawer__body:last-child {
  flex: 1;
}

.pf-v5-c-drawer__body > .pf-v5-c-page__main {
  height: 100%;
}

.pf-v5-c-drawer__splitter {
  width: var(--pf-v5-c-drawer__splitter--Width);
  height: var(--pf-v5-c-drawer__splitter--Height);
  cursor: var(--pf-v5-c-drawer__splitter--Cursor);
  background-color: var(--pf-v5-c-drawer__splitter--BackgroundColor);
  display: none;
  position: relative;
}

.pf-v5-c-drawer__splitter.pf-m-vertical {
  --pf-v5-c-drawer__splitter--Height: var(--pf-v5-c-drawer__splitter--m-vertical--Height);
  --pf-v5-c-drawer__splitter--Width: var(--pf-v5-c-drawer__splitter--m-vertical--Width);
  --pf-v5-c-drawer__splitter--Cursor: var(--pf-v5-c-drawer__splitter--m-vertical--Cursor);
  --pf-v5-c-drawer__splitter-handle--after--Width: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--Width);
  --pf-v5-c-drawer__splitter-handle--after--Height: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--Height);
  --pf-v5-c-drawer__splitter-handle--after--BorderTopWidth: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderTopWidth);
  --pf-v5-c-drawer__splitter-handle--after--BorderRightWidth: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderRightWidth);
  --pf-v5-c-drawer__splitter-handle--after--BorderBottomWidth: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderBottomWidth);
  --pf-v5-c-drawer__splitter-handle--after--BorderLeftWidth: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderLeftWidth);
}

.pf-v5-c-drawer__splitter:hover {
  --pf-v5-c-drawer__splitter-handle--after--BorderColor: var(--pf-v5-c-drawer__splitter--hover__splitter-handle--after--BorderColor);
}

.pf-v5-c-drawer__splitter:focus {
  --pf-v5-c-drawer__splitter-handle--after--BorderColor: var(--pf-v5-c-drawer__splitter--focus__splitter-handle--after--BorderColor);
}

.pf-v5-c-drawer__splitter:after {
  content: "";
  border: solid var(--pf-v5-c-drawer__splitter--after--BorderColor);
  border-block-start-width: var(--pf-v5-c-drawer__splitter--after--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-drawer__splitter--after--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-drawer__splitter--after--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-drawer__splitter--after--BorderRightWidth);
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
}

.pf-v5-c-drawer__splitter-handle {
  position: absolute;
  inset-block-start: var(--pf-v5-c-drawer__splitter-handle--Top);
  inset-inline-start: var(--pf-v5-c-drawer__splitter-handle--Left);
  transform: translate(-50%, -50%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer__splitter-handle {
  transform: translate(calc(-50% * var(--pf-v5-global--inverse--multiplier)), -50%);
}

.pf-v5-c-drawer__splitter-handle:after {
  width: var(--pf-v5-c-drawer__splitter-handle--after--Width);
  height: var(--pf-v5-c-drawer__splitter-handle--after--Height);
  content: "";
  border-color: var(--pf-v5-c-drawer__splitter-handle--after--BorderColor);
  border-style: solid;
  border-block-start-width: var(--pf-v5-c-drawer__splitter-handle--after--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-drawer__splitter-handle--after--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-drawer__splitter-handle--after--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-drawer__splitter-handle--after--BorderRightWidth);
  display: block;
}

@media screen and (width >= 768px) {
  .pf-v5-c-drawer {
    min-width: var(--pf-v5-c-drawer__panel--MinWidth);
  }

  .pf-v5-c-drawer.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    box-shadow: var(--pf-v5-c-drawer--m-expanded__panel--BoxShadow);
  }

  .pf-v5-c-drawer > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-resizable {
    --pf-v5-c-drawer__panel--md--FlexBasis--min: var(--pf-v5-c-drawer__panel--m-resizable--md--FlexBasis--min);
    flex-direction: var(--pf-v5-c-drawer__panel--m-resizable--FlexDirection);
    min-width: var(--pf-v5-c-drawer__panel--m-resizable--MinWidth);
  }

  .pf-v5-c-drawer > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-resizable:after {
    width: 0;
    height: 0;
  }

  .pf-v5-c-drawer > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-resizable > .pf-v5-c-drawer__splitter {
    flex-shrink: 0;
  }

  .pf-v5-c-drawer > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-resizable > .pf-v5-c-drawer__panel-main {
    flex-shrink: 1;
  }

  .pf-v5-c-drawer.pf-m-panel-left {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: var(--pf-v5-c-drawer--m-expanded--m-panel-left__panel--BoxShadow);
  }

  .pf-v5-c-drawer.pf-m-panel-left.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border, .pf-m-resizable), .pf-v5-c-drawer.pf-m-panel-left.pf-m-static > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border, .pf-m-resizable) {
    padding-inline-start: 0;
    padding-inline-end: var(--pf-v5-c-drawer--m-panel-left--m-inline__panel--PaddingRight);
  }

  .pf-v5-c-drawer.pf-m-panel-left.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:after {
    inset-inline: auto 0;
  }

  .pf-v5-c-drawer.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-resizable > .pf-v5-c-drawer__splitter {
    --pf-v5-c-drawer__splitter-handle--Left: var(--pf-v5-c-drawer--m-panel-left__splitter-handle--Left);
    --pf-v5-c-drawer__splitter--after--BorderRightWidth: 0;
    --pf-v5-c-drawer__splitter--after--BorderLeftWidth: var(--pf-v5-c-drawer--m-panel-left__splitter--after--BorderLeftWidth);
    order: 1;
  }

  .pf-v5-c-drawer.pf-m-panel-bottom {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: var(--pf-v5-c-drawer--m-expanded--m-panel-bottom__panel--BoxShadow);
    --pf-v5-c-drawer__panel--MaxHeight: 100%;
    --pf-v5-c-drawer__panel--FlexBasis--min: var(--pf-v5-c-drawer--m-panel-bottom__panel--FlexBasis--min);
    min-height: var(--pf-v5-c-drawer--m-panel-bottom__panel--md--MinHeight);
    min-width: auto;
  }

  .pf-v5-c-drawer.pf-m-panel-bottom.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border, .pf-m-resizable), .pf-v5-c-drawer.pf-m-panel-bottom.pf-m-static > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border, .pf-m-resizable) {
    padding-block-start: var(--pf-v5-c-drawer--m-panel-bottom--m-inline__panel--PaddingTop);
    padding-inline-start: 0;
  }

  .pf-v5-c-drawer.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:after {
    height: var(--pf-v5-c-drawer--m-panel-bottom__panel--after--Height);
    width: 100%;
    inset-block-start: 0;
    inset-inline-start: auto;
  }

  .pf-v5-c-drawer.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-resizable {
    --pf-v5-c-drawer__panel--md--FlexBasis--min: var(--pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--md--FlexBasis--min);
    --pf-v5-c-drawer__panel--m-resizable--FlexDirection: var(--pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--FlexDirection);
    --pf-v5-c-drawer__panel--m-resizable--MinWidth: 0;
    min-height: var(--pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--MinHeight);
  }

  .pf-v5-c-drawer.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-resizable > .pf-v5-c-drawer__splitter {
    --pf-v5-c-drawer__splitter-handle--Top: var(--pf-v5-c-drawer--m-panel-bottom__splitter-handle--Top);
    --pf-v5-c-drawer__splitter--after--BorderRightWidth: 0;
    --pf-v5-c-drawer__splitter--after--BorderBottomWidth: var(--pf-v5-c-drawer--m-panel-bottom__splitter--after--BorderBottomWidth);
  }

  .pf-v5-c-drawer.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-resizable > .pf-v5-c-drawer__splitter {
    --pf-v5-c-drawer__splitter--m-vertical--Width: var(--pf-v5-c-drawer--m-inline__splitter--m-vertical--Width);
    --pf-v5-c-drawer__splitter-handle--Left: var(--pf-v5-c-drawer--m-inline__splitter-handle--Left);
    --pf-v5-c-drawer__splitter--after--BorderRightWidth: var(--pf-v5-c-drawer--m-inline__splitter--after--BorderRightWidth);
    --pf-v5-c-drawer__splitter--after--BorderLeftWidth: var(--pf-v5-c-drawer--m-inline__splitter--after--BorderLeftWidth);
    outline-offset: var(--pf-v5-c-drawer--m-inline__splitter--focus--OutlineOffset);
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-resizable > .pf-v5-c-drawer__splitter {
    --pf-v5-c-drawer__splitter--Height: var(--pf-v5-c-drawer--m-inline--m-panel-bottom__splitter--Height);
    --pf-v5-c-drawer__splitter-handle--Top: var(--pf-v5-c-drawer--m-inline--m-panel-bottom__splitter-handle--Top);
    --pf-v5-c-drawer__splitter--after--BorderTopWidth: var(--pf-v5-c-drawer--m-inline--m-panel-bottom__splitter--after--BorderTopWidth);
    --pf-v5-c-drawer__splitter--after--BorderRightWidth: 0;
    --pf-v5-c-drawer__splitter--after--BorderLeftWidth: 0;
  }

  .pf-v5-c-drawer > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-no-border, .pf-v5-c-drawer.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel.pf-m-no-border {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: none;
  }

  .pf-v5-c-drawer__splitter {
    display: block;
  }
}

@media (width >= 768px) {
  .pf-v5-c-drawer__panel.pf-m-width-25 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 25%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-33 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 33%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-50 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 50%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-66 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 66%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-75 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 75%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-100 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 100%;
  }
}

@media (width >= 992px) {
  .pf-v5-c-drawer__panel.pf-m-width-25-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 25%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-33-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 33%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-50-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 50%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-66-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 66%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-75-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 75%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-100-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 100%;
  }
}

@media (width >= 1200px) {
  .pf-v5-c-drawer__panel.pf-m-width-25-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 25%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-33-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 33%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-50-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 50%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-66-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 66%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-75-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 75%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-100-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 100%;
  }
}

@media (width >= 1450px) {
  .pf-v5-c-drawer__panel.pf-m-width-25-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 25%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-33-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 33%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-50-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 50%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-66-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 66%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-75-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 75%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-100-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 100%;
  }
}

@media (width >= 768px) {
  .pf-v5-c-drawer.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content, .pf-v5-c-drawer.pf-m-static > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content {
    flex-shrink: 1;
  }

  .pf-v5-c-drawer.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel, .pf-v5-c-drawer.pf-m-static > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: none;
  }

  .pf-v5-c-drawer.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border):after, .pf-v5-c-drawer.pf-m-static > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border):after {
    background-color: var(--pf-v5-c-drawer--m-inline--m-expanded__panel--after--BackgroundColor);
  }

  .pf-v5-c-drawer.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content {
    overflow-x: auto;
  }

  .pf-v5-c-drawer.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateX(100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(calc(100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    margin-inline-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateX(-100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-left.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-block-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateY(100%);
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-bottom.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-block-end: 0;
    transform: translateY(0);
  }

  .pf-v5-c-drawer.pf-m-static > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: none;
  }
}

@media (width >= 992px) {
  .pf-v5-c-drawer.pf-m-inline-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content, .pf-v5-c-drawer.pf-m-static-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content {
    flex-shrink: 1;
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel, .pf-v5-c-drawer.pf-m-static-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: none;
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border):after, .pf-v5-c-drawer.pf-m-static-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border):after {
    background-color: var(--pf-v5-c-drawer--m-inline--m-expanded__panel--after--BackgroundColor);
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content {
    overflow-x: auto;
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateX(100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(calc(100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    margin-inline-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateX(-100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-left.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-block-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateY(100%);
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-bottom.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-block-end: 0;
    transform: translateY(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-lg.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-lg.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-lg > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: none;
  }
}

@media (width >= 1200px) {
  .pf-v5-c-drawer.pf-m-inline-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content, .pf-v5-c-drawer.pf-m-static-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content {
    flex-shrink: 1;
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel, .pf-v5-c-drawer.pf-m-static-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: none;
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border):after, .pf-v5-c-drawer.pf-m-static-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border):after {
    background-color: var(--pf-v5-c-drawer--m-inline--m-expanded__panel--after--BackgroundColor);
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content {
    overflow-x: auto;
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateX(100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(calc(100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    margin-inline-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateX(-100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-left.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-block-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateY(100%);
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-bottom.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-block-end: 0;
    transform: translateY(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-xl.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-xl.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: none;
  }
}

@media (width >= 1450px) {
  .pf-v5-c-drawer.pf-m-inline-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content, .pf-v5-c-drawer.pf-m-static-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content {
    flex-shrink: 1;
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel, .pf-v5-c-drawer.pf-m-static-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: none;
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border):after, .pf-v5-c-drawer.pf-m-static-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel:not(.pf-m-no-border):after {
    background-color: var(--pf-v5-c-drawer--m-inline--m-expanded__panel--after--BackgroundColor);
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__content {
    overflow-x: auto;
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateX(100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(calc(100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    margin-inline-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateX(-100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-left.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-block-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis) * -1);
    transform: translateY(100%);
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-bottom.pf-m-expanded > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-block-end: 0;
    transform: translateY(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-2xl.pf-m-panel-left > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-2xl.pf-m-panel-bottom > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-2xl > .pf-v5-c-drawer__main > .pf-v5-c-drawer__panel > .pf-v5-c-drawer__body > .pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: none;
  }
}

:where(.pf-v5-theme-dark) .pf-v5-c-drawer {
  --pf-v5-c-drawer__panel--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-drawer__splitter--BackgroundColor: transparent;
}

:where(.pf-v5-theme-dark) .pf-v5-c-drawer.pf-m-inline, :where(.pf-v5-theme-dark) .pf-v5-c-drawer.pf-m-static {
  --pf-v5-c-drawer__panel--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
}

.pf-v5-c-toolbar__content-section, .pf-v5-c-toolbar__content, .pf-v5-c-toolbar__item, .pf-v5-c-toolbar__group {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}

.pf-m-hidden.pf-v5-c-toolbar__content-section, .pf-m-hidden.pf-v5-c-toolbar__content, .pf-m-hidden.pf-v5-c-toolbar__item, .pf-m-hidden.pf-v5-c-toolbar__group {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}

@media screen and (width >= 576px) {
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__content-section, .pf-m-hidden-on-sm.pf-v5-c-toolbar__content, .pf-m-hidden-on-sm.pf-v5-c-toolbar__item, .pf-m-hidden-on-sm.pf-v5-c-toolbar__group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-sm.pf-v5-c-toolbar__content-section, .pf-m-visible-on-sm.pf-v5-c-toolbar__content, .pf-m-visible-on-sm.pf-v5-c-toolbar__item, .pf-m-visible-on-sm.pf-v5-c-toolbar__group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 768px) {
  .pf-m-hidden-on-md.pf-v5-c-toolbar__content-section, .pf-m-hidden-on-md.pf-v5-c-toolbar__content, .pf-m-hidden-on-md.pf-v5-c-toolbar__item, .pf-m-hidden-on-md.pf-v5-c-toolbar__group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-md.pf-v5-c-toolbar__content-section, .pf-m-visible-on-md.pf-v5-c-toolbar__content, .pf-m-visible-on-md.pf-v5-c-toolbar__item, .pf-m-visible-on-md.pf-v5-c-toolbar__group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 992px) {
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__content-section, .pf-m-hidden-on-lg.pf-v5-c-toolbar__content, .pf-m-hidden-on-lg.pf-v5-c-toolbar__item, .pf-m-hidden-on-lg.pf-v5-c-toolbar__group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-lg.pf-v5-c-toolbar__content-section, .pf-m-visible-on-lg.pf-v5-c-toolbar__content, .pf-m-visible-on-lg.pf-v5-c-toolbar__item, .pf-m-visible-on-lg.pf-v5-c-toolbar__group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 1200px) {
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__content-section, .pf-m-hidden-on-xl.pf-v5-c-toolbar__content, .pf-m-hidden-on-xl.pf-v5-c-toolbar__item, .pf-m-hidden-on-xl.pf-v5-c-toolbar__group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-xl.pf-v5-c-toolbar__content-section, .pf-m-visible-on-xl.pf-v5-c-toolbar__content, .pf-m-visible-on-xl.pf-v5-c-toolbar__item, .pf-m-visible-on-xl.pf-v5-c-toolbar__group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 1450px) {
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__content-section, .pf-m-hidden-on-2xl.pf-v5-c-toolbar__content, .pf-m-hidden-on-2xl.pf-v5-c-toolbar__item, .pf-m-hidden-on-2xl.pf-v5-c-toolbar__group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-2xl.pf-v5-c-toolbar__content-section, .pf-m-visible-on-2xl.pf-v5-c-toolbar__content, .pf-m-visible-on-2xl.pf-v5-c-toolbar__item, .pf-m-visible-on-2xl.pf-v5-c-toolbar__group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

.pf-v5-c-toolbar {
  --pf-v5-c-toolbar--AlignItems--base: flex-start;
  --pf-v5-c-toolbar--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-toolbar--RowGap--base: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-toolbar--RowGap: var(--pf-v5-c-toolbar--RowGap--base);
  --pf-v5-c-toolbar--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar--item--RowGap--base: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-toolbar__item--Display: flex;
  --pf-v5-c-toolbar__item--MinWidth--base: auto;
  --pf-v5-c-toolbar__item--AlignItems: var(--pf-v5-c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__item--AlignSelf: var(--pf-v5-c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__group--Display: flex;
  --pf-v5-c-toolbar__group--RowGap: var(--pf-v5-c-toolbar--item--RowGap--base);
  --pf-v5-c-toolbar__group--AlignItems: var(--pf-v5-c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__group--AlignSelf: auto;
  --pf-v5-c-toolbar--m-sticky--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-toolbar--m-sticky--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-toolbar--m-align-items-center--AlignItems: center;
  --pf-v5-c-toolbar--m-align-items-baseline--AlignItems: baseline;
  --pf-v5-c-toolbar--m-align-self-center--AlignSelf: center;
  --pf-v5-c-toolbar--m-align-self-baseline--AlignSelf: baseline;
  --pf-v5-c-toolbar__content--Display: flex;
  --pf-v5-c-toolbar__content--AlignItems: var(--pf-v5-c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__content--RowGap: var(--pf-v5-c-toolbar--RowGap--base);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar__content-section--Display: flex;
  --pf-v5-c-toolbar__content-section--AlignItems: var(--pf-v5-c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__content-section--RowGap: var(--pf-v5-c-toolbar--item--RowGap--base);
  --pf-v5-c-toolbar--m-page-insets--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar--m-page-insets--xl--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-toolbar__expandable-content--Display: grid;
  --pf-v5-c-toolbar__expandable-content--PaddingTop: 0;
  --pf-v5-c-toolbar__expandable-content--PaddingRight: var(--pf-v5-c-toolbar__content--PaddingRight);
  --pf-v5-c-toolbar__expandable-content--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar__expandable-content--PaddingLeft: var(--pf-v5-c-toolbar__content--PaddingLeft);
  --pf-v5-c-toolbar__expandable-content--lg--PaddingRight: 0;
  --pf-v5-c-toolbar__expandable-content--lg--PaddingBottom: 0;
  --pf-v5-c-toolbar__expandable-content--lg--PaddingLeft: 0;
  --pf-v5-c-toolbar__expandable-content--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-toolbar__expandable-content--BoxShadow: var(--pf-v5-global--BoxShadow--md-bottom);
  --pf-v5-c-toolbar__expandable-content--BackgroundColor: var(--pf-v5-c-toolbar--BackgroundColor);
  --pf-v5-c-toolbar__expandable-content--m-expanded--GridRowGap: var(--pf-v5-global--gutter--md);
  --pf-v5-c-toolbar__group--m-chip-container--MarginTop: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-toolbar__group--m-chip-container__item--MarginTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar--spacer--base: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar__item--spacer: var(--pf-v5-c-toolbar--spacer--base);
  --pf-v5-c-toolbar__item--Width: auto;
  --pf-v5-c-toolbar__group--spacer: var(--pf-v5-c-toolbar--spacer--base);
  --pf-v5-c-toolbar__group--m-toggle-group--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer: var(--pf-v5-c-toolbar__group--spacer);
  --pf-v5-c-toolbar__group--m-icon-button-group--spacer: var(--pf-v5-c-toolbar__group--spacer);
  --pf-v5-c-toolbar__group--m-icon-button-group--space-items: 0;
  --pf-v5-c-toolbar__group--m-button-group--spacer: var(--pf-v5-c-toolbar__group--spacer);
  --pf-v5-c-toolbar__group--m-button-group--space-items: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toolbar__group--m-filter-group--spacer: var(--pf-v5-c-toolbar__group--spacer);
  --pf-v5-c-toolbar__group--m-filter-group--space-items: 0;
  --pf-v5-c-toolbar__item--m-overflow-menu--spacer: var(--pf-v5-c-toolbar__item--spacer);
  --pf-v5-c-toolbar__item--m-bulk-select--spacer: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-toolbar__expand-all-icon--Rotate: 0;
  --pf-v5-c-toolbar__expand-all-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-toolbar__item--m-expand-all--m-expanded__expand-all-icon--Rotate: 90deg;
  --pf-v5-c-toolbar__item--m-search-filter--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toolbar__item--m-chip-group--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toolbar__item--m-label--spacer: var(--pf-v5-c-toolbar__item--spacer);
  --pf-v5-c-toolbar__item--m-label--TranslateY: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-toolbar__item--m-label--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-toolbar__item--m-form-element--spacer: var(--pf-v5-c-toolbar__item--spacer);
  --pf-v5-c-toolbar__item--m-form-element--TranslateY: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-toolbar__item--m-form-element--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-toolbar__item--m-form-control--TranslateY: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-toolbar__expandable-content__item--m-label--MarginBottom: calc(-1 * var(--pf-v5-c-toolbar__expandable-content--m-expanded--GridRowGap)  + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-toolbar__expandable-content__item--m-label--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-toolbar__toggle--m-expanded__c-button--m-plain--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-toolbar--c-divider--m-vertical--spacer: var(--pf-v5-c-toolbar--spacer--base);
  --pf-v5-c-toolbar--m-full-height--PaddingTop: 0;
  --pf-v5-c-toolbar--m-full-height--PaddingBottom: 0;
  --pf-v5-c-toolbar--m-full-height__item--Display: flex;
  --pf-v5-c-toolbar--m-full-height__item--AlignItems: center;
  row-gap: var(--pf-v5-c-toolbar--RowGap);
  background-color: var(--pf-v5-c-toolbar--BackgroundColor);
  padding-block-start: var(--pf-v5-c-toolbar--PaddingTop);
  padding-block-end: var(--pf-v5-c-toolbar--PaddingBottom);
  display: grid;
  position: relative;
}

@media screen and (width >= 992px) {
  .pf-v5-c-toolbar {
    --pf-v5-c-toolbar__expandable-content--PaddingRight: var(--pf-v5-c-toolbar__expandable-content--lg--PaddingRight);
    --pf-v5-c-toolbar__expandable-content--PaddingBottom: var(--pf-v5-c-toolbar__expandable-content--lg--PaddingBottom);
    --pf-v5-c-toolbar__expandable-content--PaddingLeft: var(--pf-v5-c-toolbar__expandable-content--lg--PaddingLeft);
  }
}

@media screen and (width >= 1200px) {
  .pf-v5-c-toolbar {
    --pf-v5-c-toolbar--m-page-insets--inset: var(--pf-v5-c-toolbar--m-page-insets--xl--inset);
  }
}

.pf-v5-c-toolbar.pf-m-page-insets {
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--m-page-insets--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--m-page-insets--inset);
}

.pf-v5-c-toolbar.pf-m-sticky {
  z-index: var(--pf-v5-c-toolbar--m-sticky--ZIndex);
  box-shadow: var(--pf-v5-c-toolbar--m-sticky--BoxShadow);
  position: sticky;
  inset-block-start: 0;
}

.pf-v5-c-toolbar.pf-m-full-height {
  --pf-v5-c-toolbar--PaddingTop: var(--pf-v5-c-toolbar--m-full-height--PaddingTop);
  --pf-v5-c-toolbar--PaddingBottom: var(--pf-v5-c-toolbar--m-full-height--PaddingTop);
  --pf-v5-c-toolbar__item--Display: var(--pf-v5-c-toolbar--m-full-height__item--Display);
  height: 100%;
}

.pf-v5-c-toolbar.pf-m-full-height, .pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__content, .pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__content-section, .pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__expandable-content, .pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__group {
  min-width: 0;
}

.pf-v5-c-toolbar.pf-m-full-height, .pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__content, .pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__content-section, .pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__group, .pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__item {
  align-self: stretch;
}

.pf-v5-c-toolbar.pf-m-full-height :where(.pf-v5-c-toolbar__item) {
  --pf-v5-c-toolbar__item--AlignItems: var(--pf-v5-c-toolbar--m-full-height__item--AlignItems);
}

.pf-v5-c-toolbar.pf-m-static, .pf-v5-c-toolbar.pf-m-static .pf-v5-c-toolbar__content {
  position: static;
}

.pf-v5-c-toolbar.pf-m-static .pf-v5-c-toolbar__expandable-content {
  position: absolute;
}

.pf-v5-c-toolbar__content-section > .pf-v5-c-divider, .pf-v5-c-toolbar__group > .pf-v5-c-divider {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar--c-divider--m-vertical--spacer);
}

.pf-v5-c-toolbar__content-section > .pf-v5-c-divider.pf-m-vertical, .pf-v5-c-toolbar__group > .pf-v5-c-divider.pf-m-vertical {
  margin-inline-end: var(--pf-v5-c-toolbar--spacer);
}

.pf-v5-c-toolbar__content-section > .pf-v5-c-divider.pf-m-vertical:last-child, .pf-v5-c-toolbar__group > .pf-v5-c-divider.pf-m-vertical:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-start, .pf-v5-c-toolbar__group.pf-m-align-items-start {
  align-items: flex-start;
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-center, .pf-v5-c-toolbar__group.pf-m-align-items-center {
  --pf-v5-c-toolbar__group--AlignItems: var(--pf-v5-c-toolbar--m-align-items-center--AlignItems);
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-baseline, .pf-v5-c-toolbar__group.pf-m-align-items-baseline {
  --pf-v5-c-toolbar__group--AlignItems: var(--pf-v5-c-toolbar--m-align-items-baseline--AlignItems);
}

.pf-v5-c-toolbar__content-section.pf-m-align-self-start, .pf-v5-c-toolbar__group.pf-m-align-self-start {
  align-self: flex-start;
}

.pf-v5-c-toolbar__content-section.pf-m-align-self-center, .pf-v5-c-toolbar__group.pf-m-align-self-center {
  --pf-v5-c-toolbar__group--AlignSelf: var(--pf-v5-c-toolbar--m-align-self-center--AlignItems);
}

.pf-v5-c-toolbar__content-section.pf-m-align-self-baseline, .pf-v5-c-toolbar__group.pf-m-align-self-baseline {
  --pf-v5-c-toolbar__group--AlignSelf: var(--pf-v5-c-toolbar--m-align-self-baseline--AlignItems);
}

.pf-v5-c-toolbar__group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--spacer);
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-toolbar__group--Display);
  row-gap: var(--pf-v5-c-toolbar__group--RowGap);
  align-items: var(--pf-v5-c-toolbar__group--AlignItems);
  align-self: var(--pf-v5-c-toolbar__group--AlignSelf);
  margin-inline-end: var(--pf-v5-c-toolbar--spacer);
}

.pf-v5-c-toolbar__group.pf-m-button-group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-button-group--spacer);
}

.pf-v5-c-toolbar__group.pf-m-button-group > * {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-button-group--space-items);
}

.pf-v5-c-toolbar__group.pf-m-icon-button-group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-icon-button-group--spacer);
}

.pf-v5-c-toolbar__group.pf-m-icon-button-group > * {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-icon-button-group--space-items);
}

.pf-v5-c-toolbar__group.pf-m-filter-group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-filter-group--spacer);
}

.pf-v5-c-toolbar__group.pf-m-filter-group > * {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-filter-group--space-items);
}

.pf-v5-c-toolbar__group.pf-m-filter-group > * + * {
  margin-inline-start: -1px;
}

.pf-v5-c-toolbar__group.pf-m-toggle-group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--spacer);
}

.pf-v5-c-toolbar__group.pf-m-toggle-group .pf-v5-c-toolbar__group, .pf-v5-c-toolbar__group.pf-m-toggle-group .pf-v5-c-toolbar__item {
  display: none;
}

.pf-v5-c-toolbar__group.pf-m-toggle-group .pf-v5-c-toolbar__toggle {
  display: inline-block;
}

.pf-v5-c-toolbar__group:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar__item {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--spacer);
  --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width);
  --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth);
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-toolbar__item--Display);
  align-items: var(--pf-v5-c-toolbar__item--AlignItems);
  align-self: var(--pf-v5-c-toolbar__item--AlignSelf);
  width: var(--pf-v5-c-toolbar__item--Width--base);
  min-width: var(--pf-v5-c-toolbar__item--MinWidth--base);
  margin-inline-end: var(--pf-v5-c-toolbar--spacer);
}

@media (width >= 576px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width-on-sm, var(--pf-v5-c-toolbar__item--Width));
  }
}

@media (width >= 768px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width-on-md, var(--pf-v5-c-toolbar__item--Width-on-sm, var(--pf-v5-c-toolbar__item--Width)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width-on-lg, var(--pf-v5-c-toolbar__item--Width-on-md, var(--pf-v5-c-toolbar__item--Width-on-sm, var(--pf-v5-c-toolbar__item--Width))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width-on-xl, var(--pf-v5-c-toolbar__item--Width-on-lg, var(--pf-v5-c-toolbar__item--Width-on-md, var(--pf-v5-c-toolbar__item--Width-on-sm, var(--pf-v5-c-toolbar__item--Width)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width-on-2xl, var(--pf-v5-c-toolbar__item--Width-on-xl, var(--pf-v5-c-toolbar__item--Width-on-lg, var(--pf-v5-c-toolbar__item--Width-on-md, var(--pf-v5-c-toolbar__item--Width-on-sm, var(--pf-v5-c-toolbar__item--Width))))));
  }
}

@media (width >= 576px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth-on-sm, var(--pf-v5-c-toolbar__item--MinWidth));
  }
}

@media (width >= 768px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth-on-md, var(--pf-v5-c-toolbar__item--MinWidth-on-sm, var(--pf-v5-c-toolbar__item--MinWidth)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth-on-lg, var(--pf-v5-c-toolbar__item--MinWidth-on-md, var(--pf-v5-c-toolbar__item--MinWidth-on-sm, var(--pf-v5-c-toolbar__item--MinWidth))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth-on-xl, var(--pf-v5-c-toolbar__item--MinWidth-on-lg, var(--pf-v5-c-toolbar__item--MinWidth-on-md, var(--pf-v5-c-toolbar__item--MinWidth-on-sm, var(--pf-v5-c-toolbar__item--MinWidth)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth-on-2xl, var(--pf-v5-c-toolbar__item--MinWidth-on-xl, var(--pf-v5-c-toolbar__item--MinWidth-on-lg, var(--pf-v5-c-toolbar__item--MinWidth-on-md, var(--pf-v5-c-toolbar__item--MinWidth-on-sm, var(--pf-v5-c-toolbar__item--MinWidth))))));
  }
}

.pf-v5-c-toolbar__item.pf-m-align-items-start {
  align-items: flex-start;
}

.pf-v5-c-toolbar__item.pf-m-align-items-center {
  align-items: center;
}

.pf-v5-c-toolbar__item.pf-m-align-items-baseline {
  align-items: baseline;
}

.pf-v5-c-toolbar__item.pf-m-align-self-start {
  align-self: flex-start;
}

.pf-v5-c-toolbar__item.pf-m-align-self-center {
  align-self: center;
}

.pf-v5-c-toolbar__item.pf-m-align-self-baseline {
  align-self: baseline;
}

.pf-v5-c-toolbar__item.pf-m-overflow-menu {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-overflow-menu--spacer);
}

.pf-v5-c-toolbar__item.pf-m-bulk-select {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-bulk-select--spacer);
}

.pf-v5-c-toolbar__item.pf-m-expand-all.pf-m-expanded {
  --pf-v5-c-toolbar__expand-all-icon--Rotate: var(--pf-v5-c-toolbar__item--m-expand-all--m-expanded__expand-all-icon--Rotate);
}

.pf-v5-c-toolbar__item.pf-m-search-filter {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-search-filter--spacer);
}

.pf-v5-c-toolbar__item.pf-m-chip-group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-chip-group--spacer);
}

.pf-v5-c-toolbar__item.pf-m-label {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-label--spacer);
  font-weight: var(--pf-v5-c-toolbar__item--m-label--FontWeight);
  transform: translateY(var(--pf-v5-c-toolbar__item--m-label--TranslateY));
}

.pf-v5-c-toolbar__item.pf-m-form-element {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-form-element--spacer);
  font-weight: var(--pf-v5-c-toolbar__item--m-form-element--FontWeight);
  transform: translateY(var(--pf-v5-c-toolbar__item--m-form-element--TranslateY));
}

.pf-v5-c-toolbar__item.pf-m-pagination {
  margin-inline-start: auto;
}

.pf-v5-c-toolbar__item.pf-m-pagination .pf-v5-c-pagination {
  flex-wrap: nowrap;
}

.pf-v5-c-toolbar__item:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar__group.pf-m-overflow-container, .pf-v5-c-toolbar__item.pf-m-overflow-container {
  flex: 1;
  overflow: hidden;
}

.pf-v5-c-toolbar__expand-all-icon {
  transition: var(--pf-v5-c-toolbar__expand-all-icon--Transition);
  transform: rotate(var(--pf-v5-c-toolbar__expand-all-icon--Rotate));
  display: inline-block;
}

.pf-v5-c-toolbar__content, .pf-v5-c-toolbar__content-section {
  flex-wrap: wrap;
}

.pf-v5-c-toolbar__content {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-toolbar__content--Display);
  row-gap: var(--pf-v5-c-toolbar__content--RowGap);
  align-items: var(--pf-v5-c-toolbar__content--AlignItems);
  padding-inline-start: var(--pf-v5-c-toolbar__content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-toolbar__content--PaddingRight);
  position: relative;
}

.pf-v5-c-toolbar__content-section {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-toolbar__content-section--Display);
  row-gap: var(--pf-v5-c-toolbar__content-section--RowGap);
  align-items: var(--pf-v5-c-toolbar__content-section--AlignItems);
  width: 100%;
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-start {
  align-items: flex-start;
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-center {
  --pf-v5-c-toolbar__content-section--AlignItems: var(--pf-v5-c-toolbar--m-align-items-center--AlignItems);
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-baseline {
  align-items: baseline;
}

.pf-v5-c-toolbar__expandable-content {
  z-index: var(--pf-v5-c-toolbar__expandable-content--ZIndex);
  background-color: var(--pf-v5-c-toolbar__expandable-content--BackgroundColor);
  box-shadow: var(--pf-v5-c-toolbar__expandable-content--BoxShadow);
  width: 100%;
  padding-block-start: var(--pf-v5-c-toolbar__expandable-content--PaddingTop);
  padding-block-end: var(--pf-v5-c-toolbar__expandable-content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-toolbar__expandable-content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-toolbar__expandable-content--PaddingRight);
  display: none;
  position: absolute;
  inset-block-start: calc(100% + var(--pf-v5-c-toolbar__content--RowGap));
  inset-inline: 0;
}

@media screen and (width >= 992px) {
  .pf-v5-c-toolbar__expandable-content {
    box-shadow: none;
    position: static;
  }
}

.pf-v5-c-toolbar__expandable-content.pf-m-expanded {
  grid-row-gap: var(--pf-v5-c-toolbar__expandable-content--m-expanded--GridRowGap);
  display: grid;
}

.pf-v5-c-toolbar__expandable-content .pf-v5-c-toolbar__group, .pf-v5-c-toolbar__expandable-content .pf-v5-c-toolbar__item {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar__expandable-content .pf-v5-c-toolbar__group {
  grid-row-gap: var(--pf-v5-c-toolbar__expandable-content--m-expanded--GridRowGap);
  display: grid;
}

.pf-v5-c-toolbar__expandable-content .pf-v5-c-toolbar__item.pf-m-label {
  font-size: var(--pf-v5-c-toolbar__expandable-content__item--m-label--FontSize);
  margin-block-end: var(--pf-v5-c-toolbar__expandable-content__item--m-label--MarginBottom);
}

.pf-v5-c-toolbar__content.pf-m-chip-container, .pf-v5-c-toolbar__group.pf-m-chip-container {
  grid-row-gap: 0;
  flex-wrap: wrap;
  align-items: baseline;
  margin-block-start: var(--pf-v5-c-toolbar__group--m-chip-container--MarginTop);
  display: flex;
}

.pf-v5-c-toolbar__content.pf-m-chip-container .pf-v5-c-toolbar__item, .pf-v5-c-toolbar__group.pf-m-chip-container .pf-v5-c-toolbar__item {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--spacer);
  --pf-v5-c-toolbar__item--AlignSelf: auto;
  margin-block-start: var(--pf-v5-c-toolbar__group--m-chip-container__item--MarginTop);
}

.pf-v5-c-toolbar__content.pf-m-chip-container .pf-v5-c-toolbar__group, .pf-v5-c-toolbar__group.pf-m-chip-container .pf-v5-c-toolbar__group {
  --pf-v5-c-toolbar__group--AlignItems: center;
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--spacer);
  grid-row-gap: 0;
  flex-wrap: wrap;
  display: flex;
}

.pf-v5-c-toolbar__content.pf-m-chip-container .pf-v5-c-toolbar__group:last-child, .pf-v5-c-toolbar__content.pf-m-chip-container .pf-v5-c-toolbar__item:last-child, .pf-v5-c-toolbar__group.pf-m-chip-container .pf-v5-c-toolbar__group:last-child, .pf-v5-c-toolbar__group.pf-m-chip-container .pf-v5-c-toolbar__item:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-v5-c-chip-group:last-child {
  --pf-v5-c-chip-group--MarginRight: 0;
}

.pf-v5-c-toolbar .pf-v5-c-chip-group li:last-child {
  --pf-v5-c-chip-group__li--m-toolbar--MarginRight: 0;
}

.pf-v5-c-toolbar__toggle.pf-m-expanded .pf-v5-c-button.pf-m-plain {
  color: var(--pf-v5-c-toolbar__toggle--m-expanded__c-button--m-plain--Color);
}

.pf-m-toggle-group.pf-m-show {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
}

.pf-m-toggle-group.pf-m-show .pf-v5-c-toolbar__group, .pf-m-toggle-group.pf-m-show .pf-v5-c-toolbar__item {
  flex: 0 auto;
  display: flex;
}

.pf-m-toggle-group.pf-m-show .pf-v5-c-toolbar__toggle {
  display: none;
}

@media (width >= 576px) {
  .pf-m-toggle-group.pf-m-show-on-sm {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
  }

  .pf-m-toggle-group.pf-m-show-on-sm .pf-v5-c-toolbar__group, .pf-m-toggle-group.pf-m-show-on-sm .pf-v5-c-toolbar__item {
    flex: 0 auto;
    display: flex;
  }

  .pf-m-toggle-group.pf-m-show-on-sm .pf-v5-c-toolbar__toggle {
    display: none;
  }
}

@media (width >= 768px) {
  .pf-m-toggle-group.pf-m-show-on-md {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
  }

  .pf-m-toggle-group.pf-m-show-on-md .pf-v5-c-toolbar__group, .pf-m-toggle-group.pf-m-show-on-md .pf-v5-c-toolbar__item {
    flex: 0 auto;
    display: flex;
  }

  .pf-m-toggle-group.pf-m-show-on-md .pf-v5-c-toolbar__toggle {
    display: none;
  }
}

@media (width >= 992px) {
  .pf-m-toggle-group.pf-m-show-on-lg {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
  }

  .pf-m-toggle-group.pf-m-show-on-lg .pf-v5-c-toolbar__group, .pf-m-toggle-group.pf-m-show-on-lg .pf-v5-c-toolbar__item {
    flex: 0 auto;
    display: flex;
  }

  .pf-m-toggle-group.pf-m-show-on-lg .pf-v5-c-toolbar__toggle {
    display: none;
  }
}

@media (width >= 1200px) {
  .pf-m-toggle-group.pf-m-show-on-xl {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
  }

  .pf-m-toggle-group.pf-m-show-on-xl .pf-v5-c-toolbar__group, .pf-m-toggle-group.pf-m-show-on-xl .pf-v5-c-toolbar__item {
    flex: 0 auto;
    display: flex;
  }

  .pf-m-toggle-group.pf-m-show-on-xl .pf-v5-c-toolbar__toggle {
    display: none;
  }
}

@media (width >= 1450px) {
  .pf-m-toggle-group.pf-m-show-on-2xl {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
  }

  .pf-m-toggle-group.pf-m-show-on-2xl .pf-v5-c-toolbar__group, .pf-m-toggle-group.pf-m-show-on-2xl .pf-v5-c-toolbar__item {
    flex: 0 auto;
    display: flex;
  }

  .pf-m-toggle-group.pf-m-show-on-2xl .pf-v5-c-toolbar__toggle {
    display: none;
  }
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right {
  margin-inline-start: auto;
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left {
  margin-inline-start: 0;
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left ~ .pf-m-pagination {
  margin-inline-start: auto;
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap {
  flex-wrap: nowrap;
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap {
  flex-wrap: wrap;
}

@media (width >= 576px) {
  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-sm, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-sm {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-sm ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-sm ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-sm, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-sm {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-sm ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-sm ~ .pf-m-pagination {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap-on-sm, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap-on-sm {
    flex-wrap: nowrap;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap-on-sm, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap-on-sm {
    flex-wrap: wrap;
  }
}

@media (width >= 768px) {
  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-md, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-md {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-md ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-md ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-md, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-md {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-md ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-md ~ .pf-m-pagination {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap-on-md, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap-on-md {
    flex-wrap: nowrap;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap-on-md, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap-on-md {
    flex-wrap: wrap;
  }
}

@media (width >= 992px) {
  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-lg, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-lg {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-lg ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-lg ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-lg, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-lg {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-lg ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-lg ~ .pf-m-pagination {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap-on-lg, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap-on-lg {
    flex-wrap: nowrap;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap-on-lg, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap-on-lg {
    flex-wrap: wrap;
  }
}

@media (width >= 1200px) {
  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-xl, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-xl {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-xl ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-xl ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-xl, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-xl {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-xl ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-xl ~ .pf-m-pagination {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap-on-xl, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap-on-xl {
    flex-wrap: nowrap;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap-on-xl, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap-on-xl {
    flex-wrap: wrap;
  }
}

@media (width >= 1450px) {
  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-2xl, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-2xl {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-2xl ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-2xl ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-2xl, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-2xl {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-2xl ~ .pf-m-pagination, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-2xl ~ .pf-m-pagination {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap-on-2xl, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap-on-2xl {
    flex-wrap: nowrap;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap-on-2xl, .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap-on-2xl {
    flex-wrap: wrap;
  }
}

.pf-v5-c-toolbar .pf-m-space-items-none > *, .pf-v5-c-toolbar .pf-m-space-items-none > :last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-m-space-items-sm > * {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-toolbar .pf-m-space-items-sm > :last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-m-space-items-md > * {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-toolbar .pf-m-space-items-md > :last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-m-space-items-lg > * {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-toolbar .pf-m-space-items-lg > :last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

@media (width >= 576px) {
  .pf-v5-c-toolbar .pf-m-space-items-none-on-sm > *, .pf-v5-c-toolbar .pf-m-space-items-none-on-sm > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-sm > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-sm > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-sm > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-sm > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-sm > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-sm > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }
}

@media (width >= 768px) {
  .pf-v5-c-toolbar .pf-m-space-items-none-on-md > *, .pf-v5-c-toolbar .pf-m-space-items-none-on-md > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-md > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-md > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-md > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-md > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-md > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-md > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }
}

@media (width >= 992px) {
  .pf-v5-c-toolbar .pf-m-space-items-none-on-lg > *, .pf-v5-c-toolbar .pf-m-space-items-none-on-lg > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-lg > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-lg > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-lg > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-lg > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-lg > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-lg > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }
}

@media (width >= 1200px) {
  .pf-v5-c-toolbar .pf-m-space-items-none-on-xl > *, .pf-v5-c-toolbar .pf-m-space-items-none-on-xl > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-xl > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-xl > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-xl > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-xl > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-xl > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-xl > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }
}

@media (width >= 1450px) {
  .pf-v5-c-toolbar .pf-m-space-items-none-on-2xl > *, .pf-v5-c-toolbar .pf-m-space-items-none-on-2xl > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-2xl > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-2xl > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-2xl > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-2xl > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-2xl > * {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-2xl > :last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }
}

.pf-v5-c-toolbar .pf-m-spacer-none, .pf-v5-c-toolbar .pf-m-spacer-none:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-m-spacer-sm, .pf-v5-c-toolbar .pf-m-spacer-sm:last-child {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-toolbar .pf-m-spacer-md, .pf-v5-c-toolbar .pf-m-spacer-md:last-child {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-toolbar .pf-m-spacer-lg, .pf-v5-c-toolbar .pf-m-spacer-lg:last-child {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
}

@media (width >= 576px) {
  .pf-v5-c-toolbar .pf-m-spacer-none-on-sm, .pf-v5-c-toolbar .pf-m-spacer-none-on-sm:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-sm, .pf-v5-c-toolbar .pf-m-spacer-sm-on-sm:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-sm, .pf-v5-c-toolbar .pf-m-spacer-md-on-sm:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-sm, .pf-v5-c-toolbar .pf-m-spacer-lg-on-sm:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }
}

@media (width >= 768px) {
  .pf-v5-c-toolbar .pf-m-spacer-none-on-md, .pf-v5-c-toolbar .pf-m-spacer-none-on-md:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-md, .pf-v5-c-toolbar .pf-m-spacer-sm-on-md:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-md, .pf-v5-c-toolbar .pf-m-spacer-md-on-md:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-md, .pf-v5-c-toolbar .pf-m-spacer-lg-on-md:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }
}

@media (width >= 992px) {
  .pf-v5-c-toolbar .pf-m-spacer-none-on-lg, .pf-v5-c-toolbar .pf-m-spacer-none-on-lg:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-lg, .pf-v5-c-toolbar .pf-m-spacer-sm-on-lg:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-lg, .pf-v5-c-toolbar .pf-m-spacer-md-on-lg:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-lg, .pf-v5-c-toolbar .pf-m-spacer-lg-on-lg:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }
}

@media (width >= 1200px) {
  .pf-v5-c-toolbar .pf-m-spacer-none-on-xl, .pf-v5-c-toolbar .pf-m-spacer-none-on-xl:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-xl, .pf-v5-c-toolbar .pf-m-spacer-sm-on-xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-xl, .pf-v5-c-toolbar .pf-m-spacer-md-on-xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-xl, .pf-v5-c-toolbar .pf-m-spacer-lg-on-xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }
}

@media (width >= 1450px) {
  .pf-v5-c-toolbar .pf-m-spacer-none-on-2xl, .pf-v5-c-toolbar .pf-m-spacer-none-on-2xl:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-2xl, .pf-v5-c-toolbar .pf-m-spacer-sm-on-2xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-2xl, .pf-v5-c-toolbar .pf-m-spacer-md-on-2xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-2xl, .pf-v5-c-toolbar .pf-m-spacer-lg-on-2xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }
}

.pf-v5-c-toolbar.pf-m-inset-none {
  --pf-v5-c-toolbar--inset: 0;
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

.pf-v5-c-toolbar.pf-m-inset-sm {
  --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

.pf-v5-c-toolbar.pf-m-inset-md {
  --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

.pf-v5-c-toolbar.pf-m-inset-lg {
  --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

.pf-v5-c-toolbar.pf-m-inset-xl {
  --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

.pf-v5-c-toolbar.pf-m-inset-2xl {
  --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

@media (width >= 576px) {
  .pf-v5-c-toolbar.pf-m-inset-none-on-sm {
    --pf-v5-c-toolbar--inset: 0;
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-sm-on-sm {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-md-on-sm {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-lg-on-sm {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-xl-on-sm {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-2xl-on-sm {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }
}

@media (width >= 768px) {
  .pf-v5-c-toolbar.pf-m-inset-none-on-md {
    --pf-v5-c-toolbar--inset: 0;
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-sm-on-md {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-md-on-md {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-lg-on-md {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-xl-on-md {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-2xl-on-md {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }
}

@media (width >= 992px) {
  .pf-v5-c-toolbar.pf-m-inset-none-on-lg {
    --pf-v5-c-toolbar--inset: 0;
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-sm-on-lg {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-md-on-lg {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-lg-on-lg {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-xl-on-lg {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-2xl-on-lg {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }
}

@media (width >= 1200px) {
  .pf-v5-c-toolbar.pf-m-inset-none-on-xl {
    --pf-v5-c-toolbar--inset: 0;
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-sm-on-xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-md-on-xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-lg-on-xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-xl-on-xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-2xl-on-xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }
}

@media (width >= 1450px) {
  .pf-v5-c-toolbar.pf-m-inset-none-on-2xl {
    --pf-v5-c-toolbar--inset: 0;
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-sm-on-2xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-md-on-2xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-lg-on-2xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-xl-on-2xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-2xl-on-2xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }
}

.pf-v5-c-toolbar__content-section > :last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-divider {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}

.pf-m-hidden.pf-v5-c-divider {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}

@media screen and (width >= 576px) {
  .pf-m-hidden-on-sm.pf-v5-c-divider {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-sm.pf-v5-c-divider {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 768px) {
  .pf-m-hidden-on-md.pf-v5-c-divider {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-md.pf-v5-c-divider {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 992px) {
  .pf-m-hidden-on-lg.pf-v5-c-divider {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-lg.pf-v5-c-divider {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 1200px) {
  .pf-m-hidden-on-xl.pf-v5-c-divider {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-xl.pf-v5-c-divider {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 1450px) {
  .pf-m-hidden-on-2xl.pf-v5-c-divider {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-2xl.pf-v5-c-divider {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

.pf-v5-c-divider {
  --pf-v5-c-divider--BorderWidth--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-divider--BorderColor--base: var(--pf-v5-c-divider--BackgroundColor);
  --pf-v5-c-divider--Height: var(--pf-v5-c-divider--BorderWidth--base);
  --pf-v5-c-divider--BackgroundColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-divider--after--BackgroundColor: var(--pf-v5-c-divider--BorderColor--base);
  --pf-v5-c-divider--after--FlexBasis: 100%;
  --pf-v5-c-divider--after--Inset: 0%;
  --pf-v5-c-divider--m-vertical--after--FlexBasis: 100%;
  --pf-v5-c-divider--m-horizontal--Display: flex;
  --pf-v5-c-divider--m-horizontal--FlexDirection: row;
  --pf-v5-c-divider--m-horizontal--after--Height: var(--pf-v5-c-divider--Height);
  --pf-v5-c-divider--m-horizontal--after--Width: auto;
  --pf-v5-c-divider--m-vertical--Display: inline-flex;
  --pf-v5-c-divider--m-vertical--FlexDirection: column;
  --pf-v5-c-divider--m-vertical--after--Height: auto;
  --pf-v5-c-divider--m-vertical--after--Width: var(--pf-v5-c-divider--BorderWidth--base);
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-divider--Display);
  --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
  --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
  --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
  --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
  flex-direction: var(--pf-v5-c-divider--FlexDirection);
  border: 0;
  flex-shrink: 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: auto;
}

.pf-v5-c-divider:after {
  flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset) * 2);
  width: var(--pf-v5-c-divider--after--Width);
  height: var(--pf-v5-c-divider--after--Height);
  content: "";
  background-color: var(--pf-v5-c-divider--after--BackgroundColor);
  place-self: stretch center;
}

.pf-v5-c-divider.pf-m-vertical {
  --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
  --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
  --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
  --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
  height: inherit;
  width: auto;
}

.pf-v5-c-divider.pf-m-vertical:after {
  flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset));
}

.pf-v5-c-divider.pf-m-inset-none {
  --pf-v5-c-divider--after--Inset: 0%;
}

.pf-v5-c-divider.pf-m-inset-xs {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
}

.pf-v5-c-divider.pf-m-inset-sm {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-divider.pf-m-inset-md {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-divider.pf-m-inset-lg {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-divider.pf-m-inset-xl {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
}

.pf-v5-c-divider.pf-m-inset-2xl {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
}

.pf-v5-c-divider.pf-m-inset-3xl {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
}

@media (width >= 576px) {
  .pf-v5-c-divider.pf-m-horizontal-on-sm {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
    width: 100%;
    height: auto;
  }

  .pf-v5-c-divider.pf-m-horizontal-on-sm:after {
    flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset) * 2);
  }

  .pf-v5-c-divider.pf-m-vertical-on-sm {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
    height: inherit;
    width: auto;
  }

  .pf-v5-c-divider.pf-m-vertical-on-sm:after {
    flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset));
  }

  .pf-v5-c-divider.pf-m-inset-none-on-sm {
    --pf-v5-c-divider--after--Inset: 0%;
  }

  .pf-v5-c-divider.pf-m-inset-xs-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-c-divider.pf-m-inset-sm-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-divider.pf-m-inset-md-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-divider.pf-m-inset-lg-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-divider.pf-m-inset-xl-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-divider.pf-m-inset-2xl-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-c-divider.pf-m-inset-3xl-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
  }
}

@media (width >= 768px) {
  .pf-v5-c-divider.pf-m-horizontal-on-md {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
    width: 100%;
    height: auto;
  }

  .pf-v5-c-divider.pf-m-horizontal-on-md:after {
    flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset) * 2);
  }

  .pf-v5-c-divider.pf-m-vertical-on-md {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
    height: inherit;
    width: auto;
  }

  .pf-v5-c-divider.pf-m-vertical-on-md:after {
    flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset));
  }

  .pf-v5-c-divider.pf-m-inset-none-on-md {
    --pf-v5-c-divider--after--Inset: 0%;
  }

  .pf-v5-c-divider.pf-m-inset-xs-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-c-divider.pf-m-inset-sm-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-divider.pf-m-inset-md-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-divider.pf-m-inset-lg-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-divider.pf-m-inset-xl-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-divider.pf-m-inset-2xl-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-c-divider.pf-m-inset-3xl-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
  }
}

@media (width >= 992px) {
  .pf-v5-c-divider.pf-m-horizontal-on-lg {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
    width: 100%;
    height: auto;
  }

  .pf-v5-c-divider.pf-m-horizontal-on-lg:after {
    flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset) * 2);
  }

  .pf-v5-c-divider.pf-m-vertical-on-lg {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
    height: inherit;
    width: auto;
  }

  .pf-v5-c-divider.pf-m-vertical-on-lg:after {
    flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset));
  }

  .pf-v5-c-divider.pf-m-inset-none-on-lg {
    --pf-v5-c-divider--after--Inset: 0%;
  }

  .pf-v5-c-divider.pf-m-inset-xs-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-c-divider.pf-m-inset-sm-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-divider.pf-m-inset-md-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-divider.pf-m-inset-lg-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-divider.pf-m-inset-xl-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-divider.pf-m-inset-2xl-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-c-divider.pf-m-inset-3xl-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
  }
}

@media (width >= 1200px) {
  .pf-v5-c-divider.pf-m-horizontal-on-xl {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
    width: 100%;
    height: auto;
  }

  .pf-v5-c-divider.pf-m-horizontal-on-xl:after {
    flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset) * 2);
  }

  .pf-v5-c-divider.pf-m-vertical-on-xl {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
    height: inherit;
    width: auto;
  }

  .pf-v5-c-divider.pf-m-vertical-on-xl:after {
    flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset));
  }

  .pf-v5-c-divider.pf-m-inset-none-on-xl {
    --pf-v5-c-divider--after--Inset: 0%;
  }

  .pf-v5-c-divider.pf-m-inset-xs-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-c-divider.pf-m-inset-sm-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-divider.pf-m-inset-md-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-divider.pf-m-inset-lg-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-divider.pf-m-inset-xl-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-divider.pf-m-inset-2xl-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-c-divider.pf-m-inset-3xl-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
  }
}

@media (width >= 1450px) {
  .pf-v5-c-divider.pf-m-horizontal-on-2xl {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
    width: 100%;
    height: auto;
  }

  .pf-v5-c-divider.pf-m-horizontal-on-2xl:after {
    flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset) * 2);
  }

  .pf-v5-c-divider.pf-m-vertical-on-2xl {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
    height: inherit;
    width: auto;
  }

  .pf-v5-c-divider.pf-m-vertical-on-2xl:after {
    flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis)  - var(--pf-v5-c-divider--after--Inset));
  }

  .pf-v5-c-divider.pf-m-inset-none-on-2xl {
    --pf-v5-c-divider--after--Inset: 0%;
  }

  .pf-v5-c-divider.pf-m-inset-xs-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-c-divider.pf-m-inset-sm-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-divider.pf-m-inset-md-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-divider.pf-m-inset-lg-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-divider.pf-m-inset-xl-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-divider.pf-m-inset-2xl-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-c-divider.pf-m-inset-3xl-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
  }
}

.pf-v5-c-button {
  --pf-v5-c-button--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-button--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-button--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-button--BackgroundColor: transparent;
  --pf-v5-c-button--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-button--after--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-button--after--BorderColor: transparent;
  --pf-v5-c-button--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-button--hover--after--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--focus--after--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--active--after--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-button--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-button--disabled--after--BorderColor: transparent;
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-primary--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-primary--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-button--m-primary--hover--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-primary--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-button--m-primary--focus--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-primary--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-button--m-primary--active--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-secondary--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--after--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--hover--after--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--hover--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--focus--after--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--focus--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--active--after--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--active--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--m-danger--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--m-danger--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-secondary--m-danger--after--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-secondary--m-danger--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--m-danger--hover--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-secondary--m-danger--hover--after--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-secondary--m-danger--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--m-danger--focus--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-secondary--m-danger--focus--after--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-secondary--m-danger--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--m-danger--active--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-secondary--m-danger--active--after--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-tertiary--BackgroundColor: transparent;
  --pf-v5-c-button--m-tertiary--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-tertiary--hover--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-tertiary--focus--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-tertiary--active--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-warning--BackgroundColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-button--m-warning--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-button--m-warning--hover--BackgroundColor: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-button--m-warning--hover--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-button--m-warning--focus--BackgroundColor: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-button--m-warning--focus--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-button--m-warning--active--BackgroundColor: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-button--m-warning--active--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-danger--hover--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-danger--hover--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-danger--focus--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-danger--focus--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-danger--active--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-danger--active--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-link--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-button--m-link--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-button--m-link--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--focus--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-button--m-link--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--active--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-button--m-link--disabled--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-button--m-link--m-inline--FontSize: inherit;
  --pf-v5-c-button--m-link--m-inline--hover--TextDecoration: var(--pf-v5-global--link--TextDecoration--hover);
  --pf-v5-c-button--m-link--m-inline--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-button--m-link--m-inline--PaddingTop: 0;
  --pf-v5-c-button--m-link--m-inline--PaddingRight: 0;
  --pf-v5-c-button--m-link--m-inline--PaddingBottom: 0;
  --pf-v5-c-button--m-link--m-inline--PaddingLeft: 0;
  --pf-v5-c-button--m-link--m-inline__progress--Left: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-button--m-link--m-inline--m-in-progress--PaddingLeft: calc(var(--pf-v5-c-button--m-link--m-inline__progress--Left)  + 1rem + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-button--m-link--m-danger--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--m-danger--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-link--m-danger--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--m-danger--hover--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-link--m-danger--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--m-danger--focus--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-link--m-danger--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--m-danger--active--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-plain--BackgroundColor: transparent;
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-button--m-plain--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-plain--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-plain--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-plain--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-plain--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-plain--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-plain--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-button--m-plain--disabled--BackgroundColor: transparent;
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-button--m-control--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-control--BorderRadius: 0;
  --pf-v5-c-button--m-control--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-button--m-control--after--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-button--m-control--after--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-button--m-control--after--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-button--m-control--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-button--m-control--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-button--m-control--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-control--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--m-control--hover--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-button--m-control--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-button--m-control--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-control--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--m-control--active--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-button--m-control--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-button--m-control--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-control--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--m-control--focus--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-button--m-control--m-expanded--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-button--m-control--m-expanded--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-control--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--m-control--m-expanded--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-button--m-small--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-button--m-display-lg--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--m-display-lg--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-button--m-display-lg--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--m-display-lg--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-button--m-display-lg--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-button--m-link--m-display-lg--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-button__icon--m-start--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-button__icon--m-end--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-button__progress--width: calc(var(--pf-v5-global--icon--FontSize--md)  + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-button__progress--Opacity: 0;
  --pf-v5-c-button__progress--TranslateY: -50%;
  --pf-v5-c-button__progress--TranslateX: 0;
  --pf-v5-c-button__progress--Top: 50%;
  --pf-v5-c-button__progress--Left: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--m-progress--TransitionProperty: padding;
  --pf-v5-c-button--m-progress--TransitionDuration: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-button--m-progress--PaddingRight: calc(var(--pf-v5-global--spacer--md)  + var(--pf-v5-c-button__progress--width) / 2);
  --pf-v5-c-button--m-progress--PaddingLeft: calc(var(--pf-v5-global--spacer--md)  + var(--pf-v5-c-button__progress--width) / 2);
  --pf-v5-c-button--m-in-progress--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--m-in-progress--PaddingLeft: calc(var(--pf-v5-global--spacer--md)  + var(--pf-v5-c-button__progress--width));
  --pf-v5-c-button--m-in-progress--m-plain--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-in-progress--m-plain__progress--Left: 50%;
  --pf-v5-c-button--m-in-progress--m-plain__progress--TranslateX: -50%;
  --pf-v5-c-button__count--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-button--disabled__c-badge--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-button--disabled__c-badge--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-button--m-primary__c-badge--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-button--m-primary__c-badge--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  font-size: var(--pf-v5-c-button--FontSize);
  font-weight: var(--pf-v5-c-button--FontWeight);
  line-height: var(--pf-v5-c-button--LineHeight);
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--pf-v5-c-button--BorderRadius);
  border: 0;
  padding-block-start: var(--pf-v5-c-button--PaddingTop);
  padding-block-end: var(--pf-v5-c-button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-button--PaddingRight);
  display: inline-block;
  position: relative;
}

.pf-v5-c-button:after {
  pointer-events: none;
  content: "";
  border: var(--pf-v5-c-button--after--BorderWidth) solid;
  border-color: var(--pf-v5-c-button--after--BorderColor);
  border-radius: var(--pf-v5-c-button--after--BorderRadius);
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
}

.pf-v5-c-button:hover {
  --pf-v5-c-button--after--BorderWidth: var(--pf-v5-c-button--hover--after--BorderWidth);
  text-decoration: none;
}

.pf-v5-c-button:focus {
  --pf-v5-c-button--after--BorderWidth: var(--pf-v5-c-button--focus--after--BorderWidth);
}

.pf-v5-c-button:active, .pf-v5-c-button.pf-m-active {
  --pf-v5-c-button--after--BorderWidth: var(--pf-v5-c-button--active--after--BorderWidth);
}

.pf-v5-c-button.pf-m-block {
  width: 100%;
  display: block;
}

.pf-v5-c-button.pf-m-small {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-button--m-small--FontSize);
}

.pf-v5-c-button.pf-m-primary.pf-m-display-lg, .pf-v5-c-button.pf-m-secondary.pf-m-display-lg, .pf-v5-c-button.pf-m-tertiary.pf-m-display-lg, .pf-v5-c-button.pf-m-link.pf-m-display-lg {
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-button--m-display-lg--PaddingTop);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-button--m-display-lg--PaddingRight);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-button--m-display-lg--PaddingBottom);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-button--m-display-lg--PaddingLeft);
  --pf-v5-c-button--FontWeight: var(--pf-v5-c-button--m-display-lg--FontWeight);
}

.pf-v5-c-button.pf-m-primary {
  color: var(--pf-v5-c-button--m-primary--Color);
  background-color: var(--pf-v5-c-button--m-primary--BackgroundColor);
}

.pf-v5-c-button.pf-m-primary:hover {
  --pf-v5-c-button--m-primary--Color: var(--pf-v5-c-button--m-primary--hover--Color);
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-c-button--m-primary--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-primary:focus {
  --pf-v5-c-button--m-primary--Color: var(--pf-v5-c-button--m-primary--focus--Color);
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-c-button--m-primary--focus--BackgroundColor);
}

.pf-v5-c-button.pf-m-primary:active, .pf-v5-c-button.pf-m-primary.pf-m-active {
  --pf-v5-c-button--m-primary--Color: var(--pf-v5-c-button--m-primary--active--Color);
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-c-button--m-primary--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-primary .pf-v5-c-badge.pf-m-unread {
  border: var(--pf-v5-c-button--m-primary__c-badge--BorderWidth) solid var(--pf-v5-c-button--m-primary__c-badge--BorderColor);
}

.pf-v5-c-button.pf-m-secondary {
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--after--BorderColor);
  color: var(--pf-v5-c-button--m-secondary--Color);
  background-color: var(--pf-v5-c-button--m-secondary--BackgroundColor);
}

.pf-v5-c-button.pf-m-secondary:hover {
  --pf-v5-c-button--m-secondary--Color: var(--pf-v5-c-button--m-secondary--hover--Color);
  --pf-v5-c-button--m-secondary--BackgroundColor: var(--pf-v5-c-button--m-secondary--hover--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--hover--after--BorderColor);
}

.pf-v5-c-button.pf-m-secondary:focus {
  --pf-v5-c-button--m-secondary--Color: var(--pf-v5-c-button--m-secondary--focus--Color);
  --pf-v5-c-button--m-secondary--BackgroundColor: var(--pf-v5-c-button--m-secondary--focus--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--focus--after--BorderColor);
}

.pf-v5-c-button.pf-m-secondary.pf-m-active, .pf-v5-c-button.pf-m-secondary:active {
  --pf-v5-c-button--m-secondary--Color: var(--pf-v5-c-button--m-secondary--active--Color);
  --pf-v5-c-button--m-secondary--BackgroundColor: var(--pf-v5-c-button--m-secondary--active--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--active--after--BorderColor);
}

.pf-v5-c-button.pf-m-tertiary {
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-tertiary--after--BorderColor);
  color: var(--pf-v5-c-button--m-tertiary--Color);
  background-color: var(--pf-v5-c-button--m-tertiary--BackgroundColor);
}

.pf-v5-c-button.pf-m-tertiary:hover {
  --pf-v5-c-button--m-tertiary--Color: var(--pf-v5-c-button--m-tertiary--hover--Color);
  --pf-v5-c-button--m-tertiary--BackgroundColor: var(--pf-v5-c-button--m-tertiary--hover--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-tertiary--hover--after--BorderColor);
}

.pf-v5-c-button.pf-m-tertiary:focus {
  --pf-v5-c-button--m-tertiary--Color: var(--pf-v5-c-button--m-tertiary--focus--Color);
  --pf-v5-c-button--m-tertiary--BackgroundColor: var(--pf-v5-c-button--m-tertiary--focus--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-tertiary--focus--after--BorderColor);
}

.pf-v5-c-button.pf-m-tertiary:active, .pf-v5-c-button.pf-m-tertiary.pf-m-active {
  --pf-v5-c-button--m-tertiary--Color: var(--pf-v5-c-button--m-tertiary--active--Color);
  --pf-v5-c-button--m-tertiary--BackgroundColor: var(--pf-v5-c-button--m-tertiary--active--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-tertiary--active--after--BorderColor);
}

.pf-v5-c-button.pf-m-link {
  --pf-v5-c-button--disabled--BackgroundColor: var(--pf-v5-c-button--m-link--disabled--BackgroundColor);
  --pf-v5-c-button--disabled--Color: var(--pf-v5-c-button--m-link--disabled--Color);
  color: var(--pf-v5-c-button--m-link--Color);
  background-color: var(--pf-v5-c-button--m-link--BackgroundColor);
}

.pf-v5-c-button.pf-m-link:not(.pf-m-inline):hover {
  --pf-v5-c-button--m-link--Color: var(--pf-v5-c-button--m-link--hover--Color);
  --pf-v5-c-button--m-link--BackgroundColor: var(--pf-v5-c-button--m-link--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-link:not(.pf-m-inline):focus {
  --pf-v5-c-button--m-link--Color: var(--pf-v5-c-button--m-link--focus--Color);
  --pf-v5-c-button--m-link--BackgroundColor: var(--pf-v5-c-button--m-link--focus--BackgroundColor);
}

.pf-v5-c-button.pf-m-link:not(.pf-m-inline):active, .pf-v5-c-button.pf-m-link:not(.pf-m-inline).pf-m-active {
  --pf-v5-c-button--m-link--Color: var(--pf-v5-c-button--m-link--active--Color);
  --pf-v5-c-button--m-link--BackgroundColor: var(--pf-v5-c-button--m-link--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-link.pf-m-inline {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-button--m-link--m-inline--FontSize);
  --pf-v5-c-button__progress--Left: var(--pf-v5-c-button--m-link--m-inline__progress--Left);
  text-align: start;
  white-space: normal;
  cursor: pointer;
  padding-block-start: var(--pf-v5-c-button--m-link--m-inline--PaddingTop);
  padding-block-end: var(--pf-v5-c-button--m-link--m-inline--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-button--m-link--m-inline--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-button--m-link--m-inline--PaddingRight);
  display: inline;
}

.pf-v5-c-button.pf-m-link.pf-m-inline:hover {
  --pf-v5-c-button--m-link--Color: var(--pf-v5-c-button--m-link--m-inline--hover--Color);
  -webkit-text-decoration: var(--pf-v5-c-button--m-link--m-inline--hover--TextDecoration);
  text-decoration: var(--pf-v5-c-button--m-link--m-inline--hover--TextDecoration);
}

.pf-v5-c-button.pf-m-link.pf-m-display-lg {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-button--m-link--m-display-lg--FontSize);
}

.pf-v5-c-button.pf-m-danger {
  color: var(--pf-v5-c-button--m-danger--Color);
  background-color: var(--pf-v5-c-button--m-danger--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger:hover {
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-c-button--m-danger--hover--Color);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-c-button--m-danger--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger:focus {
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-c-button--m-danger--focus--Color);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-c-button--m-danger--focus--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger:active, .pf-v5-c-button.pf-m-danger.pf-m-active {
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-c-button--m-danger--active--Color);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-c-button--m-danger--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-secondary {
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-c-button--m-secondary--m-danger--Color);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-c-button--m-secondary--m-danger--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--m-danger--after--BorderColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-secondary:hover {
  --pf-v5-c-button--m-secondary--m-danger--Color: var(--pf-v5-c-button--m-secondary--m-danger--hover--Color);
  --pf-v5-c-button--m-secondary--m-danger--BackgroundColor: var(--pf-v5-c-button--m-secondary--m-danger--hover--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--m-danger--hover--after--BorderColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-secondary:focus {
  --pf-v5-c-button--m-secondary--m-danger--Color: var(--pf-v5-c-button--m-secondary--m-danger--focus--Color);
  --pf-v5-c-button--m-secondary--m-danger--BackgroundColor: var(--pf-v5-c-button--m-secondary--m-danger--focus--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--m-danger--focus--after--BorderColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-secondary:active, .pf-v5-c-button.pf-m-danger.pf-m-secondary.pf-m-active {
  --pf-v5-c-button--m-secondary--m-danger--Color: var(--pf-v5-c-button--m-secondary--m-danger--active--Color);
  --pf-v5-c-button--m-secondary--m-danger--BackgroundColor: var(--pf-v5-c-button--m-secondary--m-danger--active--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--m-danger--active--after--BorderColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-link {
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-c-button--m-link--m-danger--Color);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-c-button--m-link--m-danger--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-link:hover {
  --pf-v5-c-button--m-link--m-danger--Color: var(--pf-v5-c-button--m-link--m-danger--hover--Color);
  --pf-v5-c-button--m-link--m-danger--BackgroundColor: var(--pf-v5-c-button--m-link--m-danger--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-link:focus {
  --pf-v5-c-button--m-link--m-danger--Color: var(--pf-v5-c-button--m-link--m-danger--focus--Color);
  --pf-v5-c-button--m-link--m-danger--BackgroundColor: var(--pf-v5-c-button--m-link--m-danger--focus--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-link:active, .pf-v5-c-button.pf-m-danger.pf-m-link.pf-m-active {
  --pf-v5-c-button--m-link--m-danger--Color: var(--pf-v5-c-button--m-link--m-danger--active--Color);
  --pf-v5-c-button--m-link--m-danger--BackgroundColor: var(--pf-v5-c-button--m-link--m-danger--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-warning {
  color: var(--pf-v5-c-button--m-warning--Color);
  background-color: var(--pf-v5-c-button--m-warning--BackgroundColor);
}

.pf-v5-c-button.pf-m-warning:hover {
  --pf-v5-c-button--m-warning--Color: var(--pf-v5-c-button--m-warning--hover--Color);
  --pf-v5-c-button--m-warning--BackgroundColor: var(--pf-v5-c-button--m-warning--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-warning:focus {
  --pf-v5-c-button--m-warning--Color: var(--pf-v5-c-button--m-warning--focus--Color);
  --pf-v5-c-button--m-warning--BackgroundColor: var(--pf-v5-c-button--m-warning--focus--BackgroundColor);
}

.pf-v5-c-button.pf-m-warning:active, .pf-v5-c-button.pf-m-warning.pf-m-active {
  --pf-v5-c-button--m-warning--Color: var(--pf-v5-c-button--m-warning--active--Color);
  --pf-v5-c-button--m-warning--BackgroundColor: var(--pf-v5-c-button--m-warning--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-control {
  --pf-v5-c-button--BorderRadius: var(--pf-v5-c-button--m-control--BorderRadius);
  --pf-v5-c-button--disabled--BackgroundColor: var(--pf-v5-c-button--m-control--disabled--BackgroundColor);
  --pf-v5-c-button--after--BorderWidth: var(--pf-v5-c-button--m-control--after--BorderWidth);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-control--after--BorderTopColor) var(--pf-v5-c-button--m-control--after--BorderRightColor) var(--pf-v5-c-button--m-control--after--BorderBottomColor) var(--pf-v5-c-button--m-control--after--BorderLeftColor);
  color: var(--pf-v5-c-button--m-control--Color);
  background-color: var(--pf-v5-c-button--m-control--BackgroundColor);
}

.pf-v5-c-button.pf-m-control:after {
  border-radius: initial;
}

.pf-v5-c-button.pf-m-control:hover {
  --pf-v5-c-button--m-control--Color: var(--pf-v5-c-button--m-control--hover--Color);
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-c-button--m-control--hover--BackgroundColor);
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-c-button--m-control--hover--after--BorderBottomColor);
}

.pf-v5-c-button.pf-m-control:hover:after {
  border-block-end-width: var(--pf-v5-c-button--m-control--hover--after--BorderBottomWidth);
}

.pf-v5-c-button.pf-m-control:active, .pf-v5-c-button.pf-m-control.pf-m-active {
  --pf-v5-c-button--m-control--Color: var(--pf-v5-c-button--m-control--active--Color);
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-c-button--m-control--active--BackgroundColor);
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-c-button--m-control--active--after--BorderBottomColor);
}

.pf-v5-c-button.pf-m-control:active:after, .pf-v5-c-button.pf-m-control.pf-m-active:after {
  border-block-end-width: var(--pf-v5-c-button--m-control--active--after--BorderBottomWidth);
}

.pf-v5-c-button.pf-m-control:focus {
  --pf-v5-c-button--m-control--Color: var(--pf-v5-c-button--m-control--focus--Color);
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-c-button--m-control--focus--BackgroundColor);
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-c-button--m-control--focus--after--BorderBottomColor);
}

.pf-v5-c-button.pf-m-control:focus:after {
  border-block-end-width: var(--pf-v5-c-button--m-control--focus--after--BorderBottomWidth);
}

.pf-v5-c-button.pf-m-control.pf-m-expanded {
  --pf-v5-c-button--m-control--Color: var(--pf-v5-c-button--m-control--m-expanded--Color);
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-c-button--m-control--m-expanded--BackgroundColor);
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-c-button--m-control--m-expanded--after--BorderBottomColor);
}

.pf-v5-c-button.pf-m-control.pf-m-expanded:after {
  border-block-end-width: var(--pf-v5-c-button--m-control--m-expanded--after--BorderBottomWidth);
}

.pf-v5-c-button.pf-m-plain {
  --pf-v5-c-button--disabled--Color: var(--pf-v5-c-button--m-plain--disabled--Color);
  --pf-v5-c-button--disabled--BackgroundColor: var(--pf-v5-c-button--m-plain--disabled--BackgroundColor);
  color: var(--pf-v5-c-button--m-plain--Color);
  background-color: var(--pf-v5-c-button--m-plain--BackgroundColor);
}

.pf-v5-c-button.pf-m-plain.pf-m-no-padding {
  padding: 0;
}

.pf-v5-c-button.pf-m-plain:hover {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-button--m-plain--hover--Color);
  --pf-v5-c-button--m-plain--BackgroundColor: var(--pf-v5-c-button--m-plain--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-plain:active, .pf-v5-c-button.pf-m-plain.pf-m-active {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-button--m-plain--active--Color);
  --pf-v5-c-button--m-plain--BackgroundColor: var(--pf-v5-c-button--m-plain--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-plain:focus {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-button--m-plain--focus--Color);
  --pf-v5-c-button--m-plain--BackgroundColor: var(--pf-v5-c-button--m-plain--focus--BackgroundColor);
}

.pf-v5-c-button:disabled, .pf-v5-c-button.pf-m-disabled {
  pointer-events: none;
}

.pf-v5-c-button:disabled, .pf-v5-c-button.pf-m-disabled, .pf-v5-c-button.pf-m-aria-disabled {
  color: var(--pf-v5-c-button--disabled--Color);
  background-color: var(--pf-v5-c-button--disabled--BackgroundColor);
}

.pf-v5-c-button:disabled:after, .pf-v5-c-button.pf-m-disabled:after, .pf-v5-c-button.pf-m-aria-disabled:after {
  border-color: var(--pf-v5-c-button--disabled--after--BorderColor);
}

.pf-v5-c-button:disabled .pf-v5-c-badge, .pf-v5-c-button.pf-m-disabled .pf-v5-c-badge, .pf-v5-c-button.pf-m-aria-disabled .pf-v5-c-badge {
  --pf-v5-c-badge--m-unread--Color: var(--pf-v5-c-button--disabled__c-badge--Color);
  --pf-v5-c-badge--m-unread--BackgroundColor: var(--pf-v5-c-button--disabled__c-badge--BackgroundColor);
  --pf-v5-c-button--m-primary__c-badge--BorderWidth: 0;
}

.pf-v5-c-button.pf-m-aria-disabled {
  --pf-v5-c-button--after--BorderWidth: 0;
  --pf-v5-c-button--m-link--m-inline--hover--TextDecoration: none;
  cursor: default;
}

.pf-v5-c-button.pf-m-progress {
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-button--m-progress--PaddingRight);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-button--m-progress--PaddingLeft);
  transition: var(--pf-v5-c-button--m-progress--TransitionProperty) var(--pf-v5-c-button--m-progress--TransitionDuration);
}

.pf-v5-c-button.pf-m-in-progress {
  --pf-v5-c-button--m-link--m-inline--PaddingLeft: var(--pf-v5-c-button--m-link--m-inline--m-in-progress--PaddingLeft);
}

.pf-v5-c-button.pf-m-in-progress:not(.pf-m-plain) {
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-button--m-in-progress--PaddingRight);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-button--m-in-progress--PaddingLeft);
}

.pf-v5-c-button.pf-m-in-progress.pf-m-plain {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-button--m-in-progress--m-plain--Color);
  --pf-v5-c-button__progress--Left: var(--pf-v5-c-button--m-in-progress--m-plain__progress--Left);
  --pf-v5-c-button__progress--TranslateX: var(--pf-v5-c-button--m-in-progress--m-plain__progress--TranslateX);
}

.pf-v5-c-button.pf-m-in-progress.pf-m-plain > :not(.pf-v5-c-button__progress) {
  opacity: 0;
}

.pf-v5-c-button__icon.pf-m-start {
  margin-inline-end: var(--pf-v5-c-button__icon--m-start--MarginRight);
}

.pf-v5-c-button__icon.pf-m-end {
  margin-inline-start: var(--pf-v5-c-button__icon--m-end--MarginLeft);
}

.pf-v5-c-button__progress {
  transform: translate(var(--pf-v5-c-button__progress--TranslateX), var(--pf-v5-c-button__progress--TranslateY));
  line-height: 1;
  position: absolute;
  inset-block-start: var(--pf-v5-c-button__progress--Top);
  inset-inline-start: var(--pf-v5-c-button__progress--Left);
}

.pf-v5-c-button__progress .pf-v5-c-spinner {
  --pf-v5-c-spinner--Color: currentcolor;
}

.pf-v5-c-button__count {
  align-items: center;
  margin-inline-start: var(--pf-v5-c-button__count--MarginLeft);
  display: inline-flex;
}

:where(.pf-v5-theme-dark) .pf-v5-c-button {
  --pf-v5-c-button--disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-button--m-primary--Color: var(--pf-v5-global--primary-color--400);
  --pf-v5-c-button--m-tertiary--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-button--m-tertiary--Color: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-button--m-tertiary--hover--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-button--m-tertiary--hover--Color: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-button--m-tertiary--focus--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-button--m-tertiary--focus--Color: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-button--m-tertiary--active--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-button--m-tertiary--active--Color: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-button--m-warning--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-warning--hover--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-warning--focus--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-warning--active--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-warning--hover--BackgroundColor: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-button--m-warning--focus--BackgroundColor: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-button--m-warning--active--BackgroundColor: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-danger--hover--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-danger--focus--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-danger--active--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-link--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-button--m-control--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-button--m-control--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-button--m-control--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-button--m-control--m-expanded--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-button--m-control--after--BorderTopColor: transparent;
  --pf-v5-c-button--m-control--after--BorderRightColor: transparent;
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-button--m-control--after--BorderLeftColor: transparent;
  --pf-v5-c-button--m-control--hover--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-control--active--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-control--focus--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-control--m-expanded--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-control--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-button--m-primary__c-badge--BorderColor: var(--pf-v5-global--Color--100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-button.pf-m-control:disabled:after {
  border: 0;
  border-block-end: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--palette--black-700);
}

.pf-v5-c-spinner {
  --pf-v5-c-spinner--diameter: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-spinner--Width: var(--pf-v5-c-spinner--diameter);
  --pf-v5-c-spinner--Height: var(--pf-v5-c-spinner--diameter);
  --pf-v5-c-spinner--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-spinner--m-sm--diameter: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-spinner--m-md--diameter: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-spinner--m-lg--diameter: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-spinner--m-xl--diameter: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-spinner--m-inline--diameter: 1em;
  width: var(--pf-v5-c-spinner--Width);
  height: var(--pf-v5-c-spinner--Height);
  overflow: hidden;
}

.pf-v5-c-spinner.pf-m-inline {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-inline--diameter);
}

.pf-v5-c-spinner.pf-m-sm {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-sm--diameter);
}

.pf-v5-c-spinner.pf-m-md {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-md--diameter);
}

.pf-v5-c-spinner.pf-m-lg {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-lg--diameter);
}

.pf-v5-c-spinner.pf-m-xl {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-xl--diameter);
}

svg.pf-v5-c-spinner {
  --pf-v5-c-spinner--diameter: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-spinner--AnimationDuration: 1.4s;
  --pf-v5-c-spinner--AnimationTimingFunction: linear;
  --pf-v5-c-spinner--stroke-width: 10;
  --pf-v5-c-spinner__path--Stroke: var(--pf-v5-c-spinner--Color);
  --pf-v5-c-spinner__path--StrokeWidth: var(--pf-v5-c-spinner--stroke-width);
  --pf-v5-c-spinner__path--AnimationTimingFunction: ease-in-out;
  --pf-v5-c-spinner--m-sm--diameter: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-spinner--m-md--diameter: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-spinner--m-lg--diameter: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-spinner--m-xl--diameter: var(--pf-v5-global--icon--FontSize--xl);
  animation: pf-v5-c-spinner-animation-rotate calc(var(--pf-v5-c-spinner--AnimationDuration) * 2) var(--pf-v5-c-spinner--AnimationTimingFunction) infinite;
}

svg.pf-v5-c-spinner.pf-m-sm {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-sm--diameter);
}

svg.pf-v5-c-spinner.pf-m-md {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-md--diameter);
}

svg.pf-v5-c-spinner.pf-m-lg {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-lg--diameter);
}

svg.pf-v5-c-spinner.pf-m-xl {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-xl--diameter);
}

.pf-v5-c-spinner__path {
  stroke: var(--pf-v5-c-spinner--Color);
  stroke-dasharray: 283;
  stroke-dashoffset: 280px;
  stroke-linecap: round;
  stroke-width: var(--pf-v5-c-spinner--stroke-width);
  transform-origin: 50%;
  animation: pf-v5-c-spinner-animation-dash var(--pf-v5-c-spinner--AnimationDuration) var(--pf-v5-c-spinner__path--AnimationTimingFunction) infinite;
  width: 100%;
  height: 100%;
}

@keyframes pf-v5-c-spinner-animation-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pf-v5-c-spinner-animation-dash {
  0% {
    stroke-dashoffset: 280px;
    transform: rotate(0);
  }

  15% {
    stroke-width: calc(var(--pf-v5-c-spinner__path--StrokeWidth)  - 4);
  }

  40% {
    stroke-dasharray: 220;
    stroke-dashoffset: 150px;
  }

  100% {
    stroke-dashoffset: 280px;
    transform: rotate(720deg);
  }
}

.pf-v5-c-badge {
  --pf-v5-c-badge--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-badge--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-badge--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-badge--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-badge--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-badge--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-badge--MinWidth: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-badge--m-read--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-badge--m-read--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-badge--m-unread--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-badge--m-unread--Color: var(--pf-v5-global--Color--light-100);
  min-width: var(--pf-v5-c-badge--MinWidth);
  font-size: var(--pf-v5-c-badge--FontSize);
  font-weight: var(--pf-v5-c-badge--FontWeight);
  color: var(--pf-v5-c-badge--Color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--pf-v5-c-badge--BackgroundColor);
  border-radius: var(--pf-v5-c-badge--BorderRadius);
  padding-inline-start: var(--pf-v5-c-badge--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-badge--PaddingRight);
  display: inline-block;
}

.pf-v5-c-badge.pf-m-read {
  --pf-v5-c-badge--Color: var(--pf-v5-c-badge--m-read--Color);
  --pf-v5-c-badge--BackgroundColor: var(--pf-v5-c-badge--m-read--BackgroundColor);
}

.pf-v5-c-badge.pf-m-unread {
  --pf-v5-c-badge--Color: var(--pf-v5-c-badge--m-unread--Color);
  --pf-v5-c-badge--BackgroundColor: var(--pf-v5-c-badge--m-unread--BackgroundColor);
}

:where(.pf-v5-theme-dark) .pf-v5-c-badge {
  --pf-v5-c-badge--m-read--BackgroundColor: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-badge--m-unread--Color: var(--pf-v5-global--primary-color--400);
  --pf-v5-c-badge--m-unread--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

html, body, #root {
  height: 100%;
}

.pf-v5-c-masthead {
  --pf-v5-global--BackgroundColor--dark-100: #1e2939;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__link {
  font-weight: bold;
}

.pf-v5-c-menu-toggle:before {
  border-style: none;
}

.pf-v5-c-menu-toggle:after {
  border-block-end-style: none;
}

.centered {
  --pf-v5-c-page__main-section--PaddingRight: 20%;
  --pf-v5-c-page__main-section--PaddingLeft: 20%;
  padding-left: 20%;
  padding-right: 20%;
}

.pf-v5-c-alert {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
  --pf-v5-c-alert--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-alert--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-alert--GridTemplateColumns: max-content 1fr max-content;
  --pf-v5-c-alert--GridTemplateAreas: "icon title action" ". description description" ". actiongroup actiongroup";
  --pf-v5-c-alert--BorderTopWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-alert--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert__FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-alert__toggle--MarginTop: calc(-1 * var(--pf-v5-global--spacer--form-element)  - .0625rem);
  --pf-v5-c-alert__toggle--MarginBottom: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-alert__toggle--MarginLeft: calc(-1 * var(--pf-v5-global--spacer--md));
  --pf-v5-c-alert__toggle-icon--Rotate: 0;
  --pf-v5-c-alert__toggle-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-alert__icon--MarginTop: .0625rem;
  --pf-v5-c-alert__icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-alert__icon--FontSize: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-alert__title--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-alert__title--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-alert__title--max-lines: 1;
  --pf-v5-c-alert__action--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-alert__action--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-alert__action--TranslateY: .125rem;
  --pf-v5-c-alert__action--MarginRight: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-alert__description--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-alert__action-group--PaddingTop-base: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-alert__action-group--PaddingTop: var(--pf-v5-c-alert__action-group--PaddingTop-base);
  --pf-v5-c-alert__description--action-group--PaddingTop-base: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert__description--action-group--PaddingTop: var(--pf-v5-c-alert__description--action-group--PaddingTop-base);
  --pf-v5-c-alert__action-group__c-button--not-last-child--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-alert--m-custom--BorderTopColor: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-alert--m-custom__icon--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-alert--m-custom__title--Color: var(--pf-v5-global--custom-color--300);
  --pf-v5-c-alert--m-success--BorderTopColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-alert--m-success__icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-alert--m-success__title--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-alert--m-danger--BorderTopColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-alert--m-danger__icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-alert--m-danger__title--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-alert--m-warning--BorderTopColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-alert--m-warning__icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-alert--m-warning__title--Color: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-alert--m-info--BorderTopColor: var(--pf-v5-global--info-color--100);
  --pf-v5-c-alert--m-info__icon--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-alert--m-info__title--Color: var(--pf-v5-global--info-color--200);
  --pf-v5-c-alert--m-inline--BoxShadow: none;
  --pf-v5-c-alert--m-inline--m-custom--BackgroundColor: var(--pf-v5-global--palette--cyan-50);
  --pf-v5-c-alert--m-inline--m-success--BackgroundColor: var(--pf-v5-global--palette--green-50);
  --pf-v5-c-alert--m-inline--m-danger--BackgroundColor: var(--pf-v5-global--palette--red-50);
  --pf-v5-c-alert--m-inline--m-warning--BackgroundColor: var(--pf-v5-global--palette--gold-50);
  --pf-v5-c-alert--m-inline--m-info--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --pf-v5-c-alert--m-inline--m-plain--BorderTopWidth: 0;
  --pf-v5-c-alert--m-inline--m-plain--BackgroundColor: transparent;
  --pf-v5-c-alert--m-inline--m-plain--PaddingTop: 0;
  --pf-v5-c-alert--m-inline--m-plain--PaddingRight: 0;
  --pf-v5-c-alert--m-inline--m-plain--PaddingBottom: 0;
  --pf-v5-c-alert--m-inline--m-plain--PaddingLeft: 0;
  --pf-v5-c-alert--m-expandable--GridTemplateColumns: auto max-content 1fr max-content;
  --pf-v5-c-alert--m-expandable--GridTemplateAreas: "toggle icon title action" ". . description description" ". . actiongroup actiongroup";
  --pf-v5-c-alert--m-expandable__description--action-group--PaddingTop: var(--pf-v5-c-alert__action-group--PaddingTop-base);
  --pf-v5-c-alert--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-alert--m-expanded__description--action-group--PaddingTop: var(--pf-v5-c-alert__description--action-group--PaddingTop-base);
  color: var(--pf-v5-global--Color--100);
  grid-template-areas: var(--pf-v5-c-alert--GridTemplateAreas);
  grid-template-columns: var(--pf-v5-c-alert--GridTemplateColumns);
  font-size: var(--pf-v5-c-alert__FontSize);
  background-color: var(--pf-v5-c-alert--BackgroundColor);
  border-block-start: var(--pf-v5-c-alert--BorderTopWidth) solid var(--pf-v5-c-alert--BorderTopColor);
  box-shadow: var(--pf-v5-c-alert--BoxShadow);
  padding-block-start: var(--pf-v5-c-alert--PaddingTop);
  padding-block-end: var(--pf-v5-c-alert--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-alert--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-alert--PaddingRight);
  display: grid;
  position: relative;
}

.pf-v5-c-alert.pf-m-custom {
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-c-alert--m-custom--BorderTopColor);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-c-alert--m-custom__icon--Color);
  --pf-v5-c-alert__title--Color: var(--pf-v5-c-alert--m-custom__title--Color);
  --pf-v5-c-alert--m-inline--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-custom--BackgroundColor);
}

.pf-v5-c-alert.pf-m-success {
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-c-alert--m-success--BorderTopColor);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-c-alert--m-success__icon--Color);
  --pf-v5-c-alert__title--Color: var(--pf-v5-c-alert--m-success__title--Color);
  --pf-v5-c-alert--m-inline--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-success--BackgroundColor);
}

.pf-v5-c-alert.pf-m-danger {
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-c-alert--m-danger--BorderTopColor);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-c-alert--m-danger__icon--Color);
  --pf-v5-c-alert__title--Color: var(--pf-v5-c-alert--m-danger__title--Color);
  --pf-v5-c-alert--m-inline--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-danger--BackgroundColor);
}

.pf-v5-c-alert.pf-m-warning {
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-c-alert--m-warning--BorderTopColor);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-c-alert--m-warning__icon--Color);
  --pf-v5-c-alert__title--Color: var(--pf-v5-c-alert--m-warning__title--Color);
  --pf-v5-c-alert--m-inline--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-warning--BackgroundColor);
}

.pf-v5-c-alert.pf-m-info {
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-c-alert--m-info--BorderTopColor);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-c-alert--m-info__icon--Color);
  --pf-v5-c-alert__title--Color: var(--pf-v5-c-alert--m-info__title--Color);
  --pf-v5-c-alert--m-inline--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-info--BackgroundColor);
}

.pf-v5-c-alert.pf-m-inline {
  --pf-v5-c-alert--BoxShadow: var(--pf-v5-c-alert--m-inline--BoxShadow);
  --pf-v5-c-alert--BackgroundColor: var(--pf-v5-c-alert--m-inline--BackgroundColor);
}

.pf-v5-c-alert.pf-m-plain {
  --pf-v5-c-alert--BorderTopWidth: var(--pf-v5-c-alert--m-inline--m-plain--BorderTopWidth);
  --pf-v5-c-alert--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-plain--BackgroundColor);
  --pf-v5-c-alert--PaddingTop: var(--pf-v5-c-alert--m-inline--m-plain--PaddingTop);
  --pf-v5-c-alert--PaddingRight: var(--pf-v5-c-alert--m-inline--m-plain--PaddingRight);
  --pf-v5-c-alert--PaddingBottom: var(--pf-v5-c-alert--m-inline--m-plain--PaddingBottom);
  --pf-v5-c-alert--PaddingLeft: var(--pf-v5-c-alert--m-inline--m-plain--PaddingLeft);
}

.pf-v5-c-alert.pf-m-expandable {
  --pf-v5-c-alert--GridTemplateColumns: var(--pf-v5-c-alert--m-expandable--GridTemplateColumns);
  --pf-v5-c-alert--GridTemplateAreas: var(--pf-v5-c-alert--m-expandable--GridTemplateAreas);
  --pf-v5-c-alert__description--action-group--PaddingTop: var(--pf-v5-c-alert--m-expandable__description--action-group--PaddingTop);
}

.pf-v5-c-alert.pf-m-expanded {
  --pf-v5-c-alert__toggle-icon--Rotate: var(--pf-v5-c-alert--m-expanded__toggle-icon--Rotate);
  --pf-v5-c-alert__description--action-group--PaddingTop: var(--pf-v5-c-alert--m-expanded__description--action-group--PaddingTop);
}

.pf-v5-c-alert__toggle {
  margin-block-start: var(--pf-v5-c-alert__toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-alert__toggle--MarginBottom);
  margin-inline-start: var(--pf-v5-c-alert__toggle--MarginLeft);
}

.pf-v5-c-alert__toggle-icon {
  transition: var(--pf-v5-c-alert__toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-alert__toggle-icon--Rotate));
  display: inline-block;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-alert__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-alert__icon {
  font-size: var(--pf-v5-c-alert__icon--FontSize);
  color: var(--pf-v5-c-alert__icon--Color);
  grid-area: icon;
  margin-block-start: var(--pf-v5-c-alert__icon--MarginTop);
  margin-inline-end: var(--pf-v5-c-alert__icon--MarginRight);
  display: flex;
}

.pf-v5-c-alert__title {
  font-weight: var(--pf-v5-c-alert__title--FontWeight);
  color: var(--pf-v5-c-alert__title--Color);
  word-break: break-word;
  grid-area: title;
}

.pf-v5-c-alert__title.pf-m-truncate {
  -webkit-line-clamp: var(--pf-v5-c-alert__title--max-lines);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.pf-v5-c-alert__description {
  word-break: break-word;
  grid-area: description;
  padding-block-start: var(--pf-v5-c-alert__description--PaddingTop);
}

.pf-v5-c-alert__description + .pf-v5-c-alert__action-group {
  --pf-v5-c-alert__action-group--PaddingTop: var(--pf-v5-c-alert__description--action-group--PaddingTop);
}

.pf-v5-c-alert__action {
  transform: translateY(var(--pf-v5-c-alert__action--TranslateY));
  grid-area: action;
  margin-block-start: var(--pf-v5-c-alert__action--MarginTop);
  margin-block-end: var(--pf-v5-c-alert__action--MarginBottom);
  margin-inline-end: var(--pf-v5-c-alert__action--MarginRight);
}

.pf-v5-c-alert__action > .pf-v5-c-button {
  --pf-v5-c-button--LineHeight: 1;
}

.pf-v5-c-alert__action-group {
  grid-area: actiongroup;
  padding-block-start: var(--pf-v5-c-alert__action-group--PaddingTop);
}

.pf-v5-c-alert__action-group > .pf-v5-c-button {
  --pf-v5-c-button--m-link--m-inline--hover--TextDecoration: none;
}

.pf-v5-c-alert__action-group > .pf-v5-c-button:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-alert__action-group__c-button--not-last-child--MarginRight);
}

:where(.pf-v5-theme-dark) .pf-v5-c-alert {
  --pf-v5-c-alert--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-alert--m-custom__title--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-alert--m-success__title--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-alert--m-danger__title--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-alert--m-warning__title--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-alert--m-info__title--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-alert--m-inline--m-custom--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-alert--m-inline--m-success--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-alert--m-inline--m-danger--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-alert--m-inline--m-warning--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-alert--m-inline--m-info--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-c-icon {
  --pf-v5-c-icon--Width: 1em;
  --pf-v5-c-icon--Height: 1em;
  --pf-v5-c-icon--m-sm--Width: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-icon--m-sm--Height: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-icon--m-md--Width: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-icon--m-md--Height: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-icon--m-lg--Width: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-icon--m-lg--Height: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-icon--m-xl--Width: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-icon--m-xl--Height: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-icon--m-inline--Width: 1em;
  --pf-v5-c-icon--m-inline--Height: 1em;
  --pf-v5-c-icon__content--svg--VerticalAlign: -.125em;
  --pf-v5-c-icon__content--Color: initial;
  --pf-v5-c-icon__content--m-danger--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-icon__content--m-warning--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-icon__content--m-success--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-icon__content--m-info--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-icon__content--m-custom--Color: var(--pf-v5-global--custom-color--100);
  --pf-v5-c-icon--m-inline__content--Color: initial;
  --pf-v5-c-icon__content--FontSize: 1em;
  --pf-v5-c-icon--m-sm__content--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-icon--m-md__content--FontSize: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-icon--m-lg__content--FontSize: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-icon--m-xl__content--FontSize: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-icon--m-inline__content--FontSize: 1em;
  width: var(--pf-v5-c-icon--Width);
  height: var(--pf-v5-c-icon--Height);
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.pf-v5-c-icon.pf-m-inline {
  --pf-v5-c-icon--Width: var(--pf-v5-c-icon--m-inline--Width);
  --pf-v5-c-icon--Height: var(--pf-v5-c-icon--m-inline--Height);
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-inline__content--FontSize);
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon--m-inline__content--Color);
  line-height: 1;
}

.pf-v5-c-icon.pf-m-inline .pf-v5-c-spinner {
  --pf-v5-c-spinner--diameter: 1em;
}

.pf-v5-c-icon.pf-m-sm {
  --pf-v5-c-icon--Width: var(--pf-v5-c-icon--m-sm--Width);
  --pf-v5-c-icon--Height: var(--pf-v5-c-icon--m-sm--Height);
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-sm__content--FontSize);
}

.pf-v5-c-icon.pf-m-md {
  --pf-v5-c-icon--Width: var(--pf-v5-c-icon--m-md--Width);
  --pf-v5-c-icon--Height: var(--pf-v5-c-icon--m-md--Height);
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-md__content--FontSize);
}

.pf-v5-c-icon.pf-m-lg {
  --pf-v5-c-icon--Width: var(--pf-v5-c-icon--m-lg--Width);
  --pf-v5-c-icon--Height: var(--pf-v5-c-icon--m-lg--Height);
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-lg__content--FontSize);
}

.pf-v5-c-icon.pf-m-xl {
  --pf-v5-c-icon--Width: var(--pf-v5-c-icon--m-xl--Width);
  --pf-v5-c-icon--Height: var(--pf-v5-c-icon--m-xl--Height);
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-xl__content--FontSize);
}

.pf-v5-c-icon.pf-m-in-progress {
  --pf-v5-c-icon__content--Opacity: 0;
  --pf-v5-c-icon__progress--Opacity: 1;
}

.pf-v5-c-icon__content, .pf-v5-c-icon__progress {
  font-size: var(--pf-v5-c-icon__content--FontSize);
}

.pf-v5-c-icon__content svg, .pf-v5-c-icon__progress svg {
  vertical-align: var(--pf-v5-c-icon__content--svg--VerticalAlign);
}

.pf-v5-c-icon__content.pf-m-sm, .pf-v5-c-icon__progress.pf-m-sm {
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-sm__content--FontSize);
}

.pf-v5-c-icon__content.pf-m-md, .pf-v5-c-icon__progress.pf-m-md {
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-md__content--FontSize);
}

.pf-v5-c-icon__content.pf-m-lg, .pf-v5-c-icon__progress.pf-m-lg {
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-lg__content--FontSize);
}

.pf-v5-c-icon__content.pf-m-xl, .pf-v5-c-icon__progress.pf-m-xl {
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-xl__content--FontSize);
}

.pf-v5-c-icon__content {
  color: var(--pf-v5-c-icon__content--Color, inherit);
  opacity: var(--pf-v5-c-icon__content--Opacity, 1);
}

.pf-v5-c-icon__content.pf-m-danger {
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon__content--m-danger--Color);
}

.pf-v5-c-icon__content.pf-m-warning {
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon__content--m-warning--Color);
}

.pf-v5-c-icon__content.pf-m-success {
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon__content--m-success--Color);
}

.pf-v5-c-icon__content.pf-m-info {
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon__content--m-info--Color);
}

.pf-v5-c-icon__content.pf-m-custom {
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon__content--m-custom--Color);
}

.pf-v5-c-icon__progress {
  opacity: var(--pf-v5-c-icon__progress--Opacity, 0);
  transform: translateY(calc(-50% - .5 * var(--pf-v5-c-icon__content--svg--VerticalAlign)));
  position: absolute;
  inset-block-start: calc(50% + .5 * var(--pf-v5-c-icon__content--svg--VerticalAlign));
}

.pf-v5-c-backdrop {
  --pf-v5-c-backdrop--Position: fixed;
  --pf-v5-c-backdrop--ZIndex: var(--pf-v5-global--ZIndex--lg);
  --pf-v5-c-backdrop--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-transparent-100);
  position: var(--pf-v5-c-backdrop--Position);
  z-index: var(--pf-v5-c-backdrop--ZIndex);
  background-color: var(--pf-v5-c-backdrop--BackgroundColor);
  width: 100%;
  height: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.pf-v5-c-backdrop__open {
  overflow: hidden;
}

.pf-v5-c-modal-box {
  --pf-v5-c-modal-box--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-modal-box--BoxShadow: var(--pf-v5-global--BoxShadow--xl);
  --pf-v5-c-modal-box--ZIndex: var(--pf-v5-global--ZIndex--xl);
  --pf-v5-c-modal-box--Width: 100%;
  --pf-v5-c-modal-box--MaxWidth: calc(100% - var(--pf-v5-global--spacer--xl));
  --pf-v5-c-modal-box--m-sm--sm--MaxWidth: 35rem;
  --pf-v5-c-modal-box--m-md--Width: 52.5rem;
  --pf-v5-c-modal-box--m-lg--lg--MaxWidth: 70rem;
  --pf-v5-c-modal-box--MaxHeight: calc(100% - var(--pf-v5-global--spacer--2xl));
  --pf-v5-c-modal-box--m-align-top--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-modal-box--m-align-top--xl--spacer: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-modal-box--m-align-top--MarginTop: var(--pf-v5-c-modal-box--m-align-top--spacer);
  --pf-v5-c-modal-box--m-align-top--MaxHeight: calc(100% - min(var(--pf-v5-c-modal-box--m-align-top--spacer), var(--pf-v5-global--spacer--2xl))  - var(--pf-v5-c-modal-box--m-align-top--spacer));
  --pf-v5-c-modal-box--m-align-top--MaxWidth: calc(100% - min(var(--pf-v5-c-modal-box--m-align-top--spacer) * 2, var(--pf-v5-global--spacer--xl)));
  --pf-v5-c-modal-box--m-danger__title-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-modal-box--m-warning__title-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-modal-box--m-success__title-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-modal-box--m-info__title-icon--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-modal-box--m-custom__title-icon--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-modal-box__header--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__header--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__header--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__header--last-child--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__title--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-modal-box__title--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-modal-box__title--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-modal-box__title-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-modal-box__description--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-modal-box__body--MinHeight: calc(var(--pf-v5-global--FontSize--md) * var(--pf-v5-global--LineHeight--md));
  --pf-v5-c-modal-box__body--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__body--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__body--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__body--last-child--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__header--body--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-modal-box__close--Top: calc(var(--pf-v5-global--spacer--lg));
  --pf-v5-c-modal-box__close--Right: var(--pf-v5-global--spacer--md);
  --pf-v5-c-modal-box__close--sibling--MarginRight: calc(var(--pf-v5-global--spacer--xl)  + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-modal-box__footer--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__footer--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__footer--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__footer--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__footer--c-button--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-modal-box__footer--c-button--sm--MarginRight: calc(var(--pf-v5-c-modal-box__footer--c-button--MarginRight) / 2);
  z-index: var(--pf-v5-c-modal-box--ZIndex);
  width: var(--pf-v5-c-modal-box--Width);
  max-width: var(--pf-v5-c-modal-box--MaxWidth);
  max-height: var(--pf-v5-c-modal-box--MaxHeight);
  background-color: var(--pf-v5-c-modal-box--BackgroundColor);
  box-shadow: var(--pf-v5-c-modal-box--BoxShadow);
  flex-direction: column;
  display: flex;
  position: relative;
}

@media (width >= 1200px) {
  .pf-v5-c-modal-box {
    --pf-v5-c-modal-box--m-align-top--spacer: var(--pf-v5-c-modal-box--m-align-top--xl--spacer);
  }
}

.pf-v5-c-modal-box.pf-m-sm {
  --pf-v5-c-modal-box--Width: var(--pf-v5-c-modal-box--m-sm--sm--MaxWidth);
}

.pf-v5-c-modal-box.pf-m-md {
  --pf-v5-c-modal-box--Width: var(--pf-v5-c-modal-box--m-md--Width);
}

.pf-v5-c-modal-box.pf-m-lg {
  --pf-v5-c-modal-box--Width: var(--pf-v5-c-modal-box--m-lg--lg--MaxWidth);
}

.pf-v5-c-modal-box.pf-m-align-top {
  max-width: var(--pf-v5-c-modal-box--m-align-top--MaxWidth);
  max-height: var(--pf-v5-c-modal-box--m-align-top--MaxHeight);
  align-self: flex-start;
  inset-block-start: var(--pf-v5-c-modal-box--m-align-top--MarginTop);
}

.pf-v5-c-modal-box__title.pf-m-danger, .pf-v5-c-modal-box.pf-m-danger {
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-c-modal-box--m-danger__title-icon--Color);
}

.pf-v5-c-modal-box__title.pf-m-warning, .pf-v5-c-modal-box.pf-m-warning {
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-c-modal-box--m-warning__title-icon--Color);
}

.pf-v5-c-modal-box__title.pf-m-success, .pf-v5-c-modal-box.pf-m-success {
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-c-modal-box--m-success__title-icon--Color);
}

.pf-v5-c-modal-box__title.pf-m-custom, .pf-v5-c-modal-box.pf-m-custom {
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-c-modal-box--m-custom__title-icon--Color);
}

.pf-v5-c-modal-box__title.pf-m-info, .pf-v5-c-modal-box.pf-m-info {
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-c-modal-box--m-info__title-icon--Color);
}

.pf-v5-c-modal-box__close {
  position: absolute;
  inset-block-start: var(--pf-v5-c-modal-box__close--Top);
  inset-inline-end: var(--pf-v5-c-modal-box__close--Right);
}

.pf-v5-c-modal-box__close + * {
  margin-inline-end: var(--pf-v5-c-modal-box__close--sibling--MarginRight);
}

.pf-v5-c-modal-box__header {
  flex-direction: column;
  flex-shrink: 0;
  padding-block-start: var(--pf-v5-c-modal-box__header--PaddingTop);
  padding-inline-start: var(--pf-v5-c-modal-box__header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-modal-box__header--PaddingRight);
  display: flex;
}

.pf-v5-c-modal-box__header.pf-m-help {
  flex-direction: row;
  display: flex;
}

.pf-v5-c-modal-box__header:last-child {
  padding-block-end: var(--pf-v5-c-modal-box__header--last-child--PaddingBottom);
}

.pf-v5-c-modal-box__header + .pf-v5-c-modal-box__body {
  --pf-v5-c-modal-box__body--PaddingTop: var(--pf-v5-c-modal-box__header--body--PaddingTop);
}

.pf-v5-c-modal-box__header-main {
  flex-grow: 1;
  min-width: 0;
}

.pf-v5-c-modal-box__title, .pf-v5-c-modal-box__title-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pf-v5-c-modal-box__title {
  font-family: var(--pf-v5-c-modal-box__title--FontFamily);
  font-size: var(--pf-v5-c-modal-box__title--FontSize);
  line-height: var(--pf-v5-c-modal-box__title--LineHeight);
  flex: none;
}

.pf-v5-c-modal-box__title.pf-m-icon {
  display: flex;
}

.pf-v5-c-modal-box__title-icon {
  color: var(--pf-v5-c-modal-box__title-icon--Color);
  margin-inline-end: var(--pf-v5-c-modal-box__title-icon--MarginRight);
}

.pf-v5-c-modal-box__description {
  padding-block-start: var(--pf-v5-c-modal-box__description--PaddingTop);
}

.pf-v5-c-modal-box__body {
  min-height: var(--pf-v5-c-modal-box__body--MinHeight);
  overscroll-behavior: contain;
  word-break: break-word;
  -webkit-overflow-scrolling: touch;
  flex: auto;
  padding-block-start: var(--pf-v5-c-modal-box__body--PaddingTop);
  padding-inline-start: var(--pf-v5-c-modal-box__body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-modal-box__body--PaddingRight);
  overflow: hidden auto;
}

.pf-v5-c-modal-box__body:last-child {
  padding-block-end: var(--pf-v5-c-modal-box__body--last-child--PaddingBottom);
}

.pf-v5-c-modal-box__footer {
  flex: none;
  align-items: center;
  padding-block-start: var(--pf-v5-c-modal-box__footer--PaddingTop);
  padding-block-end: var(--pf-v5-c-modal-box__footer--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-modal-box__footer--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-modal-box__footer--PaddingRight);
  display: flex;
}

.pf-v5-c-modal-box__footer > .pf-v5-c-button:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-modal-box__footer--c-button--MarginRight);
}

@media screen and (width >= 576px) {
  .pf-v5-c-modal-box__footer > .pf-v5-c-button:not(:last-child) {
    --pf-v5-c-modal-box__footer--c-button--MarginRight: var(--pf-v5-c-modal-box__footer--c-button--sm--MarginRight);
  }
}

:where(.pf-v5-theme-dark) .pf-v5-c-modal-box {
  --pf-v5-c-modal-box--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-l-bullseye {
  --pf-v5-l-bullseye--Padding: 0;
  padding: var(--pf-v5-l-bullseye--Padding);
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  display: flex;
}

.pf-v5-c-popover {
  --pf-v5-c-popover--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-popover--MinWidth: calc(var(--pf-v5-c-popover__content--PaddingLeft)  + var(--pf-v5-c-popover__content--PaddingRight)  + 18.75rem);
  --pf-v5-c-popover--MaxWidth: calc(var(--pf-v5-c-popover__content--PaddingLeft)  + var(--pf-v5-c-popover__content--PaddingRight)  + 18.75rem);
  --pf-v5-c-popover--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-popover--m-danger__title-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-popover--m-warning__title-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-popover--m-success__title-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-popover--m-info__title-icon--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-popover--m-custom__title-icon--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-popover--m-danger__title-text--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-popover--m-warning__title-text--Color: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-popover--m-success__title-text--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-popover--m-info__title-text--Color: var(--pf-v5-global--info-color--200);
  --pf-v5-c-popover--m-custom__title-text--Color: var(--pf-v5-global--custom-color--300);
  --pf-v5-c-popover__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-popover__content--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__content--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__content--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__content--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__arrow--Width: var(--pf-v5-global--arrow--width-lg);
  --pf-v5-c-popover__arrow--Height: var(--pf-v5-global--arrow--width-lg);
  --pf-v5-c-popover__arrow--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-popover__arrow--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-popover__arrow--m-top--TranslateX: -50%;
  --pf-v5-c-popover__arrow--m-top--TranslateY: 50%;
  --pf-v5-c-popover__arrow--m-top--Rotate: 45deg;
  --pf-v5-c-popover__arrow--m-right--TranslateX: -50%;
  --pf-v5-c-popover__arrow--m-right--TranslateY: -50%;
  --pf-v5-c-popover__arrow--m-right--Rotate: 45deg;
  --pf-v5-c-popover__arrow--m-bottom--TranslateX: -50%;
  --pf-v5-c-popover__arrow--m-bottom--TranslateY: -50%;
  --pf-v5-c-popover__arrow--m-bottom--Rotate: 45deg;
  --pf-v5-c-popover__arrow--m-left--TranslateX: 50%;
  --pf-v5-c-popover__arrow--m-left--TranslateY: -50%;
  --pf-v5-c-popover__arrow--m-left--Rotate: 45deg;
  --pf-v5-c-popover__close--Top: calc(var(--pf-v5-c-popover__content--PaddingTop)  - var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-popover__close--Right: calc(var(--pf-v5-c-popover__content--PaddingRight)  - var(--pf-v5-global--spacer--md));
  --pf-v5-c-popover__close--sibling--PaddingRight: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-popover__header--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-popover__title-text--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-popover__title-text--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-popover__title-text--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-popover__title-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-popover__title-icon--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-popover__footer--MarginTop: var(--pf-v5-global--spacer--md);
  min-width: var(--pf-v5-c-popover--MinWidth);
  max-width: var(--pf-v5-c-popover--MaxWidth);
  font-size: var(--pf-v5-c-popover--FontSize);
  box-shadow: var(--pf-v5-c-popover--BoxShadow);
  position: relative;
}

.pf-v5-c-popover.pf-m-no-padding {
  --pf-v5-c-popover__content--PaddingTop: 0px;
  --pf-v5-c-popover__content--PaddingRight: 0px;
  --pf-v5-c-popover__content--PaddingBottom: 0px;
  --pf-v5-c-popover__content--PaddingLeft: 0px;
}

.pf-v5-c-popover.pf-m-width-auto {
  --pf-v5-c-popover--MinWidth: auto;
  --pf-v5-c-popover--MaxWidth: none;
}

.pf-v5-c-popover:is(.pf-m-top, .pf-m-top-left, .pf-m-top-right) {
  --pf-v5-c-popover__arrow--Bottom: var(--pf-v5-c-popover--m-top--Bottom, 0);
  --pf-v5-c-popover__arrow--Left: var(--pf-v5-c-popover--m-top--Left, 50%);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-top--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-top--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-top--Rotate);
}

.pf-v5-c-popover:is(.pf-m-bottom, .pf-m-bottom-left, .pf-m-bottom-right) {
  --pf-v5-c-popover__arrow--Top: var(--pf-v5-c-popover--m-bottom--Top, 0);
  --pf-v5-c-popover__arrow--Left: var(--pf-v5-c-popover--m-bottom--Left, 50%);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-bottom--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-bottom--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-bottom--Rotate);
}

.pf-v5-c-popover:is(.pf-m-left, .pf-m-left-top, .pf-m-left-bottom) {
  --pf-v5-c-popover__arrow--Top: var(--pf-v5-c-popover--m-left--Top, 50%);
  --pf-v5-c-popover__arrow--Right: var(--pf-v5-c-popover--m-left--Right, 0);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-left--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-left--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-left--Rotate);
}

.pf-v5-c-popover:is(.pf-m-right, .pf-m-right-top, .pf-m-right-bottom) {
  --pf-v5-c-popover__arrow--Top: var(--pf-v5-c-popover--m-right--Top, 50%);
  --pf-v5-c-popover__arrow--Left: var(--pf-v5-c-popover--m-right--Left, 0);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-right--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-right--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-right--Rotate);
}

.pf-v5-c-popover:is(.pf-m-left-top, .pf-m-right-top) {
  --pf-v5-c-popover__arrow--Top: 0;
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-top--TranslateY);
}

.pf-v5-c-popover:is(.pf-m-left-bottom, .pf-m-right-bottom) {
  --pf-v5-c-popover__arrow--Top: auto;
  --pf-v5-c-popover__arrow--Bottom: 0;
}

.pf-v5-c-popover:is(.pf-m-top-left, .pf-m-bottom-left) {
  --pf-v5-c-popover__arrow--Left: 0;
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-left--TranslateX);
}

.pf-v5-c-popover:is(.pf-m-top-right, .pf-m-bottom-right) {
  --pf-v5-c-popover__arrow--Right: 0;
  --pf-v5-c-popover__arrow--Left: auto;
}

.pf-v5-c-popover.pf-m-danger {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-danger__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-danger__title-text--Color);
}

.pf-v5-c-popover.pf-m-warning {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-warning__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-warning__title-text--Color);
}

.pf-v5-c-popover.pf-m-success {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-success__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-success__title-text--Color);
}

.pf-v5-c-popover.pf-m-custom {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-custom__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-custom__title-text--Color);
}

.pf-v5-c-popover.pf-m-info {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-info__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-info__title-text--Color);
}

.pf-v5-c-popover__content {
  background-color: var(--pf-v5-c-popover__content--BackgroundColor);
  padding-block-start: var(--pf-v5-c-popover__content--PaddingTop);
  padding-block-end: var(--pf-v5-c-popover__content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-popover__content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-popover__content--PaddingRight);
  position: relative;
}

.pf-v5-c-popover__close {
  position: absolute;
  inset-block-start: var(--pf-v5-c-popover__close--Top);
  inset-inline-end: var(--pf-v5-c-popover__close--Right);
}

.pf-v5-c-popover__close + * {
  padding-inline-end: var(--pf-v5-c-popover__close--sibling--PaddingRight);
}

.pf-v5-c-popover__arrow {
  top: var(--pf-v5-c-popover__arrow--Top, auto);
  right: var(--pf-v5-c-popover__arrow--Right, auto);
  bottom: var(--pf-v5-c-popover__arrow--Bottom, auto);
  left: var(--pf-v5-c-popover__arrow--Left, auto);
  width: var(--pf-v5-c-popover__arrow--Width);
  height: var(--pf-v5-c-popover__arrow--Height);
  pointer-events: none;
  background-color: var(--pf-v5-c-popover__arrow--BackgroundColor);
  box-shadow: var(--pf-v5-c-popover__arrow--BoxShadow);
  transform: translateX(var(--pf-v5-c-popover__arrow--TranslateX, 0)) translateY(var(--pf-v5-c-popover__arrow--TranslateY, 0)) rotate(var(--pf-v5-c-popover__arrow--Rotate, 0));
  position: absolute;
}

.pf-v5-c-popover__header {
  margin-block-end: var(--pf-v5-c-popover__header--MarginBottom);
}

.pf-v5-c-popover__title {
  flex: none;
  display: flex;
}

.pf-v5-c-popover__title-icon {
  font-size: var(--pf-v5-c-popover__title-icon--FontSize);
  color: var(--pf-v5-c-popover__title-icon--Color);
  margin-inline-end: var(--pf-v5-c-popover__title-icon--MarginRight);
}

.pf-v5-c-popover__title-text {
  font-family: var(--pf-v5-c-popover__title-text--FontFamily);
  font-size: var(--pf-v5-c-popover__title-text--FontSize);
  line-height: var(--pf-v5-c-popover__title-text--LineHeight);
  color: var(--pf-v5-c-popover__title-text--Color, inherit);
}

.pf-v5-c-popover__body {
  word-wrap: break-word;
}

.pf-v5-c-popover__footer {
  margin-block-start: var(--pf-v5-c-popover__footer--MarginTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-popover {
  --pf-v5-c-popover__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-popover__arrow--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-popover--m-custom__title-text--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-popover--m-info__title-text--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-popover--m-success__title-text--Color: var(--pf-v5-global--success-color--100);
}

.pf-v5-c-switch {
  --pf-v5-c-switch--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-switch__label--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-switch--ColumnGap: var(--pf-v5-c-switch__label--PaddingLeft);
  --pf-v5-c-switch__toggle-icon--FontSize: calc(var(--pf-v5-c-switch--FontSize) * .625);
  --pf-v5-c-switch__toggle-icon--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-switch__toggle-icon--Left: calc(var(--pf-v5-c-switch--FontSize) * .4);
  --pf-v5-c-switch__toggle-icon--Offset: .125rem;
  --pf-v5-c-switch__input--disabled__toggle-icon--Color: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-switch--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-switch--Height: auto;
  --pf-v5-c-switch__input--checked__toggle--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-switch__input--checked__toggle--before--TranslateX: calc(100% + var(--pf-v5-c-switch__toggle-icon--Offset));
  --pf-v5-c-switch__input--checked__label--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-switch__input--not-checked__label--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-switch__input--disabled__label--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-switch__input--disabled__toggle--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-switch__input--disabled__toggle--before--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-switch__input--focus__toggle--OutlineWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-switch__input--focus__toggle--OutlineOffset: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-switch__input--focus__toggle--OutlineColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-switch__toggle--Height: calc(var(--pf-v5-c-switch--FontSize) * var(--pf-v5-c-switch--LineHeight));
  --pf-v5-c-switch__toggle--BackgroundColor: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-switch__toggle--BorderRadius: var(--pf-v5-c-switch__toggle--Height);
  --pf-v5-c-switch__toggle--before--Width: calc(var(--pf-v5-c-switch--FontSize)  - var(--pf-v5-c-switch__toggle-icon--Offset));
  --pf-v5-c-switch__toggle--before--Height: var(--pf-v5-c-switch__toggle--before--Width);
  --pf-v5-c-switch__toggle--before--Top: calc((var(--pf-v5-c-switch__toggle--Height)  - var(--pf-v5-c-switch__toggle--before--Height)) / 2);
  --pf-v5-c-switch__toggle--before--Left: var(--pf-v5-c-switch__toggle--before--Top);
  --pf-v5-c-switch__toggle--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-switch__toggle--before--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-switch__toggle--before--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-switch__toggle--before--Transition: transform .25s ease 0s;
  --pf-v5-c-switch__toggle--Width: calc(var(--pf-v5-c-switch__toggle--Height)  + var(--pf-v5-c-switch__toggle-icon--Offset)  + var(--pf-v5-c-switch__toggle--before--Width));
  --pf-v5-c-switch__label--Color: var(--pf-v5-global--Color--dark-100);
  column-gap: var(--pf-v5-c-switch--ColumnGap);
  height: var(--pf-v5-c-switch--Height);
  font-size: var(--pf-v5-c-switch--FontSize);
  line-height: var(--pf-v5-c-switch--LineHeight);
  vertical-align: middle;
  cursor: pointer;
  grid-template-columns: auto;
  grid-auto-columns: 1fr;
  display: inline-grid;
  position: relative;
}

.pf-v5-c-switch.pf-m-reverse .pf-v5-c-switch__label, .pf-v5-c-switch.pf-m-reverse .pf-v5-c-switch__toggle {
  grid-row: 1;
}

.pf-v5-c-switch.pf-m-reverse .pf-v5-c-switch__label {
  grid-column: 1;
}

.pf-v5-c-switch__input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.pf-v5-c-switch__input:focus-visible ~ .pf-v5-c-switch__toggle {
  outline: var(--pf-v5-c-switch__input--focus__toggle--OutlineWidth) solid var(--pf-v5-c-switch__input--focus__toggle--OutlineColor);
  outline-offset: var(--pf-v5-c-switch__input--focus__toggle--OutlineOffset);
}

.pf-v5-c-switch__input:checked ~ .pf-v5-c-switch__label {
  color: var(--pf-v5-c-switch__input--checked__label--Color);
}

.pf-v5-c-switch__input:checked ~ .pf-v5-c-switch__toggle {
  background-color: var(--pf-v5-c-switch__input--checked__toggle--BackgroundColor);
}

.pf-v5-c-switch__input:checked ~ .pf-v5-c-switch__toggle:before {
  transform: translateX(var(--pf-v5-c-switch__input--checked__toggle--before--TranslateX));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-switch__input:checked ~ .pf-v5-c-switch__toggle:before {
  transform: translateX(calc(var(--pf-v5-c-switch__input--checked__toggle--before--TranslateX) * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-switch__input:checked ~ .pf-m-off {
  display: none;
}

.pf-v5-c-switch__input:not(:checked) ~ .pf-v5-c-switch__label {
  color: var(--pf-v5-c-switch__input--not-checked__label--Color);
}

.pf-v5-c-switch__input:not(:checked) ~ .pf-v5-c-switch__toggle .pf-v5-c-switch__toggle-icon, .pf-v5-c-switch__input:not(:checked) ~ .pf-m-on {
  display: none;
}

.pf-v5-c-switch__input:disabled {
  cursor: not-allowed;
}

.pf-v5-c-switch__input:disabled ~ .pf-v5-c-switch__label {
  color: var(--pf-v5-c-switch__input--disabled__label--Color);
  cursor: not-allowed;
}

.pf-v5-c-switch__input:disabled ~ .pf-v5-c-switch__toggle {
  --pf-v5-c-switch__toggle-icon--Color: var(--pf-v5-c-switch__input--disabled__toggle-icon--Color);
  cursor: not-allowed;
  background-color: var(--pf-v5-c-switch__input--disabled__toggle--BackgroundColor);
}

.pf-v5-c-switch__input:disabled ~ .pf-v5-c-switch__toggle:before {
  background-color: var(--pf-v5-c-switch__input--disabled__toggle--before--BackgroundColor);
}

.pf-v5-c-switch__toggle {
  width: var(--pf-v5-c-switch__toggle--Width);
  height: var(--pf-v5-c-switch__toggle--Height);
  background-color: var(--pf-v5-c-switch__toggle--BackgroundColor);
  border-radius: var(--pf-v5-c-switch__toggle--BorderRadius);
  display: inline-block;
  position: relative;
}

.pf-v5-c-switch__toggle:before {
  width: var(--pf-v5-c-switch__toggle--before--Width);
  height: var(--pf-v5-c-switch__toggle--before--Height);
  content: "";
  background-color: var(--pf-v5-c-switch__toggle--before--BackgroundColor);
  border-radius: var(--pf-v5-c-switch__toggle--before--BorderRadius);
  box-shadow: var(--pf-v5-c-switch__toggle--before--BoxShadow);
  transition: var(--pf-v5-c-switch__toggle--before--Transition);
  display: block;
  position: absolute;
  inset-block-start: var(--pf-v5-c-switch__toggle--before--Top);
  inset-inline-start: var(--pf-v5-c-switch__toggle--before--Left);
}

.pf-v5-c-switch__toggle-icon {
  font-size: var(--pf-v5-c-switch__toggle-icon--FontSize);
  color: var(--pf-v5-c-switch__toggle-icon--Color);
  align-items: center;
  display: flex;
  position: absolute;
  inset-block: 0;
  inset-inline-start: var(--pf-v5-c-switch__toggle-icon--Left);
}

.pf-v5-c-switch__label {
  color: var(--pf-v5-c-switch__label--Color);
  vertical-align: top;
  grid-column: 2;
  display: inline-block;
}

:where(.pf-v5-theme-dark) .pf-v5-c-switch {
  --pf-v5-c-switch__toggle-icon--Color: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-switch__input--not-checked__label--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-switch__input--disabled__toggle--before--BackgroundColor: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-switch__toggle--before--BoxShadow: none;
  --pf-v5-c-switch__input--disabled__toggle-icon--Color: var(--pf-v5-global--disabled-color--100);
}

.pf-v5-c-title {
  --pf-v5-c-title--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-title--m-4xl--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-title--m-4xl--FontSize: var(--pf-v5-global--FontSize--4xl);
  --pf-v5-c-title--m-4xl--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-title--m-3xl--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-title--m-3xl--FontSize: var(--pf-v5-global--FontSize--3xl);
  --pf-v5-c-title--m-3xl--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-title--m-2xl--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-title--m-2xl--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-title--m-2xl--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-title--m-xl--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-title--m-xl--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-title--m-xl--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-title--m-lg--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-title--m-lg--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-title--m-lg--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-title--m-md--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-title--m-md--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-title--m-md--FontWeight: var(--pf-v5-global--FontWeight--normal);
  font-family: var(--pf-v5-c-title--FontFamily);
  word-break: break-word;
}

.pf-v5-c-title.pf-m-4xl {
  font-size: var(--pf-v5-c-title--m-4xl--FontSize);
  font-weight: var(--pf-v5-c-title--m-4xl--FontWeight);
  line-height: var(--pf-v5-c-title--m-4xl--LineHeight);
}

.pf-v5-c-title.pf-m-3xl {
  font-size: var(--pf-v5-c-title--m-3xl--FontSize);
  font-weight: var(--pf-v5-c-title--m-3xl--FontWeight);
  line-height: var(--pf-v5-c-title--m-3xl--LineHeight);
}

.pf-v5-c-title.pf-m-2xl {
  font-size: var(--pf-v5-c-title--m-2xl--FontSize);
  font-weight: var(--pf-v5-c-title--m-2xl--FontWeight);
  line-height: var(--pf-v5-c-title--m-2xl--LineHeight);
}

.pf-v5-c-title.pf-m-xl {
  font-size: var(--pf-v5-c-title--m-xl--FontSize);
  font-weight: var(--pf-v5-c-title--m-xl--FontWeight);
  line-height: var(--pf-v5-c-title--m-xl--LineHeight);
}

.pf-v5-c-title.pf-m-lg {
  font-size: var(--pf-v5-c-title--m-lg--FontSize);
  font-weight: var(--pf-v5-c-title--m-lg--FontWeight);
  line-height: var(--pf-v5-c-title--m-lg--LineHeight);
}

.pf-v5-c-title.pf-m-md {
  font-size: var(--pf-v5-c-title--m-md--FontSize);
  font-weight: var(--pf-v5-c-title--m-md--FontWeight);
  line-height: var(--pf-v5-c-title--m-md--LineHeight);
}

.pf-v5-c-table {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > * {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-hidden {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}

@media screen and (width >= 576px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-hidden-on-sm {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-visible-on-sm {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 768px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-hidden-on-md {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-visible-on-md {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 992px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-hidden-on-lg {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-visible-on-lg {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 1200px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-hidden-on-xl {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-visible-on-xl {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (width >= 1450px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-hidden-on-2xl {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-visible-on-2xl {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

.pf-v5-c-table {
  --pf-v5-c-table--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table--border-width--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table__caption--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-table__caption--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-table__caption--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__caption--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__caption--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__caption--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__caption--xl--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__caption--xl--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__thead--cell--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-table__thead--cell--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tr--BoxShadow--top--base: 0 -.1875rem .25rem -.125rem #03030314;
  --pf-v5-c-table--cell--Padding--base: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--cell--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-table--cell--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-table--cell--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--cell--Padding--base);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--cell--Padding--base);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--cell--Padding--base);
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--cell--Padding--base);
  --pf-v5-c-table--cell--first-last-child--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--cell--first-last-child--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--cell--first-last-child--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--cell--first-last-child--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tr--m-first-cell-offset-reset--cell--PaddingLeft: var(--pf-v5-c-table--cell--Padding--base);
  --pf-v5-c-table--cell--MinWidth: 0;
  --pf-v5-c-table--cell--MaxWidth: none;
  --pf-v5-c-table--cell--Width: auto;
  --pf-v5-c-table--cell--Overflow: visible;
  --pf-v5-c-table--cell--TextOverflow: clip;
  --pf-v5-c-table--cell--WhiteSpace: normal;
  --pf-v5-c-table--cell--WordBreak: normal;
  --pf-v5-c-table--cell--m-border-right--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table--cell--m-border-right--before--BorderRightColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table--cell--m-border-left--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table--cell--m-border-left--before--BorderLeftColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table--cell--m-help--MinWidth: 11ch;
  --pf-v5-c-table--m-truncate--cell--MaxWidth: 1px;
  --pf-v5-c-table--m-truncate--cell--MinWidth: calc(5ch + var(--pf-v5-c-table--cell--PaddingRight)  + var(--pf-v5-c-table--cell--PaddingLeft));
  --pf-v5-c-table--cell--hidden-visible--Display: table-cell;
  --pf-v5-c-table__toggle--c-button--MarginTop: calc(.375rem * -1);
  --pf-v5-c-table__toggle--c-button--MarginBottom: calc(.375rem * -1);
  --pf-v5-c-table__toggle--c-button__toggle-icon--Rotate: 270deg;
  --pf-v5-c-table__toggle--c-button__toggle-icon--Transition: .2s ease-in 0s;
  --pf-v5-c-table__toggle--c-button--m-expanded__toggle-icon--Rotate: 360deg;
  --pf-v5-c-table__button--BackgroundColor: transparent;
  --pf-v5-c-table__button--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__button--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__button--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__button--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__button--OutlineOffset: calc(var(--pf-v5-global--BorderWidth--lg) * -1);
  --pf-v5-c-table--m-compact__toggle--PaddingTop: 0;
  --pf-v5-c-table--m-compact__toggle--PaddingBottom: 0;
  --pf-v5-c-table__check--input--MarginTop: .1875rem;
  --pf-v5-c-table__thead__check--input--TranslateY: var(--pf-v5-c-table__check--input--MarginTop);
  --pf-v5-c-table__check--input--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-table--cell--m-favorite--Color: var(--pf-v5-global--Color--light-300);
  --pf-v5-c-table__favorite--c-button--Color: var(--pf-v5-global--Color--light-300);
  --pf-v5-c-table__favorite--c-button--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-table__favorite--c-button--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__favorite--c-button--MarginRight: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-table__favorite--c-button--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__favorite--c-button--MarginLeft: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-table__favorite--m-favorited--c-button--Color: var(--pf-v5-global--palette--gold-400);
  --pf-v5-c-table__sort--m-favorite__button__text--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-table__sort--m-favorite__button--hover__text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__sort--m-favorite__button--focus__text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__sort--m-favorite__button--active__text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__draggable--c-button--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__draggable--c-button--MarginRight: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-table__draggable--c-button--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__draggable--c-button--MarginLeft: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-table__tr--m-ghost-row--Opacity: .4;
  --pf-v5-c-table__tr--m-ghost-row--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__action--PaddingTop: 0;
  --pf-v5-c-table__action--PaddingRight: 0;
  --pf-v5-c-table__action--PaddingBottom: 0;
  --pf-v5-c-table__action--PaddingLeft: 0;
  --pf-v5-c-table__inline-edit-action--PaddingTop: 0;
  --pf-v5-c-table__inline-edit-action--PaddingRight: 0;
  --pf-v5-c-table__inline-edit-action--PaddingBottom: 0;
  --pf-v5-c-table__inline-edit-action--PaddingLeft: 0;
  --pf-v5-c-table__expandable-row--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-table__expandable-row--MaxHeight: 28.125rem;
  --pf-v5-c-table__expandable-row-content--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-table__expandable-row-content--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__expandable-row-content--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__expandable-row--after--Top: calc(var(--pf-v5-c-table--border-width--base) * -1);
  --pf-v5-c-table__expandable-row--after--Bottom: calc(var(--pf-v5-c-table--border-width--base) * -1);
  --pf-v5-c-table__expandable-row--after--border-width--base: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
  --pf-v5-c-table__expandable-row--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__icon-inline--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__sort--MinWidth: calc(6ch + var(--pf-v5-c-table--cell--PaddingRight)  + var(--pf-v5-c-table--cell--PaddingLeft)  + var(--pf-v5-c-table__sort-indicator--MarginLeft));
  --pf-v5-c-table__sort__button--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-table__sort__button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__sort__button--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-table__sort__button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__sort__button--MarginTop: calc(var(--pf-v5-c-table__sort__button--PaddingTop) * -1);
  --pf-v5-c-table__sort__button--MarginBottom: calc(var(--pf-v5-c-table__sort__button--PaddingBottom) * -1);
  --pf-v5-c-table__sort__button--MarginLeft: calc(var(--pf-v5-c-table__sort__button--PaddingLeft) * -1);
  --pf-v5-c-table__sort__button--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__sort--m-selected__button--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__sort--m-help--MinWidth: 15ch;
  --pf-v5-c-table__sort__button__text--Color: currentcolor;
  --pf-v5-c-table__sort__button--hover__text--Color: currentcolor;
  --pf-v5-c-table__sort__button--focus__text--Color: currentcolor;
  --pf-v5-c-table__sort__button--active__text--Color: currentcolor;
  --pf-v5-c-table__sort-indicator--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-table__sort-indicator--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__sort--m-selected__sort-indicator--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__sort__button--hover__sort-indicator--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__sort__button--active__sort-indicator--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__sort__button--focus__sort-indicator--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__th--m-help--MinWidth: 11ch;
  --pf-v5-c-table__column-help--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table__column-help--TranslateY: .125rem;
  --pf-v5-c-table__column-help--c-button--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__column-help--c-button--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__column-help--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__column-help--c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__compound-expansion-toggle__button--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__compound-expansion-toggle__button--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-table__compound-expansion-toggle__button--focus--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-table__compound-expansion-toggle__button--active--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--before--Bottom: calc(var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
  --pf-v5-c-table__compound-expansion-toggle__button--before--Left: calc(var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
  --pf-v5-c-table__compound-expansion-toggle__button--after--border-width--base: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-table__compound-expansion-toggle__button--after--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-table__compound-expansion-toggle__button--after--BorderTopWidth: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--after--Top: calc(var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
  --pf-v5-c-table__compound-expansion-toggle__button--after--Left: calc(var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
  --pf-v5-c-table--m-compact__th--PaddingTop: calc(var(--pf-v5-global--spacer--sm)  + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table--m-compact__th--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact--cell--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact--cell--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact--cell--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact--cell--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact--cell--first-last-child--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-compact--cell--first-last-child--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-compact--cell--first-last-child--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--m-compact--cell--first-last-child--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--m-compact--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-table--m-compact__expandable-row-content--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--m-compact__expandable-row-content--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--m-compact__expandable-row-content--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--m-compact__expandable-row-content--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--nested--first-last-child--PaddingRight: var(--pf-v5-c-table--m-compact--cell--first-last-child--PaddingLeft);
  --pf-v5-c-table--nested--first-last-child--PaddingLeft: var(--pf-v5-c-table--m-compact--cell--first-last-child--PaddingRight);
  --pf-v5-c-table__expandable-row--m-expanded--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table__tr--m-clickable--BoxShadow--top: var(--pf-v5-c-table__tr--BoxShadow--top--base);
  --pf-v5-c-table__tr--m-clickable--BackgroundColor: transparent;
  --pf-v5-c-table__tr--m-clickable--BoxShadow: none;
  --pf-v5-c-table__tr--m-clickable--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table__tr--m-clickable--hover--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-clickable--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tr--m-clickable--focus--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-clickable--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tr--m-clickable--active--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-clickable--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tr--m-clickable--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom) inset, var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-selected--BoxShadow--top: var(--pf-v5-c-table__tr--BoxShadow--top--base);
  --pf-v5-c-table__tr--m-selected--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tr--m-selected--BoxShadow: var(--pf-v5-c-table__tr--m-selected--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-selected--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table__tr--m-selected--after--BorderLeftWidth: calc(2 * var(--pf-v5-c-table__expandable-row--after--border-width--base));
  --pf-v5-c-table__tr--m-selected--after--BorderLeftColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__tr--m-selected--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-selected--hover__tr--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom) inset, var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-selected__tr--m-selected--hover--BoxShadow: var(--pf-v5-c-table__tr--m-selected--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-clickable--BoxShadow--top: var(--pf-v5-c-table__tr--BoxShadow--top--base);
  --pf-v5-c-table__tbody--m-clickable--BoxShadow: none;
  --pf-v5-c-table__tbody--m-clickable--BackgroundColor: transparent;
  --pf-v5-c-table__tbody--m-clickable--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table__tbody--m-clickable--hover--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-clickable--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tbody--m-clickable--focus--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-clickable--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tbody--m-clickable--active--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-clickable--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tbody--m-clickable--m-expanded--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-table__tbody--m-clickable--m-selected--hover__tr--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom) inset, var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-selected--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tbody--m-selected--BoxShadow--top: var(--pf-v5-c-table__tr--BoxShadow--top--base);
  --pf-v5-c-table__tbody--m-selected--BoxShadow: var(--pf-v5-c-table__tbody--m-selected--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-selected--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table__tbody--m-selected--after--BorderLeftWidth: calc(2 * var(--pf-v5-c-table__expandable-row--after--border-width--base));
  --pf-v5-c-table__tbody--m-selected--after--BorderLeftColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__tbody--m-selected--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-selected--hover__tbody--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom) inset, var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-selected__tbody--m-selected--hover--BoxShadow: var(--pf-v5-c-table__tr--m-selected--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__thead--m-nested-column-header--button--OutlineOffset: -.1875rem;
  --pf-v5-c-table__thead--m-nested-column-header__tr--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table__thead--m-nested-column-header__tr--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table__subhead--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-table--m-striped__tr--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-200);
  --pf-v5-c-table--m-sticky-header--cell--ZIndex: var(--pf-v5-global--ZIndex--xs);
  color: var(--pf-v5-global--Color--100);
  background-color: var(--pf-v5-c-table--BackgroundColor);
  width: 100%;
}

@media screen and (width <= 1200px) {
  .pf-v5-c-table {
    --pf-v5-c-table__caption--PaddingRight: var(--pf-v5-c-table__caption--xl--PaddingRight);
    --pf-v5-c-table__caption--PaddingLeft: var(--pf-v5-c-table__caption--xl--PaddingLeft);
  }
}

@media screen and (width >= 1200px) {
  .pf-v5-c-table {
    --pf-v5-c-table--cell--first-last-child--PaddingRight: var(--pf-v5-c-table--cell--first-last-child--xl--PaddingRight);
    --pf-v5-c-table--cell--first-last-child--PaddingLeft: var(--pf-v5-c-table--cell--first-last-child--xl--PaddingLeft);
    --pf-v5-c-table--m-compact--cell--first-last-child--PaddingLeft: var(--pf-v5-c-table--m-compact--cell--first-last-child--xl--PaddingLeft);
    --pf-v5-c-table--m-compact--cell--first-last-child--PaddingRight: var(--pf-v5-c-table--m-compact--cell--first-last-child--xl--PaddingRight);
  }
}

.pf-v5-c-table.pf-m-fixed {
  table-layout: fixed;
}

.pf-v5-c-table.pf-m-sticky-header {
  position: relative;
}

.pf-v5-c-table.pf-m-sticky-header thead:where(.pf-v5-c-table__thead) tr:where(.pf-v5-c-table__tr) {
  border-block-end: 0;
}

.pf-v5-c-table.pf-m-sticky-header thead:where(.pf-v5-c-table__thead) tr:where(.pf-v5-c-table__tr) > * {
  z-index: var(--pf-v5-c-table--m-sticky-header--cell--ZIndex);
}

.pf-v5-c-table.pf-m-sticky-header > thead:where(.pf-v5-c-table__thead):not(.pf-m-nested-column-header) > tr:where(.pf-v5-c-table__tr) > * {
  background: var(--pf-v5-c-table--BackgroundColor);
  position: sticky;
  inset-block-start: 0;
}

.pf-v5-c-table.pf-m-sticky-header > thead:where(.pf-v5-c-table__thead):not(.pf-m-nested-column-header) > tr:where(.pf-v5-c-table__tr) > :after {
  content: "";
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  position: absolute;
  inset-block-end: 0;
  inset-inline: 0;
}

.pf-v5-c-table.pf-m-sticky-header > .pf-m-nested-column-header {
  z-index: var(--pf-v5-global--ZIndex--xs);
  background: var(--pf-v5-c-table--BackgroundColor);
  position: sticky;
  inset-block-start: 0;
}

.pf-v5-c-table.pf-m-sticky-header > .pf-m-nested-column-header > .pf-m-border-row {
  height: var(--pf-v5-c-table--border-width--base);
  background-color: var(--pf-v5-c-table--BorderColor);
}

.pf-v5-c-table.pf-m-sticky-header > .pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:nth-last-child(2)) th:where(.pf-v5-c-table__th):not([rowspan]), .pf-v5-c-table.pf-m-sticky-header > .pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:nth-last-child(2)) td:where(.pf-v5-c-table__td):not([rowspan]) {
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table__thead--m-nested-column-header__tr--PaddingBottom);
}

.pf-v5-c-table:not(.pf-m-sticky-header) > .pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:last-child) {
  border-block-end: 0;
}

.pf-v5-c-table:not(.pf-m-sticky-header) > .pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:last-child) th:where(.pf-v5-c-table__th):not([rowspan]), .pf-v5-c-table:not(.pf-m-sticky-header) > .pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:last-child) td:where(.pf-v5-c-table__td):not([rowspan]) {
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table__thead--m-nested-column-header__tr--PaddingBottom);
}

.pf-v5-c-table.pf-m-striped:not(.pf-m-expandable) > tbody:where(.pf-v5-c-table__tbody) > tr:where(.pf-v5-c-table__tr):nth-child(odd), .pf-v5-c-table.pf-m-striped.pf-m-expandable > tbody:where(.pf-v5-c-table__tbody):nth-of-type(odd) > tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row), .pf-v5-c-table > .pf-m-striped > tr:nth-child(odd), .pf-v5-c-table > .pf-m-striped-even > tr:nth-child(2n), .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-striped {
  --pf-v5-c-table__sticky-column--BackgroundColor: var(--pf-v5-c-table--m-striped__tr--BackgroundColor);
  background: var(--pf-v5-c-table--m-striped__tr--BackgroundColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row).pf-m-ghost-row {
  background-color: var(--pf-v5-c-table__tr--m-ghost-row--BackgroundColor);
  opacity: var(--pf-v5-c-table__tr--m-ghost-row--Opacity);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > * {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-table--cell--hidden-visible--Display);
  width: var(--pf-v5-c-table--cell--Width);
  min-width: var(--pf-v5-c-table--cell--MinWidth);
  max-width: var(--pf-v5-c-table--cell--MaxWidth);
  overflow: var(--pf-v5-c-table--cell--Overflow);
  font-size: var(--pf-v5-c-table--cell--FontSize);
  font-weight: var(--pf-v5-c-table--cell--FontWeight);
  color: var(--pf-v5-c-table--cell--Color);
  text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
  word-break: var(--pf-v5-c-table--cell--WordBreak);
  white-space: var(--pf-v5-c-table--cell--WhiteSpace);
  padding-block-start: var(--pf-v5-c-table--cell--PaddingTop);
  padding-block-end: var(--pf-v5-c-table--cell--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table--cell--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-table--cell--PaddingRight);
  position: relative;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :first-child {
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--cell--first-last-child--PaddingLeft);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :last-child {
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--cell--first-last-child--PaddingRight);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-center {
  text-align: center;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :empty {
  width: auto;
  min-width: 0;
  padding: 0;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-help {
  --pf-v5-c-table--cell--MinWidth: var(--pf-v5-c-table--cell--m-help--MinWidth);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-favorite {
  --pf-v5-c-table__button--Color: var(--pf-v5-c-table--cell--m-favorite--Color);
  --pf-v5-c-table__sort--MinWidth: fit-content;
  --pf-v5-c-table--cell--MaxWidth: fit-content;
  --pf-v5-c-table--cell--Overflow: visible;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-border-right:before, .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-border-left:before {
  pointer-events: none;
  content: "";
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-border-right:before {
  border-inline-end: var(--pf-v5-c-table--cell--m-border-right--before--BorderRightWidth) solid var(--pf-v5-c-table--cell--m-border-right--before--BorderRightColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > .pf-m-border-left:before {
  border-inline-start: var(--pf-v5-c-table--cell--m-border-left--before--BorderLeftWidth) solid var(--pf-v5-c-table--cell--m-border-left--before--BorderLeftColor);
}

.pf-v5-c-table caption:where(.pf-v5-c-table__caption) {
  font-size: var(--pf-v5-c-table__caption--FontSize);
  color: var(--pf-v5-c-table__caption--Color);
  text-align: start;
  background-color: var(--pf-v5-c-table--BackgroundColor);
  padding-block-start: var(--pf-v5-c-table__caption--PaddingTop);
  padding-block-end: var(--pf-v5-c-table__caption--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table__caption--PaddingLeft);
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
  --pf-v5-c-table--cell--FontSize: var(--pf-v5-c-table__thead--cell--FontSize);
  --pf-v5-c-table--cell--FontWeight: var(--pf-v5-c-table__thead--cell--FontWeight);
  vertical-align: bottom;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header button:where(.pf-v5-c-button) {
  outline-offset: var(--pf-v5-c-table__thead--m-nested-column-header--button--OutlineOffset);
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header th:where(.pf-v5-c-table__th), .pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header td:where(.pf-v5-c-table__td), .pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header input:where(.pf-v5-c-check__input) {
  vertical-align: middle;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header .pf-v5-c-table__check label {
  align-items: center;
  display: flex;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:first-child) th:where(.pf-v5-c-table__th):not([rowspan]), .pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:first-child) td:where(.pf-v5-c-table__td):not([rowspan]) {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table__thead--m-nested-column-header__tr--PaddingTop);
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead) .pf-v5-c-table__subhead {
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__subhead--Color);
  color: var(--pf-v5-c-table__subhead--Color);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table__tbody--cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table__tbody--cell--PaddingBottom);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) > tr:where(.pf-v5-c-table__tr) > * {
  overflow-wrap: break-word;
  vertical-align: baseline;
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) > tr:where(.pf-v5-c-table__tr) > :first-child:after {
  content: "";
  border-inline-start: var(--pf-v5-c-table__expandable-row--after--BorderLeftWidth) solid var(--pf-v5-c-table__expandable-row--after--BorderColor);
  background-color: #0000;
  position: absolute;
  inset-block-start: var(--pf-v5-c-table__expandable-row--after--Top);
  inset-block-end: var(--pf-v5-c-table__expandable-row--after--Bottom);
  inset-inline-start: 0;
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) .pf-v5-c-table__check input:where(.pf-v5-c-check__input) {
  vertical-align: top;
  margin-block-start: var(--pf-v5-c-table__check--input--MarginTop);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded > :first-child:not(.pf-v5-c-table__control-row) {
  border-block-end-width: 0;
}

.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle, .pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle:first-child, .pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle:last-child {
  padding: 0;
}

.pf-v5-c-table .pf-v5-c-table__sort {
  min-width: var(--pf-v5-c-table__sort--MinWidth);
}

.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td).pf-m-help {
  min-width: var(--pf-v5-c-table__th--m-help--MinWidth);
}

.pf-v5-c-table > thead, .pf-v5-c-table :where([class*="pf-v5-c-table"]).pf-m-truncate, :where(.pf-v5-c-table) > :is(thead, tbody).pf-m-truncate {
  --pf-v5-c-table--cell--MinWidth: var(--pf-v5-c-table--m-truncate--cell--MinWidth);
  --pf-v5-c-table--cell--MaxWidth: var(--pf-v5-c-table--m-truncate--cell--MaxWidth);
  --pf-v5-c-table--cell--Overflow: hidden;
  --pf-v5-c-table--cell--TextOverflow: ellipsis;
  --pf-v5-c-table--cell--WhiteSpace: nowrap;
}

.pf-v5-c-table :where([class*="pf-v5-c-table"]).pf-m-wrap, :where(.pf-v5-c-table) > :is(thead, tbody).pf-m-wrap {
  --pf-v5-c-table--cell--MinWidth: 0;
  --pf-v5-c-table--cell--MaxWidth: none;
  --pf-v5-c-table--cell--Overflow: visible;
  --pf-v5-c-table--cell--TextOverflow: clip;
  --pf-v5-c-table--cell--WhiteSpace: normal;
}

.pf-v5-c-table :where([class*="pf-v5-c-table"]).pf-m-nowrap, :where(.pf-v5-c-table) > :is(thead, tbody).pf-m-nowrap {
  --pf-v5-c-table--cell--MinWidth: 0;
  --pf-v5-c-table--cell--MaxWidth: none;
  --pf-v5-c-table--cell--Overflow: visible;
  --pf-v5-c-table--cell--TextOverflow: clip;
  --pf-v5-c-table--cell--WhiteSpace: nowrap;
}

.pf-v5-c-table :where([class*="pf-v5-c-table"]) .pf-v5-c-table__icon, .pf-v5-c-table :where([class*="pf-v5-c-table"]).pf-m-fit-content, :where(.pf-v5-c-table) > :is(thead, tbody) .pf-v5-c-table__icon, :where(.pf-v5-c-table) > :is(thead, tbody).pf-m-fit-content {
  --pf-v5-c-table--cell--MinWidth: fit-content;
  --pf-v5-c-table--cell--MaxWidth: none;
  --pf-v5-c-table--cell--Width: 1%;
  --pf-v5-c-table--cell--Overflow: visible;
  --pf-v5-c-table--cell--TextOverflow: clip;
  --pf-v5-c-table--cell--WhiteSpace: nowrap;
}

.pf-v5-c-table :where([class*="pf-v5-c-table"]).pf-m-break-word, :where(.pf-v5-c-table) > :is(thead, tbody).pf-m-break-word {
  --pf-v5-c-table--cell--WordBreak: break-word;
  --pf-v5-c-table--cell--WhiteSpace: normal;
}

.pf-v5-c-table.pf-m-no-border-rows > tbody:where(.pf-v5-c-table__tbody) > tr:where(.pf-v5-c-table__tr) {
  border-block-end: 0;
}

.pf-v5-c-table.pf-m-no-border-rows > tbody:where(.pf-v5-c-table__tbody) > tr:where(.pf-v5-c-table__tr) > :first-child:after {
  border-inline-start: 0;
}

.pf-v5-c-table.pf-m-no-border-rows > tbody:where(.pf-v5-c-table__tbody):not(.pf-m-expanded) .pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:before {
  display: none;
}

.pf-v5-c-table.pf-m-no-border-rows > tbody:where(.pf-v5-c-table__tbody).pf-m-expanded > .pf-v5-c-table__control-row {
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
}

.pf-v5-c-table.pf-m-no-border-rows > tbody:where(.pf-v5-c-table__tbody) .pf-v5-c-table__control-row > .pf-v5-c-table__compound-expansion-toggle:first-child > :before {
  border-inline-start-width: 0;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable {
  cursor: pointer;
  background-color: var(--pf-v5-c-table__tr--m-clickable--BackgroundColor);
  outline-offset: var(--pf-v5-c-table__tr--m-clickable--OutlineOffset);
  box-shadow: var(--pf-v5-c-table__tr--m-clickable--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:hover:not(.pf-m-selected) + tr:where(.pf-v5-c-table__tr).pf-m-selected, .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:focus:not(.pf-m-selected) + tr:where(.pf-v5-c-table__tr).pf-m-selected, .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:active:not(.pf-m-selected) + tr:where(.pf-v5-c-table__tr).pf-m-selected {
  box-shadow: var(--pf-v5-c-table__tr--m-clickable--m-selected--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:hover {
  --pf-v5-c-table__tr--m-clickable--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--hover--BoxShadow);
  --pf-v5-c-table__tr--m-clickable--BackgroundColor: var(--pf-v5-c-table__tr--m-clickable--hover--BackgroundColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:focus {
  --pf-v5-c-table__tr--m-clickable--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--focus--BoxShadow);
  --pf-v5-c-table__tr--m-clickable--BackgroundColor: var(--pf-v5-c-table__tr--m-clickable--focus--BackgroundColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:active {
  --pf-v5-c-table__tr--m-clickable--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--active--BoxShadow);
  --pf-v5-c-table__tr--m-clickable--BackgroundColor: var(--pf-v5-c-table__tr--m-clickable--active--BackgroundColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
  --pf-v5-c-table__expandable-row--after--BorderLeftWidth: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
  --pf-v5-c-table__expandable-row--after--BorderColor: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
  background-color: var(--pf-v5-c-table__tr--m-selected--BackgroundColor);
  outline-offset: var(--pf-v5-c-table__tr--m-selected--OutlineOffset);
  box-shadow: var(--pf-v5-c-table__tr--m-selected--BoxShadow);
  position: relative;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected + tr:where(.pf-v5-c-table__tr).pf-m-selected {
  box-shadow: var(--pf-v5-c-table__tr--m-selected--m-selected--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected + tr:where(.pf-v5-c-table__tr).pf-m-selected:hover {
  box-shadow: var(--pf-v5-c-table__tr--m-selected__tr--m-selected--hover--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected:hover + tr:where(.pf-v5-c-table__tr).pf-m-selected {
  box-shadow: var(--pf-v5-c-table__tr--m-selected--hover__tr--m-selected--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-first-cell-offset-reset {
  --pf-v5-c-table--cell--first-last-child--PaddingLeft: var(--pf-v5-c-table__tr--m-first-cell-offset-reset--cell--PaddingLeft);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable {
  cursor: pointer;
  background-color: var(--pf-v5-c-table__tbody--m-clickable--BackgroundColor);
  outline-offset: var(--pf-v5-c-table__tbody--m-clickable--OutlineOffset);
  box-shadow: var(--pf-v5-c-table__tbody--m-clickable--BoxShadow);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable.pf-m-expanded:not(.pf-m-selected) {
  --pf-v5-c-table__expandable-row--after--BorderColor: var(--pf-v5-c-table__tbody--m-clickable--m-expanded--BorderColor);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:hover:not(.pf-m-selected) + tbody:where(.pf-v5-c-table__tbody).pf-m-selected, .pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:focus:not(.pf-m-selected) + tbody:where(.pf-v5-c-table__tbody).pf-m-selected, .pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:active:not(.pf-m-selected) + tbody:where(.pf-v5-c-table__tbody).pf-m-selected {
  box-shadow: var(--pf-v5-c-table__tbody--m-clickable--m-selected--hover__tr--BoxShadow);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:hover {
  --pf-v5-c-table__tbody--m-clickable--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--hover--BoxShadow);
  --pf-v5-c-table__tbody--m-clickable--BackgroundColor: var(--pf-v5-c-table__tbody--m-clickable--hover--BackgroundColor);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:focus {
  --pf-v5-c-table__tbody--m-clickable--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--focus--BoxShadow);
  --pf-v5-c-table__tbody--m-clickable--BackgroundColor: var(--pf-v5-c-table__tbody--m-clickable--focus--BackgroundColor);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:active {
  --pf-v5-c-table__tbody--m-clickable--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--active--BoxShadow);
  --pf-v5-c-table__tbody--m-clickable--BackgroundColor: var(--pf-v5-c-table__tbody--m-clickable--active--BackgroundColor);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-selected {
  --pf-v5-c-table__expandable-row--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after--BorderLeftWidth);
  --pf-v5-c-table__expandable-row--after--BorderColor: var(--pf-v5-c-table__tbody--m-selected--after--BorderLeftColor);
  background-color: var(--pf-v5-c-table__tbody--m-selected--BackgroundColor);
  outline-offset: var(--pf-v5-c-table__tbody--m-selected--OutlineOffset);
  box-shadow: var(--pf-v5-c-table__tbody--m-selected--BoxShadow);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-selected + tbody:where(.pf-v5-c-table__tbody).pf-m-selected {
  --pf-v5-c-table__tbody--m-selected--BoxShadow: var(--pf-v5-c-table__tbody--m-selected--m-selected--BoxShadow);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-selected + tbody:where(.pf-v5-c-table__tbody).pf-m-selected:hover {
  box-shadow: var(--pf-v5-c-table__tbody--m-selected__tbody--m-selected--hover--BoxShadow);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-selected:hover + tbody:where(.pf-v5-c-table__tbody).pf-m-selected {
  box-shadow: var(--pf-v5-c-table__tbody--m-selected--hover__tbody--m-selected--BoxShadow);
}

.pf-v5-c-table.pf-m-drag-over {
  overflow-anchor: none;
}

.pf-v5-c-table__text {
  --pf-v5-c-table--cell--MaxWidth: 100%;
  width: var(--pf-v5-c-table--cell--Width);
  min-width: var(--pf-v5-c-table--cell--MinWidth);
  max-width: var(--pf-v5-c-table--cell--MaxWidth);
  overflow: var(--pf-v5-c-table--cell--Overflow);
  text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
  word-break: var(--pf-v5-c-table--cell--WordBreak);
  white-space: var(--pf-v5-c-table--cell--WhiteSpace);
  display: block;
  position: relative;
}

.pf-v5-c-table__text.pf-m-truncate {
  --pf-v5-c-table--cell--MinWidth: 100%;
}

.pf-v5-c-table__text.pf-m-truncate > * {
  overflow: var(--pf-v5-c-table--cell--Overflow);
  text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
  white-space: var(--pf-v5-c-table--cell--WhiteSpace);
}

.pf-v5-c-table__button {
  font-size: inherit;
  font-weight: inherit;
  color: var(--pf-v5-c-table__button--Color);
  text-align: start;
  white-space: inherit;
  -webkit-user-select: text;
  user-select: text;
  background-color: var(--pf-v5-c-table__button--BackgroundColor);
  border: 0;
  width: 100%;
  padding-block-start: var(--pf-v5-c-table--cell--PaddingTop);
  padding-block-end: var(--pf-v5-c-table--cell--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table--cell--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-table--cell--PaddingRight);
  position: static;
}

.pf-v5-c-table__button:before {
  cursor: pointer;
  content: "";
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
}

.pf-v5-c-table__button:hover {
  color: var(--pf-v5-c-table__button--hover--Color);
}

.pf-v5-c-table__button:focus {
  color: var(--pf-v5-c-table__button--focus--Color);
}

.pf-v5-c-table__button:active {
  color: var(--pf-v5-c-table__button--active--Color);
}

.pf-v5-c-table__sort .pf-v5-c-table__text, .pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__text {
  overflow: var(--pf-v5-c-table--cell--Overflow);
  text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
  white-space: var(--pf-v5-c-table--cell--WhiteSpace);
  width: auto;
  display: block;
}

.pf-v5-c-table__sort .pf-v5-c-table__text {
  --pf-v5-c-table--cell--MinWidth: 0;
}

.pf-v5-c-table__button-content, .pf-v5-c-table__column-help {
  grid-template-columns: auto max-content;
  justify-content: start;
  align-items: end;
  display: grid;
}

.pf-v5-c-table__button-content .pf-v5-c-table__text, .pf-v5-c-table__column-help .pf-v5-c-table__text {
  min-width: auto;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nowrap .pf-v5-c-table__button-content, .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-nowrap .pf-v5-c-table__button-content, .pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-nowrap .pf-v5-c-table__button-content, .pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nowrap .pf-v5-c-table__column-help, .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-nowrap .pf-v5-c-table__column-help, .pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-nowrap .pf-v5-c-table__column-help {
  grid-template-columns: min-content max-content;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-fit-content .pf-v5-c-table__button-content, .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-fit-content .pf-v5-c-table__button-content, .pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-fit-content .pf-v5-c-table__button-content, .pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-fit-content .pf-v5-c-table__column-help, .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-fit-content .pf-v5-c-table__column-help, .pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-fit-content .pf-v5-c-table__column-help {
  grid-template-columns: fit-content max-content;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-wrap .pf-v5-c-table__button-content, .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-wrap .pf-v5-c-table__button-content, .pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-wrap .pf-v5-c-table__button-content, .pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-truncate .pf-v5-c-table__button-content, .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-truncate .pf-v5-c-table__button-content, .pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-truncate .pf-v5-c-table__button-content, .pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-wrap .pf-v5-c-table__column-help, .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-wrap .pf-v5-c-table__column-help, .pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-wrap .pf-v5-c-table__column-help, .pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-truncate .pf-v5-c-table__column-help, .pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-truncate .pf-v5-c-table__column-help, .pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-truncate .pf-v5-c-table__column-help {
  grid-template-columns: auto max-content;
}

.pf-v5-c-table .pf-v5-c-table__toggle, .pf-v5-c-table .pf-v5-c-table__action, .pf-v5-c-table .pf-v5-c-table__inline-edit-action {
  --pf-v5-c-table--cell--PaddingBottom: 0;
}

.pf-v5-c-table .pf-v5-c-table__check, .pf-v5-c-table .pf-v5-c-table__toggle, .pf-v5-c-table .pf-v5-c-table__action, .pf-v5-c-table .pf-v5-c-table__favorite, .pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-favorite, .pf-v5-c-table .pf-v5-c-table__inline-edit-action, .pf-v5-c-table .pf-v5-c-table__draggable {
  --pf-v5-c-table--cell--MinWidth: 0;
  --pf-v5-c-table--cell--Width: 1%;
}

.pf-v5-c-table__toggle {
  --pf-v5-c-table--cell--PaddingRight: 0;
  --pf-v5-c-table--cell--PaddingLeft: 0;
  vertical-align: top;
}

.pf-v5-c-table__toggle .pf-v5-c-button {
  margin-block-start: var(--pf-v5-c-table__toggle--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-table__toggle--c-button--MarginBottom);
}

.pf-v5-c-table__toggle .pf-v5-c-button.pf-m-expanded .pf-v5-c-table__toggle-icon {
  transform: rotate(var(--pf-v5-c-table__toggle--c-button--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-table__toggle .pf-v5-c-table__toggle-icon {
  transition: var(--pf-v5-c-table__toggle--c-button__toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-table__toggle--c-button__toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-table__toggle .pf-v5-c-table__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-table__toggle svg {
  pointer-events: none;
}

.pf-v5-c-table__check {
  --pf-v5-c-table--cell--FontSize: var(--pf-v5-c-table__check--input--FontSize);
}

.pf-v5-c-table__check label:not([disabled]), .pf-v5-c-table__check input:not([disabled]) {
  cursor: pointer;
}

.pf-v5-c-table__check label {
  padding: inherit;
  margin-block-start: calc(var(--pf-v5-c-table--cell--PaddingTop) * -1);
  margin-block-end: calc(var(--pf-v5-c-table--cell--PaddingBottom) * -1);
  margin-inline-start: calc(var(--pf-v5-c-table--cell--PaddingLeft) * -1);
  margin-inline-end: calc(var(--pf-v5-c-table--cell--PaddingRight) * -1);
  display: inline-flex;
}

.pf-v5-c-table__thead .pf-v5-c-check.pf-m-standalone {
  --pf-v5-c-check__input--TranslateY: var(--pf-v5-c-table__thead__check--input--TranslateY);
}

.pf-v5-c-table__favorite .pf-v5-c-button {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-table__favorite--c-button--Color);
  --pf-v5-c-button--FontSize: var(--pf-v5-c-table__favorite--c-button--FontSize);
  margin-block-start: var(--pf-v5-c-table__favorite--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-table__favorite--c-button--MarginBottom);
  margin-inline-start: var(--pf-v5-c-table__favorite--c-button--MarginLeft);
  margin-inline-end: var(--pf-v5-c-table__favorite--c-button--MarginRight);
}

.pf-m-favorited.pf-v5-c-table__favorite .pf-v5-c-button {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-table__favorite--m-favorited--c-button--Color);
}

.pf-v5-c-table__draggable .pf-v5-c-button {
  cursor: grab;
  margin-block-start: var(--pf-v5-c-table__draggable--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-table__draggable--c-button--MarginBottom);
  margin-inline-start: var(--pf-v5-c-table__draggable--c-button--MarginLeft);
  margin-inline-end: var(--pf-v5-c-table__draggable--c-button--MarginRight);
}

.pf-v5-c-table__draggable .pf-v5-c-button:active {
  cursor: grabbing;
}

.pf-v5-c-table__action, .pf-v5-c-table__inline-edit-action {
  --pf-v5-c-table--cell--PaddingTop: 0;
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table__action--PaddingRight);
  --pf-v5-c-table--cell--PaddingBottom: 0;
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table__action--PaddingLeft);
  vertical-align: middle;
  padding-block: 0;
}

.pf-v5-c-table__action {
  text-align: end;
}

.pf-v5-c-table__inline-edit-action {
  --pf-v5-c-table--cell--PaddingLeft: 0;
  --pf-v5-c-table--cell--PaddingRight: 0;
  text-align: end;
}

.pf-v5-c-table__compound-expansion-toggle {
  --pf-v5-c-table__button--Color: var(--pf-v5-c-table__compound-expansion-toggle__button--Color);
  --pf-v5-c-table__button--hover--Color: var(--pf-v5-c-table__compound-expansion-toggle__button--hover--Color);
  --pf-v5-c-table__button--focus--Color: var(--pf-v5-c-table__compound-expansion-toggle__button--focus--Color);
  --pf-v5-c-table__button--active--Color: var(--pf-v5-c-table__compound-expansion-toggle__button--active--Color);
  position: relative;
}

.pf-v5-c-table__compound-expansion-toggle.pf-m-truncate {
  overflow: visible;
}

.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button {
  min-width: 100%;
  overflow: hidden;
}

.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:hover, .pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:focus, .pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:active {
  outline: 0;
}

.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:before, .pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:after {
  content: "";
  border-style: solid;
  border-width: 0;
  position: absolute;
  inset-inline-end: 0;
}

.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:before {
  border-color: var(--pf-v5-c-table__compound-expansion-toggle__button--before--BorderColor);
  border-inline-start-width: var(--pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth);
  inset-block-start: 0;
  inset-block-end: var(--pf-v5-c-table__compound-expansion-toggle__button--before--Bottom);
  inset-inline-start: var(--pf-v5-c-table__compound-expansion-toggle__button--before--Left);
}

.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:after {
  pointer-events: none;
  border-color: var(--pf-v5-c-table__compound-expansion-toggle__button--after--BorderColor);
  border-block-start-width: var(--pf-v5-c-table__compound-expansion-toggle__button--after--BorderTopWidth);
  inset-block-start: var(--pf-v5-c-table__compound-expansion-toggle__button--after--Top);
  inset-inline-start: var(--pf-v5-c-table__compound-expansion-toggle__button--after--Left);
}

.pf-v5-c-table__compound-expansion-toggle:hover, .pf-v5-c-table__compound-expansion-toggle:focus-within, .pf-v5-c-table__compound-expansion-toggle.pf-m-expanded {
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base);
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base);
  --pf-v5-c-table__compound-expansion-toggle__button--after--BorderTopWidth: var(--pf-v5-c-table__compound-expansion-toggle__button--after--border-width--base);
}

.pf-v5-c-table__compound-expansion-toggle:first-child {
  --pf-v5-c-table__compound-expansion-toggle__button--before--Left: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--after--Left: 0;
}

.pf-v5-c-table__compound-expansion-toggle.pf-m-expanded .pf-v5-c-table__button:before {
  border-block-end: var(--pf-v5-c-table--BackgroundColor) solid var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base);
}

.pf-v5-c-table__compound-expansion-toggle.pf-m-expanded:first-child {
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
}

.pf-v5-c-table__compound-expansion-toggle:focus-within {
  outline-offset: var(--pf-v5-c-table__button--OutlineOffset);
}

@media (-webkit-device-pixel-ratio >= 0) {
  .pf-v5-c-table__compound-expansion-toggle:focus-within {
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.pf-v5-c-table__column-help-action {
  transform: translateY(var(--pf-v5-c-table__column-help--TranslateY));
  margin-inline-start: var(--pf-v5-c-table__column-help--MarginLeft);
}

.pf-v5-c-table__column-help-action .pf-v5-c-button {
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-table__column-help--c-button--PaddingRight);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-table__column-help--c-button--PaddingLeft);
  font-size: inherit;
  margin-block-start: var(--pf-v5-c-table__column-help--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-table__column-help--c-button--MarginBottom);
  line-height: 1;
}

.pf-v5-c-table__sort .pf-v5-c-table__button {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table__sort__button--PaddingTop);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table__sort__button--PaddingRight);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table__sort__button--PaddingBottom);
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table__sort__button--PaddingLeft);
  width: auto;
  margin-block-start: var(--pf-v5-c-table__sort__button--MarginTop);
  margin-block-end: var(--pf-v5-c-table__sort__button--MarginBottom);
  margin-inline-start: var(--pf-v5-c-table__sort__button--MarginLeft);
  display: flex;
}

.pf-v5-c-table__sort .pf-v5-c-table__button:hover {
  --pf-v5-c-table__sort-indicator--Color: var(--pf-v5-c-table__sort__button--hover__sort-indicator--Color);
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__sort__button--hover__text--Color);
}

.pf-v5-c-table__sort .pf-v5-c-table__button:focus {
  --pf-v5-c-table__sort-indicator--Color: var(--pf-v5-c-table__sort__button--focus__sort-indicator--Color);
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__sort__button--focus__text--Color);
}

.pf-v5-c-table__sort .pf-v5-c-table__button:active {
  --pf-v5-c-table__sort-indicator--Color: var(--pf-v5-c-table__sort__button--active__sort-indicator--Color);
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__sort__button--active__text--Color);
}

.pf-v5-c-table__sort .pf-v5-c-table__button .pf-v5-c-table__text {
  color: var(--pf-v5-c-table__sort__button__text--Color);
}

.pf-v5-c-table__sort.pf-m-selected .pf-v5-c-table__button {
  --pf-v5-c-table__sort-indicator--Color: var(--pf-v5-c-table__sort--m-selected__sort-indicator--Color);
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__sort--m-selected__button__text--Color);
  color: var(--pf-v5-c-table__sort--m-selected__button--Color);
}

.pf-v5-c-table__sort.pf-m-help {
  --pf-v5-c-table__th--m-help--MinWidth: var(--pf-v5-c-table__sort--m-help--MinWidth);
}

.pf-v5-c-table__sort.pf-m-favorite {
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__sort--m-favorite__button__text--Color);
  --pf-v5-c-table__sort__button--hover__text--Color: var(--pf-v5-c-table__sort--m-favorite__button--hover__text--Color);
  --pf-v5-c-table__sort__button--focus__text--Color: var(--pf-v5-c-table__sort--m-favorite__button--focus__text--Color);
  --pf-v5-c-table__sort__button--active__text--Color: var(--pf-v5-c-table__sort--m-favorite__button--active__text--Color);
  --pf-v5-c-table__sort--m-selected__button__text--Color: currentcolor;
}

.pf-v5-c-table__sort-indicator {
  color: var(--pf-v5-c-table__sort-indicator--Color);
  pointer-events: none;
  grid-column: 2;
  margin-inline-start: var(--pf-v5-c-table__sort-indicator--MarginLeft);
}

.pf-v5-c-table__expandable-row {
  --pf-v5-c-table--cell--PaddingTop: 0;
  --pf-v5-c-table--cell--PaddingBottom: 0;
  border-block-end: 0 solid #0000;
  position: relative;
  box-shadow: 0 0 #0000;
}

.pf-v5-c-table__expandable-row, .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td):first-child:after {
  transition: var(--pf-v5-c-table__expandable-row--Transition);
}

.pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding, .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding {
  padding-block: 0;
  padding-inline-start: var(--pf-v5-c-table__expandable-row--after--border-width--base);
  padding-inline-end: 0;
}

.pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content, .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
  padding: 0;
}

.pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
  padding-block-start: var(--pf-v5-c-table__expandable-row-content--PaddingTop);
  padding-block-end: var(--pf-v5-c-table__expandable-row-content--PaddingBottom);
}

.pf-v5-c-table__expandable-row.pf-m-expanded {
  border-block-end-color: var(--pf-v5-c-table__expandable-row--m-expanded--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-table--border-width--base);
  box-shadow: var(--pf-v5-c-table__expandable-row--m-expanded--BoxShadow);
}

.pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
  display: none;
}

.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :first-child {
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--nested--first-last-child--PaddingLeft);
}

.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :last-child {
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--nested--first-last-child--PaddingRight);
}

.pf-v5-c-table.pf-m-compact {
  --pf-v5-c-table--cell--FontSize: var(--pf-v5-c-table--m-compact--FontSize);
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-compact--cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-compact--cell--PaddingBottom);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-compact--cell--PaddingLeft);
}

.pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr) {
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-compact--cell--PaddingLeft);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-compact--cell--PaddingRight);
}

.pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  --pf-v5-c-table--cell--FontSize: var(--pf-v5-c-table--m-compact--FontSize);
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-compact--cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-compact--cell--PaddingBottom);
}

.pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > :first-child {
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-compact--cell--first-last-child--PaddingLeft);
}

.pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > :last-child {
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-compact--cell--first-last-child--PaddingRight);
}

.pf-v5-c-table.pf-m-compact thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th), .pf-v5-c-table.pf-m-compact thead:where(.pf-v5-c-table__thead) .pf-v5-c-table__toggle {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-compact__th--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-compact__th--PaddingBottom);
}

.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table__action--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table__action--PaddingBottom);
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table__action--PaddingLeft);
}

.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-compact__toggle--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-compact__toggle--PaddingBottom);
}

.pf-v5-c-table.pf-m-compact .pf-v5-c-table__icon {
  text-align: center;
  width: auto;
  min-width: 0;
}

.pf-v5-c-table .pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr) > :first-child {
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--nested--first-last-child--PaddingLeft);
}

.pf-v5-c-table .pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr) > :last-child {
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--nested--first-last-child--PaddingRight);
}

.pf-v5-c-table.pf-m-compact .pf-v5-c-table__expandable-row-content {
  --pf-v5-c-table__expandable-row-content--PaddingTop: var(--pf-v5-c-table--m-compact__expandable-row-content--PaddingTop);
  --pf-v5-c-table__expandable-row-content--PaddingBottom: var(--pf-v5-c-table--m-compact__expandable-row-content--PaddingBottom);
}

.pf-v5-c-table__icon-inline {
  align-items: center;
  display: flex;
}

.pf-v5-c-table__icon-inline > :not(:last-child) {
  margin-inline-end: var(--pf-v5-c-table__icon-inline--MarginRight);
}

.pf-v5-c-table .pf-m-width-10 {
  --pf-v5-c-table--cell--Width: 10%;
}

.pf-v5-c-table .pf-m-width-15 {
  --pf-v5-c-table--cell--Width: 15%;
}

.pf-v5-c-table .pf-m-width-20 {
  --pf-v5-c-table--cell--Width: 20%;
}

.pf-v5-c-table .pf-m-width-25 {
  --pf-v5-c-table--cell--Width: 25%;
}

.pf-v5-c-table .pf-m-width-30 {
  --pf-v5-c-table--cell--Width: 30%;
}

.pf-v5-c-table .pf-m-width-35 {
  --pf-v5-c-table--cell--Width: 35%;
}

.pf-v5-c-table .pf-m-width-40 {
  --pf-v5-c-table--cell--Width: 40%;
}

.pf-v5-c-table .pf-m-width-45 {
  --pf-v5-c-table--cell--Width: 45%;
}

.pf-v5-c-table .pf-m-width-50 {
  --pf-v5-c-table--cell--Width: 50%;
}

.pf-v5-c-table .pf-m-width-60 {
  --pf-v5-c-table--cell--Width: 60%;
}

.pf-v5-c-table .pf-m-width-70 {
  --pf-v5-c-table--cell--Width: 70%;
}

.pf-v5-c-table .pf-m-width-80 {
  --pf-v5-c-table--cell--Width: 80%;
}

.pf-v5-c-table .pf-m-width-90 {
  --pf-v5-c-table--cell--Width: 90%;
}

.pf-v5-c-table .pf-m-width-100 {
  --pf-v5-c-table--cell--Width: 100%;
}

:where(.pf-v5-theme-dark) .pf-v5-c-table {
  --pf-v5-c-table--m-striped__tr--BackgroundColor: var(--pf-v5-global--BackgroundColor--150);
}

:where(.pf-v5-theme-dark) .pf-v5-c-table__favorite .pf-v5-c-button:hover, :where(.pf-v5-theme-dark) .pf-v5-c-table__favorite .pf-v5-c-button:focus {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-global--palette--gold-100);
}

.pf-v5-c-table[class*="pf-m-grid"] {
  --pf-v5-c-table--responsive--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-table__tbody--responsive--border-width--base: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tbody--after--border-width--base: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  --pf-v5-c-table__tbody--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__tr--responsive--border-width--base: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table__tr--responsive--GridColumnGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__tr--responsive--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tr--responsive--xl--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tr--responsive--xl--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__tr--responsive--nested-table--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table__tr--responsive--nested-table--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tr--responsive--nested-table--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table__tr--responsive--nested-table--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: 0;
  --pf-v5-c-table__tbody--after__tr--BorderLeftColor: transparent;
  --pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth: var(--pf-v5-c-table__expandable-row--after--border-width--base);
  --pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth: var(--pf-v5-c-table__expandable-row--after--border-width--base);
  --pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table--m-grid--cell--hidden-visible--Display: grid;
  --pf-v5-c-table--m-grid--cell--PaddingTop: 0;
  --pf-v5-c-table--m-grid--cell--PaddingRight: 0;
  --pf-v5-c-table--m-grid--cell--PaddingBottom: 0;
  --pf-v5-c-table--m-grid--cell--PaddingLeft: 0;
  --pf-v5-c-table-td--responsive--GridColumnGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--cell--first-child--responsive--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--cell--responsive--PaddingRight: 0;
  --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
  --pf-v5-c-table--m-compact__tr--responsive--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact__tr--responsive--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table--m-compact__check--responsive--MarginTop: .4375rem;
  --pf-v5-c-table--m-compact__action--responsive--MarginTop: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-table--m-compact__action--responsive--MarginBottom: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom: calc(.375rem * -1);
  --pf-v5-c-table__expandable-row-content--responsive--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__expandable-row-content--responsive--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__expandable-row-content--responsive--xl--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__expandable-row-content--responsive--xl--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__expandable-row-content--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__check--responsive--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__check--responsive--MarginTop: .875rem;
  --pf-v5-c-table--m-grid__favorite--MarginTop: .5rem;
  --pf-v5-c-table--m-grid__check--favorite--MarginLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-grid__action--MarginTop: .375rem;
  --pf-v5-c-table__action--responsive--MarginLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-grid__favorite--action--MarginLeft: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-table--m-grid__check--favorite--action--MarginLeft: calc(var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft)  + var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft));
  --pf-v5-c-table__toggle__icon--Transition: .2s ease-in 0s;
  --pf-v5-c-table__toggle--m-expanded__icon--Rotate: 180deg;
}

@media screen and (width <= 1200px) {
  .pf-v5-c-table[class*="pf-m-grid"] {
    --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--xl--PaddingRight);
    --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--xl--PaddingLeft);
    --pf-v5-c-table__expandable-row-content--responsive--PaddingRight: var(--pf-v5-c-table__expandable-row-content--responsive--xl--PaddingRight);
    --pf-v5-c-table__expandable-row-content--responsive--PaddingLeft: var(--pf-v5-c-table__expandable-row-content--responsive--xl--PaddingLeft);
  }
}

.pf-m-grid.pf-v5-c-table {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
  --pf-v5-c-table__favorite--c-button--MarginTop: auto;
  --pf-v5-c-table__favorite--c-button--MarginRight: auto;
  --pf-v5-c-table__favorite--c-button--MarginBottom: auto;
  --pf-v5-c-table__favorite--c-button--MarginLeft: auto;
  border: none;
  display: grid;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > * {
  text-overflow: clip;
  white-space: normal;
  width: auto;
  min-width: 0;
  max-width: none;
  overflow: visible;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__text {
  overflow: var(--pf-v5-c-table--cell--Overflow);
  text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
  white-space: var(--pf-v5-c-table--cell--WhiteSpace);
  width: auto;
  min-width: 0;
  max-width: none;
  position: relative;
}

.pf-m-grid.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
  display: none;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
  display: block;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):first-of-type {
  border-block-start: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table.pf-m-compact > tbody:where(.pf-v5-c-table__tbody) {
  border-block-start: 0;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  border-block-end: var(--pf-v5-c-table__tr--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):last-child, .pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):last-of-type:not(:only-of-type) > tr:where(.pf-v5-c-table__tr) {
  border-block-end-width: var(--pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  border-block-end: 0;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded:not(:last-of-type) {
  border-block-end: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
  --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
  --pf-v5-c-table__expandable-row--after--BorderColor: transparent;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  grid-column-gap: var(--pf-v5-c-table__tr--responsive--GridColumnGap);
  grid-template-columns: 1fr;
  grid-auto-columns: max-content;
  height: auto;
  padding-block-start: var(--pf-v5-c-table__tr--responsive--PaddingTop);
  padding-block-end: var(--pf-v5-c-table__tr--responsive--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table__tr--responsive--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-table__tr--responsive--PaddingRight);
  display: grid;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > * {
  padding-block-start: var(--pf-v5-c-table--cell--responsive--PaddingTop);
  padding-block-end: var(--pf-v5-c-table--cell--responsive--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table--cell--responsive--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-table--cell--responsive--PaddingRight);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > :first-child {
  --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--cell--first-child--responsive--PaddingTop);
}

.pf-m-grid.pf-v5-c-table.pf-m-compact {
  --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingTop);
  --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingBottom);
  --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop);
  --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom);
  --pf-v5-c-table__check--responsive--MarginTop: var(--pf-v5-c-table--m-compact__check--responsive--MarginTop);
  --pf-v5-c-table__check--input--MarginTop: 0;
}

.pf-m-grid.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
  margin-block-start: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
  margin-block-end: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
}

.pf-m-grid.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle .pf-v5-c-button {
  margin-block-end: var(--pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__icon > * {
  text-align: start;
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] {
  --pf-v5-c-table--cell--hidden-visible--Display: var(--pf-v5-c-table--m-grid--cell--hidden-visible--Display);
  grid-column-gap: var(--pf-v5-c-table-td--responsive--GridColumnGap);
  grid-column: 1;
  grid-template-columns: 1fr minmax(0, 1.5fr);
  align-items: start;
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] > * {
  grid-column: 2;
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]:before {
  text-align: start;
  content: attr(data-label);
  font-weight: bold;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :first-child {
  --pf-v5-c-table--cell--PaddingLeft: 0;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :last-child {
  --pf-v5-c-table--cell--PaddingRight: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table {
  --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingTop);
  --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingRight);
  --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingBottom);
  --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingLeft);
  border: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) + tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  --pf-v5-c-table__tr--responsive--PaddingTop: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle {
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--after--Top: 100%;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
  position: relative;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):after {
  content: "";
  border: 0;
  border-inline-start: var(--pf-v5-c-table__tbody--after--BorderLeftWidth) solid var(--pf-v5-c-table__tbody--after--BorderColor);
  position: absolute;
  inset-block: 0;
  inset-inline-start: 0;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
  --pf-v5-c-table__tbody--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--after--border-width--base);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tbody:where(.pf-v5-c-table__tbody) {
  --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) > tr:where(.pf-v5-c-table__tr) > :first-child:not(.pf-v5-c-table__check):after {
  --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
  background-color: #0000;
  width: auto;
  position: static;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row {
  --pf-v5-c-table--cell--responsive--PaddingTop: 0;
  --pf-v5-c-table--cell--responsive--PaddingRight: 0;
  --pf-v5-c-table--cell--responsive--PaddingBottom: 0;
  --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
  --pf-v5-c-table--cell--PaddingRight: 0;
  --pf-v5-c-table--cell--PaddingLeft: 0;
  max-height: var(--pf-v5-c-table__expandable-row--MaxHeight);
  box-shadow: none;
  border-block-end: none;
  display: block;
  overflow-y: auto;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row > * {
  display: block;
  position: static;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row.pf-m-expanded {
  border-block-start-color: var(--pf-v5-c-table--BorderColor);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row > :first-child:not(.pf-v5-c-table__check):after {
  content: none;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content, .pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
  padding: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
  display: none;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
  padding-inline-start: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingRight);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable {
  --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  --pf-v5-c-table__tbody--after--BorderLeftColor: transparent;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable, .pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable > tr:where(.pf-v5-c-table__tr) {
  position: relative;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable > tr:where(.pf-v5-c-table__tr):after {
  content: "";
  width: var(--pf-v5-c-table__tbody--after__tr--BorderLeftWidth);
  background-color: var(--pf-v5-c-table__tbody--after__tr--BorderLeftColor);
  position: absolute;
  inset-block: 0;
  inset-inline-start: 0;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-expanded {
  --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth);
  --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-selected {
  --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth);
  --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected:after {
  content: "";
  width: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
  background-color: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
  position: absolute;
  inset-block: 0;
  inset-inline-start: 0;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__toggle, .pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__check, .pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__favorite, .pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__action {
  width: auto;
  padding: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__toggle {
  grid-column: -1;
  grid-row-start: 20;
  justify-self: end;
  padding-inline-end: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__toggle:after {
  content: none;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__button {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check, .pf-m-grid.pf-v5-c-table .pf-v5-c-table__favorite, .pf-m-grid.pf-v5-c-table .pf-v5-c-table__action {
  grid-row-start: 1;
  grid-column-start: 2;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check {
  margin-block-start: var(--pf-v5-c-table__check--responsive--MarginTop);
  margin-inline-start: var(--pf-v5-c-table__check--responsive--MarginLeft);
  line-height: 1;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__favorite {
  margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__favorite ~ .pf-v5-c-table__action {
  margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--action--MarginLeft);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__action {
  margin-inline-start: var(--pf-v5-c-table__action--responsive--MarginLeft);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check label {
  margin: 0;
  display: inline-block;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__favorite {
  margin-block-start: var(--pf-v5-c-table--m-grid__favorite--MarginTop);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__favorite ~ .pf-v5-c-table__action {
  margin-inline-start: var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__action {
  text-align: end;
  margin-block-start: var(--pf-v5-c-table--m-grid__action--MarginTop);
}

@media screen and (width <= 576px) {
  .pf-m-grid.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-inline-start: 0;
  }
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
  grid-area: 2 / 2;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__toggle-icon {
  transition: var(--pf-v5-c-table__toggle__icon--Transition);
}

.pf-v5-c-button.pf-m-expanded > .pf-m-grid.pf-v5-c-table .pf-v5-c-table__toggle-icon {
  transform: rotate(var(--pf-v5-c-table__toggle--m-expanded__icon--Rotate));
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-nowrap {
  --pf-v5-c-table--cell--Overflow: auto;
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-fit-content {
  white-space: normal;
  width: auto;
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-truncate {
  --pf-v5-c-table--cell--MaxWidth: 100%;
}

.pf-m-grid.pf-v5-c-table [class*="pf-m-width"] {
  --pf-v5-c-table--cell--Width: auto;
}

@media screen and (width <= 768px) {
  .pf-m-grid-md.pf-v5-c-table {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
    --pf-v5-c-table__favorite--c-button--MarginTop: auto;
    --pf-v5-c-table__favorite--c-button--MarginRight: auto;
    --pf-v5-c-table__favorite--c-button--MarginBottom: auto;
    --pf-v5-c-table__favorite--c-button--MarginLeft: auto;
    border: none;
    display: grid;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > * {
    text-overflow: clip;
    white-space: normal;
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: visible;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__text {
    overflow: var(--pf-v5-c-table--cell--Overflow);
    text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
    white-space: var(--pf-v5-c-table--cell--WhiteSpace);
    width: auto;
    min-width: 0;
    max-width: none;
    position: relative;
  }

  .pf-m-grid-md.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
    display: none;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    display: block;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):first-of-type {
    border-block-start: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table.pf-m-compact > tbody:where(.pf-v5-c-table__tbody) {
    border-block-start: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: var(--pf-v5-c-table__tr--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):last-child, .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):last-of-type:not(:only-of-type) > tr:where(.pf-v5-c-table__tr) {
    border-block-end-width: var(--pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded:not(:last-of-type) {
    border-block-end: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    --pf-v5-c-table__expandable-row--after--BorderColor: transparent;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    grid-column-gap: var(--pf-v5-c-table__tr--responsive--GridColumnGap);
    grid-template-columns: 1fr;
    grid-auto-columns: max-content;
    height: auto;
    padding-block-start: var(--pf-v5-c-table__tr--responsive--PaddingTop);
    padding-block-end: var(--pf-v5-c-table__tr--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table__tr--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table__tr--responsive--PaddingRight);
    display: grid;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > * {
    padding-block-start: var(--pf-v5-c-table--cell--responsive--PaddingTop);
    padding-block-end: var(--pf-v5-c-table--cell--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--cell--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table--cell--responsive--PaddingRight);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > :first-child {
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--cell--first-child--responsive--PaddingTop);
  }

  .pf-m-grid-md.pf-v5-c-table.pf-m-compact {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingBottom);
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop);
    --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom);
    --pf-v5-c-table__check--responsive--MarginTop: var(--pf-v5-c-table--m-compact__check--responsive--MarginTop);
    --pf-v5-c-table__check--input--MarginTop: 0;
  }

  .pf-m-grid-md.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
  }

  .pf-m-grid-md.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle .pf-v5-c-button {
    margin-block-end: var(--pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__icon > * {
    text-align: start;
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] {
    --pf-v5-c-table--cell--hidden-visible--Display: var(--pf-v5-c-table--m-grid--cell--hidden-visible--Display);
    grid-column-gap: var(--pf-v5-c-table-td--responsive--GridColumnGap);
    grid-column: 1;
    grid-template-columns: 1fr minmax(0, 1.5fr);
    align-items: start;
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]:before {
    text-align: start;
    content: attr(data-label);
    font-weight: bold;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :first-child {
    --pf-v5-c-table--cell--PaddingLeft: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :last-child {
    --pf-v5-c-table--cell--PaddingRight: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingRight);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingBottom);
    --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingLeft);
    border: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) + tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    --pf-v5-c-table__tr--responsive--PaddingTop: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle {
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--after--Top: 100%;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    position: relative;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):after {
    content: "";
    border: 0;
    border-inline-start: var(--pf-v5-c-table__tbody--after--BorderLeftWidth) solid var(--pf-v5-c-table__tbody--after--BorderColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--after--border-width--base);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tbody:where(.pf-v5-c-table__tbody) {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) > tr:where(.pf-v5-c-table__tr) > :first-child:not(.pf-v5-c-table__check):after {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    background-color: #0000;
    width: auto;
    position: static;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row {
    --pf-v5-c-table--cell--responsive--PaddingTop: 0;
    --pf-v5-c-table--cell--responsive--PaddingRight: 0;
    --pf-v5-c-table--cell--responsive--PaddingBottom: 0;
    --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
    --pf-v5-c-table--cell--PaddingRight: 0;
    --pf-v5-c-table--cell--PaddingLeft: 0;
    max-height: var(--pf-v5-c-table__expandable-row--MaxHeight);
    box-shadow: none;
    border-block-end: none;
    display: block;
    overflow-y: auto;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row > * {
    display: block;
    position: static;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row.pf-m-expanded {
    border-block-start-color: var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row > :first-child:not(.pf-v5-c-table__check):after {
    content: none;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content, .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
    padding: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
    display: none;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
    padding-inline-start: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingRight);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
    --pf-v5-c-table__tbody--after--BorderLeftColor: transparent;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable, .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable > tr:where(.pf-v5-c-table__tr) {
    position: relative;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable > tr:where(.pf-v5-c-table__tr):after {
    content: "";
    width: var(--pf-v5-c-table__tbody--after__tr--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tbody--after__tr--BorderLeftColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-expanded {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-selected {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected:after {
    content: "";
    width: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__toggle, .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__check, .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__favorite, .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__action {
    width: auto;
    padding: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__toggle {
    grid-column: -1;
    grid-row-start: 20;
    justify-self: end;
    padding-inline-end: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__toggle:after {
    content: none;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__button {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check, .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__favorite, .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check {
    margin-block-start: var(--pf-v5-c-table__check--responsive--MarginTop);
    margin-inline-start: var(--pf-v5-c-table__check--responsive--MarginLeft);
    line-height: 1;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__favorite {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__favorite ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--action--MarginLeft);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table__action--responsive--MarginLeft);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check label {
    margin: 0;
    display: inline-block;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__favorite {
    margin-block-start: var(--pf-v5-c-table--m-grid__favorite--MarginTop);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__favorite ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    text-align: end;
    margin-block-start: var(--pf-v5-c-table--m-grid__action--MarginTop);
  }
}

@media screen and (width <= 768px) and (width <= 576px) {
  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-inline-start: 0;
  }
}

@media screen and (width <= 768px) {
  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
    grid-area: 2 / 2;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transition: var(--pf-v5-c-table__toggle__icon--Transition);
  }

  .pf-v5-c-button.pf-m-expanded > .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transform: rotate(var(--pf-v5-c-table__toggle--m-expanded__icon--Rotate));
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-nowrap {
    --pf-v5-c-table--cell--Overflow: auto;
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-fit-content {
    white-space: normal;
    width: auto;
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-truncate {
    --pf-v5-c-table--cell--MaxWidth: 100%;
  }

  .pf-m-grid-md.pf-v5-c-table [class*="pf-m-width"] {
    --pf-v5-c-table--cell--Width: auto;
  }
}

@media screen and (width <= 992px) {
  .pf-m-grid-lg.pf-v5-c-table {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
    --pf-v5-c-table__favorite--c-button--MarginTop: auto;
    --pf-v5-c-table__favorite--c-button--MarginRight: auto;
    --pf-v5-c-table__favorite--c-button--MarginBottom: auto;
    --pf-v5-c-table__favorite--c-button--MarginLeft: auto;
    border: none;
    display: grid;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > * {
    text-overflow: clip;
    white-space: normal;
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: visible;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__text {
    overflow: var(--pf-v5-c-table--cell--Overflow);
    text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
    white-space: var(--pf-v5-c-table--cell--WhiteSpace);
    width: auto;
    min-width: 0;
    max-width: none;
    position: relative;
  }

  .pf-m-grid-lg.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
    display: none;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    display: block;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):first-of-type {
    border-block-start: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table.pf-m-compact > tbody:where(.pf-v5-c-table__tbody) {
    border-block-start: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: var(--pf-v5-c-table__tr--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):last-child, .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):last-of-type:not(:only-of-type) > tr:where(.pf-v5-c-table__tr) {
    border-block-end-width: var(--pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded:not(:last-of-type) {
    border-block-end: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    --pf-v5-c-table__expandable-row--after--BorderColor: transparent;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    grid-column-gap: var(--pf-v5-c-table__tr--responsive--GridColumnGap);
    grid-template-columns: 1fr;
    grid-auto-columns: max-content;
    height: auto;
    padding-block-start: var(--pf-v5-c-table__tr--responsive--PaddingTop);
    padding-block-end: var(--pf-v5-c-table__tr--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table__tr--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table__tr--responsive--PaddingRight);
    display: grid;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > * {
    padding-block-start: var(--pf-v5-c-table--cell--responsive--PaddingTop);
    padding-block-end: var(--pf-v5-c-table--cell--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--cell--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table--cell--responsive--PaddingRight);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > :first-child {
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--cell--first-child--responsive--PaddingTop);
  }

  .pf-m-grid-lg.pf-v5-c-table.pf-m-compact {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingBottom);
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop);
    --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom);
    --pf-v5-c-table__check--responsive--MarginTop: var(--pf-v5-c-table--m-compact__check--responsive--MarginTop);
    --pf-v5-c-table__check--input--MarginTop: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
  }

  .pf-m-grid-lg.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle .pf-v5-c-button {
    margin-block-end: var(--pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__icon > * {
    text-align: start;
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] {
    --pf-v5-c-table--cell--hidden-visible--Display: var(--pf-v5-c-table--m-grid--cell--hidden-visible--Display);
    grid-column-gap: var(--pf-v5-c-table-td--responsive--GridColumnGap);
    grid-column: 1;
    grid-template-columns: 1fr minmax(0, 1.5fr);
    align-items: start;
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]:before {
    text-align: start;
    content: attr(data-label);
    font-weight: bold;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :first-child {
    --pf-v5-c-table--cell--PaddingLeft: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :last-child {
    --pf-v5-c-table--cell--PaddingRight: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingRight);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingBottom);
    --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingLeft);
    border: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) + tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    --pf-v5-c-table__tr--responsive--PaddingTop: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle {
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--after--Top: 100%;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    position: relative;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):after {
    content: "";
    border: 0;
    border-inline-start: var(--pf-v5-c-table__tbody--after--BorderLeftWidth) solid var(--pf-v5-c-table__tbody--after--BorderColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--after--border-width--base);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tbody:where(.pf-v5-c-table__tbody) {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) > tr:where(.pf-v5-c-table__tr) > :first-child:not(.pf-v5-c-table__check):after {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    background-color: #0000;
    width: auto;
    position: static;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row {
    --pf-v5-c-table--cell--responsive--PaddingTop: 0;
    --pf-v5-c-table--cell--responsive--PaddingRight: 0;
    --pf-v5-c-table--cell--responsive--PaddingBottom: 0;
    --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
    --pf-v5-c-table--cell--PaddingRight: 0;
    --pf-v5-c-table--cell--PaddingLeft: 0;
    max-height: var(--pf-v5-c-table__expandable-row--MaxHeight);
    box-shadow: none;
    border-block-end: none;
    display: block;
    overflow-y: auto;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row > * {
    display: block;
    position: static;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row.pf-m-expanded {
    border-block-start-color: var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row > :first-child:not(.pf-v5-c-table__check):after {
    content: none;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content, .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
    padding: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
    display: none;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
    padding-inline-start: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingRight);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
    --pf-v5-c-table__tbody--after--BorderLeftColor: transparent;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable, .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable > tr:where(.pf-v5-c-table__tr) {
    position: relative;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable > tr:where(.pf-v5-c-table__tr):after {
    content: "";
    width: var(--pf-v5-c-table__tbody--after__tr--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tbody--after__tr--BorderLeftColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-expanded {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-selected {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected:after {
    content: "";
    width: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__toggle, .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__check, .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__favorite, .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__action {
    width: auto;
    padding: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__toggle {
    grid-column: -1;
    grid-row-start: 20;
    justify-self: end;
    padding-inline-end: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__toggle:after {
    content: none;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__button {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check, .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__favorite, .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check {
    margin-block-start: var(--pf-v5-c-table__check--responsive--MarginTop);
    margin-inline-start: var(--pf-v5-c-table__check--responsive--MarginLeft);
    line-height: 1;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__favorite {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__favorite ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--action--MarginLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table__action--responsive--MarginLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check label {
    margin: 0;
    display: inline-block;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__favorite {
    margin-block-start: var(--pf-v5-c-table--m-grid__favorite--MarginTop);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__favorite ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    text-align: end;
    margin-block-start: var(--pf-v5-c-table--m-grid__action--MarginTop);
  }
}

@media screen and (width <= 992px) and (width <= 576px) {
  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-inline-start: 0;
  }
}

@media screen and (width <= 992px) {
  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
    grid-area: 2 / 2;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transition: var(--pf-v5-c-table__toggle__icon--Transition);
  }

  .pf-v5-c-button.pf-m-expanded > .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transform: rotate(var(--pf-v5-c-table__toggle--m-expanded__icon--Rotate));
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-nowrap {
    --pf-v5-c-table--cell--Overflow: auto;
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-fit-content {
    white-space: normal;
    width: auto;
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-truncate {
    --pf-v5-c-table--cell--MaxWidth: 100%;
  }

  .pf-m-grid-lg.pf-v5-c-table [class*="pf-m-width"] {
    --pf-v5-c-table--cell--Width: auto;
  }
}

@media screen and (width <= 1200px) {
  .pf-m-grid-xl.pf-v5-c-table {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
    --pf-v5-c-table__favorite--c-button--MarginTop: auto;
    --pf-v5-c-table__favorite--c-button--MarginRight: auto;
    --pf-v5-c-table__favorite--c-button--MarginBottom: auto;
    --pf-v5-c-table__favorite--c-button--MarginLeft: auto;
    border: none;
    display: grid;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > * {
    text-overflow: clip;
    white-space: normal;
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: visible;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__text {
    overflow: var(--pf-v5-c-table--cell--Overflow);
    text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
    white-space: var(--pf-v5-c-table--cell--WhiteSpace);
    width: auto;
    min-width: 0;
    max-width: none;
    position: relative;
  }

  .pf-m-grid-xl.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
    display: none;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    display: block;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):first-of-type {
    border-block-start: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table.pf-m-compact > tbody:where(.pf-v5-c-table__tbody) {
    border-block-start: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: var(--pf-v5-c-table__tr--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):last-child, .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):last-of-type:not(:only-of-type) > tr:where(.pf-v5-c-table__tr) {
    border-block-end-width: var(--pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded:not(:last-of-type) {
    border-block-end: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    --pf-v5-c-table__expandable-row--after--BorderColor: transparent;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    grid-column-gap: var(--pf-v5-c-table__tr--responsive--GridColumnGap);
    grid-template-columns: 1fr;
    grid-auto-columns: max-content;
    height: auto;
    padding-block-start: var(--pf-v5-c-table__tr--responsive--PaddingTop);
    padding-block-end: var(--pf-v5-c-table__tr--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table__tr--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table__tr--responsive--PaddingRight);
    display: grid;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > * {
    padding-block-start: var(--pf-v5-c-table--cell--responsive--PaddingTop);
    padding-block-end: var(--pf-v5-c-table--cell--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--cell--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table--cell--responsive--PaddingRight);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > :first-child {
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--cell--first-child--responsive--PaddingTop);
  }

  .pf-m-grid-xl.pf-v5-c-table.pf-m-compact {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingBottom);
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop);
    --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom);
    --pf-v5-c-table__check--responsive--MarginTop: var(--pf-v5-c-table--m-compact__check--responsive--MarginTop);
    --pf-v5-c-table__check--input--MarginTop: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
  }

  .pf-m-grid-xl.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle .pf-v5-c-button {
    margin-block-end: var(--pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__icon > * {
    text-align: start;
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] {
    --pf-v5-c-table--cell--hidden-visible--Display: var(--pf-v5-c-table--m-grid--cell--hidden-visible--Display);
    grid-column-gap: var(--pf-v5-c-table-td--responsive--GridColumnGap);
    grid-column: 1;
    grid-template-columns: 1fr minmax(0, 1.5fr);
    align-items: start;
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]:before {
    text-align: start;
    content: attr(data-label);
    font-weight: bold;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :first-child {
    --pf-v5-c-table--cell--PaddingLeft: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :last-child {
    --pf-v5-c-table--cell--PaddingRight: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingRight);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingBottom);
    --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingLeft);
    border: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) + tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    --pf-v5-c-table__tr--responsive--PaddingTop: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle {
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--after--Top: 100%;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    position: relative;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):after {
    content: "";
    border: 0;
    border-inline-start: var(--pf-v5-c-table__tbody--after--BorderLeftWidth) solid var(--pf-v5-c-table__tbody--after--BorderColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--after--border-width--base);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tbody:where(.pf-v5-c-table__tbody) {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) > tr:where(.pf-v5-c-table__tr) > :first-child:not(.pf-v5-c-table__check):after {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    background-color: #0000;
    width: auto;
    position: static;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row {
    --pf-v5-c-table--cell--responsive--PaddingTop: 0;
    --pf-v5-c-table--cell--responsive--PaddingRight: 0;
    --pf-v5-c-table--cell--responsive--PaddingBottom: 0;
    --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
    --pf-v5-c-table--cell--PaddingRight: 0;
    --pf-v5-c-table--cell--PaddingLeft: 0;
    max-height: var(--pf-v5-c-table__expandable-row--MaxHeight);
    box-shadow: none;
    border-block-end: none;
    display: block;
    overflow-y: auto;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row > * {
    display: block;
    position: static;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row.pf-m-expanded {
    border-block-start-color: var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row > :first-child:not(.pf-v5-c-table__check):after {
    content: none;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content, .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
    padding: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
    display: none;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
    padding-inline-start: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingRight);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
    --pf-v5-c-table__tbody--after--BorderLeftColor: transparent;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable, .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable > tr:where(.pf-v5-c-table__tr) {
    position: relative;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable > tr:where(.pf-v5-c-table__tr):after {
    content: "";
    width: var(--pf-v5-c-table__tbody--after__tr--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tbody--after__tr--BorderLeftColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-expanded {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-selected {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected:after {
    content: "";
    width: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__toggle, .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__check, .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__favorite, .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__action {
    width: auto;
    padding: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__toggle {
    grid-column: -1;
    grid-row-start: 20;
    justify-self: end;
    padding-inline-end: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__toggle:after {
    content: none;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__button {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check, .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__favorite, .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check {
    margin-block-start: var(--pf-v5-c-table__check--responsive--MarginTop);
    margin-inline-start: var(--pf-v5-c-table__check--responsive--MarginLeft);
    line-height: 1;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__favorite {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__favorite ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--action--MarginLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table__action--responsive--MarginLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check label {
    margin: 0;
    display: inline-block;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__favorite {
    margin-block-start: var(--pf-v5-c-table--m-grid__favorite--MarginTop);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__favorite ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    text-align: end;
    margin-block-start: var(--pf-v5-c-table--m-grid__action--MarginTop);
  }
}

@media screen and (width <= 1200px) and (width <= 576px) {
  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-inline-start: 0;
  }
}

@media screen and (width <= 1200px) {
  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
    grid-area: 2 / 2;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transition: var(--pf-v5-c-table__toggle__icon--Transition);
  }

  .pf-v5-c-button.pf-m-expanded > .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transform: rotate(var(--pf-v5-c-table__toggle--m-expanded__icon--Rotate));
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-nowrap {
    --pf-v5-c-table--cell--Overflow: auto;
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-fit-content {
    white-space: normal;
    width: auto;
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-truncate {
    --pf-v5-c-table--cell--MaxWidth: 100%;
  }

  .pf-m-grid-xl.pf-v5-c-table [class*="pf-m-width"] {
    --pf-v5-c-table--cell--Width: auto;
  }
}

@media screen and (width <= 1450px) {
  .pf-m-grid-2xl.pf-v5-c-table {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
    --pf-v5-c-table__favorite--c-button--MarginTop: auto;
    --pf-v5-c-table__favorite--c-button--MarginRight: auto;
    --pf-v5-c-table__favorite--c-button--MarginBottom: auto;
    --pf-v5-c-table__favorite--c-button--MarginLeft: auto;
    border: none;
    display: grid;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > * {
    text-overflow: clip;
    white-space: normal;
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: visible;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__text {
    overflow: var(--pf-v5-c-table--cell--Overflow);
    text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
    white-space: var(--pf-v5-c-table--cell--WhiteSpace);
    width: auto;
    min-width: 0;
    max-width: none;
    position: relative;
  }

  .pf-m-grid-2xl.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
    display: none;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    display: block;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):first-of-type {
    border-block-start: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table.pf-m-compact > tbody:where(.pf-v5-c-table__tbody) {
    border-block-start: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: var(--pf-v5-c-table__tr--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):last-child, .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):last-of-type:not(:only-of-type) > tr:where(.pf-v5-c-table__tr) {
    border-block-end-width: var(--pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded:not(:last-of-type) {
    border-block-end: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    --pf-v5-c-table__expandable-row--after--BorderColor: transparent;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    grid-column-gap: var(--pf-v5-c-table__tr--responsive--GridColumnGap);
    grid-template-columns: 1fr;
    grid-auto-columns: max-content;
    height: auto;
    padding-block-start: var(--pf-v5-c-table__tr--responsive--PaddingTop);
    padding-block-end: var(--pf-v5-c-table__tr--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table__tr--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table__tr--responsive--PaddingRight);
    display: grid;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > * {
    padding-block-start: var(--pf-v5-c-table--cell--responsive--PaddingTop);
    padding-block-end: var(--pf-v5-c-table--cell--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--cell--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table--cell--responsive--PaddingRight);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > :first-child {
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--cell--first-child--responsive--PaddingTop);
  }

  .pf-m-grid-2xl.pf-v5-c-table.pf-m-compact {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingBottom);
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop);
    --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom);
    --pf-v5-c-table__check--responsive--MarginTop: var(--pf-v5-c-table--m-compact__check--responsive--MarginTop);
    --pf-v5-c-table__check--input--MarginTop: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
  }

  .pf-m-grid-2xl.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle .pf-v5-c-button {
    margin-block-end: var(--pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__icon > * {
    text-align: start;
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] {
    --pf-v5-c-table--cell--hidden-visible--Display: var(--pf-v5-c-table--m-grid--cell--hidden-visible--Display);
    grid-column-gap: var(--pf-v5-c-table-td--responsive--GridColumnGap);
    grid-column: 1;
    grid-template-columns: 1fr minmax(0, 1.5fr);
    align-items: start;
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]:before {
    text-align: start;
    content: attr(data-label);
    font-weight: bold;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :first-child {
    --pf-v5-c-table--cell--PaddingLeft: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr) > :last-child {
    --pf-v5-c-table--cell--PaddingRight: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingRight);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingBottom);
    --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingLeft);
    border: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) + tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    --pf-v5-c-table__tr--responsive--PaddingTop: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle {
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--after--Top: 100%;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    position: relative;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):after {
    content: "";
    border: 0;
    border-inline-start: var(--pf-v5-c-table__tbody--after--BorderLeftWidth) solid var(--pf-v5-c-table__tbody--after--BorderColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--after--border-width--base);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tbody:where(.pf-v5-c-table__tbody) {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) > tr:where(.pf-v5-c-table__tr) > :first-child:not(.pf-v5-c-table__check):after {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    background-color: #0000;
    width: auto;
    position: static;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row {
    --pf-v5-c-table--cell--responsive--PaddingTop: 0;
    --pf-v5-c-table--cell--responsive--PaddingRight: 0;
    --pf-v5-c-table--cell--responsive--PaddingBottom: 0;
    --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
    --pf-v5-c-table--cell--PaddingRight: 0;
    --pf-v5-c-table--cell--PaddingLeft: 0;
    max-height: var(--pf-v5-c-table__expandable-row--MaxHeight);
    box-shadow: none;
    border-block-end: none;
    display: block;
    overflow-y: auto;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row > * {
    display: block;
    position: static;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row.pf-m-expanded {
    border-block-start-color: var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row > :first-child:not(.pf-v5-c-table__check):after {
    content: none;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content, .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
    padding: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
    display: none;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
    padding-inline-start: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingRight);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
    --pf-v5-c-table__tbody--after--BorderLeftColor: transparent;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable, .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable > tr:where(.pf-v5-c-table__tr) {
    position: relative;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable > tr:where(.pf-v5-c-table__tr):after {
    content: "";
    width: var(--pf-v5-c-table__tbody--after__tr--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tbody--after__tr--BorderLeftColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-expanded {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-selected {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected:after {
    content: "";
    width: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__toggle, .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__check, .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__favorite, .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__action {
    width: auto;
    padding: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__toggle {
    grid-column: -1;
    grid-row-start: 20;
    justify-self: end;
    padding-inline-end: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__toggle:after {
    content: none;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__button {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check, .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__favorite, .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check {
    margin-block-start: var(--pf-v5-c-table__check--responsive--MarginTop);
    margin-inline-start: var(--pf-v5-c-table__check--responsive--MarginLeft);
    line-height: 1;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__favorite {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__favorite ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--action--MarginLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table__action--responsive--MarginLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check label {
    margin: 0;
    display: inline-block;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__favorite {
    margin-block-start: var(--pf-v5-c-table--m-grid__favorite--MarginTop);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__favorite ~ .pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    text-align: end;
    margin-block-start: var(--pf-v5-c-table--m-grid__action--MarginTop);
  }
}

@media screen and (width <= 1450px) and (width <= 576px) {
  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-inline-start: 0;
  }
}

@media screen and (width <= 1450px) {
  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
    grid-area: 2 / 2;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transition: var(--pf-v5-c-table__toggle__icon--Transition);
  }

  .pf-v5-c-button.pf-m-expanded > .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transform: rotate(var(--pf-v5-c-table__toggle--m-expanded__icon--Rotate));
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-nowrap {
    --pf-v5-c-table--cell--Overflow: auto;
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-fit-content {
    white-space: normal;
    width: auto;
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-truncate {
    --pf-v5-c-table--cell--MaxWidth: 100%;
  }

  .pf-m-grid-2xl.pf-v5-c-table [class*="pf-m-width"] {
    --pf-v5-c-table--cell--Width: auto;
  }
}

.pf-v5-c-table {
  --pf-v5-c-table__tree-view-main--indent--base: calc(var(--pf-v5-global--spacer--md) * 2 + var(--pf-v5-c-table__tree-view-icon--MinWidth));
  --pf-v5-c-table__tree-view-main--nested-indent--base: calc(var(--pf-v5-c-table__tree-view-main--indent--base)  - var(--pf-v5-global--spacer--md));
  --pf-v5-c-table__tree-view-main--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
  --pf-v5-c-table__tree-view-main--MarginLeft: calc(var(--pf-v5-c-table--cell--PaddingLeft) * -1);
  --pf-v5-c-table__tree-view-main--c-table__check--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tree-view-main--c-table__check--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tree-view-main--c-table__check--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tree-view-icon--MinWidth: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-table__tree-view-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-tree-view__toggle--Position: absolute;
  --pf-v5-c-table--m-tree-view__toggle--Left: var(--pf-v5-c-table__tree-view-main--PaddingLeft);
  --pf-v5-c-table--m-tree-view__toggle--TranslateX: -100%;
  --pf-v5-c-table--m-tree-view__toggle__toggle-icon--MinWidth: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-table--m-no-inset__tree-view-main--PaddingLeft: 0;
  --pf-v5-c-table--m-no-inset__tree-view-main--MarginLeft: 0;
}

.pf-v5-c-table.pf-m-tree-view > tbody > tr {
  --pf-v5-c-table--m-tree-view__toggle--Left: var(--pf-v5-c-table__tree-view-main--PaddingLeft);
}

.pf-v5-c-table.pf-m-tree-view > tbody > tr.pf-m-no-inset, .pf-v5-c-table.pf-m-tree-view.pf-m-no-inset {
  --pf-v5-c-table__tree-view-main--PaddingLeft: var(--pf-v5-c-table--m-no-inset__tree-view-main--PaddingLeft);
  --pf-v5-c-table__tree-view-main--MarginLeft: var(--pf-v5-c-table--m-no-inset__tree-view-main--MarginLeft);
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table__tree-view-main {
  text-align: start;
  cursor: pointer;
  align-items: baseline;
  min-width: 0;
  margin-inline-start: var(--pf-v5-c-table__tree-view-main--MarginLeft);
  padding-inline-start: var(--pf-v5-c-table__tree-view-main--PaddingLeft);
  display: flex;
  position: relative;
}

.pf-v5-c-table__tree-view-main > .pf-v5-c-table__toggle {
  transform: translateX(var(--pf-v5-c-table--m-tree-view__toggle--TranslateX));
  position: var(--pf-v5-c-table--m-tree-view__toggle--Position);
  inset-inline-start: var(--pf-v5-c-table--m-tree-view__toggle--Left);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-table__tree-view-main > .pf-v5-c-table__toggle {
  transform: translateX(calc(var(--pf-v5-c-table--m-tree-view__toggle--TranslateX) * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-table__tree-view-main > .pf-v5-c-table__toggle .pf-v5-c-table__toggle-icon {
  min-width: var(--pf-v5-c-table--m-tree-view__toggle__toggle-icon--MinWidth);
}

.pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
  margin-inline-end: var(--pf-v5-c-table__tree-view-main--c-table__check--MarginRight);
}

.pf-v5-c-table__tree-view-main > .pf-v5-c-table__check label {
  margin-block: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--pf-v5-c-table__tree-view-main--c-table__check--MarginRight) * -1);
  padding-inline-start: var(--pf-v5-c-table__tree-view-main--c-table__check--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-table__tree-view-main--c-table__check--PaddingRight);
}

.pf-v5-c-table__tree-view-text {
  display: flex;
}

.pf-v5-c-table__tree-view-icon {
  min-width: var(--pf-v5-c-table__tree-view-icon--MinWidth);
  margin-inline-end: var(--pf-v5-c-table__tree-view-icon--MarginRight);
}

.pf-v5-c-table__tree-view-details-toggle {
  display: none;
}

.pf-m-tree-view-grid.pf-v5-c-table {
  --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
  --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
  --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
  --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
  --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
  --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(.375rem * -1);
  --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(.375rem * -1);
  --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(.375rem * -1);
  --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(.375rem * -1);
}

.pf-m-tree-view-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
  outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
  border: none;
  position: relative;
}

.pf-m-tree-view-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr):before {
  content: "";
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  position: absolute;
  inset-block: 0;
  inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  inset-inline-end: 0;
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
  grid-template-columns: 1fr max-content;
  display: grid;
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell ~ .pf-v5-c-table__action {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
}

.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-text {
  padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
}

.pf-m-tree-view-grid.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th), .pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td) {
  display: none;
}

.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
  grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
  grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
  grid-column: 1;
  align-items: start;
}

.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] > * {
  grid-column: 2;
}

.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]:before {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  text-align: start;
  content: attr(data-label);
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
  padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
  display: grid;
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
  display: block;
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle, .pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__action {
  display: inline-block;
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__action {
  --pf-v5-c-table--cell--Width: auto;
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
  grid-area: 1 / 2;
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__action > .pf-v5-c-dropdown {
  margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
  margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
  order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
  margin-inline-start: auto;
  margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
  margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

@media screen and (width <= 768px) {
  .pf-m-tree-view-grid-md.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(.375rem * -1);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
    border: none;
    position: relative;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr):before {
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    grid-template-columns: 1fr max-content;
    display: grid;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell ~ .pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th), .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    grid-column: 1;
    align-items: start;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]:before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle, .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-area: 1 / 2;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__action > .pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

@media screen and (width <= 992px) {
  .pf-m-tree-view-grid-lg.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(.375rem * -1);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
    border: none;
    position: relative;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr):before {
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    grid-template-columns: 1fr max-content;
    display: grid;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell ~ .pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th), .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    grid-column: 1;
    align-items: start;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]:before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle, .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-area: 1 / 2;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__action > .pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

@media screen and (width <= 1200px) {
  .pf-m-tree-view-grid-xl.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(.375rem * -1);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
    border: none;
    position: relative;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr):before {
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    grid-template-columns: 1fr max-content;
    display: grid;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell ~ .pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th), .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    grid-column: 1;
    align-items: start;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]:before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle, .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-area: 1 / 2;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__action > .pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

@media screen and (width <= 1450px) {
  .pf-m-tree-view-grid-2xl.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(.375rem * -1);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
    border: none;
    position: relative;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr):before {
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
    position: absolute;
    inset-block: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    grid-template-columns: 1fr max-content;
    display: grid;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell ~ .pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th), .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    grid-column: 1;
    align-items: start;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]:before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle, .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-area: 1 / 2;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__action > .pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

.pf-v5-c-table {
  --pf-v5-c-table__sticky-cell--MinWidth--base: 12.5rem;
  --pf-v5-c-table__sticky-cell--MinWidth: var(--pf-v5-c-table__sticky-cell--MinWidth--base);
  --pf-v5-c-table__sticky-cell--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-table__sticky-cell--Right: auto;
  --pf-v5-c-table__sticky-cell--Left: auto;
  --pf-v5-c-table__sticky-cell--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__sticky-cell--m-border-right--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table__sticky-cell--m-border-right--before--BorderRightColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table__sticky-cell--m-border-left--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table__sticky-cell--m-border-left--before--BorderLeftColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table__sticky-cell--m-right--Right: 0;
  --pf-v5-c-table__sticky-cell--m-left--Left: 0;
  --pf-v5-c-table--m-sticky-header__sticky-cell--ZIndex: calc(var(--pf-v5-c-table__sticky-cell--ZIndex)  + 1);
}

.pf-v5-c-table .pf-v5-c-table__sticky-cell {
  --pf-v5-c-table--cell--Overflow: visible;
  --pf-v5-c-table--m-sticky-header--cell--ZIndex: var(--pf-v5-c-table--m-sticky-header__sticky-cell--ZIndex);
  z-index: var(--pf-v5-c-table__sticky-cell--ZIndex);
  min-width: var(--pf-v5-c-table__sticky-cell--MinWidth);
  background-color: var(--pf-v5-c-table__sticky-cell--BackgroundColor);
  background-clip: padding-box;
  position: sticky;
  inset-inline-start: var(--pf-v5-c-table__sticky-cell--Left);
  inset-inline-end: var(--pf-v5-c-table__sticky-cell--Right);
}

.pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-border-right:before {
  --pf-v5-c-table--cell--m-border-right--before--BorderRightWidth: var(--pf-v5-c-table__sticky-cell--m-border-right--before--BorderRightWidth);
  --pf-v5-c-table--cell--m-border-right--before--BorderRightColor: var(--pf-v5-c-table__sticky-cell--m-border-right--before--BorderRightColor);
}

.pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-border-left:before {
  --pf-v5-c-table--cell--m-border-left--before--BorderLeftWidth: var(--pf-v5-c-table__sticky-cell--m-border-left--before--BorderLeftWidth);
  --pf-v5-c-table--cell--m-border-left--before--BorderLeftColor: var(--pf-v5-c-table__sticky-cell--m-border-left--before--BorderLeftColor);
}

.pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-right, .pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-inline-end {
  --pf-v5-c-table__sticky-cell--Right: var(--pf-v5-c-table__sticky-cell--m-right--Right);
}

.pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-left, .pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-inline-start {
  --pf-v5-c-table__sticky-cell--Left: var(--pf-v5-c-table__sticky-cell--m-left--Left);
}

.pf-v5-c-scroll-outer-wrapper {
  --pf-v5-c-scroll-outer-wrapper--MinHeight: 25rem;
  --pf-v5-c-scroll-outer-wrapper--MaxHeight: 100%;
  min-height: var(--pf-v5-c-scroll-outer-wrapper--MinHeight);
  max-height: var(--pf-v5-c-scroll-outer-wrapper--MaxHeight);
  flex-direction: column;
  max-width: 100%;
  display: flex;
  overflow: hidden;
}

.pf-v5-c-scroll-inner-wrapper {
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  overflow: auto;
}

.pf-v5-c-inline-edit {
  --pf-v5-c-inline-edit__group--item--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-inline-edit__action--m-icon-group--item--MarginRight: 0;
  --pf-v5-c-inline-edit__group--m-footer--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-inline-edit__label--m-bold--FontWeight: var(--pf-v5-global--FontWeight--bold);
}

.pf-v5-c-inline-edit__group {
  align-items: baseline;
  display: flex;
}

.pf-v5-c-inline-edit__group > * {
  margin-inline-end: var(--pf-v5-c-inline-edit__group--item--MarginRight);
}

.pf-v5-c-inline-edit__group.pf-m-icon-group {
  --pf-v5-c-inline-edit__group--item--MarginRight: var(--pf-v5-c-inline-edit__action--m-icon-group--item--MarginRight);
}

.pf-v5-c-inline-edit__group.pf-m-footer {
  margin-block-start: var(--pf-v5-c-inline-edit__group--m-footer--MarginTop);
}

.pf-v5-c-inline-edit__group.pf-m-column {
  --pf-v5-c-inline-edit__group--item--MarginRight: 0;
  flex-direction: column;
}

.pf-v5-c-inline-edit__group > :last-child {
  --pf-v5-c-inline-edit__group--item--MarginRight: 0;
}

.pf-v5-c-inline-edit__input {
  flex: 1;
}

.pf-v5-c-inline-edit__action.pf-m-valid .pf-v5-c-button.pf-m-plain {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--Color);
}

.pf-v5-c-inline-edit__action.pf-m-valid .pf-v5-c-button.pf-m-plain:hover {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--hover--Color);
}

.pf-v5-c-inline-edit__input, .pf-v5-c-inline-edit__action, .pf-v5-c-inline-edit__group.pf-m-action-group {
  display: none;
}

.pf-v5-c-inline-edit__action.pf-m-enable-editable {
  display: inline-block;
}

.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__input, .pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__input {
  display: block;
}

.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__action, .pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__action {
  display: inline-block;
}

.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__group.pf-m-action-group, .pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__group.pf-m-action-group {
  display: inline-flex;
}

.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__value, .pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__action.pf-m-enable-editable, .pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__value, .pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__action.pf-m-enable-editable {
  display: none;
}

.pf-v5-c-inline-edit__label + .pf-v5-c-inline-edit__action.pf-m-enable > .pf-v5-c-button {
  margin-block-start: calc(var(--pf-v5-c-button--PaddingTop) * -1);
  margin-block-end: calc(var(--pf-v5-c-button--PaddingBottom) * -1);
}

.pf-v5-c-inline-edit__label.pf-m-bold {
  font-weight: var(--pf-v5-c-inline-edit__label--m-bold--FontWeight);
}

.pf-v5-c-inline-edit__editable-text br {
  display: none;
}

.pf-v5-c-form {
  --pf-v5-c-form--GridGap: var(--pf-v5-global--gutter--md);
  --pf-v5-c-form__group--m-action--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth: 9.375rem;
  --pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth: 1fr;
  --pf-v5-c-form--m-limit-width--MaxWidth: 55rem;
  --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: calc((((((var(--pf-v5-global--FontSize--md) * var(--pf-v5-global--LineHeight--md))  + (2 * var(--pf-v5-global--BorderWidth--sm)))  - var(--pf-v5-global--FontSize--md)) / 2)  + var(--pf-v5-global--FontSize--md))  - ((((var(--pf-v5-global--FontSize--sm) * var(--pf-v5-global--LineHeight--sm))  - var(--pf-v5-global--FontSize--sm)) / 2)  + var(--pf-v5-global--FontSize--sm))  + var(--pf-v5-global--BorderWidth--sm));
  --pf-v5-c-form__group-label--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop: 0;
  --pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY: calc(((((var(--pf-v5-global--FontSize--sm) * var(--pf-v5-global--LineHeight--sm))  - var(--pf-v5-global--FontSize--sm)) / 2)  + var(--pf-v5-global--FontSize--sm))  - ((((var(--pf-v5-global--FontSize--md) * var(--pf-v5-global--LineHeight--sm))  - var(--pf-v5-global--FontSize--md)) / 2)  + var(--pf-v5-global--FontSize--md)));
  --pf-v5-c-form__label--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-form__label--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-form__label--m-disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-form__label--hover--Cursor: pointer;
  --pf-v5-c-form__label--m-disabled--hover--Cursor: not-allowed;
  --pf-v5-c-form__label-text--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-form__label-required--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__label-required--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-form__label-required--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form__group-label-help--BackgroundColor: transparent;
  --pf-v5-c-form__group-label-help--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__group-label-help--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__group-label-help--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__group-label-help--PaddingLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__group-label-help--MarginTop: calc(var(--pf-v5-c-form__group-label-help--PaddingTop) * -1);
  --pf-v5-c-form__group-label-help--MarginRight: calc(var(--pf-v5-c-form__group-label-help--PaddingRight) * -1);
  --pf-v5-c-form__group-label-help--MarginBottom: calc(var(--pf-v5-c-form__group-label-help--PaddingBottom) * -1);
  --pf-v5-c-form__group-label-help--MarginLeft: calc(var(--pf-v5-c-form__group-label-help--PaddingLeft) * -1 + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-form__group-label-help--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-form__group-label-help--TranslateY: .125rem;
  --pf-v5-c-form__group-label-help--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-form__group-label-help--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form__group-label-help--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form__group-label--m-info--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__group-label-info--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-form--m-horizontal__group-label--m-info--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__group-control--m-inline--child--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__group-control__helper-text--MarginBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__group-control--m-stack--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__group-control--m-stack__helper-text--MarginTop: calc(var(--pf-v5-c-form__group-control--m-stack--Gap) * -1 + var(--pf-v5-c-form__helper-text--MarginTop--base));
  --pf-v5-c-form__actions--child--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__actions--child--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__actions--child--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__actions--child--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__actions--MarginTop: calc(var(--pf-v5-c-form__actions--child--MarginTop) * -1);
  --pf-v5-c-form__actions--MarginRight: calc(var(--pf-v5-c-form__actions--child--MarginRight) * -1);
  --pf-v5-c-form__actions--MarginBottom: calc(var(--pf-v5-c-form__actions--child--MarginBottom) * -1);
  --pf-v5-c-form__actions--MarginLeft: calc(var(--pf-v5-c-form__actions--child--MarginLeft) * -1);
  --pf-v5-c-form__helper-text--MarginTop--base: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__helper-text--MarginTop: var(--pf-v5-c-form__helper-text--MarginTop--base);
  --pf-v5-c-form__helper-text--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-form__helper-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form__helper-text-icon--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-form__helper-text-icon--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__helper-text--m-success--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-form__helper-text--m-warning--Color: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-form__helper-text--m-error--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form__section--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form__section--Gap: var(--pf-v5-global--gutter--md);
  --pf-v5-c-form__section-title--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-form__section-title--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-form__section-title--MarginBottom: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-form__field-group--border-width-base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form__field-group--BorderTopWidth: var(--pf-v5-c-form__field-group--border-width-base);
  --pf-v5-c-form__field-group--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-form__field-group--BorderBottomWidth: var(--pf-v5-c-form__field-group--border-width-base);
  --pf-v5-c-form__field-group--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-form__field-group--field-group--MarginTop: calc(var(--pf-v5-c-form--GridGap) * -1);
  --pf-v5-c-form__field-group--GridTemplateColumns--toggle: calc(var(--pf-v5-global--spacer--md) * 2 + var(--pf-v5-c-form__field-group-toggle-icon--MinWidth)  + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-form__field-group-toggle--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-form__field-group-toggle--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__field-group__field-group__field-group-toggle--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__field-group-header-toggle--BorderWidth--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form__field-group__field-group--field-group__field-group-toggle--after--BorderTopWidth: var(--pf-v5-c-form__field-group-header-toggle--BorderWidth--base);
  --pf-v5-c-form__field-group-toggle-button--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-form__field-group-toggle-button--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-form__field-group-toggle-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-form__field-group-toggle-icon--MinWidth: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-form__field-group-toggle-icon--Rotate: 0;
  --pf-v5-c-form__field-group--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-form__field-group-header--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-form__field-group-header--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-form__field-group-header--GridColumn: 1 / 3;
  --pf-v5-c-form__field-group__field-group__field-group-header--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__field-group__field-group__field-group-header--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__field-group-toggle--field-group-header--GridColumn: 2 / 3;
  --pf-v5-c-form__field-group__field-group--field-group__field-group-header--after--BorderTopWidth: var(--pf-v5-c-form__field-group-header-toggle--BorderWidth--base);
  --pf-v5-c-form__field-group-header-description--MarginTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__field-group-header-description--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-form__field-group-header-actions--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-form__field-group-header-actions--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-form__field-group-header-actions--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__field-group-body--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__field-group-body--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__field-group-body--Gap: var(--pf-v5-c-form--GridGap);
  --pf-v5-c-form__field-group-body--GridColumn: 2 / 3;
  --pf-v5-c-form__field-group__field-group__field-group-body--GridColumn: 1 / 3;
  --pf-v5-c-form__field-group__field-group__field-group-toggle--field-group-body--GridColumn: 2 / 3;
  --pf-v5-c-form__field-group-body__field-group--last-child--MarginBottom: calc(var(--pf-v5-c-form__field-group-body--PaddingBottom) * -1);
  gap: var(--pf-v5-c-form--GridGap);
  display: grid;
}

.pf-v5-c-form.pf-m-horizontal.pf-m-align-right .pf-v5-c-form__label {
  text-align: end;
}

@media screen and (width >= 768px) {
  .pf-v5-c-form.pf-m-horizontal {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group {
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
    display: grid;
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group-label.pf-m-info {
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    flex-direction: column;
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media screen and (width >= 0) {
  .pf-v5-c-form.pf-m-horizontal-on-xs {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group {
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
    display: grid;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group-label.pf-m-info {
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    flex-direction: column;
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media (width >= 576px) {
  .pf-v5-c-form.pf-m-horizontal-on-sm {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group {
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
    display: grid;
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group-label.pf-m-info {
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    flex-direction: column;
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media (width >= 768px) {
  .pf-v5-c-form.pf-m-horizontal-on-md {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group {
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
    display: grid;
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group-label.pf-m-info {
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    flex-direction: column;
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media (width >= 992px) {
  .pf-v5-c-form.pf-m-horizontal-on-lg {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group {
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
    display: grid;
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group-label.pf-m-info {
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    flex-direction: column;
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media (width >= 1200px) {
  .pf-v5-c-form.pf-m-horizontal-on-xl {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group {
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
    display: grid;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group-label.pf-m-info {
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    flex-direction: column;
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media (width >= 1450px) {
  .pf-v5-c-form.pf-m-horizontal-on-2xl {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group {
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
    display: grid;
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group-label.pf-m-info {
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    flex-direction: column;
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

.pf-v5-c-form.pf-m-limit-width {
  max-width: var(--pf-v5-c-form--m-limit-width--MaxWidth);
}

.pf-v5-c-form__group {
  min-width: 0;
}

.pf-v5-c-form__group.pf-m-action {
  margin-block-start: var(--pf-v5-c-form__group--m-action--MarginTop);
  overflow: hidden;
}

.pf-v5-c-form__section {
  gap: var(--pf-v5-c-form__section--Gap);
  display: grid;
}

.pf-v5-c-form__section + .pf-v5-c-form__group:not(.pf-m-action), .pf-v5-c-form__section:not(:first-child) {
  margin-block-start: var(--pf-v5-c-form__section--MarginTop);
}

.pf-v5-c-form__section-title {
  font-size: var(--pf-v5-c-form__section-title--FontSize);
  font-weight: var(--pf-v5-c-form__section-title--FontWeight);
  margin-block-end: var(--pf-v5-c-form__section-title--MarginBottom);
}

.pf-v5-c-form__group-label {
  --pf-v5-c-form__helper-text--MarginTop: 0;
  padding-block-end: var(--pf-v5-c-form__group-label--PaddingBottom);
}

.pf-v5-c-form__group-label.pf-m-info {
  gap: var(--pf-v5-c-form__group-label--m-info--Gap);
  align-items: flex-end;
  display: flex;
}

.pf-v5-c-form__group-label-main {
  flex-grow: 1;
}

.pf-v5-c-form__group-label-info {
  font-size: var(--pf-v5-c-form__group-label-info--FontSize);
}

.pf-v5-c-form__label {
  font-size: var(--pf-v5-c-form__label--FontSize);
  line-height: var(--pf-v5-c-form__label--LineHeight);
}

.pf-v5-c-form__label::selection {
  background-color: none;
}

.pf-v5-c-form__label:not(.pf-m-disabled):hover {
  cursor: var(--pf-v5-c-form__label--hover--Cursor);
}

.pf-v5-c-form__label.pf-m-disabled {
  color: var(--pf-v5-c-form__label--m-disabled--Color);
}

.pf-v5-c-form__label.pf-m-disabled:hover {
  cursor: var(--pf-v5-c-form__label--m-disabled--hover--Cursor);
}

.pf-v5-c-form__label-text {
  font-weight: var(--pf-v5-c-form__label-text--FontWeight);
}

.pf-v5-c-form__label-required {
  font-size: var(--pf-v5-c-form__label-required--FontSize);
  color: var(--pf-v5-c-form__label-required--Color);
  margin-inline-start: var(--pf-v5-c-form__label-required--MarginLeft);
}

.pf-v5-c-form__group-label-help {
  font-size: var(--pf-v5-c-form__group-label-help--FontSize);
  color: var(--pf-v5-c-form__group-label-help--Color);
  cursor: pointer;
  background-color: var(--pf-v5-c-form__group-label-help--BackgroundColor);
  transform: translateY(var(--pf-v5-c-form__group-label-help--TranslateY));
  border: 0;
  margin-block-start: var(--pf-v5-c-form__group-label-help--MarginTop);
  margin-block-end: var(--pf-v5-c-form__group-label-help--MarginBottom);
  margin-inline-start: var(--pf-v5-c-form__group-label-help--MarginLeft);
  margin-inline-end: var(--pf-v5-c-form__group-label-help--MarginRight);
  padding-block-start: var(--pf-v5-c-form__group-label-help--PaddingTop);
  padding-block-end: var(--pf-v5-c-form__group-label-help--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-form__group-label-help--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-form__group-label-help--PaddingRight);
  line-height: 1;
}

.pf-v5-c-form__group-label-help:hover {
  --pf-v5-c-form__group-label-help--Color: var(--pf-v5-c-form__group-label-help--hover--Color);
}

.pf-v5-c-form__group-label-help:focus-within {
  --pf-v5-c-form__group-label-help--Color: var(--pf-v5-c-form__group-label-help--focus--Color);
}

.pf-v5-c-form__group-control {
  min-width: 0;
}

.pf-v5-c-form__group-control.pf-m-inline {
  flex-flow: wrap;
  display: flex;
}

.pf-v5-c-form__group-control.pf-m-inline > * {
  margin-inline-end: var(--pf-v5-c-form__group-control--m-inline--child--MarginRight);
}

.pf-v5-c-form__group-control.pf-m-inline > :last-child {
  --pf-v5-c-form__group-control--m-inline--child--MarginRight: 0;
}

.pf-v5-c-form__group-control.pf-m-stack {
  --pf-v5-c-form__helper-text--MarginTop: var(--pf-v5-c-form__group-control--m-stack__helper-text--MarginTop);
  gap: var(--pf-v5-c-form__group-control--m-stack--Gap);
  display: grid;
}

.pf-v5-c-form__group-control .pf-v5-c-form__helper-text:first-child {
  --pf-v5-c-form__helper-text--MarginTop: 0;
  margin-block-end: var(--pf-v5-c-form__group-control__helper-text--MarginBottom);
}

.pf-v5-c-form__helper-text {
  font-size: var(--pf-v5-c-form__helper-text--FontSize);
  color: var(--pf-v5-c-form__helper-text--Color);
  margin-block-start: var(--pf-v5-c-form__helper-text--MarginTop);
}

.pf-v5-c-form__helper-text.pf-m-error {
  --pf-v5-c-form__helper-text--Color: var(--pf-v5-c-form__helper-text--m-error--Color);
}

.pf-v5-c-form__helper-text.pf-m-success {
  --pf-v5-c-form__helper-text--Color: var(--pf-v5-c-form__helper-text--m-success--Color);
}

.pf-v5-c-form__helper-text.pf-m-warning {
  --pf-v5-c-form__helper-text--Color: var(--pf-v5-c-form__helper-text--m-warning--Color);
}

.pf-v5-c-form__helper-text.pf-m-inactive {
  display: none;
}

.pf-v5-c-form__helper-text.pf-m-hidden {
  visibility: hidden;
  opacity: 0;
}

.pf-v5-c-form__helper-text-icon {
  font-size: var(--pf-v5-c-form__helper-text-icon--FontSize);
  margin-inline-end: var(--pf-v5-c-form__helper-text-icon--MarginRight);
}

.pf-v5-c-form__fieldset {
  border: 0;
}

.pf-v5-c-form__actions {
  flex-wrap: wrap;
  margin-block-start: var(--pf-v5-c-form__actions--MarginTop);
  margin-block-end: var(--pf-v5-c-form__actions--MarginBottom);
  margin-inline-start: var(--pf-v5-c-form__actions--MarginLeft);
  margin-inline-end: var(--pf-v5-c-form__actions--MarginRight);
  display: flex;
}

.pf-v5-c-form__actions > * {
  margin-block-start: var(--pf-v5-c-form__actions--child--MarginTop);
  margin-block-end: var(--pf-v5-c-form__actions--child--MarginBottom);
  margin-inline-start: var(--pf-v5-c-form__actions--child--MarginLeft);
  margin-inline-end: var(--pf-v5-c-form__actions--child--MarginRight);
}

.pf-v5-c-form__field-group {
  --pf-v5-c-form__field-group--BorderTopWidth: var(--pf-v5-c-form__field-group--border-width-base);
  grid-template-columns: minmax(var(--pf-v5-c-form__field-group--GridTemplateColumns--toggle), max-content) 1fr;
  border-block-start: var(--pf-v5-c-form__field-group--BorderTopWidth) solid var(--pf-v5-c-form__field-group--BorderTopColor);
  border-block-end: var(--pf-v5-c-form__field-group--BorderBottomWidth) solid var(--pf-v5-c-form__field-group--BorderBottomColor);
  display: grid;
}

.pf-v5-c-form__field-group:last-child {
  --pf-v5-c-form__field-group--BorderBottomWidth: 0;
}

.pf-v5-c-form__field-group + .pf-v5-c-form__field-group, .pf-v5-c-form__field-group:first-child {
  --pf-v5-c-form__field-group--BorderTopWidth: 0;
}

.pf-v5-c-form__field-group + .pf-v5-c-form__field-group {
  margin-block-start: var(--pf-v5-c-form__field-group--field-group--MarginTop);
}

.pf-v5-c-form__field-group .pf-v5-c-form__field-group {
  --pf-v5-c-form__field-group-body--GridColumn: var(--pf-v5-c-form__field-group__field-group__field-group-body--GridColumn);
  --pf-v5-c-form__field-group-toggle--PaddingTop: var(--pf-v5-c-form__field-group__field-group__field-group-toggle--PaddingTop);
  --pf-v5-c-form__field-group-header--PaddingTop: var(--pf-v5-c-form__field-group__field-group__field-group-header--PaddingTop);
  --pf-v5-c-form__field-group-header--PaddingBottom: var(--pf-v5-c-form__field-group__field-group__field-group-header--PaddingBottom);
  --pf-v5-c-form__field-group-body--PaddingTop: 0;
}

.pf-v5-c-form__field-group .pf-v5-c-form__field-group .pf-v5-c-form__field-group-toggle ~ .pf-v5-c-form__field-group-body {
  --pf-v5-c-form__field-group-body--GridColumn: var(--pf-v5-c-form__field-group__field-group__field-group-toggle--field-group-body--GridColumn);
}

.pf-v5-c-form__field-group.pf-m-expanded > .pf-v5-c-form__field-group-toggle {
  --pf-v5-c-form__field-group-toggle-icon--Rotate: var(--pf-v5-c-form__field-group--m-expanded__toggle-icon--Rotate);
}

.pf-v5-c-form__field-group-toggle {
  grid-area: 1 / 1 / 2 / 2;
  padding-block-start: var(--pf-v5-c-form__field-group-toggle--PaddingTop);
  padding-inline-end: var(--pf-v5-c-form__field-group-toggle--PaddingRight);
}

.pf-v5-c-form__field-group-toggle + .pf-v5-c-form__field-group-header {
  --pf-v5-c-form__field-group-header--GridColumn: var(--pf-v5-c-form__field-group-toggle--field-group-header--GridColumn);
}

.pf-v5-c-form__field-group-toggle-button {
  margin-block-start: var(--pf-v5-c-form__field-group-toggle-button--MarginTop);
  margin-block-end: var(--pf-v5-c-form__field-group-toggle-button--MarginBottom);
}

.pf-v5-c-form__field-group-toggle-icon {
  min-width: var(--pf-v5-c-form__field-group-toggle-icon--MinWidth);
  text-align: center;
  transition: var(--pf-v5-c-form__field-group-toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-form__field-group-toggle-icon--Rotate));
  display: inline-block;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-form__field-group-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-form__field-group-header {
  grid-row: 1 / 2;
  grid-column: var(--pf-v5-c-form__field-group-header--GridColumn);
  align-items: flex-start;
  padding-block-start: var(--pf-v5-c-form__field-group-header--PaddingTop);
  padding-block-end: var(--pf-v5-c-form__field-group-header--PaddingBottom);
  display: flex;
}

.pf-v5-c-form__field-group-header-main {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.pf-v5-c-form__field-group-header-title {
  display: flex;
}

.pf-v5-c-form__field-group-header-title-text {
  flex-grow: 1;
}

.pf-v5-c-form__field-group-header-description {
  color: var(--pf-v5-c-form__field-group-header-description--Color);
  margin-block-start: var(--pf-v5-c-form__field-group-header-description--MarginTop);
}

.pf-v5-c-form__field-group-header-actions {
  white-space: nowrap;
  margin-block-start: var(--pf-v5-c-form__field-group-header-actions--MarginTop);
  margin-block-end: var(--pf-v5-c-form__field-group-header-actions--MarginBottom);
  margin-inline-start: var(--pf-v5-c-form__field-group-header-actions--MarginLeft);
}

.pf-v5-c-form__field-group-body {
  grid-column: var(--pf-v5-c-form__field-group-body--GridColumn);
  gap: var(--pf-v5-c-form__field-group-body--Gap);
  padding-block-start: var(--pf-v5-c-form__field-group-body--PaddingTop);
  padding-block-end: var(--pf-v5-c-form__field-group-body--PaddingBottom);
  display: grid;
}

.pf-v5-c-form__field-group-body > .pf-v5-c-form__field-group:first-child {
  --pf-v5-c-form__field-group-toggle--PaddingTop: 0;
  --pf-v5-c-form__field-group-header--PaddingTop: 0;
}

.pf-v5-c-form__field-group-body > .pf-v5-c-form__field-group:last-child {
  margin-block-end: var(--pf-v5-c-form__field-group-body__field-group--last-child--MarginBottom);
}

:where(.pf-v5-theme-dark) .pf-v5-c-form {
  --pf-v5-c-form__helper-text--m-success--Color: var(--pf-v5-global--success-color--100);
}

.pf-v5-c-form-control {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
  --pf-v5-c-form-control--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-form-control--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-form-control--Resize: none;
  --pf-v5-c-form-control--OutlineOffset: -2px;
  --pf-v5-c-form-control--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderBottomWidth: 0;
  --pf-v5-c-form-control--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--before--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--before--BorderBottomColor: transparent;
  --pf-v5-c-form-control--before--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-form-control--Width: 100%;
  --pf-v5-c-form-control--inset--base: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control--PaddingLeft: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control--hover--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--focus--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-expanded--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--m-placeholder--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-form-control--m-placeholder--child--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--m-disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-form-control--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-disabled--after--BorderColor: transparent;
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--m-readonly--m-plain--BackgroundColor: transparent;
  --pf-v5-c-form-control--m-readonly--m-plain--inset--base: 0;
  --pf-v5-c-form-control--m-readonly--m-plain--OutlineOffset: 0;
  --pf-v5-c-form-control--m-success--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-success--after--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-form-control--m-success--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-warning--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-warning--after--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-form-control--m-warning--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-error--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-error--after--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form-control--m-error--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-error--icon--width: var(--pf-v5-c-form-control--FontSize);
  --pf-v5-c-form-control--m-icon--PaddingRight: calc(var(--pf-v5-c-form-control--inset--base)  + var(--pf-v5-c-form-control--m-icon--icon--width)  + var(--pf-v5-c-form-control--m-icon--icon--spacer));
  --pf-v5-c-form-control--m-icon--icon--width: var(--pf-v5-c-form-control--FontSize);
  --pf-v5-c-form-control--m-icon--icon--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--m-icon--icon--PaddingRight: calc(var(--pf-v5-c-form-control--inset--base)  + var(--pf-v5-c-form-control--m-error--icon--width)  + var(--pf-v5-c-form-control--m-icon--icon--spacer)  + var(--pf-v5-c-form-control--m-icon--icon--width)  + var(--pf-v5-c-form-control--m-icon--icon--spacer));
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form-control__select--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control__select--m-success--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control__select--m-warning--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control__select--m-error--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control--textarea--Width: var(--pf-v5-c-form-control--Width);
  --pf-v5-c-form-control--textarea--Height: auto;
  --pf-v5-c-form-control__icon--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control__icon--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-form-control--m-success__icon--m-status--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-form-control--m-warning__icon--m-status--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-form-control--m-error__icon--m-status--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form-control__utilities--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control__utilities--PaddingTop: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__utilities--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control__toggle-icon--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--PaddingLeft: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--m-disabled__toggle-icon--Color: var(--pf-v5-global--disabled-color--100);
  color: var(--pf-v5-c-form-control--Color);
  column-gap: var(--pf-v5-c-form-control--ColumnGap);
  width: var(--pf-v5-c-form-control--Width);
  font-size: var(--pf-v5-c-form-control--FontSize);
  line-height: var(--pf-v5-c-form-control--LineHeight);
  resize: var(--pf-v5-c-form-control--Resize);
  background-color: var(--pf-v5-c-form-control--BackgroundColor);
  grid-template-columns: 1fr auto;
  align-items: start;
  display: grid;
  position: relative;
}

.pf-v5-c-form-control:before, .pf-v5-c-form-control:after {
  pointer-events: none;
  content: "";
  position: absolute;
  inset: 0;
}

.pf-v5-c-form-control:before {
  border-style: var(--pf-v5-c-form-control--before--BorderStyle, solid);
  border-block-start-color: var(--pf-v5-c-form-control--before--BorderTopColor);
  border-block-start-width: var(--pf-v5-c-form-control--before--BorderTopWidth);
  border-block-end-color: var(--pf-v5-c-form-control--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-form-control--before--BorderBottomWidth);
  border-inline-start-color: var(--pf-v5-c-form-control--before--BorderLeftColor);
  border-inline-start-width: var(--pf-v5-c-form-control--before--BorderLeftWidth);
  border-inline-end-color: var(--pf-v5-c-form-control--before--BorderRightColor);
  border-inline-end-width: var(--pf-v5-c-form-control--before--BorderRightWidth);
}

.pf-v5-c-form-control:after {
  border-block-end: var(--pf-v5-c-form-control--after--BorderBottomWidth) var(--pf-v5-c-form-control--after--BorderStyle, solid) var(--pf-v5-c-form-control--after--BorderBottomColor);
}

.pf-v5-c-form-control > :is(input, select, textarea) {
  color: var(--pf-v5-c-form-control--Color);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #0000;
  border: none;
  grid-area: 1 / 1 / 2 / -1;
  padding-block-start: var(--pf-v5-c-form-control--PaddingTop);
  padding-block-end: var(--pf-v5-c-form-control--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-form-control--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-form-control--PaddingRight);
}

.pf-v5-c-form-control > :is(input, select, textarea):focus {
  outline-offset: var(--pf-v5-c-form-control--OutlineOffset);
}

.pf-v5-c-form-control > ::placeholder {
  color: var(--pf-v5-c-form-control--m-placeholder--Color);
}

.pf-v5-c-form-control > :is(input, select) {
  text-overflow: ellipsis;
}

.pf-v5-c-form-control.pf-m-readonly {
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-c-form-control--m-readonly--BackgroundColor);
}

.pf-v5-c-form-control.pf-m-readonly:not(.pf-m-success, .pf-m-warning, .pf-m-error) {
  --pf-v5-c-form-control--hover--after--BorderBottomColor: var(--pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor);
  --pf-v5-c-form-control--focus--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-readonly--focus--after--BorderBottomWidth);
  --pf-v5-c-form-control--focus--after--BorderBottomColor: var(--pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor);
}

.pf-v5-c-form-control.pf-m-readonly.pf-m-plain {
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-c-form-control--m-readonly--m-plain--BackgroundColor);
  --pf-v5-c-form-control--inset--base: var(--pf-v5-c-form-control--m-readonly--m-plain--inset--base);
  --pf-v5-c-form-control--before--BorderStyle: none;
  --pf-v5-c-form-control--after--BorderStyle: none;
  --pf-v5-c-form-control--OutlineOffset: var(--pf-v5-c-form-control--m-readonly--m-plain--OutlineOffset);
}

.pf-v5-c-form-control:hover {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--hover--after--BorderBottomColor);
}

.pf-v5-c-form-control:focus-within {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--focus--after--BorderBottomColor);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--focus--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-expanded {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-expanded--after--BorderBottomColor);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-expanded--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-disabled {
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-c-form-control--m-disabled--BackgroundColor);
  --pf-v5-c-form-control--Color: var(--pf-v5-c-form-control--m-disabled--Color);
  --pf-v5-c-form-control--m-placeholder--Color: var(--pf-v5-c-form-control--m-disabled--Color);
  --pf-v5-c-form-control__toggle-icon--Color: var(--pf-v5-c-form-control--m-disabled__toggle-icon--Color);
  --pf-v5-c-form-control--before--BorderStyle: none;
  --pf-v5-c-form-control--after--BorderStyle: none;
  cursor: not-allowed;
}

.pf-v5-c-form-control.pf-m-error {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-error--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-error--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-error__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-error--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-error--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-error.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}

.pf-v5-c-form-control.pf-m-success {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-success--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-success--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-success__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-success--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-success--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-success.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}

.pf-v5-c-form-control.pf-m-warning {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-warning--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-warning--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-warning__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-warning--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-warning--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-warning.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}

.pf-v5-c-form-control.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--PaddingRight);
}

.pf-v5-c-form-control > select {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control__select--PaddingRight);
  --pf-v5-c-form-control--PaddingLeft: var(--pf-v5-c-form-control__select--PaddingLeft);
  background-color: var(--pf-v5-c-form-control--BackgroundColor);
}

@-moz-document url-prefix() {
  .pf-v5-c-form-control > select {
    --pf-v5-c-form-control--PaddingRight: calc(var(--pf-v5-c-form-control__select--PaddingRight)  - 1px);
    --pf-v5-c-form-control--PaddingLeft: calc(var(--pf-v5-c-form-control__select--PaddingLeft)  - 4px);
  }
}

.pf-v5-c-form-control.pf-m-placeholder > select {
  --pf-v5-c-form-control--Color: var(--pf-v5-c-form-control--m-placeholder--Color);
}

.pf-v5-c-form-control.pf-m-placeholder > select * {
  color: var(--pf-v5-c-form-control--m-placeholder--child--Color);
}

.pf-v5-c-form-control.pf-m-placeholder > select :disabled {
  color: revert;
}

.pf-v5-c-form-control > textarea {
  vertical-align: bottom;
  resize: none;
  width: 100%;
  height: 100%;
}

.pf-v5-c-form-control.pf-m-resize-vertical {
  --pf-v5-c-form-control--Resize: vertical;
  overflow: auto;
}

.pf-v5-c-form-control.pf-m-resize-horizontal {
  --pf-v5-c-form-control--Resize: horizontal;
  overflow: auto;
}

.pf-v5-c-form-control.pf-m-resize-both {
  --pf-v5-c-form-control--Resize: both;
  overflow: auto;
}

.pf-v5-c-form-control__icon {
  color: var(--pf-v5-c-form-control__icon--Color);
  padding-block-start: var(--pf-v5-c-form-control__icon--PaddingTop);
}

.pf-v5-c-form-control__icon.pf-m-status {
  --pf-v5-c-form-control__icon--Color: var(--pf-v5-c-form-control__icon--m-status--Color);
}

.pf-v5-c-form-control__toggle-icon {
  color: var(--pf-v5-c-form-control__toggle-icon--Color);
  pointer-events: none;
  grid-area: 1 / 3 / 2;
  padding-block-start: var(--pf-v5-c-form-control__toggle-icon--PaddingTop);
  padding-inline-start: var(--pf-v5-c-form-control__toggle-icon--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-form-control__toggle-icon--PaddingRight);
}

.pf-v5-c-form-control__utilities {
  gap: var(--pf-v5-c-form-control__utilities--Gap);
  pointer-events: none;
  flex-wrap: nowrap;
  grid-area: 1 / 2 / 2;
  padding-inline-end: var(--pf-v5-c-form-control__utilities--PaddingRight);
  display: flex;
}

:where(.pf-v5-theme-dark) .pf-v5-c-form-control {
  --pf-v5-c-form-control--before--BorderTopColor: transparent;
  --pf-v5-c-form-control--before--BorderRightColor: transparent;
  --pf-v5-c-form-control--before--BorderLeftColor: transparent;
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-form-control--m-disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-form-control--m-disabled__toggle-icon--Color: var(--pf-v5-global--disabled-color--300);
  color: var(--pf-v5-global--Color--100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-form-control::-webkit-calendar-picker-indicator {
  filter: invert();
}

.pf-v5-c-input-group {
  --pf-v5-c-input-group--child--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-input-group__item--offset: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-input-group__item--MarginLeft: calc(var(--pf-v5-c-input-group__item--offset) * -1);
  --pf-v5-c-input-group__item--BorderWidth--base: var(--pf-v5-c-input-group__item--offset);
  --pf-v5-c-input-group__item--BorderColor--base: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-input-group__item--BorderColor--accent: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-input-group__item--BackgroundColor: transparent;
  --pf-v5-c-input-group__item--m-box--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-input-group__item--m-box--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-input-group__item--m-box--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-input-group__item--m-box--BorderWidth: var(--pf-v5-c-input-group__item--offset);
  --pf-v5-c-input-group__item--m-box--BorderTopColor: var(--pf-v5-c-input-group__item--BorderColor--base);
  --pf-v5-c-input-group__item--m-box--BorderRightColor: var(--pf-v5-c-input-group__item--BorderColor--base);
  --pf-v5-c-input-group__item--m-box--BorderBottomColor: var(--pf-v5-c-input-group__item--BorderColor--accent);
  --pf-v5-c-input-group__item--m-box--BorderLeftColor: var(--pf-v5-c-input-group__item--BorderColor--base);
  --pf-v5-c-input-group__item--m-plain--BackgroundColor: transparent;
  --pf-v5-c-input-group__text--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-input-group__text--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-input-group__item--m-disabled__text--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-input-group__item--m-disabled--BorderBottomColor: transparent;
  --pf-v5-c-input-group__item--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  width: 100%;
  display: flex;
}

.pf-v5-c-input-group__item {
  min-width: var(--pf-v5-c-input-group__item--MinWidth, revert);
  max-width: var(--pf-v5-c-input-group__item--MaxWidth, revert);
  background-color: var(--pf-v5-c-input-group__item--BackgroundColor);
  border-block-start-color: var(--pf-v5-c-input-group__item--m-box--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-input-group__item--m-box--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-input-group__item--m-box--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-input-group__item--m-box--BorderRightColor);
  display: flex;
  position: relative;
}

.pf-v5-c-input-group__item:where(:not(:first-child)) {
  margin-inline-start: var(--pf-v5-c-input-group__item--MarginLeft);
}

.pf-v5-c-input-group__item.pf-m-box {
  --pf-v5-c-input-group__item--BackgroundColor: var(--pf-v5-c-input-group__item--m-box--BackgroundColor);
  border: var(--pf-v5-c-input-group__item--m-box--BorderWidth) solid;
  border-block-start-color: var(--pf-v5-c-input-group__item--m-box--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-input-group__item--m-box--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-input-group__item--m-box--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-input-group__item--m-box--BorderRightColor);
  padding-inline-start: var(--pf-v5-c-input-group__item--m-box--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-input-group__item--m-box--PaddingRight);
}

.pf-v5-c-input-group__item.pf-m-plain {
  --pf-v5-c-input-group__item--MarginLeft: 0;
  --pf-v5-c-input-group__item--BackgroundColor: var(--pf-v5-c-input-group__item--m-plain--BackgroundColor);
  border: none;
}

.pf-v5-c-input-group__item.pf-m-disabled {
  --pf-v5-c-input-group__item--BackgroundColor: var(--pf-v5-c-input-group__item--m-disabled--BackgroundColor);
  --pf-v5-c-input-group__item--m-box--BorderBottomColor: var(--pf-v5-c-input-group__item--m-disabled--BorderBottomColor);
  --pf-v5-c-input-group__text--Color: var(--pf-v5-c-input-group__item--m-disabled__text--Color);
}

.pf-v5-c-input-group__item.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-c-input-group__text {
  font-size: var(--pf-v5-c-input-group__text--FontSize);
  color: var(--pf-v5-c-input-group__text--Color);
  align-self: center;
}

label.pf-v5-c-input-group__text {
  cursor: pointer;
}

:where(.pf-v5-theme-dark) .pf-v5-c-input-group {
  --pf-v5-c-input-group__item--BorderLeftWidth: var(--pf-v5-c-input-group__item--BorderWidth--base);
  --pf-v5-c-input-group__item--BorderLeftColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-input-group__item--m-box--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-input-group__item--m-box--BorderTopColor: transparent;
  --pf-v5-c-input-group__item--m-box--BorderRightColor: transparent;
  --pf-v5-c-input-group__item--m-box--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-input-group__item--m-box--BorderLeftColor: transparent;
  --pf-v5-c-input-group__item--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-input-group__item--m-disabled--BorderBottomColor: transparent;
  --pf-v5-c-input-group__item--m-disabled__text--Color: var(--pf-v5-global--disabled-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-input-group > * + * {
  --pf-v5-c-input-group__item--m-box--BorderLeftColor: var(--pf-v5-c-input-group__item--BorderLeftColor);
  border-inline-start: var(--pf-v5-c-input-group__item--BorderLeftWidth) solid var(--pf-v5-c-input-group__item--BorderLeftColor);
}

.pf-v5-c-helper-text {
  --pf-v5-c-helper-text--Gap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-helper-text--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-helper-text__item-icon--m-indeterminate--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text__item-text--m-indeterminate--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text__item-icon--m-warning--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-helper-text__item-text--m-warning--Color: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-helper-text__item-icon--m-success--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-helper-text__item-text--m-success--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-helper-text__item-icon--m-error--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-helper-text__item-text--m-error--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-helper-text--m-dynamic__item-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-helper-text--m-dynamic__item-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-helper-text--m-dynamic--m-indeterminate__item-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text--m-dynamic--m-indeterminate__item-text--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text--m-dynamic--m-warning__item-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-helper-text--m-dynamic--m-warning__item-text--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text--m-dynamic--m-success__item-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-helper-text--m-dynamic--m-success__item-text--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text--m-dynamic--m-error__item-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-helper-text--m-dynamic--m-error__item-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-helper-text__item-icon--MarginRight: var(--pf-v5-global--spacer--xs);
  gap: var(--pf-v5-c-helper-text--Gap);
  font-size: var(--pf-v5-c-helper-text--FontSize);
  display: grid;
}

.pf-v5-c-helper-text.pf-m-hidden {
  visibility: hidden;
  opacity: 0;
}

.pf-v5-c-helper-text__item {
  display: flex;
}

.pf-v5-c-helper-text__item.pf-m-indeterminate {
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-c-helper-text__item-icon--m-indeterminate--Color);
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-c-helper-text__item-text--m-indeterminate--Color);
  --pf-v5-c-helper-text--m-dynamic__item-icon--Color: var(--pf-v5-c-helper-text--m-dynamic--m-indeterminate__item-icon--Color);
  --pf-v5-c-helper-text--m-dynamic__item-text--Color: var(--pf-v5-c-helper-text--m-dynamic--m-indeterminate__item-text--Color);
}

.pf-v5-c-helper-text__item.pf-m-warning {
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-c-helper-text__item-icon--m-warning--Color);
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-c-helper-text__item-text--m-warning--Color);
  --pf-v5-c-helper-text--m-dynamic__item-icon--Color: var(--pf-v5-c-helper-text--m-dynamic--m-warning__item-icon--Color);
  --pf-v5-c-helper-text--m-dynamic__item-text--Color: var(--pf-v5-c-helper-text--m-dynamic--m-warning__item-text--Color);
}

.pf-v5-c-helper-text__item.pf-m-success {
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-c-helper-text__item-icon--m-success--Color);
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-c-helper-text__item-text--m-success--Color);
  --pf-v5-c-helper-text--m-dynamic__item-icon--Color: var(--pf-v5-c-helper-text--m-dynamic--m-success__item-icon--Color);
  --pf-v5-c-helper-text--m-dynamic__item-text--Color: var(--pf-v5-c-helper-text--m-dynamic--m-success__item-text--Color);
}

.pf-v5-c-helper-text__item.pf-m-error {
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-c-helper-text__item-icon--m-error--Color);
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-c-helper-text__item-text--m-error--Color);
  --pf-v5-c-helper-text--m-dynamic__item-icon--Color: var(--pf-v5-c-helper-text--m-dynamic--m-error__item-icon--Color);
  --pf-v5-c-helper-text--m-dynamic__item-text--Color: var(--pf-v5-c-helper-text--m-dynamic--m-error__item-text--Color);
}

.pf-v5-c-helper-text__item.pf-m-dynamic {
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-c-helper-text--m-dynamic__item-text--Color);
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-c-helper-text--m-dynamic__item-icon--Color);
}

.pf-v5-c-helper-text__item-icon {
  color: var(--pf-v5-c-helper-text__item-icon--Color);
  margin-inline-end: var(--pf-v5-c-helper-text__item-icon--MarginRight);
}

.pf-v5-c-helper-text__item-text {
  color: var(--pf-v5-c-helper-text__item-text--Color);
}

:where(.pf-v5-theme-dark) .pf-v5-c-helper-text {
  --pf-v5-c-helper-text__item-text--m-success--Color: var(--pf-v5-global--success-color--100);
}

.pf-v5-c-login__footer, .pf-v5-c-login__header {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--light-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--light-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--light-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--light-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--light);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--light);
}

.pf-v5-c-login__footer .pf-v5-c-button, .pf-v5-c-login__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--dark-100);
}

.pf-v5-c-login {
  --pf-v5-c-login--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login__container--xl--GridColumnGap: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-login__container--MaxWidth: 31.25rem;
  --pf-v5-c-login__container--xl--MaxWidth: none;
  --pf-v5-c-login__container--PaddingLeft: 6.125rem;
  --pf-v5-c-login__container--PaddingRight: 6.125rem;
  --pf-v5-c-login__container--xl--GridTemplateColumns: 34rem minmax(auto, 34rem);
  --pf-v5-c-login__header--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__header--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__header--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__header--xl--MarginBottom: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__header--xl--MarginTop: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-login__header--c-brand--MarginBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login__header--c-brand--xl--MarginBottom: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-login__main--MarginBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login__main--BoxShadow: var(--pf-v5-global--BoxShadow--xl);
  --pf-v5-c-login__main-header--PaddingTop: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main-header--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-header--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-header--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-header--md--PaddingRight: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main-header--md--PaddingLeft: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main-header--ColumnGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-header--RowGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-header-desc--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-login__main-header-desc--md--MarginBottom: 0;
  --pf-v5-c-login__main-header-desc--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-login__main-body--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-body--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-body--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-body--md--PaddingRight: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main-body--md--PaddingLeft: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main-footer--PaddingBottom: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-login__main-footer--c-title--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-footer-links--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-login__main-footer-links--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-login__main-footer-links--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-footer-links--PaddingLeft: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-login__main-footer-links-item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-footer-links-item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-footer-links-item--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-login__main-footer-links-item-link-svg--Fill: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-login__main-footer-links-item-link-svg--Width: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-login__main-footer-links-item-link-svg--Height: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-login__main-footer-links-item-link-svg--hover--Fill: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-login__main-footer-band--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login__main-footer-band--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-footer-band--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login__main-footer-band--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-footer-band--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-login__main-footer-band--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-login__main-footer-band-item--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__footer--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__footer--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__footer--c-list--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__footer--c-list--xl--PaddingTop: var(--pf-v5-global--spacer--2xl);
  justify-content: center;
  min-height: 100vh;
  padding-block-start: var(--pf-v5-c-login--PaddingTop);
  padding-block-end: var(--pf-v5-c-login--PaddingBottom);
  display: flex;
}

@media (width >= 1200px) {
  .pf-v5-c-login {
    --pf-v5-c-login__container--MaxWidth: var(--pf-v5-c-login__container--xl--MaxWidth);
  }
}

@media (width >= 576px) {
  .pf-v5-c-login {
    --pf-v5-c-login__header--PaddingRight: 0;
    --pf-v5-c-login__header--PaddingLeft: 0;
  }
}

@media (width >= 1200px) {
  .pf-v5-c-login {
    --pf-v5-c-login__header--MarginBottom: var(--pf-v5-c-login__header--xl--MarginBottom);
    --pf-v5-c-login__header--c-brand--MarginBottom: var(--pf-v5-c-login__header--c-brand--xl--MarginBottom);
    --pf-v5-c-login__main--MarginBottom: 0;
  }
}

@media (width >= 768px) {
  .pf-v5-c-login {
    --pf-v5-c-login__main-header--PaddingRight: var(--pf-v5-c-login__main-header--md--PaddingRight);
    --pf-v5-c-login__main-header--PaddingLeft: var(--pf-v5-c-login__main-header--md--PaddingLeft);
    --pf-v5-c-login__main-header-desc--MarginBottom: var(--pf-v5-c-login__main-header-desc--md--MarginBottom);
    --pf-v5-c-login__main-body--PaddingRight: var(--pf-v5-c-login__main-body--md--PaddingRight);
    --pf-v5-c-login__main-body--PaddingLeft: var(--pf-v5-c-login__main-body--md--PaddingLeft);
  }
}

@media (width >= 576px) {
  .pf-v5-c-login {
    --pf-v5-c-login__footer--PaddingRight: 0;
    --pf-v5-c-login__footer--PaddingLeft: 0;
  }
}

@media (width >= 1200px) {
  .pf-v5-c-login {
    --pf-v5-c-login__footer--c-list--PaddingTop: var(--pf-v5-c-login__footer--c-list--xl--PaddingTop);
  }
}

@media (width >= 576px) {
  .pf-v5-c-login {
    align-items: center;
  }
}

.pf-v5-c-login__container {
  max-width: var(--pf-v5-c-login__container--MaxWidth);
  width: 100%;
}

@media (width >= 1200px) {
  .pf-v5-c-login__container {
    grid-template-areas: "main header"
                         "main footer"
                         "main .";
    grid-template-columns: var(--pf-v5-c-login__container--xl--GridTemplateColumns);
    grid-column-gap: var(--pf-v5-c-login__container--xl--GridColumnGap);
    justify-content: center;
    padding-inline-start: var(--pf-v5-c-login__container--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-login__container--PaddingRight);
    display: grid;
  }
}

.pf-v5-c-login__header {
  color: var(--pf-v5-global--Color--100);
  grid-area: header;
  padding-inline-start: var(--pf-v5-c-login__header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__header--PaddingRight);
}

@media (width >= 1200px) {
  .pf-v5-c-login__header {
    margin-block-start: var(--pf-v5-c-login__header--xl--MarginTop);
  }
}

.pf-v5-c-login__header .pf-v5-c-brand {
  margin-block-end: var(--pf-v5-c-login__header--c-brand--MarginBottom);
}

.pf-v5-c-login__main {
  background-color: var(--pf-v5-c-login__main--BackgroundColor);
  box-shadow: var(--pf-v5-c-login__main--BoxShadow);
  grid-area: main;
  align-self: start;
  margin-block-end: var(--pf-v5-c-login__main--MarginBottom);
}

.pf-v5-c-login__main > :first-child:not(.pf-v5-c-login__main-header) {
  padding-block-start: var(--pf-v5-c-login__main-header--PaddingTop);
}

.pf-v5-c-login__main > :last-child:not(.pf-v5-c-login__main-footer) {
  padding-block-end: var(--pf-v5-c-login__main-footer--PaddingBottom);
}

.pf-v5-c-login__main-header {
  row-gap: var(--pf-v5-c-login__main-header--RowGap);
  column-gap: var(--pf-v5-c-login__main-header--ColumnGap);
  grid-template-columns: 100%;
  align-items: center;
  padding-block-start: var(--pf-v5-c-login__main-header--PaddingTop);
  padding-block-end: var(--pf-v5-c-login__main-header--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-login__main-header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__main-header--PaddingRight);
  display: grid;
}

@media (width >= 768px) {
  .pf-v5-c-login__main-header {
    grid-template-columns: 1fr auto;
  }
}

.pf-v5-c-login__main-header-utilities, .pf-v5-c-login__main-header .pf-v5-c-dropdown {
  grid-area: auto;
}

@media (width >= 768px) {
  .pf-v5-c-login__main-header-utilities, .pf-v5-c-login__main-header .pf-v5-c-dropdown {
    grid-area: 1 / 2 / auto / 3;
  }
}

.pf-v5-c-login__main-header-utilities .pf-v5-c-dropdown {
  grid-area: auto;
}

.pf-v5-c-login__main-header-desc {
  font-size: var(--pf-v5-c-login__main-header-desc--FontSize);
  grid-column: 1 / -1;
  margin-block-end: var(--pf-v5-c-login__main-header-desc--MarginBottom);
}

.pf-v5-c-login__main-body {
  padding-block-end: var(--pf-v5-c-login__main-body--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-login__main-body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__main-body--PaddingRight);
}

.pf-v5-c-login__main-footer {
  flex-wrap: wrap;
  display: flex;
}

.pf-v5-c-login__main-footer .pf-v5-c-title {
  text-align: center;
  margin-block-end: var(--pf-v5-c-login__main-footer--c-title--MarginBottom);
}

.pf-v5-c-login__main-footer > * {
  flex-basis: 100%;
}

.pf-v5-c-login__main-footer-links {
  flex-wrap: wrap;
  justify-content: center;
  padding-block-start: var(--pf-v5-c-login__main-footer-links--PaddingTop);
  padding-block-end: var(--pf-v5-c-login__main-footer-links--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-login__main-footer-links--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__main-footer-links--PaddingRight);
  display: flex;
}

.pf-v5-c-login__main-footer-links-item {
  margin-block-end: var(--pf-v5-c-login__main-footer-links-item--MarginBottom);
  padding-inline-start: var(--pf-v5-c-login__main-footer-links-item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__main-footer-links-item--PaddingRight);
}

.pf-v5-c-login__main-footer-links-item-link svg {
  max-width: var(--pf-v5-c-login__main-footer-links-item-link-svg--Width);
  max-height: var(--pf-v5-c-login__main-footer-links-item-link-svg--Height);
  fill: var(--pf-v5-c-login__main-footer-links-item-link-svg--Fill);
  width: 100%;
  height: 100%;
}

.pf-v5-c-login__main-footer-links-item-link:hover svg {
  fill: var(--pf-v5-c-login__main-footer-links-item-link-svg--hover--Fill);
}

.pf-v5-c-login__main-footer-band {
  text-align: center;
  border-block-start: var(--pf-v5-c-login__main-footer-band--BorderTopWidth) solid var(--pf-v5-c-login__main-footer-band--BorderTopColor);
  padding-block-start: var(--pf-v5-c-login__main-footer-band--PaddingTop);
  padding-block-end: var(--pf-v5-c-login__main-footer-band--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-login__main-footer-band--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__main-footer-band--PaddingRight);
}

.pf-v5-c-login__main-footer-band > * + * {
  padding-block-start: var(--pf-v5-c-login__main-footer-band-item--PaddingTop);
}

.pf-v5-c-login__footer {
  color: var(--pf-v5-global--Color--100);
  grid-area: footer;
  padding-inline-start: var(--pf-v5-c-login__footer--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__footer--PaddingRight);
}

.pf-v5-c-login__footer .pf-v5-c-list a {
  color: unset;
}

.pf-v5-c-login__footer .pf-v5-c-list:not(:only-child) {
  padding-block-start: var(--pf-v5-c-login__footer--c-list--PaddingTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button, :where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-login {
  --pf-v5-c-login__main--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-login__header, :where(.pf-v5-theme-dark) .pf-v5-c-login__footer {
  color: var(--pf-v5-global--Color--100);
}

.pf-v5-c-background-image {
  --pf-v5-c-background-image--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-background-image--BackgroundImage: none;
  --pf-v5-c-background-image--BackgroundSize--min-width: 200px;
  --pf-v5-c-background-image--BackgroundSize--width: 60%;
  --pf-v5-c-background-image--BackgroundSize--max-width: 600px;
  --pf-v5-c-background-image--BackgroundSize: clamp(var(--pf-v5-c-background-image--BackgroundSize--min-width), var(--pf-v5-c-background-image--BackgroundSize--width), var(--pf-v5-c-background-image--BackgroundSize--max-width));
  --pf-v5-c-background-image--BackgroundPosition: bottom right;
  z-index: -1;
  background-color: var(--pf-v5-c-background-image--BackgroundColor);
  background-image: var(--pf-v5-c-background-image--BackgroundImage);
  background-repeat: no-repeat;
  background-position: var(--pf-v5-c-background-image--BackgroundPosition);
  background-size: var(--pf-v5-c-background-image--BackgroundSize);
  width: 100%;
  height: 100%;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-background-image {
  --pf-v5-c-background-image--BackgroundPosition: bottom left;
}

.pf-v5-c-brand {
  --pf-v5-c-brand--Width: auto;
  --pf-v5-c-brand--Height: auto;
  width: var(--pf-v5-c-brand--Width--base);
  height: var(--pf-v5-c-brand--Height--base);
  --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width);
  --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height);
}

@media (width >= 576px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width-on-sm, var(--pf-v5-c-brand--Width));
  }
}

@media (width >= 768px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width-on-md, var(--pf-v5-c-brand--Width-on-sm, var(--pf-v5-c-brand--Width)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width-on-lg, var(--pf-v5-c-brand--Width-on-md, var(--pf-v5-c-brand--Width-on-sm, var(--pf-v5-c-brand--Width))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width-on-xl, var(--pf-v5-c-brand--Width-on-lg, var(--pf-v5-c-brand--Width-on-md, var(--pf-v5-c-brand--Width-on-sm, var(--pf-v5-c-brand--Width)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width-on-2xl, var(--pf-v5-c-brand--Width-on-xl, var(--pf-v5-c-brand--Width-on-lg, var(--pf-v5-c-brand--Width-on-md, var(--pf-v5-c-brand--Width-on-sm, var(--pf-v5-c-brand--Width))))));
  }
}

@media (width >= 576px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height-on-sm, var(--pf-v5-c-brand--Height));
  }
}

@media (width >= 768px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height-on-md, var(--pf-v5-c-brand--Height-on-sm, var(--pf-v5-c-brand--Height)));
  }
}

@media (width >= 992px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height-on-lg, var(--pf-v5-c-brand--Height-on-md, var(--pf-v5-c-brand--Height-on-sm, var(--pf-v5-c-brand--Height))));
  }
}

@media (width >= 1200px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height-on-xl, var(--pf-v5-c-brand--Height-on-lg, var(--pf-v5-c-brand--Height-on-md, var(--pf-v5-c-brand--Height-on-sm, var(--pf-v5-c-brand--Height)))));
  }
}

@media (width >= 1450px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height-on-2xl, var(--pf-v5-c-brand--Height-on-xl, var(--pf-v5-c-brand--Height-on-lg, var(--pf-v5-c-brand--Height-on-md, var(--pf-v5-c-brand--Height-on-sm, var(--pf-v5-c-brand--Height))))));
  }
}

.pf-v5-c-brand.pf-m-picture {
  max-width: 100%;
  display: inline-flex;
}

.pf-v5-c-simple-list {
  --pf-v5-c-simple-list__item-link--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-simple-list__item-link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__item-link--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-simple-list__item-link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__item-link--m-current--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-simple-list__item-link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--m-current--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__item-link--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--focus--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__item-link--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--active--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__title--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-simple-list__title--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__title--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-simple-list__title--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__title--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-simple-list__title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-simple-list__title--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-simple-list__section--section--MarginTop: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-simple-list__item-link {
  font-size: var(--pf-v5-c-simple-list__item-link--FontSize);
  font-weight: var(--pf-v5-c-simple-list__item-link--FontWeight);
  color: var(--pf-v5-c-simple-list__item-link--Color);
  text-align: start;
  background-color: var(--pf-v5-c-simple-list__item-link--BackgroundColor);
  border: none;
  width: 100%;
  padding-block-start: var(--pf-v5-c-simple-list__item-link--PaddingTop);
  padding-block-end: var(--pf-v5-c-simple-list__item-link--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-simple-list__item-link--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-simple-list__item-link--PaddingRight);
  display: block;
}

.pf-v5-c-simple-list__item-link:hover {
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--hover--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--hover--Color);
  text-decoration: none;
}

.pf-v5-c-simple-list__item-link:focus {
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-c-simple-list__item-link--focus--FontWeight);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--focus--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--focus--Color);
}

.pf-v5-c-simple-list__item-link:active {
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-c-simple-list__item-link--active--FontWeight);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--active--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--active--Color);
}

.pf-v5-c-simple-list__item-link.pf-m-current {
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-c-simple-list__item-link--m-current--FontWeight);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--m-current--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--m-current--Color);
}

.pf-v5-c-simple-list__title {
  font-size: var(--pf-v5-c-simple-list__title--FontSize);
  font-weight: var(--pf-v5-c-simple-list__title--FontWeight);
  color: var(--pf-v5-c-simple-list__title--Color);
  padding-block-start: var(--pf-v5-c-simple-list__title--PaddingTop);
  padding-block-end: var(--pf-v5-c-simple-list__title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-simple-list__title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-simple-list__title--PaddingRight);
}

.pf-v5-c-simple-list__section + .pf-v5-c-simple-list__section {
  margin-block-start: var(--pf-v5-c-simple-list__section--section--MarginTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-simple-list {
  --pf-v5-c-simple-list__item-link--m-current--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-simple-list__item-link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-simple-list__item-link--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-simple-list__item-link--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-simple-list__item-link--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}

.pf-v5-c-content {
  --pf-v5-c-content--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-content--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-content--heading--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-content--h1--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h1--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h1--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-content--h1--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-content--h1--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--h2--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h2--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h2--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--h2--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-content--h2--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--h3--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h3--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h3--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--h3--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-content--h3--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--h4--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h4--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h4--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--h4--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-content--h4--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--h5--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h5--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h5--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--h5--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-content--h5--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--h6--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h6--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h6--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--h6--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-content--h6--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--small--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--small--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--small--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-content--small--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-content--a--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-content--a--TextDecoration: var(--pf-v5-global--link--TextDecoration);
  --pf-v5-c-content--a--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-content--a--hover--TextDecoration: var(--pf-v5-global--link--TextDecoration--hover);
  --pf-v5-c-content--a--visited--Color: var(--pf-v5-global--link--Color--visited);
  --pf-v5-c-content--blockquote--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--blockquote--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--blockquote--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--blockquote--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--blockquote--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-content--blockquote--BorderLeftColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-content--blockquote--BorderLeftWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-content--ol--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--ol--MarginLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--ol--nested--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--ol--nested--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--ul--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--ul--MarginLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--ul--nested--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--ul--nested--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--ul--ListStyle: var(--pf-v5-global--ListStyle);
  --pf-v5-c-content--li--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--dl--ColumnGap: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-content--dl--RowGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--dt--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-content--dt--MarginTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--dt--sm--MarginTop: 0;
  --pf-v5-c-content--hr--Height: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-content--hr--BackgroundColor: var(--pf-v5-global--BorderColor--100);
  font-size: var(--pf-v5-c-content--FontSize);
  line-height: var(--pf-v5-c-content--LineHeight);
  color: var(--pf-v5-c-content--Color);
}

.pf-v5-c-content a {
  color: var(--pf-v5-c-content--a--Color);
  -webkit-text-decoration: var(--pf-v5-c-content--a--TextDecoration);
  text-decoration: var(--pf-v5-c-content--a--TextDecoration);
}

.pf-v5-c-content a:hover {
  --pf-v5-c-content--a--Color: var(--pf-v5-c-content--a--hover--Color);
  --pf-v5-c-content--a--TextDecoration: var(--pf-v5-c-content--a--hover--TextDecoration);
}

.pf-v5-c-content.pf-m-visited a:visited, .pf-v5-c-content a.pf-m-visited:visited {
  color: var(--pf-v5-c-content--a--visited--Color);
}

.pf-v5-c-content li + li {
  margin-block-start: var(--pf-v5-c-content--li--MarginTop);
}

.pf-v5-c-content p:not(:last-child), .pf-v5-c-content dl:not(:last-child), .pf-v5-c-content ol:not(:last-child), .pf-v5-c-content ul:not(:last-child), .pf-v5-c-content blockquote:not(:last-child), .pf-v5-c-content small:not(:last-child), .pf-v5-c-content pre:not(:last-child), .pf-v5-c-content table:not(:last-child), .pf-v5-c-content hr:not(:last-child) {
  margin-block-end: var(--pf-v5-c-content--MarginBottom);
}

.pf-v5-c-content h1, .pf-v5-c-content h2, .pf-v5-c-content h3, .pf-v5-c-content h4, .pf-v5-c-content h5, .pf-v5-c-content h6 {
  font-family: var(--pf-v5-c-content--heading--FontFamily);
  margin: 0;
}

.pf-v5-c-content h1:first-child, .pf-v5-c-content h2:first-child, .pf-v5-c-content h3:first-child, .pf-v5-c-content h4:first-child, .pf-v5-c-content h5:first-child, .pf-v5-c-content h6:first-child {
  margin-block-start: 0;
}

.pf-v5-c-content h1:last-child, .pf-v5-c-content h2:last-child, .pf-v5-c-content h3:last-child, .pf-v5-c-content h4:last-child, .pf-v5-c-content h5:last-child, .pf-v5-c-content h6:last-child {
  margin-block-end: 0;
}

.pf-v5-c-content ol, .pf-v5-c-content ul {
  margin: 0;
}

.pf-v5-c-content ol.pf-m-plain, .pf-v5-c-content ul.pf-m-plain {
  margin-inline-start: 0;
  padding-inline-start: 0;
  list-style: none;
}

.pf-v5-c-content h1 {
  font-size: var(--pf-v5-c-content--h1--FontSize);
  font-weight: var(--pf-v5-c-content--h1--FontWeight);
  line-height: var(--pf-v5-c-content--h1--LineHeight);
  margin-block-start: var(--pf-v5-c-content--h1--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h1--MarginBottom);
}

.pf-v5-c-content h2 {
  font-size: var(--pf-v5-c-content--h2--FontSize);
  font-weight: var(--pf-v5-c-content--h2--FontWeight);
  line-height: var(--pf-v5-c-content--h2--LineHeight);
  margin-block-start: var(--pf-v5-c-content--h2--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h2--MarginBottom);
}

.pf-v5-c-content h3 {
  font-size: var(--pf-v5-c-content--h3--FontSize);
  font-weight: var(--pf-v5-c-content--h3--FontWeight);
  line-height: var(--pf-v5-c-content--h3--LineHeight);
  margin-block-start: var(--pf-v5-c-content--h3--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h3--MarginBottom);
}

.pf-v5-c-content h4 {
  font-size: var(--pf-v5-c-content--h4--FontSize);
  font-weight: var(--pf-v5-c-content--h4--FontWeight);
  line-height: var(--pf-v5-c-content--h4--LineHeight);
  margin-block-start: var(--pf-v5-c-content--h4--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h4--MarginBottom);
}

.pf-v5-c-content h5 {
  font-size: var(--pf-v5-c-content--h5--FontSize);
  font-weight: var(--pf-v5-c-content--h5--FontWeight);
  line-height: var(--pf-v5-c-content--h5--LineHeight);
  margin-block-start: var(--pf-v5-c-content--h5--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h5--MarginBottom);
}

.pf-v5-c-content h6 {
  font-size: var(--pf-v5-c-content--h6--FontSize);
  font-weight: var(--pf-v5-c-content--h6--FontWeight);
  line-height: var(--pf-v5-c-content--h6--LineHeight);
  margin-block-start: var(--pf-v5-c-content--h6--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h6--MarginBottom);
}

.pf-v5-c-content small {
  font-size: var(--pf-v5-c-content--small--FontSize);
  line-height: var(--pf-v5-c-content--small--LineHeight);
  color: var(--pf-v5-c-content--small--Color);
  display: block;
}

.pf-v5-c-content small:not(:last-child) {
  margin-block-end: var(--pf-v5-c-content--small--MarginBottom);
}

.pf-v5-c-content blockquote {
  color: var(--pf-v5-c-content--blockquote--Color);
  border-inline-start: var(--pf-v5-c-content--blockquote--BorderLeftWidth) solid var(--pf-v5-c-content--blockquote--BorderLeftColor);
  padding-block-start: var(--pf-v5-c-content--blockquote--PaddingTop);
  padding-block-end: var(--pf-v5-c-content--blockquote--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-content--blockquote--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-content--blockquote--PaddingRight);
}

.pf-v5-c-content hr {
  height: var(--pf-v5-c-content--hr--Height);
  background-color: var(--pf-v5-c-content--hr--BackgroundColor);
  border: none;
}

.pf-v5-c-content ol {
  margin-inline-start: var(--pf-v5-c-content--ol--MarginLeft);
  padding-inline-start: var(--pf-v5-c-content--ol--PaddingLeft);
}

.pf-v5-c-content ol ul {
  --pf-v5-c-content--ul--MarginLeft: var(--pf-v5-c-content--ul--nested--MarginLeft);
  margin-block-start: var(--pf-v5-c-content--ul--nested--MarginTop);
}

.pf-v5-c-content ol ol {
  --pf-v5-c-content--ol--MarginLeft: var(--pf-v5-c-content--ol--nested--MarginLeft);
  margin-block-start: var(--pf-v5-c-content--ol--nested--MarginTop);
}

.pf-v5-c-content ul {
  list-style: var(--pf-v5-c-content--ul--ListStyle);
  margin-inline-start: var(--pf-v5-c-content--ul--MarginLeft);
  padding-inline-start: var(--pf-v5-c-content--ul--PaddingLeft);
}

.pf-v5-c-content ul ul {
  --pf-v5-c-content--ul--MarginLeft: var(--pf-v5-c-content--ul--nested--MarginLeft);
  margin-block-start: var(--pf-v5-c-content--ul--nested--MarginTop);
}

.pf-v5-c-content ul ol {
  --pf-v5-c-content--ol--MarginLeft: var(--pf-v5-c-content--ol--nested--MarginLeft);
  margin-block-start: var(--pf-v5-c-content--ol--nested--MarginTop);
}

.pf-v5-c-content dl {
  grid-template-columns: 1fr;
  display: grid;
}

@media screen and (width >= 576px) {
  .pf-v5-c-content dl {
    grid-row-gap: var(--pf-v5-c-content--dl--RowGap);
    grid-column-gap: var(--pf-v5-c-content--dl--ColumnGap);
    grid-template: auto / auto 1fr;
  }
}

.pf-v5-c-content dt {
  font-weight: var(--pf-v5-c-content--dt--FontWeight);
}

.pf-v5-c-content dt:not(:first-child) {
  margin-block-start: var(--pf-v5-c-content--dt--MarginTop);
}

@media screen and (width >= 576px) {
  .pf-v5-c-content dt:not(:first-child) {
    --pf-v5-c-content--dt--MarginTop: var(--pf-v5-c-content--dt--sm--MarginTop);
  }

  .pf-v5-c-content dt {
    grid-column: 1;
  }

  .pf-v5-c-content dd {
    grid-column: 2;
  }
}

.pf-v5-c-clipboard-copy {
  --pf-v5-c-clipboard-copy__toggle-icon--Transition: .2s ease-in 0s;
  --pf-v5-c-clipboard-copy--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-clipboard-copy__expandable-content--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-clipboard-copy__expandable-content--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-clipboard-copy__expandable-content--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-clipboard-copy__expandable-content--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-clipboard-copy__expandable-content--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-clipboard-copy__expandable-content--BorderTopWidth: 0;
  --pf-v5-c-clipboard-copy__expandable-content--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-clipboard-copy__expandable-content--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-clipboard-copy__expandable-content--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-clipboard-copy__expandable-content--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-clipboard-copy__expandable-content--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-clipboard-copy--m-inline--PaddingTop: 0;
  --pf-v5-c-clipboard-copy--m-inline--PaddingBottom: 0;
  --pf-v5-c-clipboard-copy--m-inline--PaddingLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-clipboard-copy--m-inline--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-clipboard-copy__text--m-code--FontFamily: var(--pf-v5-global--FontFamily--monospace);
  --pf-v5-c-clipboard-copy__text--m-code--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-clipboard-copy__actions-item--MarginTop: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-clipboard-copy__actions-item--MarginBottom: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-clipboard-copy__actions-item--button--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-clipboard-copy__actions-item--button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-clipboard-copy__actions-item--button--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-clipboard-copy__actions-item--button--PaddingLeft: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-clipboard-copy.pf-m-expanded .pf-v5-c-clipboard-copy__toggle-icon {
  transform: rotate(var(--pf-v5-c-clipboard-copy--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-clipboard-copy.pf-m-inline {
  white-space: nowrap;
  background-color: var(--pf-v5-c-clipboard-copy--m-inline--BackgroundColor);
  padding-block-start: var(--pf-v5-c-clipboard-copy--m-inline--PaddingTop);
  padding-block-end: var(--pf-v5-c-clipboard-copy--m-inline--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-clipboard-copy--m-inline--PaddingLeft);
  display: inline;
}

.pf-v5-c-clipboard-copy.pf-m-inline.pf-m-block {
  display: block;
}

.pf-v5-c-clipboard-copy__group {
  display: flex;
}

.pf-v5-c-clipboard-copy__group > * + * {
  margin-inline-start: -1px;
}

.pf-v5-c-clipboard-copy__toggle-icon {
  transition: var(--pf-v5-c-clipboard-copy__toggle-icon--Transition);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-clipboard-copy__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-clipboard-copy__expandable-content {
  word-wrap: break-word;
  background-color: var(--pf-v5-c-clipboard-copy__expandable-content--BackgroundColor);
  border: solid var(--pf-v5-c-clipboard-copy__expandable-content--BorderColor);
  border-block-start-width: var(--pf-v5-c-clipboard-copy__expandable-content--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-clipboard-copy__expandable-content--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-clipboard-copy__expandable-content--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-clipboard-copy__expandable-content--BorderRightWidth);
  box-shadow: var(--pf-v5-c-clipboard-copy__expandable-content--BoxShadow);
  background-clip: padding-box;
  padding-block-start: var(--pf-v5-c-clipboard-copy__expandable-content--PaddingTop);
  padding-block-end: var(--pf-v5-c-clipboard-copy__expandable-content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-clipboard-copy__expandable-content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-clipboard-copy__expandable-content--PaddingRight);
}

.pf-v5-c-clipboard-copy__expandable-content pre {
  white-space: pre-wrap;
}

.pf-v5-c-clipboard-copy__text {
  word-break: break-word;
  white-space: normal;
}

.pf-v5-c-clipboard-copy__text.pf-m-code {
  font-family: var(--pf-v5-c-clipboard-copy__text--m-code--FontFamily);
  font-size: var(--pf-v5-c-clipboard-copy__text--m-code--FontSize);
}

.pf-v5-c-clipboard-copy__actions {
  display: inline-flex;
}

.pf-v5-c-clipboard-copy__actions-item {
  margin-block-start: calc(-1 * var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingTop));
  margin-block-end: calc(-1 * var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingBottom));
}

.pf-v5-c-clipboard-copy__actions-item .pf-v5-c-button {
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingTop);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingRight);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingBottom);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingLeft);
}

:where(.pf-v5-theme-dark) .pf-v5-c-clipboard-copy {
  --pf-v5-c-clipboard-copy__expandable-content--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}

:where(.pf-v5-theme-dark) .pf-v5-c-clipboard-copy__group > * + * {
  margin-inline-start: 0;
  border-inline-start: 1px solid var(--pf-v5-global--palette--black-700) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-c-clipboard-copy__expandable-content {
  border: 0;
}

.Qm2kOa_smalltable {
  --pf-c-table--m-compact--cell--PaddingTop: .15rem;
  --pf-c-table--m-compact--cell--PaddingBottom: .15rem;
}

.Qm2kOa_tinytable, .Qm2kOa_tinytable th, .Qm2kOa_tinytable td {
  --pf-c-table--m-compact--cell--PaddingTop: .15rem;
  --pf-c-table--m-compact--cell--PaddingBottom: .15rem;
  --pf-c-table--m-compact--cell--PaddingLeft: .15rem;
  --pf-c-table--m-compact--cell--PaddingRight: .15rem;
  --pf-c-table--m-compact--FontSize: .8rem;
  border: 1px solid #000;
}

.Qm2kOa_smallpadding {
  --pf-c-button--PaddingTop: .05rem;
  --pf-c-button--PaddingBottom: .05rem;
  --pf-c-button--PaddingLeft: .4rem;
  --pf-c-button--PaddingRight: .4rem;
}

.pf-v5-c-back-to-top {
  --pf-v5-c-back-to-top--Right: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-back-to-top--Bottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-back-to-top--md--Bottom: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-back-to-top--c-button--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-back-to-top--c-button--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-back-to-top--c-button--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-back-to-top--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-back-to-top--c-button--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-back-to-top--c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-back-to-top--c-button--BoxShadow: var(--pf-v5-global--BoxShadow--lg-bottom);
  position: absolute;
  inset-block-end: var(--pf-v5-c-back-to-top--Bottom);
  inset-inline-end: var(--pf-v5-c-back-to-top--Right);
}

@media (width >= 768px) {
  .pf-v5-c-back-to-top {
    --pf-v5-c-back-to-top--Bottom: var(--pf-v5-c-back-to-top--md--Bottom);
  }
}

.pf-v5-c-back-to-top.pf-m-hidden {
  display: none;
}

.pf-v5-c-back-to-top .pf-v5-c-button {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-back-to-top--c-button--FontSize);
  --pf-v5-c-button--BorderRadius: var(--pf-v5-c-back-to-top--c-button--BorderRadius);
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-back-to-top--c-button--PaddingTop);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-back-to-top--c-button--PaddingRight);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-back-to-top--c-button--PaddingBottom);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-back-to-top--c-button--PaddingLeft);
  box-shadow: var(--pf-v5-c-back-to-top--c-button--BoxShadow);
}

.pf-v5-l-split {
  --pf-v5-l-stack--m-gutter--Gap: var(--pf-v5-global--gutter);
  margin: 0;
  padding: 0;
  display: flex;
}

.pf-v5-l-split.pf-m-wrap {
  flex-wrap: wrap;
}

.pf-v5-l-split__item.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-l-split.pf-m-gutter {
  gap: var(--pf-v5-l-stack--m-gutter--Gap);
}
/*# sourceMappingURL=index.395b71e3.css.map */
