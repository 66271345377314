.pf-v5-c-avatar {
  --pf-v5-c-avatar--BorderColor: transparent;
  --pf-v5-c-avatar--BorderWidth: 0;
  --pf-v5-c-avatar--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-avatar--Width: 2.25rem;
  --pf-v5-c-avatar--Height: 2.25rem;
  --pf-v5-c-avatar--m-sm--Width: 1.5rem;
  --pf-v5-c-avatar--m-sm--Height: 1.5rem;
  --pf-v5-c-avatar--m-md--Width: 2.25rem;
  --pf-v5-c-avatar--m-md--Height: 2.25rem;
  --pf-v5-c-avatar--m-lg--Width: 4.5rem;
  --pf-v5-c-avatar--m-lg--Height: 4.5rem;
  --pf-v5-c-avatar--m-xl--Width: 8rem;
  --pf-v5-c-avatar--m-xl--Height: 8rem;
  --pf-v5-c-avatar--m-light--BorderColor: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-c-avatar--m-light--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-avatar--m-dark--BorderColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-avatar--m-dark--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  width: var(--pf-v5-c-avatar--Width);
  height: var(--pf-v5-c-avatar--Height);
  border: var(--pf-v5-c-avatar--BorderWidth) solid var(--pf-v5-c-avatar--BorderColor);
  border-radius: var(--pf-v5-c-avatar--BorderRadius);
}
.pf-v5-c-avatar.pf-m-light {
  --pf-v5-c-avatar--BorderColor: var(--pf-v5-c-avatar--m-light--BorderColor);
  --pf-v5-c-avatar--BorderWidth: var(--pf-v5-c-avatar--m-light--BorderWidth);
}
.pf-v5-c-avatar.pf-m-dark {
  --pf-v5-c-avatar--BorderColor: var(--pf-v5-c-avatar--m-dark--BorderColor);
  --pf-v5-c-avatar--BorderWidth: var(--pf-v5-c-avatar--m-dark--BorderWidth);
}
.pf-v5-c-avatar.pf-m-sm {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height);
}
@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width));
  }
}
@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width)));
  }
}
@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-lg, var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-xl, var(--pf-v5-c-avatar--m-sm--Width-on-lg, var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-2xl, var(--pf-v5-c-avatar--m-sm--Width-on-xl, var(--pf-v5-c-avatar--m-sm--Width-on-lg, var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width))))));
  }
}
@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height));
  }
}
@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height)));
  }
}
@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-lg, var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-xl, var(--pf-v5-c-avatar--m-sm--Height-on-lg, var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-2xl, var(--pf-v5-c-avatar--m-sm--Height-on-xl, var(--pf-v5-c-avatar--m-sm--Height-on-lg, var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height))))));
  }
}
.pf-v5-c-avatar.pf-m-md {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height);
}
@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width));
  }
}
@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width)));
  }
}
@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-lg, var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-xl, var(--pf-v5-c-avatar--m-md--Width-on-lg, var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-2xl, var(--pf-v5-c-avatar--m-md--Width-on-xl, var(--pf-v5-c-avatar--m-md--Width-on-lg, var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width))))));
  }
}
@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height));
  }
}
@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height)));
  }
}
@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-lg, var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-xl, var(--pf-v5-c-avatar--m-md--Height-on-lg, var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-2xl, var(--pf-v5-c-avatar--m-md--Height-on-xl, var(--pf-v5-c-avatar--m-md--Height-on-lg, var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height))))));
  }
}
.pf-v5-c-avatar.pf-m-lg {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height);
}
@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width));
  }
}
@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width)));
  }
}
@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-lg, var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-xl, var(--pf-v5-c-avatar--m-lg--Width-on-lg, var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-2xl, var(--pf-v5-c-avatar--m-lg--Width-on-xl, var(--pf-v5-c-avatar--m-lg--Width-on-lg, var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width))))));
  }
}
@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height));
  }
}
@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height)));
  }
}
@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-lg, var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-xl, var(--pf-v5-c-avatar--m-lg--Height-on-lg, var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-2xl, var(--pf-v5-c-avatar--m-lg--Height-on-xl, var(--pf-v5-c-avatar--m-lg--Height-on-lg, var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height))))));
  }
}
.pf-v5-c-avatar.pf-m-xl {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height);
}
@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width));
  }
}
@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width)));
  }
}
@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-lg, var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-xl, var(--pf-v5-c-avatar--m-xl--Width-on-lg, var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-2xl, var(--pf-v5-c-avatar--m-xl--Width-on-xl, var(--pf-v5-c-avatar--m-xl--Width-on-lg, var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width))))));
  }
}
@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height));
  }
}
@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height)));
  }
}
@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-lg, var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-xl, var(--pf-v5-c-avatar--m-xl--Height-on-lg, var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-2xl, var(--pf-v5-c-avatar--m-xl--Height-on-xl, var(--pf-v5-c-avatar--m-xl--Height-on-lg, var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height))))));
  }
}